import { StoreSelectorValue } from 'components/StoreSelector/interface';
import {
  atom,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { getTalkInfoWithPhone } from 'services/api/conversation.convList';
import { StoreInfo } from 'services/api/store';
import i18next from 'i18next';
import lastUsedConversation from 'utils/lastUsedConversation';
import { lastUsedStoreIdCache, lastUsedExternalStoreIdCache } from 'utils/lastUsedStore';
import { isSbsMode } from 'hooks/business/useIsSbs';
import { starUIFilterArr } from 'views/Conversation/ConvList/starData';
import type { AuthorizationHookApi } from 'components/Authorization';
import { pickBy } from 'lodash-es';
import getNimCacheId from './im/getNimCacheId';

type Conversation = Conversation.Conversation;
type Message = Conversation.Message;

// #region 接待中的单个会话id

/** 接待中的单个会话id */
export const currentConversationIdState = atom<Conversation['conversationId'] | null>({
  key: 'currentConversationId',
  default: null,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((next) => {
        lastUsedConversation.push(String(next));
      });
    },
  ],
});

/** 外部获取`接待中的单个会话id` */
export const useCurrentConversationIdValue = () => {
  return useRecoilValue(currentConversationIdState);
};

/** 外部设置`接待中的单个会话id` */
export const useSetCurrentConversationIdValue = () => {
  return useSetRecoilState(currentConversationIdState);
};
// #endregion

// #region 当前应用已获取的全部会话信息

/** 当前应用已获取的全部会话信息 */
export const conversationMapState = atom<Record<Conversation['conversationId'], Conversation>>({
  key: 'conversationMap',
  default: {},
});
/** 外部获取`当前应用已获取的全部会话信息` */
export const useConversationMapValue = () => {
  return useRecoilValue(conversationMapState);
};
/** 外部设置`当前应用已获取的全部会话信息` */
export const useSetConversationMapValue = () => {
  return useSetRecoilState(conversationMapState);
};

export const conversationIdListState = atom<Conversation['conversationId'][]>({
  key: 'conversationIdListState',
  default: [],
});

export const useConversationIdListValue = () => {
  return useRecoilValue(conversationIdListState);
};

export const useSetConversationIdListValue = () => {
  return useSetRecoilState(conversationIdListState);
};

/** 当前已加载的会话列表是否有未读会话 */
export const hasUnreadConversation = selector({
  key: 'hasUnreadConversation',
  get: ({ get }) => {
    const conversationMap = get(conversationMapState);

    const conversationList = Object.values(conversationMap);

    return conversationList.some(
      ({ unreadCount, supervisePower, controlStatus }) => controlStatus && unreadCount,
    );
  },
});
/** 外部获取当前已加载的会话列表是否有未读会话 */
export const useHasUnreadConversation = () => {
  return useRecoilValue(hasUnreadConversation);
};

/** 同步远端未回复人数的时间点 */
export const unreplyCustormerTimeState = atom({
  key: 'unreplyCustormerTimeState',
  default: 0,
});
/** 更新`同步远端未回复人数的时间点` */
export const useSetUnreplyCustormerTimeValue = () => {
  return useSetRecoilState(unreplyCustormerTimeState);
};

/** `当前应用已获取的全部会话信息`的未回复人数总和 */
export const conversationUnreplyCustomer = atom({
  key: 'conversationUnreplyCustomer',
  default: 0,
});
/** 外部获取`当前应用已获取的全部会话信息`的未回复人数总和 */
export const useConversationUnreplyCustomer = () => {
  return useRecoilValue(conversationUnreplyCustomer);
};
/** 外部获取`当前应用已获取的全部会话信息`的未回复人数总和 */
export const useSetConversationUnreplyCustomer = () => {
  return useSetRecoilState(conversationUnreplyCustomer);
};

/** 外部设置`当前应用已获取的全部会话信息`的未回复人数总和 */
export const useConversationUnreplyCustomerCb = () => {
  const setConversationUnreplyCustomer = useSetConversationUnreplyCustomer();
  return useRecoilCallback(
    ({ snapshot }) =>
      async (conv: Conversation.Conversation) => {
        const unreplyCustormerTime = await snapshot.getPromise(unreplyCustormerTimeState);
        if (parseInt(conv.lastMsgTime, 10) < unreplyCustormerTime) {
          return;
        }

        const msgMap = await snapshot.getPromise(messageMapState);
        const msgList = Object.values(msgMap)
          .filter((msg) => msg.conversationId === conv.conversationId)
          .sort((a, b) => parseInt(`${b.sendTime}`, 10) - parseInt(`${a.sendTime}`, 10));

        const assistant = ['ASSISTANT', 'BOT'];

        if (conv.lastMsgFromType === 'BUYER') {
          if (msgList.length > 1) {
            if (assistant.includes(msgList[1].msgFromType)) {
              setConversationUnreplyCustomer((v) => v + 1);
            }
          }
        } else if (assistant.includes(conv.lastMsgFromType)) {
          if (msgList.length > 1) {
            if (msgList[1].msgFromType === 'BUYER') {
              setConversationUnreplyCustomer((v) => v - 1);
            }
          }
        }
      },
    [setConversationUnreplyCustomer],
  );
};

/**
 * 客服从退货退款/取消订单创建的会话，如果没有发送成功的消息之前，在shopee平台那边都不存在。
 */

export const fakeConversationState = atom<Record<Conversation.ConversationId, { orderSn: string }>>(
  {
    default: {},
    key: 'fakeConversationState',
  },
);

export const useFakeConversationState = () => useRecoilValue(fakeConversationState);

export const useSetFakeConversation = () => {
  const remove = useRecoilCallback(
    ({ snapshot, set }) =>
      async (conversationId: Conversation.ConversationId) => {
        const map = await snapshot.getPromise(fakeConversationState);
        const { [conversationId]: cur, ...rest } = map;
        set(fakeConversationState, rest);
      },
    [],
  );
  const add = useRecoilCallback(
    ({ set }) =>
      async (conversationId: Conversation.ConversationId, data: { orderSn: string }) => {
        set(fakeConversationState, (map) => {
          return {
            ...map,
            [conversationId]: data,
          };
        });
      },
    [],
  );

  return {
    remove,
    add,
  };
};

/** 已排序的`当前应用已获取的全部会话信息`数组, 按最新消息降序排列 */
export const conversationListState = selector({
  key: 'conversationListState',
  get: ({ get }) => {
    const conversationMap = get(conversationMapState);
    const isSbs = get(isSbsMode);
    const convList = Object.values(conversationMap);

    if (isSbs) {
      return convList.sort((a, b) => a.sbsIndex! - b.sbsIndex!);
    }

    return convList.sort((a, b) => {
      const isARemind = a.remindMark && (a.controlStatus || a.supervisePower);
      const isBRemind = b.remindMark && (b.controlStatus || b.supervisePower);

      if (isBRemind && !isARemind) {
        return 1;
      }

      if (!isBRemind && isARemind) {
        return -1;
      }

      return parseInt(b.lastMsgTime, 10) - parseInt(a.lastMsgTime, 10);
    });
  },
});
/** 外部获取已排序的`当前应用已获取的全部会话信息`数组 */
export const useConversationList = () => {
  return useRecoilValue(conversationListState);
};

/** 2分钟间隔需要同步消息的 talkId 列表 */
export const syncTalkIds = selector({
  key: 'syncTalkIds',
  get: ({ get }) => {
    const conversationMap = get(conversationMapState);
    const convList = Object.values(conversationMap).sort((a, b) => {
      return parseInt(b.lastMsgTime, 10) - parseInt(a.lastMsgTime, 10);
    });
    const talkIds: string[] = [];
    const d = Date.now();
    convList.some((v) => {
      if (d - parseInt(v.lastMsgTime, 10) < 6 * 3600 * 1000) {
        if (v.channel === 'SHOPEE') {
          talkIds.push(v.talkId);
        }
        return false;
      }
      return true;
    });
    return talkIds;
  },
});
/** 外部获取 `2分钟间隔需要同步消息的 talkId 列表` */
export const useSyncTalkIds = () => {
  return useRecoilValue(syncTalkIds);
};

/** 会话列表的分页 */
export const convListOffset = atom<string>({
  key: 'convListOffset',
  default: '0',
});
/** 外部获取`会话列表的分页` */
export const useConvListOffset = () => {
  return useRecoilValue(convListOffset);
};
/** 外部设置`会话列表的分页` */
export const useSetConvListOffset = () => {
  return useSetRecoilState(convListOffset);
};

/**
 * conversation filterbar 店铺过滤
 */
const conversationStoreFilterState = atom<StoreSelectorValue>({
  key: 'conversationStoreFilterState',
  default: {},
});

export const useConversationStoreFilterState = () => {
  return useRecoilValue(conversationStoreFilterState);
};
export const useSetConversationStoreFilterState = (
  auth: AuthorizationHookApi<Oversea.Token.Payload>,
) => {
  return useRecoilCallback(
    ({ set }) =>
      (v: StoreSelectorValue) => {
        localStorage.setItem(
          `conv_conversationStoreFilterState_${auth?.payload?.id}`,
          JSON.stringify(v),
        );
        set(conversationStoreFilterState, v);
      },
    [auth?.payload?.id],
  );
};

const followedStoreListState = atom<StoreInfo[]>({
  key: 'followedStoreIdListState',
  default: [],
});

export const useFollowedStoreListState = () => {
  return useRecoilValue(followedStoreListState);
};

export const useSetFollowedStoreListState = () => {
  return useSetRecoilState(followedStoreListState);
};

/** 当前选中的过滤器 星标,未读,全部 */
export const selectedFilterState = atom<Conversation.Filter>({
  key: 'selectedFilterState',
  default: 'all',
});
/** 外部获取`当前选中的过滤器` */
export const useSelectedFilter = () => {
  return useRecoilValue(selectedFilterState);
};
/** 外部设置`当前选中的过滤器` */
export const useSetSelectedFilter = (auth: AuthorizationHookApi<Oversea.Token.Payload>) => {
  return useRecoilCallback(
    ({ set }) =>
      (v: Conversation.Filter) => {
        localStorage.setItem(`conv_selectedFilterState_${auth?.payload?.id}`, v);
        set(selectedFilterState, v);
      },
    [auth?.payload?.id],
  );
};

/** 已排序且过滤的`当前应用已获取的全部会话信息`数组, 按最新消息降序排列 */
export const conversationListFilterState = selector({
  key: 'conversationListFilterState',
  get: ({ get }) => {
    let conversationList = get(conversationListState);
    const currentConversationId = get(currentConversationIdState);
    const storeIdsFilter = get(conversationStoreFilterState);
    const followedStores = get(followedStoreListState);
    const convIdList = get(conversationIdListState);
    const filter = get(selectedFilterState);
    const followedTunnelIds = followedStores.map((x) => x.tunnelAccountId);

    if (starUIFilterArr.includes(filter as Conversation.asteriskUIFilter)) {
      conversationList = conversationList.filter(
        (v) =>
          v.colorAsterisk === filter ||
          (filter === 'ALL' && v.colorAsterisk !== 'NO_STAR') ||
          v.conversationId === currentConversationId,
      );
    } else if (filter === 'unread') {
      conversationList = conversationList.filter(
        (v) => v.unreadCount > 0 || v.conversationId === currentConversationId,
      );
    } else if (filter === 'replied') {
      conversationList = conversationList
        .filter((v) => !v.processed || v.conversationId === currentConversationId)
        .filter((x) => !x.isAffiliateSip);
    } else if (filter === 'control') {
      conversationList = conversationList.filter(
        (v) => v.controlStatus || v.conversationId === currentConversationId,
      );
    } else if (filter === 'unassigned') {
      conversationList = conversationList.filter(
        (v) => v.isWaitAssign || v.conversationId === currentConversationId,
      );
    }

    if (storeIdsFilter.platform) {
      conversationList = conversationList.filter((v) => v.channel === storeIdsFilter.platform);
    }
    if (storeIdsFilter.region) {
      conversationList = conversationList.filter((v) => v.region === storeIdsFilter.region);
    }
    if (storeIdsFilter.storeList?.length) {
      const selectedIds = storeIdsFilter.storeList.map((x) => x.tunnelAccountId);
      conversationList = conversationList.filter((v) => selectedIds.includes(v.tunnelAccountId));
    }
    if (storeIdsFilter.follower) {
      conversationList = conversationList.filter((v) =>
        followedTunnelIds.includes(v.tunnelAccountId),
      );
    }

    return conversationList
      .filter((conv) => convIdList.includes(conv.conversationId))
      .filter((conv) => !conv.mockExternal);
  },
});
/** 外部获取已排序且过滤的`当前应用已获取的全部会话信息`数组, 按最新消息降序排列 */
export const useConversationListFilter = () => {
  return useRecoilValue(conversationListFilterState);
};

/**
 * 把会话或会话数组作用到`当前应用已获取的全部会话信息`.
 * @param type : set:新增或更新; del:删除
 */
export const useMakeConversationCache = (type: 'set' | 'del') => {
  // const conversationUnreplyCustomerCb = useConversationUnreplyCustomerCb();
  return useRecoilCallback(
    ({ set }) =>
      (conversationOrList: Conversation.Conversation | Conversation.Conversation[]) => {
        const conversationList = Array.isArray(conversationOrList)
          ? conversationOrList
          : [conversationOrList];
        set(conversationMapState, (conversationMap) => {
          return conversationList.reduce(
            (res, conversation) => {
              const { conversationId: id } = conversation;
              if (type === 'set') {
                const { [id]: preConversation } = conversationMap;
                let lastMsg = preConversation;
                if (
                  !lastMsg ||
                  parseInt(conversation.lastMsgTime, 10) >=
                    parseInt(preConversation.lastMsgTime, 10) ||
                  Number(conversation.lastMsgId) >= Number(preConversation.lastMsgId)
                ) {
                  lastMsg = conversation;
                }
                const nextConversation: Conversation = {
                  ...preConversation,
                  ...conversation,
                  lastMsgContent: lastMsg.lastMsgContent,
                  lastMsgId: lastMsg.lastMsgId,
                  lastMsgTime: lastMsg.lastMsgTime,
                  lastMsgFromType: lastMsg.lastMsgFromType,
                  failCause: lastMsg.failCause,
                };

                res[id] = nextConversation;
              } else {
                delete res[id];
              }
              return res;
            },
            {
              ...conversationMap,
            },
          );
        });
      },
    [type],
  );
};

// #endregion

export const conversationFamilyState = selectorFamily<
  Conversation.Conversation | undefined,
  Conversation.Conversation['conversationId']
>({
  key: 'conversationFamily',
  set:
    (id: Conversation.Conversation['conversationId']) =>
    ({ set }, newValue) =>
      set(conversationMapState, (prev) => {
        if (newValue) {
          return { ...prev, [id]: newValue as Conversation.Conversation };
        }
        return prev;
      }),
  get:
    (id: Conversation.Conversation['conversationId']) =>
    ({ get }) => {
      const conversationMap = get(conversationMapState);
      if (id === null) {
        return undefined;
      }
      const { [id]: currentConversation } = conversationMap;
      return currentConversation;
    },
});

// #region 接待中的单个会话信息

/** 接待中的单个会话信息 */
export const currentConversationState = selector({
  key: 'currentConversationState',
  get: ({ get }) => {
    const currentConversationId = get(currentConversationIdState);
    if (currentConversationId === null) {
      return undefined;
    }
    return get(conversationFamilyState(currentConversationId));
  },
});

/** 外部获取`接待中的单个会话信息` */
export const useCurrentConversation = () => {
  return useRecoilValue(currentConversationState);
};
// #endregion

// #region 当前操作的会话

/** 当前操作的会话id */
export const opsConversationIdState = atom<string>({
  key: 'opsConversationId',
  default: '',
});
/** 外部设置`当前操作的会话id` */
export const useSetOpsConversationId = () => {
  return useSetRecoilState(opsConversationIdState);
};

/** 外部获取`当前操作的会话数据` */
export function useCbOpsConversationInfo() {
  return useRecoilCallback(
    ({ snapshot, set }) =>
      async (conversationId?: string) => {
        const res = await snapshot.getPromise(conversationMapState);
        let id = conversationId;
        if (!id) {
          id = await snapshot.getPromise(opsConversationIdState);
        }
        if (!res[id]) {
          const conv = await getTalkInfoWithPhone({ conversationId: id, needPhone: true });
          set(conversationMapState, (map) => {
            return {
              ...map,
              [conv.conversationId]: conv,
            };
          });
          return conv;
        }
        return res[id];
      },
    [],
  );
}

// #endregion

/** 会话列表使用`useVirtualList`时的`marginTop`值 */
const convListMarginTop = atom<number>({
  key: 'convListMarginTop',
  default: 0,
});
/** 设置: 会话列表使用`useVirtualList`时的`marginTop`值 */
export function useSetConvListMarginTop() {
  return useSetRecoilState(convListMarginTop);
}

/** 会话列表使用`useVirtualList`时的`marginTop`值 */
export function useConvListMarginTop() {
  return useRecoilValue(convListMarginTop);
}
/** 获取: 会话列表使用`useVirtualList`时的`marginTop`值 */
export function useGetConvListMarginTop() {
  return useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        return snapshot.getPromise(convListMarginTop);
      },
    [],
  );
}

export type LocalStatus =
  | 'uploading'
  | 'loading'
  | 'processing'
  | 'synced'
  | 'error'
  | 'ack_timeout';
export interface LocalMessage extends Message {
  /**
   * 本地寄存状态
   * - uploading: message resource uploading
   * - loading: waiting send response
   * - processing: waiting for ack
   * - synced: did ack
   * - error: any error
   */
  localStatus?: LocalStatus;
  /**
   * 图片预览地址
   */
  preview?: string;

  // 消息发送失败以后的内容，后端是直接扔到content里面的（textContent/imageContent)，本地发送失败以后需要做一大堆格式转换，这里做个处理统一放到localMessage里面。
  failCause?: Conversation.FailContent;

  // 发送失败以后可以重新发送
  resendContext?: {
    doResend: (content: LocalMessage) => Promise<boolean | LocalMessage>;
    content: LocalMessage;
  };
}
export const messageMapState = atom<Record<Message['msgId'], LocalMessage>>({
  key: 'messageMap',
  default: {},
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      onSet((next, prev) => {
        const remap = { ...next };
        const prevMap = prev as unknown as Record<Message['msgId'], LocalMessage>;
        Object.values(remap).forEach((msg) => {
          const { yunXinMsgId, msgId } = msg;
          const nimCacheId = getNimCacheId(yunXinMsgId);
          if (msg.messageType === 'FAQ_LIVEAGENT') {
            /**
             * FAQ_LIVEAGENT 按官方设定为普通买家消息
             * 展示形式与买家消息保持一致, 并支持引用
             */
            const text = i18next.t('msgType.FAQ_LIVEAGENT');
            remap[msgId] = {
              ...msg,
              textContent: {
                text,
                originalText: text,
                translationContent: {},
              },
            };
          }
          // 处理缓存中重复的message
          if (next[nimCacheId] && msgId !== nimCacheId) {
            const { textContent, imageContent, videoContent, preview } = next[nimCacheId];
            remap[msgId] = {
              ...msg,
              textContent,
              imageContent,
              videoContent,
              preview,
            };
            delete remap[nimCacheId];
            return;
          }
          if (prevMap[msgId]?.preview) {
            const { preview } = prevMap[msgId];
            if (!msg.preview && preview) {
              remap[msgId] = { ...remap[msgId], preview };
            }
          }
        });
        const nVal = Object.values(remap)
          .filter(
            (msg) =>
              lastUsedConversation.data.includes(String(msg.conversationId)) ||
              (msg.localStatus && msg.localStatus !== 'synced'),
          )
          .reduce((pre, cur) => ({ ...pre, [cur.msgId]: cur }), {});
        setSelf(nVal);
      });
    },
  ],
});

export const messageListState = selector({
  key: 'messageList',
  get: ({ get }) =>
    Object.values(get(messageMapState)).sort(
      (a, b) => parseInt(`${a.sendTime}`, 10) - parseInt(`${b.sendTime}`, 10),
    ),
});

export const currentConversationMessageListState = selector({
  key: 'currentConversationMessageList',
  get: ({ get }) => {
    const currentConversationId = get(currentConversationIdState);
    const messageList = get(messageListState);
    return messageList
      .filter((msg) => msg.conversationId === currentConversationId)
      .sort((a, b) => {
        const timeDiff = parseInt(a.sendTime as string, 10) - parseInt(b.sendTime as string, 10);

        if (timeDiff !== 0 || !(a.externalId && b.externalId)) {
          return timeDiff;
        }

        return parseInt(a.externalId, 10) - parseInt(b.externalId, 10);
      });
  },
});

export const unreadMessageCountState = atom<number>({
  key: 'unreadMessageCount',
  default: 0,
});

const getUsedStoreIdMap = (storeIdList: string[]) => {
  return storeIdList.reduce<Partial<Record<string, true>>>((res, storeId) => {
    res[storeId] = true;

    return res;
  }, {});
};

export const tradeMapState = atom<
  Record<Conversation.Trade.Vo['internalTradeId'], Conversation.Trade.Vo>
>({
  key: 'conversation/tradeMapState',
  default: {},
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      onSet((next) => {
        let shouldUpdate = false;

        const usedStoreIdMap = getUsedStoreIdMap(lastUsedExternalStoreIdCache.data);

        const filteredNext = Object.values(next).reduce<typeof next>((res, cur) => {
          const { internalTradeId, externalShopId } = cur;

          const { [externalShopId]: isStoreUsed = false } = usedStoreIdMap;

          if (isStoreUsed) {
            res[internalTradeId] = cur;
          } else {
            shouldUpdate = true;
          }

          return res;
        }, {});

        if (shouldUpdate) {
          setSelf(filteredNext);
        }
      });
    },
  ],
});

/**
 * 以extenalItemId作为索引
 */
export const tradeExtenalMapState = selector<
  Record<Conversation.Trade.Vo['externalTradeId'], Conversation.Trade.Vo>
>({
  key: 'conversation/tradeExtenalMapState',
  get: ({ get }) => {
    const internalMap = get(tradeMapState);
    return Object.values(internalMap).reduce((result, p) => {
      // eslint-disable-next-line no-param-reassign
      result[p.externalTradeId] = p;
      return result;
    }, {} as Record<Conversation.Trade.Vo['externalTradeId'], Conversation.Trade.Vo>);
  },
});
export const productMapState = atom<
  Record<Conversation.Product['internalItemId'], Conversation.Product>
>({
  key: 'conversation/productMapState',
  default: {},
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      onSet((next) => {
        let shouldUpdate = false;

        const usedStoreIdMap = getUsedStoreIdMap(lastUsedStoreIdCache.data);

        const filteredNext = Object.values(next).reduce<typeof next>((res, cur) => {
          const { internalItemId, internalStoreId } = cur;

          const { [internalStoreId]: isStoreUsed = false } = usedStoreIdMap;

          if (isStoreUsed) {
            res[internalItemId] = cur;
          } else {
            shouldUpdate = true;
          }

          return res;
        }, {});

        if (shouldUpdate) {
          setSelf(filteredNext);
        }
      });
    },
  ],
});
/**
 * 以extenalItemId作为索引
 */
export const productExtenalMapState = selector<
  Record<Conversation.Product['externalItemId'], Conversation.Product>
>({
  key: 'conversation/productExtenalMapState',
  get: ({ get }) => {
    const internalMap = get(productMapState);
    return Object.values(internalMap).reduce((result, p) => {
      // eslint-disable-next-line no-param-reassign
      result[p.externalItemId] = p;
      return result;
    }, {} as Record<Conversation.Product['externalItemId'], Conversation.Product>);
  },
});

export const voucherMapState = atom<
  Record<
    Conversation.Voucher.Vo['externalVoucherId'],
    Conversation.Voucher.Vo & { internalStoreId: string }
  >
>({
  key: 'conversation/voucherMapState',
  default: {},
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      onSet((next) => {
        let shouldUpdate = false;

        const usedStoreIdMap = getUsedStoreIdMap(lastUsedStoreIdCache.data);

        const filteredNext = Object.values(next).reduce<typeof next>((res, cur) => {
          const { externalVoucherId, internalStoreId } = cur;

          const { [internalStoreId]: isStoreUsed = false } = usedStoreIdMap;

          if (isStoreUsed) {
            res[externalVoucherId] = cur;
          } else {
            shouldUpdate = true;
          }

          return res;
        }, {});

        if (shouldUpdate) {
          setSelf(filteredNext);
        }
      });
    },
  ],
});

export const useMessageMapValue = () => {
  return useRecoilValue(messageMapState);
};

export const useSetMessageMapValue = () => {
  return useSetRecoilState(messageMapState);
};

export const useUnreadMessageCountValue = () => {
  return useRecoilValue(unreadMessageCountState);
};

export const useSetUnreadMessageCountValue = () => {
  return useSetRecoilState(unreadMessageCountState);
};

export const useTradeMapValue = () => {
  return useRecoilValue(tradeMapState);
};

export const useSetTradeMapValue = () => {
  return useSetRecoilState(tradeMapState);
};

export const useCurrentConversationValue = () => {
  return useRecoilValue(currentConversationState);
};

export const useProductMapValue = () => {
  return useRecoilValue(productMapState);
};

export const useSetProductMapValue = () => {
  return useSetRecoilState(productMapState);
};

export const useVoucherMapValue = () => {
  return useRecoilValue(voucherMapState);
};

export const useSetVoucherMapValue = () => {
  return useSetRecoilState(voucherMapState);
};

export const useAddTradeToMap = () => {
  return useRecoilCallback(
    ({ set }) =>
      (tradeOrList: Conversation.Trade.Vo | Conversation.Trade.Vo[]) => {
        const tradeList = Array.isArray(tradeOrList) ? tradeOrList : [tradeOrList];

        if (tradeList.length === 0) {
          return;
        }

        const [{ externalShopId }] = tradeList;

        lastUsedExternalStoreIdCache.push(externalShopId);

        set(tradeMapState, (tradeMap) => {
          return tradeList.reduce(
            (res, trade) => {
              const { internalTradeId: id } = trade;

              res[id] = trade;

              return res;
            },
            {
              ...tradeMap,
            },
          );
        });
      },
    [],
  );
};

export const useAddProductToMap = () => {
  return useRecoilCallback(
    ({ set }) =>
      (productOrList: Conversation.Product | Conversation.Product[]) => {
        const productList = Array.isArray(productOrList) ? productOrList : [productOrList];

        if (productList.length === 0) {
          return;
        }

        const [{ internalStoreId }] = productList;

        lastUsedStoreIdCache.push(internalStoreId);

        set(productMapState, (productMap) => {
          return productList.reduce(
            (res, product) => {
              const { internalItemId: id } = product;

              res[id] = product;

              return res;
            },
            {
              ...productMap,
            },
          );
        });
      },
    [],
  );
};

export const useAddVoucherToMap = () => {
  return useRecoilCallback(
    ({ set }) =>
      (
        voucherOrList: Conversation.Voucher.Vo | Conversation.Voucher.Vo[],
        internalStoreId: string,
      ) => {
        const voucherList = Array.isArray(voucherOrList) ? voucherOrList : [voucherOrList];

        if (voucherList.length === 0) {
          return;
        }

        lastUsedStoreIdCache.push(internalStoreId);

        set(voucherMapState, (voucherMap) => {
          return voucherList.reduce(
            (res, voucher) => {
              const { externalVoucherId: id } = voucher;

              res[id] = {
                ...voucher,
                internalStoreId,
              };

              return res;
            },
            {
              ...voucherMap,
            },
          );
        });
      },
    [],
  );
};

// 根据id删除
export const useDelConversationById = () => {
  return useRecoilCallback(({ snapshot, set }) => async (id: string | string[]) => {
    let waitDelIds: string[];
    if (!Array.isArray(id)) {
      waitDelIds = [id];
    } else {
      waitDelIds = id;
    }
    set(conversationIdListState, (idList) => {
      return idList.filter((id) => !waitDelIds.includes(id));
    });
    set(conversationMapState, (convMap) => {
      return pickBy(convMap, (_, key) => !waitDelIds.includes(key));
    });
    const curConvId = await snapshot.getPromise(currentConversationIdState);
    if (curConvId && waitDelIds.includes(curConvId)) {
      set(currentConversationIdState, null);
    }
  });
};
