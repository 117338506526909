import { Form, InputNumber, InputNumberProps } from 'antd';

export type FormItemInputNumberProps = InputNumberProps<number> & {
  formName: string[] | string;
  formStyle?: React.CSSProperties;
};

export const FormItemInputNumber = (props: FormItemInputNumberProps) => {
  const { children, formName, formStyle, ...others } = props;

  return (
    <Form.Item style={formStyle} name={formName}>
      <InputNumber
        style={{ width: 60, marginLeft: 4, marginRight: 4 }}
        min={1}
        max={10}
        {...others}
      />
    </Form.Item>
  );
};
