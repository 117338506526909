import productLine from 'productLine';
import type { ConfigResponse } from 'services/api/flagr';

export const defaultAutoReplyDelayMinLimit = 1;
export const defaultAutoReplyDelayMaxLimit = 180;

export const defaultAutoReplyFrequencyMinLimit = 0;
export const defaultAutoReplyFrequencyMaxLimit = 48;

export const defaultBatchTransferMaxLimit = 100;

const defaultConfig: ConfigResponse = {
  version: '-1',
  contactQrcode:
    productLine === 'aeplugin'
      ? 'https://resource.zhisuitech.com/media/6387f085bf074aa4b79c32ff2d6c3e68.png'
      : 'https://clients.zhisuitech.com/fett-public/wechatqr-202112071.png',
  payQrcode: 'https://clients.zhisuitech.com/fett-public/18F75212-D790-4805-A215-4C6E36624A37.png',
  billQrcode: 'https://clients.zhisuitech.com/fett-public/F84CB657-DED0-4DE1-9D96-532EA4499C6B.png',
  qaQrcode:
    'https://ganju-oversea-clients.oss-cn-shanghai.aliyuncs.com/fett-public/lQLPJxZju5I3_tXNAYzNAYywQtRyE0RAqgcCpHfm4ADhAA_396_396.png',
  qaRegionList: ['id', 'my', 'ph', 'th', 'sg', 'vn', 'gb'],
  authProxyDisabledList: [],
  latestVersion: process.env.REACT_APP_VERSION!,
  newOrgSyncMessageIntervalSeconds: 20,
  androidQrcode: 'https://clients.zhisuitech.com/mchatpp-app/aos-nta-latest.png',
  iosQrcode: 'https://clients.zhisuitech.com/mchatpp-app/ios-nta-testflight.png',
  autoReplyDelayMinLimit: defaultAutoReplyDelayMinLimit,
  autoReplyDelayMaxLimit: defaultAutoReplyDelayMaxLimit,
  autoReplyFrequencyMinLimit: defaultAutoReplyFrequencyMinLimit,
  autoReplyFrequencyMaxLimit: defaultAutoReplyFrequencyMaxLimit,
  batchTransferMaxLimit: defaultBatchTransferMaxLimit,
  onlineSearch: true,
  requestReportMinDuration: 1500,
  nimDb: false,
  nimDbLog: false,
};
class AppConfig {
  static value = defaultConfig;
}

export default AppConfig;
