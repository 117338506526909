import styled from '@emotion/styled/macro';
import { css } from '@emotion/css/macro';
import { Carousel } from 'antd';
import AutoTrans from 'components/AutoTrans';
import React from 'react';
import productLine from 'productLine';
import computerPic from './assets/computer.png';
import BizCircle from './components/BizCircle';

export interface StartupContainerProps {
  className?: string;
}
const Container = styled.div`
  display: flex;
  padding: calc(100vh * 0.07) 0;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 992px) {
    justify-content: center;
  }
`;
const Title = styled.h2`
  color: #25365e;
  font-size: 32px;
  text-align: center;
  margin-bottom: 48px;
  white-space: break-spaces;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > img {
    width: 340px;
  }
`;
const Left = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  flex-basis: 50%;
  > * {
    max-width: 100%;
  }
  @media (max-width: 992px) {
    display: none;
  }
`;
const dotsClassName = css`
  bottom: -24px !important;
  button {
    background: var(--fett-app-primary-color) !important;
    opacity: 0.4 !important;
  }
  .slick-active button {
    opacity: 1 !important;
  }
`;
const StartupContainer: React.FC<StartupContainerProps> = (props) => {
  const { className, children } = props;
  return (
    <Container className={className}>
      <Left>
        <Carousel
          autoplay
          dots={{
            className: dotsClassName,
          }}
        >
          <div>
            <Wrapper>
              <Title>
                <AutoTrans
                  ns="ViewStartups"
                  i18nKey={
                    productLine === 'aeplugin'
                      ? 'startup.container.slider1.aetitle'
                      : 'startup.container.slider1.title'
                  }
                />
              </Title>
              <img src={computerPic} alt="guide" />
            </Wrapper>
          </div>
          <div>
            <Wrapper>
              <Title>
                <AutoTrans ns="ViewStartups" i18nKey="startup.container.slider2.title" />
              </Title>
              <BizCircle />
            </Wrapper>
          </div>
        </Carousel>
      </Left>
      <div>{children}</div>
    </Container>
  );
};
export default StartupContainer;
