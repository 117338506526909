import { get } from 'lodash-es';
import i18n from 'services/i18n';

export interface CustomServiceMsg {
  language: General.Language;
  msg: string;
}

/**
 * 用于希望提示服务端返回的 msg 的情况。
 * @param err Axios 的 error。
 * @param code 希望提示服务端返回的 msg 时的 code，默认 400。
 * @returns 提示服务端返回的 msg 时 resolve，否则 reject。
 */
function serverMsg(err: any, code = 400): Promise<string> {
  return new Promise((resolve, reject) => {
    const status = get(err, 'response.status');

    if (status !== code) {
      reject(err);

      return;
    }

    const msgRaw = get(err, 'response.data.msg');

    const msgList: CustomServiceMsg[] = JSON.parse(msgRaw);

    const languageMsgMap = msgList.reduce<Partial<Record<General.Language, string>>>((res, cur) => {
      const { language, msg } = cur;

      res[language] = msg;

      return res;
    }, {});

    const { language } = i18n;

    const { [language as General.Language]: msg } = languageMsgMap;

    if (msg) {
      resolve(msg);
    } else {
      reject(err);
    }
  });
}

export const serverMsgCapture = <P extends any[], R>(cb: (...args: P) => Promise<R>) => {
  return (...args: P) => {
    return cb(...args).catch(serverMsg);
  };
};

export default serverMsg;
