import { AxiosRequestConfig } from 'axios';
import { decode } from 'jsonwebtoken';
import { once } from 'lodash-es';

const AUTH_HEADER = process.env.REACT_APP_AUTH_HEADER || '';

export const defaultAllowRequestUrl: string[] = [
  '/oversea-hi/v1/review/stat',
  '/trade/v2/queryReturnList',
  'overseaim-store-home/org-region-config/v1/query',
  'overseaim-hi/v1/queryStoreList',
  '/api_gateway/dashboard/performance/order/overview',
  '/api_gateway/dashboard/performance/sales/convert',
  '/api_gateway/dashboard/performance/order/loss',
  '/api_gateway/dashboard/performance/order/recovery',
  '/api_gateway/dashboard/performance/order/reasons',
  '/api_gateway/dashboard/performance/comment',
  '/overseaim-hi/v1/review/search',
  'getTalkInfoByBuyerStore',
  '/oversea-conversation/v1/syncMessage',
  '/overseaim-hi/userSendHistory/query',
  'api_gateway/dashboard/assistant/performance',
  'api_gateway/dashboard/robot/contribution/conversation/outline',
  '/api_gateway/dashboard/assistant/export/performance',
  'api_gateway/dashboard/robot/contribution/conversation',
  'api_gateway/dashboard/robot/contribution/order/reminder',
  'api_gateway/dashboard/robot/contribution/payment/reminder',
  'api_gateway/dashboard/robot/contribution/cancel/order/reminder',
  'api_gateway/dashboard/robot/contribution/praise/reminder',
  'api_gateway/dashboard/robot/contribution//auto/review',
  'api_gateway/dashboard/robot/contribution/export/robot/contribution',
  '/oversea-user-voice/v1/case/list',
  'api_gateway/dashboard/assistant/performance/v2',
  'api_gateway/dashboard/assistant/performance/v3',
  'aggregation/v1/assistant_tunnels',
];
const fetchAllowList = once(() => {
  return fetch(
    'https://overseaim-store-home.oss-cn-hangzhou.aliyuncs.com/sellerDemoWhitelist.json' +
      `?t=${Date.now()}`,
  )
    .then((res) => res.json())
    .then((res) => res.sellerDemoWhitelistUrls)
    .catch(() => {
      return defaultAllowRequestUrl;
    });
});
export const isSeller = (config: AxiosRequestConfig) => {
  const { headers } = config;
  const authorization = headers[AUTH_HEADER] || '';
  const token = authorization.replace('Bearer ', '');
  if (!token) {
    return false;
  }
  const payload: any = decode(token, { json: true });
  return payload?.leyan_user?.userType === 'seller';
};

export const forbiddenSellerAction = async (config: AxiosRequestConfig) => {
  const allowRequestUrl = await fetchAllowList();
  if (
    isSeller(config) &&
    config.method?.toLowerCase() !== 'get' &&
    !allowRequestUrl.includes(config.url || '')
  ) {
    return {
      ...config,
      adapter(cfg: AxiosRequestConfig) {
        return Promise.resolve({
          data: {},
          status: 200,
          statusText: '',
          headers: config.headers,
          config,
        });
      },
    };
  }
  return config;
};
