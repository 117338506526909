import { isNil } from 'lodash-es';
import { selectorFamily, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import fetchFlag, { FlagKey, Response } from 'services/api/flagr';
import { orgIdState } from './useOrg';

export const flagrState = selectorFamily({
  key: 'flagrState',
  get:
    <T extends FlagKey, R extends Response[T]>(flagKey: T) =>
    async ({ get }) => {
      const orgId = get(orgIdState);
      if (!isNil(orgId)) {
        const flag = await fetchFlag(flagKey, { orgId });
        return flag as R;
      }
      return undefined;
    },
});

export const useFlagrValueByKey = <K extends keyof typeof FlagKey>(key: K) =>
  useRecoilValue(flagrState(FlagKey[key]));
export const useFlagrLoadableByKey = <K extends keyof typeof FlagKey>(key: K) =>
  useRecoilValueLoadable(flagrState(FlagKey[key]));

export const useFlagrChannelModulesValue = () => useRecoilValue(flagrState(FlagKey.ChannelModules));

export const useFlagrZhixiaModulesValue = () => useFlagrChannelModulesValue()?.zhixia;

export const useFlagrConfigValue = () => useRecoilValue(flagrState(FlagKey.Config));

export const useFlagrWelfareCenterValue = () => useRecoilValue(flagrState(FlagKey.WelfareCenter));

export const useFlagrAnniversaryValue = () => useRecoilValue(flagrState(FlagKey.Anniversary));

export const useFlagrDashboardRankValue = () =>
  useRecoilValue(flagrState(FlagKey.DashboardRankEnable));

export const useFlagrDashboardRankValueLoadable = () =>
  useRecoilValueLoadable(flagrState(FlagKey.DashboardRankEnable));
