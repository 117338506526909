import posthog, { Platform, Environment } from '@leyan/lytics';
import { getLeYanBotHost } from 'services/api';
import chatttSDK from 'services/chattt';

export interface TrackerInitParams {
  orgId?: number;
  id?: string;
  name?: string;
  roles?: Assistant.Role[];
}
let inited = false;
const getClientInfo = async () => {
  const ret = await chatttSDK?.app?.info?.();
  const version = await chatttSDK?.app?.getVersion();
  if (!ret && version) {
    return { version };
  }
  return ret || {};
};
export async function init(params: TrackerInitParams) {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  const { orgId, id, name, roles } = params;
  if (!inited) {
    posthog.init(`${process.env.REACT_APP_PLATFORM}-${process.env.REACT_APP_NAME}`, {
      persistence: 'localStorage+cookie',
      autocapture: true,
      api_host: process.env.REACT_APP_LYTICS_ENDPOINT as string,
    });
    inited = true;
  }
  const { channel, appId, version, deviceId, osVersion } = await getClientInfo();
  const intlDateOptions = Intl.DateTimeFormat().resolvedOptions();

  const countryCode = chatttSDK?.app?.getLocaleCountryCode?.();

  const ip = await chatttSDK?.netScan
    ?.getMyIpAddress?.()
    .then((r) => {
      return r?.ip ?? '';
    })
    .catch((e) => {
      //
    });
  posthog.register({
    app_name: process.env.REACT_APP_NAME!,
    app_platform: process.env.REACT_APP_PLATFORM! as Platform,
    app_version: process.env.REACT_APP_VERSION!,
    app_env: process.env.REACT_APP_ENV! as Environment,
    org_id: orgId,
    user_id: id,
    user_name: name,
    user_roles: roles?.map((x) => x.name),
    ip,
    timezone: intlDateOptions.timeZone,
    locale: intlDateOptions.locale,
    leyanbot_host: getLeYanBotHost(),
    country_code: countryCode,
    // 客户端信息
    client_channel: channel,
    client_app_id: appId,
    client_version: version,
    client_device_id: deviceId,
    client_os_version: osVersion,
  });

  // 请求host上报
  if (countryCode) {
    posthog.capture('$requestHost', {
      countryCode,
      countryHost: getLeYanBotHost(),
    });
  }
}
