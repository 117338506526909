import { LazadaAuthWay } from 'components/AuthShop/components/LazadaShopAuthWay';
import { ShopeeAuthWay } from 'components/AuthShop/components/ShopeeShopAuthWay';
import chatttSDK from 'services/chattt';
import isMock from 'utils/mock';
import { officialChannel } from 'views/ProductContentGenerator/components/config';
import {
  storeHomeClient,
  ImResponse,
  loginClient,
  resolve,
  storeHomeV2Client,
  doorClient,
  storeHomeNVClient,
  loginClientV2,
} from '.';
import { AuthShopOpenMode } from './flagr';
import { TokopediaStoreType } from './tokopedia';

export const sendSMSCaptcha = (
  phoneNumber: string,
  purpose: 'verify' | 'register' = 'register',
) => {
  return loginClient.post<ImResponse<{ sendCodeTimestamp: number }>>('/admin/verification/codes', {
    phoneNumber,
    purpose,
  });
};

export const createVerificationToken = (phoneNumber: string, captcha: string) => {
  return loginClient.post<ImResponse<{ verificationToken: string }>>('/admin/verification/tokens', {
    phoneNumber,
    code: captcha,
  });
};

export const createOrg = (body: {
  verificationToken: string;
  name: string;
  userName: string;
  email: string;
  password: string;
  channel?: string;
}) => {
  if (process.env.NODE_ENV !== 'production' && isMock()) {
    return import('__data__/mock.admin/org').then((r) => {
      return r.default(body);
    });
  }
  return loginClient.post<
    ImResponse<{
      userId: number;
      orgId: number;
    }>
  >('admin/orgs', {
    source: chatttSDK ? undefined : 'web_config',
    channel: officialChannel,
    ...body,
  });
};

interface CommonCreateData {
  channel?: string;
  inviteCode?: string;
}

interface PhoneCreateData extends CommonCreateData {
  smsCode: string;
  phone: string;
}

interface EmailCreateData extends CommonCreateData {
  email: string;
  password: string;
}

export const createOrgV2 = (body: PhoneCreateData | EmailCreateData) => {
  return loginClientV2.post<
    ImResponse<{
      token: string;
    }>
  >('/admin/orgs', {
    source: chatttSDK ? undefined : 'web_config',
    channel: officialChannel,
    ...body,
  });
};

// admin/org/done_auth_tutorial
// 获取 org 是否完成新手引导
export const getIsOrgDoneAuthTutorial = () => {
  return storeHomeClient.get<ImResponse<{ isTutorialDone: boolean }>>(
    'admin/org/done_auth_tutorial',
  );
};

// admin/org/done_auth_tutorial
// 设置 org 是否完成新手引导
export const setIsOrgDoneAuthTutorial = () => {
  return storeHomeClient.put<ImResponse>('admin/org/done_auth_tutorial', {});
};

export type RegionCountsMap = { [K in General.Region as `${K}Cnt`]: number };

export const getRegionsStoreCount = (permId?: General.PermissionId) => {
  return storeHomeV2Client.get<
    ImResponse<{
      shopeeCountData: RegionCountsMap;
      lazadaCountData: RegionCountsMap;
      tokopediaCountData: RegionCountsMap;
      tiktokCountData: RegionCountsMap;
      aliExpressCountData?: RegionCountsMap;
    }>
  >('admin/regions/store_count', { params: { permId } });
};

export interface TokenDate {
  year: number;
  month: number;
  day: number;
}
export interface TokenTime {
  hour: number;
  minute: number;
  second: number;
  nano: number;
}
export interface AdminStore {
  id: number;
  orgId: number;
  storeName: string;
  externalId: string;
  alias: string;
  region: General.UpperCaseRegion;
  externalType: IM_API.Admin.ExternalType;
  contractStartTime: number;
  contractEndTime: number;
  domain: string;
  productId: number;
  chatAuthStatus: IM_API.Admin.AuthStatus;
  erpAuthStatus: IM_API.Admin.AuthStatus;
  authStatus: IM_API.Admin.AuthStatus;
  authType: IM_API.Admin.AuthType;
  storeType: IM_API.Admin.StoreType;
  isStoreInitFinish: boolean;
  chatShopeeToken?: {
    id: number;
    partnerId: number;
    partnerType: string;
    orgId: number;
    storeId: number;
    externalType: string;
    externalId: string;
    storeType: string;
    refreshToken: string;
    accessToken: string;
    refreshTokenCreateTime: {
      date: TokenDate;
      time: TokenTime;
    };
    accessTokenCreateTime: {
      date: TokenDate;
      time: TokenTime;
    };
  };
  isAccountUpdate: boolean;
  isSip: boolean;
  isPrimarySip: boolean;
  authAccount: string;
  authAccountSystem: IM_API.Admin.AuthAccountSystem;

  tokopediaDomain: string;
  tokopediaStoreType: TokopediaStoreType;
}

export interface GetAdminStoresParams {
  keyWord?: string;
  region?: General.UpperCaseRegion | 'WORLD';
  externalType?: General.PlatformUpperCase;
  page: number;
  pageSize?: number;
  order?: boolean;
  authStatus?: 1;
  departmentId?: string | number;
  permId?: General.PermissionId;
}

export const getAdminStores = (params: GetAdminStoresParams) => {
  return storeHomeClient.get<ImResponse<{ stores: AdminStore[]; total: number }>>('admin/stores', {
    params,
  });
};

export const getAdminStoresByIds = (storeIds: (number | string)[]) => {
  return resolve(
    storeHomeClient.post<ImResponse<{ stores: AdminStore[] }>>('/admin/stores_by_ids', {
      storeIds,
    }),
  );
};

export type ImportAdminStoresBody =
  | {
      type: 'storeName';
      storeName: string[];
      departmentId?: number;
      permId?: General.PermissionId;
      platform?: General.PlatformUpperCaseTunnel;
    }
  | {
      type: 'alias';
      alias: string[];
      departmentId?: number;
      permId?: General.PermissionId;
      platform?: General.PlatformUpperCaseTunnel;
    }
  | {
      type: 'externalId';
      externalId: string[];
      departmentId?: number;
      permId?: General.PermissionId;
      platform?: General.PlatformUpperCaseTunnel;
    };

export const importAdminStores = (body: ImportAdminStoresBody) => {
  return resolve(
    storeHomeV2Client.post<ImResponse<AdminStore[]>>('/admin/batch_query_stores', body),
  );
};

// 授权店铺需要协助通知
export const notifyAuthShopNeedHelp = () => {
  return storeHomeClient.post<ImResponse<undefined>>('admin/auth/notify_need_help', {});
};

export interface GenerateShopeeAuthUrlBody {
  authUrl: string;
  timestamp: number;
  redirectSign: string;
}
export const generateShopeeAuthUrl = (
  partnerType: IM_API.Admin.PartnerType,
  storeType: IM_API.Admin.StoreType,
  region: General.UpperCaseRegion,
) => {
  return storeHomeClient.post<ImResponse<GenerateShopeeAuthUrlBody>>('admin/shopee_auth_url', {
    partnerType,
    storeType,
    region,
  });
};
export interface GenerateStandAloneShopeeAuthUrlBody {
  url: string;
}
export const generateStandAloneShopeeAuthUrl = (
  partnerType: IM_API.Admin.PartnerType,
  storeType: IM_API.Admin.StoreType,
  region: General.UpperCaseRegion | 'CNSC',
  uuid: string,
  authWay: ShopeeAuthWay,
  openMode?: AuthShopOpenMode,
) => {
  return storeHomeClient.post<ImResponse<GenerateStandAloneShopeeAuthUrlBody>>(
    'admin/shopee_auth/standalone_url',
    {
      partnerType,
      storeType,
      region,
      uuid,
      authWay,
      openMode,
    },
  );
};

export interface StandaloneAuthResult {
  uuid: string;
  isSuccess: boolean;
  errorMsg: string;
  storeIds: number[];
  storeNames: string[];
}
export interface StandaloneAuthResultBody {
  erpResult: StandaloneAuthResult;
  chatResult: StandaloneAuthResult;
}

export const getStandaloneAuthResult = (uuid: string) => {
  return storeHomeClient.get<ImResponse<StandaloneAuthResultBody>>(
    `admin/shopee_auth/standalone_result/${uuid}`,
  );
};

export interface PostShopeeAuthBody {
  partnerType: IM_API.Admin.PartnerType;
  storeType: IM_API.Admin.StoreType;
  region: General.UpperCaseRegion;
  timestamp: GenerateShopeeAuthUrlBody['timestamp'];
  redirectSign: GenerateShopeeAuthUrlBody['redirectSign'];
  code?: string;
  shopId?: number;
  mainAccountId?: number;
}
export const postShopeeAuth = (body: PostShopeeAuthBody) => {
  return storeHomeClient.post<ImResponse<string>>('admin/shopee_auth', body);
};

// 删除店铺
export const removeShopStore = (body: { shopId: number }) => {
  return storeHomeClient.post<ImResponse<string>>('admin/remove_shopee_store', body);
};

export interface LazadaAuthParams {
  code: string;
}

export const lazadaAuth = (params: LazadaAuthParams) => {
  return storeHomeClient.get('/admin/lazada/authResult/redirect', {
    params,
  });
};

export interface LazadaStandaloneAuthResultBody {
  isSuccess: boolean;
  successCount?: number;
  errorMsg: string;
}

export const getLazadaStandaloneAuthResult = (uuid: string) => {
  return storeHomeClient.get<ImResponse<LazadaStandaloneAuthResultBody>>(
    `admin/lazada/standalone_result/${uuid}`,
  );
};

export const getOrgSelf = () => {
  return storeHomeClient.get<
    ImResponse<{
      id: number;
      name: string;
      tryExpireTimestamp: number;
      orgChannel: 'SELF';
      externalOrgId: string;
    }>
  >('admin/org/self');
};

export const updateStoreAlias = (storeId: number | string, alias: string) => {
  return storeHomeClient.put(`admin/stores/${storeId}/alias`, { alias });
};

export const updateShopeeCredentials = (
  account: string,
  password: string,
  extra: {
    shopId?: number;
    mainAccountId?: number;
  },
) => {
  return storeHomeClient.post('admin/shopee/credentials', {
    account,
    password,
    ...extra,
  });
};

export interface AccountInfo {
  account: string;
  password: string;
  /** 0-普通店铺帐号，1-cnsc帐号 */
  accountType: number;
  region: string;
  storeId: number;
}
export const getShopeeCredential = (externalId: number) => {
  return resolve(
    storeHomeV2Client.get<ImResponse<AccountInfo>>('/admin/get_storeCredential_by_shopId', {
      params: { externalId },
    }),
  );
};

export interface UpdateCredentialReq {
  account: string;
  password: string;
  // 外部店铺id
  externalId: string;
  // 登录shopee后台成功以后标记为1，登录失败以后标记为0.默认值是0
  status?: 0 | 1;
}
export const updateCredential = (body: UpdateCredentialReq) => {
  return storeHomeV2Client.post<ImResponse<any>>('/admin/update_account_info', body);
};

// 帐号类型
export enum AccountType {
  // 普通帐号
  Normal = 1,
  // CNSC 帐号
  CNSC,
  // 子母帐号
  MainAndSub,
}

export interface batchUpdateShopeeCredentialsBody {
  orgId: number;
  authType?: number;
  credentialVOS: Array<{
    account: string;
    password: string;
    shopId: number;
    mainAccountId?: number;
    accountType: AccountType;
  }>;
}

// 批量更新店铺帐号密码
export const batchUpdateShopeeCredentials = (body: batchUpdateShopeeCredentialsBody) => {
  return storeHomeClient.post<ImResponse<any>>('admin/shopee/batch_credentials', {
    ...body,
    authType: body?.authType || 1,
  });
};

export const shopeeErrorList = () => {
  return resolve(storeHomeClient.get<ImResponse<Auth.ShopeeError[]>>('admin/shopee/auth_errors'));
};

export interface CancelShopeeUrlBody {
  shopeeAuthErrorId: number;
}

export type CancelShopeeUrlResponse = ImResponse<{
  authUrl: string;
  timestamp: number;
  redirectSign: string;
}>;

export const cancelShopeeUrl = (body: CancelShopeeUrlBody) => {
  return resolve(
    storeHomeClient.post<CancelShopeeUrlResponse>('admin/cancel_shopee_auth_url', body),
  );
};

export type CancelShopeeBaseBody<T> = {
  shopeeAuthErrorId: number;
  timestamp: number;
  redirectSign: string;
} & T;

export type CancelShopeeBody =
  | CancelShopeeBaseBody<{
      shopId: number;
    }>
  | CancelShopeeBaseBody<{
      mainAccountId: number;
    }>;

export const cancelShopee = (body: CancelShopeeBody) => {
  return resolve(storeHomeClient.post<ImResponse<never>>('admin/cancel_shopee_auth', body));
};

export const generateLazadaAuthUrl = (uuid?: string, authWay?: LazadaAuthWay) => {
  return storeHomeClient.get<
    ImResponse<{ authUrl: string; timestamp: number; redirectSign: string }>
  >('admin/lazada/authUrl/redirect', { params: { uuid, authWay } });
};

export function getUserConfig() {
  return resolve(
    storeHomeClient.get<ImResponse<{ qaAutoTranslate: boolean }>>('admin/user/config'),
  );
}

export function updateQaAutoTranslateConfig(qaAutoTranslate: boolean) {
  return resolve(
    storeHomeClient.put<ImResponse<{ qaAutoTranslate: boolean }>>(
      'admin/user/config/qaAutoTranslate',
      {
        qaAutoTranslate,
      },
    ),
  );
}

export function transferedStoreList() {
  return resolve(storeHomeClient.get<ImResponse<number[]>>('/stores/transferred'));
}

export function orgConfig() {
  return resolve(
    doorClient.get<ImResponse<Pick<General.OrgConfig, 'remindEnable'>>>(
      '/overseaim-store-home/v1/org-config/orgConfig',
    ),
  );
}

export function enterprise() {
  return resolve(
    storeHomeNVClient.get<ImResponse<Oversea.Enterprise | undefined>>(
      '/certification/get_certification_info',
    ),
  );
}

export interface UpdateEnterpriseBody extends Oversea.Enterprise {}

export function updateEnterprise(body: UpdateEnterpriseBody) {
  return resolve(storeHomeNVClient.post('/certification/update_certification', body));
}

export function org() {
  return resolve(storeHomeClient.get<ImResponse<Oversea.Org>>('/admin/org/self'));
}

export const checkIsPhoneRegistered = (phone: string) => {
  return resolve(
    loginClientV2.get<ImResponse<{ registered: boolean }>>('/admin/check_registered', {
      params: { phone },
    }),
  );
};

export const updateOgInfo = (body: { name: string }) => {
  return storeHomeClient.put<ImResponse<any>>('/admin/org_info', body);
};

export const verifySMS = (body: { phone: string; smsCode: string }) => {
  return loginClientV2.post<ImResponse<{ isMatch: boolean }>>('/admin/verify_sms_code', body);
};
export function exportStoreList(params: Omit<GetAdminStoresParams, 'page' | 'pageSize'>) {
  return resolve(
    storeHomeV2Client.post<ImResponse<string>>('/admin/export_store_info', { ...params }),
  );
}
export function batchUpdateStoreAlias(file: FormData) {
  return storeHomeV2Client.post<ImResponse<void>>('/admin/batch_update_storeAlias', file);
}
export interface NeedToUpdateStore {
  storeId: string;
  account: string;
  password: string;
  storeName: string;
  storeAlias: string;
  region: General.UpperCaseRegion;
}
export function getInvalidAccountNum() {
  return resolve(
    storeHomeV2Client.get<ImResponse<NeedToUpdateStore[]>>('/admin/get_invalid_account'),
  );
}

export function batchUpdateAccount(
  accounts: Pick<NeedToUpdateStore, 'account' | 'storeId' | 'password'>[],
) {
  return resolve(
    storeHomeV2Client.post('/admin/batch_update_credential', {
      batchUpdateCredentials: accounts,
    }),
  );
}

export function getUnassignedStore() {
  return resolve(
    storeHomeNVClient.get<ImResponse<Store.Store[]>>('/assistant_group/get_unassigned_store'),
  );
}

interface Store2GroupParams {
  storeIds: number[];
  assistantGroupIds: number[];
}
export function distributeStore2Group(params: Store2GroupParams) {
  return resolve(
    storeHomeNVClient.post('/assistant_group/store_distribute', {
      ...params,
    }),
  );
}

type AuthExceptionNumKey = `${
  | Exclude<General.Platform, 'aliexpress'>
  | 'aliExpress'}AuthExceptionNum`;
type LocalRegisterExceptionNumKey = `${'shopee'}LocalRegisterExceptionNum`;
export interface StoreAuthErrorCount
  extends Record<AuthExceptionNumKey | LocalRegisterExceptionNumKey, number> {
  // lazada 授权异常店铺
  // lazadaAuthExceptionNum: number;
  // shopee 授权异常店铺
  // shopeeAuthExceptionNum: number;
  // tokopediaAuthExceptionNum: number;
  // tiktokAuthExceptionNum: number;
  // shopee 未完成注册店铺
  // shopeeLocalRegisterExceptionNum: number;
  // [KK extends K]: number
}
// 获取店铺授权失败数
export function getStoreAuthErrorCount(orgId: number) {
  return storeHomeClient.get<ImResponse<StoreAuthErrorCount>>('admin/getStoreAuthExceptionNum', {
    params: { orgId },
  });
}

export interface ConfigOverviewParams {
  platform: General.PlatformUpperCase;
  region: General.UpperCaseRegion;
}

export interface ConfigOverviewResponse {
  configItems: Aide.Scenario[];
}

export function configOverview(params: ConfigOverviewParams) {
  return resolve(
    storeHomeV2Client.get<ImResponse<ConfigOverviewResponse>>('admin/config_overview', { params }),
  );
}

export interface StoreUsersParams {
  storeId: number | string;
  permId: General.PermissionId;
}

export function storeUsers(params: StoreUsersParams) {
  return resolve(
    storeHomeV2Client.get<ImResponse<Assistant.Account[]>>('/admin/query_user_by_stores', {
      params,
    }),
  );
}

export function getAliExpressAuthUrl() {
  return resolve(storeHomeNVClient.get<ImResponse<string>>('/aliexpress/generateAuthUrl'));
}
