import styled from '@emotion/styled';
import { useMemoizedFn } from 'ahooks3';
import { Button, Checkbox, Form, Input, message, Select, Space, Spin } from 'antd';
import AutoTrans from 'components/AutoTrans';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language, Platform } from 'services/api/chat.gpt';
import CheckboxGroup from './components/CheckboxGroup';
import { GeneratorCard } from './components/GeneratorCard';
import GeneratorEmpty from './components/GeneratorEmpty';
import { GeneratorHeader } from './components/GeneratorHeader';
import MustExcludeKeysField from './components/MustExcludeKeysField';
import MustIncludedKeysField from './components/MustIncludedKeysField';
import { SectionLayout } from './components/SectionLayout';
import {
  FlexCenter,
  LimitConditions,
  mb4,
  SectionTitle,
  SellingPointTextArea,
} from './components/styles';
import { ProductContentGeneratorContextV2 } from './ContextV2';
import useCopyText from './hooks/useCopyText';
import { FormItemInputNumber } from './components/FormItemInputNumber';
import { HotWordsDisplay } from './components/HotWordsDisplay';
import { ClearButton } from './components/ClearButton';

const Bottom = styled.div`
  display: flex;
  justify-content: center;
`;

export interface TitlePageProps {}

export const TitlePage = () => {
  const {
    platformOptions,
    titleForm,
    titleLoading,
    titlePage,
    titlePageTotal,
    titlePageContent,
    titleTranslateLang,
    titleTranslateLoading,
    languageOptions,
    setTitleTranslateLang,
    setTitlePage,
    generateTitle,
    batchTranslateTitle,
    reGenerateTitle,
    resetTitlePageContent,
    updateTitlePageContent,
  } = useContext(ProductContentGeneratorContextV2);
  const { t } = useTranslation('ViewProductContentGenerator');
  const [platform, setPlatform] = useState<Platform>('SHOPEE');
  const copyText = useCopyText();

  const [hotkeyModalKey, setHotkeyModalKey] = useState(0);

  const handleReset = useMemoizedFn(() => {
    resetTitlePageContent();
    setHotkeyModalKey((key) => key + 1);
    message.success(t('clearSuccessMsg'));
  });

  // 标题字符限制配置
  const titleCharactersLimitConfig = useMemo(() => {
    if (platform === 'TIKTOK') {
      return {
        min: 5,
        max: 188,
      };
    }
    return {
      min: 5,
      max: 255,
    };
  }, [platform]);

  const againTitleRef = useRef<HTMLDivElement>(null);

  const optimization = useMemo(
    () =>
      titlePageTotal > 0 ? (
        <>
          <SectionTitle ref={againTitleRef} style={{ marginTop: 16 }}>
            {t('generateAgainLabel')}
          </SectionTitle>
          <Form.Item label={t('highlightInfo')} name="highlightInfo">
            <Input maxLength={100} placeholder={t('inputPlaceholder')} allowClear showCount />
          </Form.Item>
          <Form.Item label={t('weakInfo')} name="weakenInfo">
            <Input maxLength={100} placeholder={t('inputPlaceholder')} allowClear showCount />
          </Form.Item>
        </>
      ) : null,
    [t, titlePageTotal],
  );

  useEffect(() => {
    const { current: againTitle } = againTitleRef;

    if (titlePageTotal === 1 && againTitle) {
      againTitle.scrollIntoView({ behavior: 'smooth' });
    }
  }, [titlePageTotal]);

  const left = (
    <div>
      <Form.Item label={t('platform')} name="platform">
        <CheckboxGroup
          options={platformOptions}
          onChange={(value) => setPlatform(value as Platform)}
        />
      </Form.Item>
      <Form.Item label={t('productBrand')} name="brand">
        <Input maxLength={100} placeholder={t('inputPlaceholder')} allowClear showCount />
      </Form.Item>
      <Form.Item label={t('productType')} name="category">
        <Input maxLength={100} placeholder={t('inputPlaceholder')} allowClear showCount />
      </Form.Item>
      <Form.Item label={t('productPoint')} name="sellingPoint">
        <SellingPointTextArea
          maxLength={1000}
          placeholder={t('sellingPointPlaceholder')}
          allowClear
          showCount
        />
      </Form.Item>
      <Form.Item label={t('inputLang')} name="language">
        <Select options={languageOptions} defaultValue="EN" />
      </Form.Item>
      <MustIncludedKeysField
        description={t('请输入希望出现在标题中的词语，如：Sunscreen，按enter添加')}
        hotkeyModalKey={hotkeyModalKey}
      />
      <MustExcludeKeysField
        description={t('请在此输入禁止出现在标题的词语，如：Free gifts，按enter添加')}
      />
      <Form.Item label={t('limitCondition')}>
        <LimitConditions>
          <FlexCenter>
            <AutoTrans
              ns="ViewProductContentGenerator"
              i18nKey="limitCharacter"
              components={[
                <FormItemInputNumber
                  formName={['titleRestriction', 'length']}
                  formStyle={mb4}
                  defaultValue={100}
                  style={{
                    width: 70,
                    marginLeft: 4,
                    marginRight: 4,
                  }}
                  min={titleCharactersLimitConfig.min}
                  max={titleCharactersLimitConfig.max}
                />,
              ]}
            />
          </FlexCenter>
          <Form.Item
            style={mb4}
            name={['titleRestriction', 'withoutSpecialCharactersAndLinks']}
            valuePropName="checked"
          >
            <Checkbox>{t('productTitleOption2')}</Checkbox>
          </Form.Item>
          <Form.Item
            style={mb4}
            name={['titleRestriction', 'notAllCapitalLetters']}
            valuePropName="checked"
          >
            <Checkbox>{t('productTitleOption3')}</Checkbox>
          </Form.Item>
          <Form.Item
            style={mb4}
            name={['titleRestriction', 'capitalizedFirstLetter']}
            valuePropName="checked"
          >
            <Checkbox>{t('productTitleOption4')}</Checkbox>
          </Form.Item>
          <Form.Item
            style={mb4}
            name={['titleRestriction', 'withoutPunctuation']}
            valuePropName="checked"
          >
            <Checkbox>{t('productTitleOption5')}</Checkbox>
          </Form.Item>
        </LimitConditions>
        {optimization}
      </Form.Item>
      <Bottom>
        <ClearButton onClear={handleReset} />
      </Bottom>
    </div>
  );

  const footer = (
    <Space>
      <FlexCenter>
        <AutoTrans
          ns="ViewProductContentGenerator"
          i18nKey="generateCount"
          components={[
            <FormItemInputNumber
              formName="generateResultNumber"
              formStyle={{ margin: 0 }}
              style={{ width: 60, marginLeft: 4, marginRight: 4 }}
              min={1}
              max={10}
            />,
          ]}
        />
      </FlexCenter>
      <Button type="primary" loading={titleLoading} onClick={() => titleForm?.submit()}>
        {titlePageTotal > 0 ? t('generateAgain') : t('generateNow')}
      </Button>
    </Space>
  );

  const currentPageContent = useMemo(() => {
    return titlePage >= 0 ? titlePageContent[titlePage] : null;
  }, [titlePage, titlePageContent]);

  const right = useMemo(() => {
    if (titleLoading)
      return (
        <Spin tip={t('loadingTips')}>
          <div style={{ height: '300px' }} />
        </Spin>
      );

    // 无数据
    if (titlePageTotal <= 0) {
      return <GeneratorEmpty />;
    }

    const highlightKeys = currentPageContent?.highlightKeys ?? [];
    const main = (
      <>
        <HotWordsDisplay
          currentPageContent={currentPageContent}
          translateLang={titleTranslateLang}
        />
        {currentPageContent?.list.map((item, index) => {
          const [one, two] = item.index;
          const translateContent = item.translateContent?.[titleTranslateLang];
          return (
            <GeneratorCard
              key={`${one}-${two}`}
              title={`${t('productTitle')}${index + 1}`}
              highlightKeys={highlightKeys}
              content={item.content}
              translateContent={translateContent}
              showReGenerate
              onReGenerate={() => {
                if (reGenerateTitle) {
                  return reGenerateTitle?.(item);
                }
                return Promise.resolve();
              }}
              onCopyContent={() => copyText(item.content)}
              onCopyTranslateContent={() => copyText(item.translateContent?.[titleTranslateLang])}
              onContentChange={(content) => {
                updateTitlePageContent(item.index, {
                  ...item,
                  content,
                });
              }}
              onTranslateContentChange={(translateContent) => {
                const translateContentCache = item.translateContent;
                updateTitlePageContent(item.index, {
                  ...item,
                  translateContent: {
                    ...translateContentCache,
                    [titleTranslateLang]: translateContent,
                  } as Record<Language, string>,
                });
              }}
            />
          );
        })}
      </>
    );

    return (
      <>
        <GeneratorHeader
          title={t('titlePageTitle')}
          lang={titleTranslateLang}
          page={titlePage}
          pageTotal={titlePageTotal}
          translateLoading={titleTranslateLoading}
          onChangePage={setTitlePage}
          onChangeLang={setTitleTranslateLang}
          onTranslate={() => {
            if (currentPageContent) {
              batchTranslateTitle?.(currentPageContent, titleTranslateLang);
            }
          }}
        />
        <Spin tip={t('loadingTips')} spinning={titleTranslateLoading}>
          {main}
        </Spin>
      </>
    );
  }, [
    batchTranslateTitle,
    copyText,
    currentPageContent,
    reGenerateTitle,
    setTitlePage,
    setTitleTranslateLang,
    t,
    titleLoading,
    titlePage,
    titlePageTotal,
    titleTranslateLang,
    titleTranslateLoading,
    updateTitlePageContent,
  ]);

  return (
    <Form
      layout="vertical"
      form={titleForm}
      onFinish={(values) => {
        generateTitle?.();
      }}
    >
      <SectionLayout left={left} footer={footer} right={right} />
    </Form>
  );
};
