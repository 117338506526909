import { isDemoChannel } from 'services/channels/demo';
import chatttSDK from 'services/chattt';
import { officialChannel } from 'views/ProductContentGenerator/components/config';
import {
  ImResponse,
  loginClient,
  emalaccaClient,
  storeHomeClient,
  storeHomeV2Client,
  resolve,
  loginClientWithoutVersion,
} from '.';

// eslint-disable-next-line import/prefer-default-export
export const login = (body: { account: string; password: string; channel?: string }) => {
  return loginClient.post<
    ImResponse<{
      token: string;
      redirectPath?: string;
    }>
  >('login', {
    source: chatttSDK ? undefined : 'web_config',
    ...body,
    channel: body.channel || officialChannel,
  });
};

export const loginBySmsCode = (body: { smsCode: string; phone: string; channel?: string }) => {
  return loginClient.post<
    ImResponse<{
      token: string;
      redirectPath?: string;
      passwordTTL: number;
    }>
  >('login_by_code', {
    source: chatttSDK ? undefined : 'web_config',
    ...body,
    channel: body.channel || officialChannel,
  });
};
/**
 * 马六甲 token 登录
 * @param body { token }
 * @returns
 */
export const loginByToken = (body: { token: string }) => {
  return emalaccaClient.post<
    ImResponse<{
      token: string;
    }>
  >('/loginByToken', body);
};

/**
 * 马六甲帐号密码登录
 * @param body { account, password }
 * @returns
 */
export const loginByPassword = (body: { account: string; password: string }) => {
  return emalaccaClient.post<
    ImResponse<{
      token: string;
    }>
  >('/loginByPassword', body);
};

export interface ChannelLoginBody {
  channelCode: string;
  username: string;
  password: string;
}

export interface ChannelLoginResponse {
  token: string;
  redirectPath: string;
}

export const channelLogin = (body: ChannelLoginBody) => {
  return resolve(
    loginClientWithoutVersion.post<ImResponse<ChannelLoginResponse>>('/channel/login', body),
  );
};

export interface ResetPasswordBody {
  phone: string;
  code: string;
  newPassword: string;
}

/**
 * 重置密码
 * @param body { phone, code, newPassword }
 * @returns
 */
export const resetPassword = (body: ResetPasswordBody) => {
  return loginClient.post<
    ImResponse<{
      token: string;
    }>
  >('reset_password', body);
};

export const updateUserInfo = (body: {
  smsCode?: string;
  newPhone?: string;
  oldPassword?: string;
  newPassword?: string;
  account?: string;
}) => {
  if (isDemoChannel) {
    return Promise.reject(new Error('不允许修改'));
  }
  return storeHomeClient.put<ImResponse<{ token: string }>>('admin/user_info', body);
};
export const checkIsPasswordSet = () => {
  return storeHomeV2Client.get<ImResponse<{ isPasswordSet: boolean }>>(
    '/admin/check_password_set ',
  );
};

export interface ChannelInfoData {
  // 渠道标记
  code: string;
  // 渠道名称
  name: string;
  // 渠道试用天数
  trialDays: number;
}

// 获取渠道信息
export const getChannelInfo = () => {
  return resolve(storeHomeV2Client.get<ImResponse<ChannelInfoData>>('admin/channel_info'));
};
