import Cache from 'utils/Cache';
import NoticeCache from 'utils/NoticeCache';

const PREFIX = process.env.REACT_APP_NAME || '';

export const localStorageCache = new Cache(localStorage);
export const sessionStorageCache = new Cache(sessionStorage);

export const noticeCache = new NoticeCache(localStorage, `${PREFIX}/notice`);

export const chatPreferenceStorageCache = new Cache(localStorage, `${PREFIX}/chat-pref`);
export const tempPermissionSessionCache = new Cache(sessionStorage, `${PREFIX}/temp-permission`);

export const sploginStorageCache = new Cache(sessionStorage, `${PREFIX}/splogin`);

export const refundTipsCache = new Cache(sessionStorage, `${PREFIX}/refundTips`);

export const preferenceCache = new Cache(localStorage, `${PREFIX}/preference`);

export const newBadgeCache = new Cache(localStorage, `${PREFIX}/new_badge`);

export const inputDraftCache = new Cache(sessionStorage, `${PREFIX}/inputDraft`);
export const inputDraftCache2 = new Cache(sessionStorage, `${PREFIX}/inputDraft2`);
