import posthog from '@leyan/lytics';
import { notification } from 'antd';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { throttle } from 'lodash-es';
import productLine from 'productLine';
import chatttSDK from 'services/chattt';
import AppConfig from 'services/config/app';
import { getHealthStatus, isNimConnected } from 'services/nim/nimitt';
import AxiosClient, { Interceptors } from 'utils/AxiosClient';
import getCookie from 'utils/getCookie';
import { isIM1 } from 'utils/is';
import logout from 'utils/logout';
import mergeCompat from 'utils/mergeCompat';
import { forbiddenSellerAction } from 'utils/sellerRole';
import { demoRequestInterceptor } from './demo/interceptors';

const defaultLeyanbotHost = process.env.REACT_APP_LEYANBOT_HOST as string;

export const regionHostMapper = {};
let leyanbotHost: string;

export const getLeYanBotHost = () => {
  if (leyanbotHost) return leyanbotHost;
  if (chatttSDK?.app?.getLocaleCountryCode) {
    const countryCode = chatttSDK.app.getLocaleCountryCode();
    // @ts-ignore
    const host = regionHostMapper[countryCode] || defaultLeyanbotHost;
    return host;
  }

  return defaultLeyanbotHost;
};

leyanbotHost = getLeYanBotHost();

export interface ImResponse<T = unknown> {
  code: number;
  msg: string;
  data: T;
}

export function resolve<T>(promise: Promise<AxiosResponse<ImResponse<T>>>) {
  return promise.then((response) => {
    return response.data.data;
  });
}
let nimHealth = getHealthStatus();
const doScan = throttle(async () => {
  window.postMessage({ cmd: 'start_net_scan' }, window.location.origin);
  try {
    await window
      // @ts-ignore
      .doNimHealthSend?.()
      .then(() => {
        nimHealth = 'alive';
      })
      .catch(() => {
        nimHealth = 'error';
      });
  } catch (e) {
    // do nothing
  }
}, 15 * 60 * 1000); // 允许15分钟内执行1次

const reportReq = throttle((cb: () => void) => {
  cb();
  // @建军 在使用该事件作为活跃依据，暂时不能直接下线
  // 不过其不关注内容及指标，可以直接降低 $request 事件的上报频率
}, 5 * 60 * 60 * 1000);
const interceptors: Interceptors = {
  request: [
    async (config: AxiosRequestConfig) => {
      const ret = await mergeCompat(demoRequestInterceptor(config), forbiddenSellerAction(config));
      return {
        ...ret,
        metadata: {
          startTime: Date.now(),
        },
      };
    },
  ],
  response: [
    (response) => {
      const { headers } = response;
      const duration = Date.now() - (response.config as any)?.metadata?.startTime;
      const reportJson = {
        info: {
          duration,
          status: response.status,
          url: response.config.url,
        },
      };
      if (duration >= AppConfig.value.requestReportMinDuration) {
        posthog.capture('$request_slow', reportJson);
      }
      reportReq(() => {
        posthog.capture('$request', reportJson);
      });

      const newToken = headers['new-jwt'];

      if (newToken) {
        const {
          location: { hash },
        } = window;

        const [hashPath, query] = hash.split('?');

        window.location.hash = `${hashPath}?${query ? `${query}&` : ''}token=${newToken}`;
      }
      return response;
    },
    (error) => {
      if (AxiosClient.isAxiosError(error)) {
        const { response, config } = error;
        const errorReport = async () => {
          const nimConnected = isNimConnected();
          nimHealth = getHealthStatus();
          const duration = Date.now() - (response?.config as any)?.metadata?.startTime;
          // duration === 0 大概率异常
          if (error?.message === 'Network Error' || duration === 0) {
            await doScan();
          }
          posthog.capture('$request_err', {
            info: {
              duration,
              status: response?.status,
              url: response?.config.url || config?.url,
              msg: response?.data?.msg || error?.message,
              nim_connected: nimConnected,
              nim_health: nimHealth,
            },
          });
        };
        errorReport();
        if (error.response?.status === 401) {
          notification.error({
            duration: 0,
            message: '未授权的访问',
            description: '授权信息已失效，请尝试重新授权',
          });

          logout();
        }

        if (error.response?.status === 403) {
          window.location.hash = '/403';
        }
      }

      throw error;
    },
  ],
};

const authHeader = process.env.REACT_APP_AUTH_HEADER as string;

export const omsClient = new AxiosClient(
  {
    baseURL: `${leyanbotHost}/overseaim-oms/v1`,
  },
  isIM1 ? undefined : interceptors,
);

export const storeHomeNVClient = new AxiosClient(
  {
    baseURL: `${leyanbotHost}/overseaim-store-home`,
  },
  interceptors,
);

export const storeHomeClient = new AxiosClient(
  {
    baseURL: `${leyanbotHost}/overseaim-store-home/v1`,
  },
  isIM1 ? undefined : interceptors,
);
export const storeHomeV2Client = new AxiosClient(
  {
    baseURL: `${leyanbotHost}/overseaim-store-home/v2`,
  },
  isIM1 ? undefined : interceptors,
);
/**
 * Basically keep asame with storeHomeClient,
 * however, skip the default interceptors,
 * in most login case the 403/401 needn't a redirect.
 * and it doesn't need a pre-auth with valid token
 */
export const loginClient = new AxiosClient({
  baseURL: `${leyanbotHost}/overseaim-store-home/v1`,
});

export const loginClientV2 = new AxiosClient({
  baseURL: `${leyanbotHost}/overseaim-store-home/v2`,
});

export const loginClientWithoutVersion = new AxiosClient({
  baseURL: `${leyanbotHost}/overseaim-store-home`,
});

export const emalaccaClient = new AxiosClient({
  baseURL: `${leyanbotHost}/overseaim-store-home/emalacca`,
});

/** 会话列表 */
export const conversationClient = new AxiosClient(
  {
    baseURL: `${leyanbotHost}/oversea-door/oversea-conversation/v1`,
  },
  interceptors,
);

export const overseaPayClient = new AxiosClient(
  {
    baseURL: `${leyanbotHost}/oversea-pay`,
  },
  interceptors,
);

export const doorClient = new AxiosClient(
  {
    baseURL: `${leyanbotHost}/oversea-door`,
  },
  interceptors,
);

export const userVoiceClient = new AxiosClient(
  {
    baseURL: `${leyanbotHost}/oversea-user-voice`,
  },
  interceptors,
);

export const courierClient = new AxiosClient(
  {
    baseURL: `${leyanbotHost}/courier`,
  },
  interceptors,
);

export const hiManagerClient = new AxiosClient(
  {
    baseURL: `${leyanbotHost}/oversea-door/overseaim-hi-manager/v1`,
  },
  interceptors,
);

export const chatgptClient = new AxiosClient(
  {
    baseURL: `${leyanbotHost}/oversea-chatgpt/oversea-chatgpt`,
  },
  interceptors,
);

export const flagrClient = new AxiosClient({
  baseURL: process.env.REACT_APP_FLAGR_ENDPOINT,
});

export const commentClient = new AxiosClient({
  baseURL: `${leyanbotHost}/oversea-inspire`,
});

let localClientPort: number;
let localAxios: AxiosClient;
export const localClient = async () => {
  try {
    const res = await chatttSDK?.kage.getJSON();

    if (res) {
      if (res.port !== localClientPort) {
        localClientPort = res.port;
        localAxios = new AxiosClient({
          baseURL: `http://127.0.0.1:${localClientPort}`,
          headers: {
            'Cache-Control': 'no-cache',
          },
        });
      }
      return localAxios;
    }
    // throw new Error('kage getJSON res error');
    return undefined;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    throw err;
  }
};

const clients = [
  ...(isIM1 ? [] : [omsClient]),
  storeHomeNVClient,
  storeHomeClient,
  storeHomeV2Client,
  conversationClient,
  doorClient,
  loginClient,
  courierClient,
  hiManagerClient,
  userVoiceClient,
  chatgptClient,
  commentClient,
];

clients.forEach((client) => {
  client.patchConfig({
    headers: {
      ...client.getConfig().headers,
      'x-lazada-ati': getCookie()._ati,
      'x-product-line': productLine,
    },
  });
});

export function authorize(token: string) {
  clients.forEach((client) => {
    client.patchConfig({
      headers: {
        ...client.getConfig().headers,
        [authHeader]: `Bearer ${token}`,
      },
    });
  });
}

const allClients = [
  omsClient,
  storeHomeNVClient,
  storeHomeClient,
  storeHomeV2Client,
  loginClient,
  loginClientV2,
  loginClientWithoutVersion,
  emalaccaClient,
  conversationClient,
  overseaPayClient,
  doorClient,
  userVoiceClient,
  courierClient,
  hiManagerClient,
  flagrClient,
];

allClients.forEach((client) => {
  client.patchConfig({
    headers: {
      ...client.getConfig().headers,
    },
  });
});
