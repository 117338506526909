import { throttle } from 'lodash-es';

let parserCache: Record<string, LocalPhone | null> = {};

const clearCache = throttle(() => {
  parserCache = {};
}, 10 * 1000 * 60); // 10分钟清空一次

export const phoneRegionCodesMap: General.PhoneRegionCodesMap = {
  cn: '+86',
  tw: '+886',
  hk: '+852',
  mo: '+853',
  my: '+60',
  id: '+62',
  th: '+66',
  ph: '+63',
  sg: '+65',
  vn: '+84',
  br: '+55',
  mx: '+52',
  cl: '+56',
  ar: '+54',
  co: '+57',
  fr: '+33',
  es: '+34',
  pl: '+48',
} as const;
export const phoneCodesArr: Array<General.PhoneRegionCodes> = [];
export const phoneCodesRegArr: Array<RegExp> = [];
export const phoneCodeRegionsMap: General.PhoneCodeRegionsMap = Object.keys(
  phoneRegionCodesMap,
).reduce((res, key) => {
  const code = phoneRegionCodesMap[key];
  res[code] = key;
  phoneCodesArr.push(code);
  phoneCodesRegArr.push(new RegExp(`(^${code.replace(/\+/g, '\\+')})(\\d+$)`));
  return res;
}, {} as General.PhoneCodeRegionsMap);
export type LocalPhoneString = `${General.PhoneRegionCodes} ${number}`;
export type LocalPhone = {
  code: General.PhoneRegionCodes;
  phone: `${number}`;
};
export const localPhoneStringify = ({ code, phone }: LocalPhone) => {
  return `${code} ${phone.trim()}` as LocalPhoneString;
};
export function localPhoneParse(raw: undefined): null;
export function localPhoneParse(raw: LocalPhoneString): LocalPhone;
export function localPhoneParse(raw: string): null | LocalPhone;
export function localPhoneParse(raw?: LocalPhoneString | string): LocalPhone | null {
  if (!raw) {
    return null;
  }
  if (parserCache[raw]) {
    return parserCache[raw];
  }
  const match1 = raw.match(/(^\+\d+)\s{0,1}(\d+$)/);
  const dealWithMatch = (match: string[] | undefined | null) => {
    if (!match) {
      return null;
    }
    const [intact, code, phone] = match;
    if (code && phoneCodeRegionsMap[code] && phone) {
      return {
        code: code as General.PhoneRegionCodes,
        phone: phone as `${number}`,
      };
    }

    if (intact && phoneCodeRegionsMap[intact]) {
      return {
        code: intact,
        phone: '',
      } as unknown as LocalPhone;
    }

    return null;
  };
  let rst: LocalPhone | null = dealWithMatch(match1);
  if (!rst) {
    phoneCodesRegArr.find((reg) => {
      const eachMatch = raw.match(reg);
      rst = dealWithMatch(eachMatch);
      return !!rst;
    });
  }
  parserCache[raw] = rst;
  // 自回收(使用throttle保证异步及回收频率)
  clearCache();
  return rst;
}
export const phoneEncode = (phone: string, mask = '****') =>
  phone.replace(/(^\+?\d{5})\d{4}(.+$)/, `$1${mask}$2`);
