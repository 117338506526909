import { useMemoizedFn } from 'ahooks3';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

const useCopyText = () => {
  const { t } = useTranslation('ViewProductContentGenerator');

  const copyText = useMemoizedFn((text?: string) => {
    if (text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          message.success(t('copySuccess'));
        })
        .catch(() => {
          message.error(t('copyFail'));
        });
    }
  });

  return copyText;
};

export default useCopyText;
