import { useMemoizedFn } from 'ahooks3';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import useLogout from './useLogout';

const useApiError = () => {
  const { t } = useTranslation('ViewProductContentGenerator');
  const logout = useLogout();

  const handler = useMemoizedFn((err, fallback) => {
    if (err?.message?.includes('429')) {
      Modal.warning({
        title: t('freeQuotaTips'),
        okText: t('freeQuotaBtn'),
        onOk: () => {
          logout('/registry');
        },
      });
    } else if (err?.message?.includes('504')) {
      Modal.warning({
        title: t('timeoutTips'),
        okText: t('okText'),
      });
    } else if (fallback) {
      Modal.warning({
        title: fallback,
        okText: t('okText'),
      });
    }
  });

  return handler;
};

export default useApiError;
