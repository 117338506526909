import React from 'react';
import styled from '@emotion/styled';
import { useSystemLanguageValue } from 'hooks/business/system/useSystem';
import AutoTrans from 'components/AutoTrans';
import circlePic from '../../assets/circle.png';

export interface BizCircleProps {}

const biz = [
  {
    title: '基础客服接待',
    subtitle: '提升效率',
  },
  {
    title: 'AI智能接待',
    subtitle: '节省人力成本',
  },
  {
    title: '智能催下单',
    subtitle: '提升店铺成单率',
  },
  {
    title: '智能催付款',
    subtitle: '提升付款率',
  },
  {
    title: '智能老客召回',
    subtitle: '提升店铺复购率',
  },
  {
    title: '智能评价管理',
    subtitle: '拉高店铺评分',
  },
  {
    title: '智能退单挽回',
    subtitle: '减少货品及运费损失',
  },
  {
    title: '智能物流呵护',
    subtitle: '降低COD订单拒收率',
  },
] as const;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  width: 450px;
  height: 440px;
  > div {
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25%;
  }
`;
const Img = styled.img`
  position: absolute;
  top: 8px;
  left: calc(50% - 170px);
  width: 340px;
`;
const Point = styled.div`
  flex: 0 0 50%;
  padding: 12px 18px;
  background: #f7faff;
  border: 1px solid rgba(254, 255, 255, 1);
  border-radius: 60px;
  color: #25365e;
  font-size: 12px;
  text-align: center;
  word-break: keep-all;
  line-height: 1em;
  z-index: 2;
  h4 {
    color: #165dff;
    font-size: 14px;
    margin-bottom: 0 0 10px 0;
  }
  h5 {
    font-size: 12px;
    margin: 0;
  }
`;
const BizCircle = (props: BizCircleProps) => {
  const systemLanguage = useSystemLanguageValue();

  return (
    <Wrapper>
      <Img alt="" src={circlePic} />
      {biz.map((b) => {
        return (
          <div key={b.title}>
            <Point>
              <h4>
                <AutoTrans
                  ns="ViewStartups"
                  i18nKey={`startup.container.slider2.points.tittle.${b.title}`}
                />
              </h4>
              {systemLanguage === 'zh-CN' && <h5>{b.subtitle}</h5>}
            </Point>
          </div>
        );
      })}
    </Wrapper>
  );
};
export default React.memo(BizCircle);
