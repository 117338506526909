import { useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import chatttSDK from 'services/chattt';
import useShopeeCountdown from './useShopeeCountdown';
import useShopeeUtils from './useShopeeUtils';

export enum AuthMode {
  Single,
  Multi,
}

export enum AuthShopsStatus {
  Idle,
  Start,
  Pending,
  Complete,
}

const INIT_PROGRESS_INFO = {
  progress: 0,
  allAccounts: 0,
  waitAuthAccounts: 0,
  authSuccessAccounts: 0,
  authFailAccounts: 0,
  authedAccounts: 0,
  authedShops: 0,
  authFailedShops: 0,
};
export interface AuthShopCaptchaState {
  visible: boolean;
  authAgent: RPA.Shopee.AuthAgentJSON | null;
}

export const authShopsTaskId = atom<string>({
  key: 'shopeeAuthShopsTaskId',
  default: '',
});

export const authShopsStatus = atom<AuthShopsStatus>({
  key: 'shopeeAuthShopsStatus',
  default: AuthShopsStatus.Idle,
});

export const authShopsMode = atom<AuthMode | null>({
  key: 'shopeeAuthShopsMode',
  default: null,
});

export const authShopsResultState = atom<RPA.Shopee.AuthAgentJSON[]>({
  key: 'shopeeAuthShopsResultState',
  default: [],
});

export const authShopsProgressInfo = atom<RPA.Shopee.AuthProgress>({
  key: 'shopeeAuthShopsProgressInfo',
  default: INIT_PROGRESS_INFO,
});

export const authShopsModalVisible = atom<boolean>({
  key: 'shopeeAuthModalVisible',
  default: false,
});

export const authShopCaptchaState = atom<AuthShopCaptchaState>({
  key: 'shopeeAuthShopCaptcha',
  default: {
    visible: false,
    authAgent: null,
  },
});

// 连续授权失败计数
export const continuousAuthFailCountState = atom<number>({
  key: 'continuousAuthFailCountState',
  default: 0,
});

// 是否快速模式
export const isQuickModeState = atom<boolean>({
  key: 'isQuickModeState',
  default: false,
});

// 是否停止事件监听处理
export const stopMessageHandlerState = atom<boolean>({
  key: 'stopMessageHandlerState',
  default: false,
});

const COUNTDOWN_SECONDS = 1000 * 60 * 15;

const useShopeeAuthShops = () => {
  const { getShoppeMultipleAuthProgressInfo, posthogShopeeAuthShop } = useShopeeUtils();
  const setShopeeAuthShopsTaskId = useSetRecoilState(authShopsTaskId);
  const setShopeeAuthShopsStatus = useSetRecoilState(authShopsStatus);
  const setShopeeAuthShopsMode = useSetRecoilState(authShopsMode);
  const setShopeeAuthShopsResult = useSetRecoilState(authShopsResultState);
  const setShopeeAuthShopsProgressInfo = useSetRecoilState(authShopsProgressInfo);
  const setShopeeAuthShopsModalVisible = useSetRecoilState(authShopsModalVisible);
  const setShopeeAuthShopCaptchaState = useSetRecoilState(authShopCaptchaState);
  const authCaptchaState = useRecoilValue(authShopCaptchaState);
  const storeTypeModalVisible = useRecoilValue(authShopsModalVisible);
  const taskId = useRecoilValue(authShopsTaskId);

  const stopMessageHandler = useRecoilValue(stopMessageHandlerState);

  const authStatus = useRecoilValue(authShopsStatus);
  const authMode = useRecoilValue(authShopsMode);
  const authProgressInfo = useRecoilValue(authShopsProgressInfo);
  const authResult = useRecoilValue(authShopsResultState);

  const [continuousAuthFailCount, setContinuousAuthFailCount] = useRecoilState(
    continuousAuthFailCountState,
  );
  const [isQuickMode, setIsQuickMode] = useRecoilState(isQuickModeState);

  const { startCountdown, stopCountdown, countdown } = useShopeeCountdown({
    onEnd: () => {
      skipCaptcha();
    },
  });

  const showStoreTypeModal = () => {
    setShopeeAuthShopsModalVisible(true);
  };
  const hideStoreTypeModal = () => {
    setShopeeAuthShopsModalVisible(false);
  };

  const messageHandler = (evt: MessageEvent) => {
    const { event: eventName, payload } = evt.data;
    if (process.env.NODE_ENV === 'development') {
      if (evt.data.source) return;
      // eslint-disable-next-line no-console
      console.log('shopee auth message: ', evt.data);
    }
    switch (eventName as RPA.Shopee.DispatchToWebMessageHooks) {
      // 授权开始
      case 'shopAuth:start':
        setShopeeAuthShopsStatus(AuthShopsStatus.Start);
        break;
      // 授权进度信息
      case 'shopAuth:progress':
        setShopeeAuthShopsStatus(AuthShopsStatus.Pending);
        setShopeeAuthShopsProgressInfo(getShoppeMultipleAuthProgressInfo(payload.data));
        setShopeeAuthShopCaptchaState({
          visible: false,
          authAgent: null,
        });
        stopCountdown();

        // 连续授权失败计数处理
        if (typeof payload.index === 'number' && payload.data[payload.index]) {
          const cur = payload.data[payload.index] as RPA.Shopee.AuthAgentJSON;
          if (cur.status !== 'DoneWithFinishAll' && cur.status.startsWith('Done')) {
            setContinuousAuthFailCount((prev) => prev + 1);
          } else if (cur.status === 'DoneWithFinishAll') {
            setContinuousAuthFailCount(0);
          }
        }

        posthogShopeeAuthShop(payload.data[payload.index]);
        break;
      // 授权完成
      case 'shopAuth:complete':
        setShopeeAuthShopsStatus(AuthShopsStatus.Complete);
        setShopeeAuthShopsResult(payload.data);
        hideStoreTypeModal();
        setContinuousAuthFailCount(0);
        break;
      // 短信验证码 / 短信验证失败重试 / 图片验证码
      case 'shopAuth:waitForOTP':
      case 'shopAuth:waitForOTPRetry':
      case 'shopAuth:waitForImageCaptcha':
        startCountdown(COUNTDOWN_SECONDS);
        setShopeeAuthShopCaptchaState({
          visible: true,
          authAgent: payload,
        });
        break;
      default:
        stopCountdown();
        break;
    }
  };

  const reset = () => {
    if (chatttSDK?.shopee?.multiAuth) {
      chatttSDK?.shopee?.multiAuth('delete', { taskId });
    }
    setShopeeAuthShopsTaskId('');
    setShopeeAuthShopsStatus(AuthShopsStatus.Idle);
    setShopeeAuthShopsResult([]);
    setShopeeAuthShopsProgressInfo(INIT_PROGRESS_INFO);
    setShopeeAuthShopCaptchaState({
      visible: false,
      authAgent: null,
    });
    stopCountdown();
  };

  const skipCaptcha = () => {
    if (chatttSDK?.shopee?.multiAuth && authCaptchaState.authAgent) {
      chatttSDK?.shopee?.multiAuth('skip:interrupt', {
        taskId,
        subTaskId: authCaptchaState.authAgent.id,
      });

      setShopeeAuthShopCaptchaState({
        visible: false,
        authAgent: null,
      });

      stopCountdown();
    }
  };

  const continueImageCaptcha = () => {
    if (chatttSDK?.shopee?.multiAuth && authCaptchaState.authAgent) {
      const { id } = authCaptchaState.authAgent;
      chatttSDK.shopee.multiAuth('continue:imageCaptcha', {
        taskId,
        subTaskId: id,
      });
      stopCountdown();
    }
  };

  const continueSmsCaptcha = (inputValue: string) => {
    if (chatttSDK?.shopee?.multiAuth && authCaptchaState.authAgent) {
      chatttSDK.shopee.multiAuth('continue:otp', {
        taskId,
        subTaskId: authCaptchaState.authAgent.id,
        otp: inputValue,
      });

      setShopeeAuthShopCaptchaState({
        visible: false,
        authAgent: null,
      });
      stopCountdown();
    }
  };

  const terminateAuth = () => {
    if (chatttSDK?.shopee?.multiAuth) {
      if (taskId) {
        chatttSDK?.shopee?.multiAuth('cancel', {
          taskId,
        });
        stopCountdown();
      }
    }
  };

  const isMultipleAuthProgressPending = useMemo(() => {
    return (
      authMode === AuthMode.Multi &&
      (authStatus === AuthShopsStatus.Start || authStatus === AuthShopsStatus.Pending) &&
      authProgressInfo !== null
    );
  }, [authMode, authProgressInfo, authStatus]);

  const isMultipleAuthResultCompleted = useMemo(() => {
    return (
      authStatus === AuthShopsStatus.Complete && authMode === AuthMode.Multi && authResult !== null
    );
  }, [authMode, authResult, authStatus]);

  const countdownFormatter = useMemo(() => {
    if (countdown) {
      const minutes = countdown.minutes.toString().padStart(2, '0');
      const seconds = countdown.seconds.toString().padStart(2, '0');
      return `${minutes}:${seconds}`;
    }
    return '';
  }, [countdown]);

  const showAuthErrorTips = useMemo(() => continuousAuthFailCount >= 10, [continuousAuthFailCount]);

  return {
    messageHandler,
    setShopeeAuthShopsTaskId,
    setShopeeAuthShopsMode,
    setShopeeAuthShopsResult,
    setShopeeAuthShopsProgressInfo,
    setShopeeAuthShopCaptchaState,
    storeTypeModalVisible,
    showStoreTypeModal,
    hideStoreTypeModal,
    reset,
    authCaptchaState,
    taskId,
    skipCaptcha,
    terminateAuth,
    isMultipleAuthProgressPending,
    isMultipleAuthResultCompleted,
    authStatus,
    authMode,
    authProgressInfo,
    authResult,
    countdown,
    countdownFormatter,
    showAuthErrorTips,
    isQuickMode,
    setIsQuickMode,
    continueImageCaptcha,
    continueSmsCaptcha,
    stopMessageHandler,
  };
};

export default useShopeeAuthShops;
