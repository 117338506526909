import { useCallback, useState } from 'react';
import { Switch, SwitchProps } from 'antd';

export interface StatusSwitchProps extends Omit<SwitchProps, 'onChange'> {
  onChange: (...params: Parameters<Required<SwitchProps>['onChange']>) => Promise<any>;
}

function StatusSwitch(props: StatusSwitchProps) {
  const { onChange, ...otherProps } = props;

  const [loading, setLoading] = useState(false);

  return (
    <Switch
      loading={loading}
      onChange={useCallback(
        (checked, e) => {
          setLoading(true);

          onChange(checked, e).finally(() => {
            setLoading(false);
          });
        },
        [onChange],
      )}
      {...otherProps}
    />
  );
}

export default StatusSwitch;
