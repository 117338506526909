import { compare } from 'compare-versions';
import { isFunction } from 'lodash-es';
import chatttSDK from './sdk';

export * from './channels/const';
export { hidePayment } from './channels/features';

export default chatttSDK;

export const syncSysSettings = (settings: Chattt.SystemSettings) => {
  if (chatttSDK?.syncSysSettings) {
    chatttSDK.syncSysSettings(settings);
  }
};
export const isMlj =
  chatttSDK?.app.channel === 'SELF_EMALACCA' || chatttSDK?.app.channel === 'EMALACCA';

export const canMultiAuth = typeof chatttSDK?.shopee?.multiAuth === 'function';

export const getVersion = () => {
  if (typeof chatttSDK?.app?.getVersionSync === 'function') {
    return chatttSDK.app.getVersionSync();
  }
  return localStorage.getItem('chatppVer') || '';
};

export const canMoveDatabase =
  chatttSDK && compare(getVersion(), '1.10', '>=') && compare(getVersion(), '2.6', '<');

export const canChkPageTrans = chatttSDK && compare(getVersion(), '2.3.4', '>=');

export const canZoomView = chatttSDK && compare(getVersion(), '2.4.0', '>=');

export const canNetScan = chatttSDK && compare(getVersion(), '2.5.0', '>=');

// @ts-ignore
export const isTokoBridgeCallApiSupported = chatttSDK && isFunction(chatttSDK?.tokoBridge?.callApi);

export const localeCountryCode = chatttSDK?.app?.getLocaleCountryCode
  ? chatttSDK.app.getLocaleCountryCode()
  : '';
