import getNimCacheId from 'hooks/business/im/getNimCacheId';
import { nimEventReport } from 'components/Nim/nimReport';
import wait from 'utils/wait';
import beginHearbeat from 'services/heartBeat';
import notification from 'services/notification';
import chatttSDK from 'services/chattt';
import focusChatttClient from 'utils/focusChatttClient';
import i18n from 'services/i18n';
import nimMsgContentParser from './nimMsgContentParser';
import promisifyNimMethod from './promisify';
import nimitt from './nimitt';

export const NIM_LOGIN_SIGNAL = Symbol('NIM_LOGIN_SIGNAL');
export type Events =
  | 'message'
  | 'connect'
  | 'disconnect'
  | 'error'
  | 'willReconnect'
  | `sys:message:${Lowercase<NimSystemMessageType>}`;
type Off = () => void;

export type NimSystemMessageType =
  | 'HEALTH_ACK'
  | 'ACK'
  | 'ACK_TIMEOUT'
  | 'ASSIGN_FAIL'
  | 'UNREAD_COUNT'
  | 'READ_EVENT'
  | 'FOLLOWER_EVENT'
  | 'AI_REPLY'
  | 'OFFICIAL_READ_MARK'
  | 'ASSISTANT_READ_MARK'
  | 'REMIND_SCENE'
  | 'REMIND_SOLVE'
  | 'STORE_TRANSFER'
  | 'MESSAGE_EVENT_STREAM'
  | 'REMOVE_TALK_CONTROL'
  | 'PAY'
  | 'PULL_NOTICE_MSG'
  | 'SYS_REMIND_TRIGGER'
  | 'SYS_REMIND_MANUAL_CLOSE'
  | 'SYS_REMIND_AUTO_CLOSE'
  | 'SYS_WA_REJECT'
  | 'SYS_ASTERISK_STAR'
  | 'RECALL'
  | 'SYNC_TIKTOK_TRADE_UID'
  | 'BATCH_TRANSFER'
  | 'SEND_MSG_REQ';
export interface NimSystemBasicMessage {
  type: NimSystemMessageType;
  content: any;
}
export interface NimSystemACKMessage extends NimSystemBasicMessage {
  type: 'ACK';
  content: {
    msgId: number;
    yunXinMsgId: Conversation.Message['yunXinMsgId'];
    success: boolean;
    failCause?: Conversation.FailContent;
  };
}
export interface NimSystemHealthACKMessage extends NimSystemBasicMessage {
  uuid: string;
}
export interface NimSystemACKTimeoutMessage extends NimSystemBasicMessage {
  type: 'ACK_TIMEOUT';
  content: {
    // msgId: number;
    yunXinMsgId: Conversation.Message['yunXinMsgId'];
    message: 'timeout';
  };
}
export interface NimSytemAssignFailMessage extends NimSystemBasicMessage {
  type: 'ASSIGN_FAIL';
  content: {
    conversationId: string;
  };
}
export interface NimSystemRemoveTalkControlMessage extends NimSystemBasicMessage {
  type: 'REMOVE_TALK_CONTROL';
  content: {
    conversationId: string;
    talkId: string;
    userId: string;
  };
}

export interface NimSystemEventStreamMessage extends NimSystemBasicMessage {
  type: 'MESSAGE_EVENT_STREAM';
  content: {
    externalStoreId: string;

    platformType: Review.Platform;

    region: General.UpperCaseRegion;

    externalBuyerId: string;

    buyerNick: string;

    eventType: 'RETURN_ORDER' | 'IN_CANCEL_ORDER' | 'COMMENT';
    content: string;
  };
}

export type ReadStatus = 'mark_as_read' | 'mark_as_unread';

export interface NimSytemUnreadCountMessage extends NimSystemBasicMessage {
  type: 'UNREAD_COUNT';
  content: {
    conversationId: string;
    status: ReadStatus;
    count: number;
    readTime: number;
    talkId: string;
  };
}

export interface NimSystemReadEventMessage extends NimSystemBasicMessage {
  type: 'READ_EVENT';
  content: {
    conversationId: number;
    readTime: number;
  };
}

export interface NimSystemFollowerEventMessage extends NimSystemBasicMessage {
  type: 'FOLLOWER_EVENT';
  content: {
    conversationId: number;
    follower: boolean;
  };
}

export interface NimSystemAIReplyMessage extends NimSystemBasicMessage {
  type: 'AI_REPLY';
  content: Conversation.AIReply.QueryReply;
}

export interface NimSystemOfficialReadMarkMessage extends NimSystemBasicMessage {
  type: 'OFFICIAL_READ_MARK';
  content: {
    conversationId: number;
    status: ReadStatus;
    timestamp: number;
  };
}

export interface NimSystemAssistantReadMarkMessage extends NimSystemBasicMessage {
  type: 'ASSISTANT_READ_MARK';
  content: {
    conversationId: number;
    talkId: number;
    status: ReadStatus;
    timestamp: number;
  };
}

export interface NimSystemRemindSceneMessage extends NimSystemBasicMessage {
  type: 'REMIND_SCENE';
  content: {
    conversationId: number;
    scene: string;
    hint: {
      zhCN: string;
      enUS: string;
    };
    timestamp: number;
  };
}

export interface NimSystemRemindSolveMessage extends NimSystemBasicMessage {
  type: 'REMIND_SOLVE';
  content: {
    conversationId: number;
    timestamp: number;
    processed: boolean;
  };
}

export interface NimSystemStoreTransferMessage extends NimSystemBasicMessage {
  type: 'STORE_TRANSFER';
}

export interface NimSystemPayMessage extends NimSystemBasicMessage {
  type: 'PAY';
  content: {
    result: 'success' | 'cancel' | 'fail';
    orderCode: string;
  };
}

export interface NimSystemBatchTransferMessage extends NimSystemBasicMessage {
  type: 'BATCH_TRANSFER';
  content: {
    success: boolean;
    failItem: Array<{ conversationId: number; code: number; msg: string }>;
  };
}

export interface NimSystemSyncTiktokTradeUidMessage extends NimSystemBasicMessage {
  type: 'SYNC_TIKTOK_TRADE_UID';
  content: { success: boolean; conversationId: number; tradeUid: string };
}

export interface NimSystemSendMsgReqMessage extends NimSystemBasicMessage {
  type: 'SEND_MSG_REQ';
  externalType: 'TOKOPEDIA';
  // 返回的时候带回来 标识唯一请求
  payload: {
    bizId: string;
    storeId: string;
    msgType: 'TEXT' | 'IMAGE';
    text: string;
    url: string;
  };
}

export interface NimMessageContent {
  msgId: number;
  yunXinMsgId: Conversation.Message['yunXinMsgId'];
  success: boolean;
}
/**
 * 描述
 * @date 2021-08-28
 * @param {any} e:'message'
 * @param {any} cb:Nim.OnMsgCb
 * @returns {any} 卸载 : () => void
 */
function on(e: 'message', cb: (message: Conversation.Message) => void): Off;
function on(e: 'message', cb: (message: Resource.Message) => void): Off;
function on(e: 'connect', cb: Nim.OnConnectCb): Off;
function on(e: 'disconnect', cb: Nim.OnDisconnectCb): Off;
function on(e: 'willReconnect', cb: Nim.OnWillreConnectCb): Off;
function on(e: 'error', cb: Nim.OnErrorCb): Off;
function on(e: 'sys:message:ack', cb: (p: NimSystemACKMessage) => void): Off;
function on(e: 'sys:message:ack_timeout', cb: (p: NimSystemACKTimeoutMessage) => void): Off;
function on(e: 'sys:message:assign_fail', cb: (p: NimSytemAssignFailMessage) => void): Off;
function on(e: 'sys:message:unread_count', cb: (p: NimSytemUnreadCountMessage) => void): Off;
function on(e: 'sys:message:read_event', cb: (p: NimSystemReadEventMessage) => void): Off;
function on(e: 'sys:message:follower_event', cb: (p: NimSystemFollowerEventMessage) => void): Off;
function on(e: 'sys:message:ai_reply', cb: (p: NimSystemAIReplyMessage) => void): Off;
function on(
  e: 'sys:message:official_read_mark',
  cb: (p: NimSystemOfficialReadMarkMessage) => void,
): Off;
function on(
  e: 'sys:message:assistant_read_mark',
  cb: (p: NimSystemAssistantReadMarkMessage) => void,
): Off;
function on(e: 'sys:message:remind_scene', cb: (p: NimSystemRemindSceneMessage) => void): Off;
function on(e: 'sys:message:remind_solve', cb: (p: NimSystemRemindSolveMessage) => void): Off;
function on(e: 'sys:message:store_transfer', cb: (p: NimSystemStoreTransferMessage) => void): Off;
function on(e: 'sys:message:pay', cb: (p: NimSystemPayMessage) => void): Off;
function on(e: 'sys:message:pull_notice_msg', cb: (p: NimSystemBasicMessage) => void): Off;
function on(
  e: 'sys:message:message_event_stream',
  cb: (p: NimSystemEventStreamMessage) => void,
): Off;
function on(
  e: 'sys:message:remove_talk_control',
  cb: (p: NimSystemRemoveTalkControlMessage) => void,
): Off;
function on(e: 'sys:message:batch_transfer', cb: (p: NimSystemBatchTransferMessage) => void): Off;
function on(
  e: 'sys:message:sync_tiktok_trade_uid',
  cb: (p: NimSystemSyncTiktokTradeUidMessage) => void,
): Off;
function on(e: 'sys:message:send_msg_req', cb: (p: NimSystemSendMsgReqMessage) => void): Off;

function on(e: 'sys:message:health_ack', cb: (p: NimSystemHealthACKMessage) => void): Off;

function on(e: typeof NIM_LOGIN_SIGNAL, cb: (p: Nim.GetInstanceParam) => any): Off;

function on(e: Events | typeof NIM_LOGIN_SIGNAL, cb: any) {
  nimitt.on(e, cb);
  return () => nimitt.off(e, cb);
}

export type EmitEvents =
  | 'message'
  | 'connect'
  | 'disconnect'
  | 'error'
  | 'willReconnect'
  | `sys:message:${Lowercase<NimSystemMessageType>}`;
/** 消息(包括系统消息) */
function emit(e: 'message', message: Conversation.Message): void;
function emit(e: 'connect', param: Nim.OnconnectParam): void;
function emit(e: 'disconnect', param: Nim.OndisconnectParam): void;
function emit(e: 'willReconnect', param: Nim.OnwillreconnectParam): void;
// #region 系统通知
function emit(e: 'sys:message:health_ack', param: NimSystemHealthACKMessage): void;
function emit(e: 'sys:message:ack', param: NimSystemACKMessage): void;
function emit(e: 'sys:message:ack_timeout', param: NimSystemACKTimeoutMessage): void;
function emit(e: 'sys:message:assign_fail', param: NimSytemAssignFailMessage): void;
function emit(e: 'sys:message:unread_count', param: NimSytemUnreadCountMessage): void;
function emit(e: 'sys:message:read_event', param: NimSystemReadEventMessage): void;
function emit(e: 'sys:message:follower_event', param: NimSystemFollowerEventMessage): void;
function emit(e: 'sys:message:ai_reply', param: NimSystemAIReplyMessage): void;
function emit(e: 'sys:message:official_read_mark', param: NimSystemOfficialReadMarkMessage): void;
function emit(e: 'sys:message:assistant_read_mark', param: NimSystemAssistantReadMarkMessage): void;
function emit(e: 'sys:message:remind_scene', param: NimSystemRemindSceneMessage): void;
function emit(e: 'sys:message:remind_solve', param: NimSystemRemindSolveMessage): void;
function emit(e: 'sys:message:store_transfer', param: NimSystemStoreTransferMessage): void;
function emit(e: 'sys:message:pay', param: NimSystemPayMessage): void;
function emit(e: 'sys:message:pull_notice_msg', param: NimSystemBasicMessage): void;
function emit(e: 'sys:message:message_event_stream', param: NimSystemEventStreamMessage): void;
function emit(e: 'sys:message:remove_talk_control', param: NimSystemRemoveTalkControlMessage): void;
function emit(e: 'sys:message:batch_transfer', param: NimSystemBatchTransferMessage): void;
function emit(
  e: 'sys:message:sync_tiktok_trade_uid',
  param: NimSystemSyncTiktokTradeUidMessage,
): void;
function emit(e: 'sys:message:send_msg_req', params: NimSystemSendMsgReqMessage): void;
// #endregion
function emit(e: 'error', param: Nim.ErrorParam): void;

function emit(e: EmitEvents, p: any) {
  nimitt.emit(e, p);
}

let __instance__: Nim.NimInstance | null = null;

export interface SendParams<C extends Object> {
  to: string;
  content: C;
  scene: Nim.MsgScene;
}
const NIM_ENV = process.env.REACT_APP_NIM_ENV as 'nta' | 'sth' | 'dew';

// ack timeout map
const ackTimeoutTaskMap: Record<
  NimSystemACKMessage['content']['yunXinMsgId'],
  {
    timeout: NodeJS.Timeout;
    status: 'ack' | 'timeover' | 'pending';
    sentTimeMs: number;
  }
> = {};
const send = async <C extends Object>(param: SendParams<C>) => {
  const { to, scene, content } = param;
  const resp = await promisifyNimMethod(
    __instance__,
    'sendCustomMsg',
  )({
    scene,
    to,
    content: JSON.stringify(content),
    env: NIM_ENV,
  });
  if (resp.idServer) {
    //  handle with ack timeout
    const now = new Date().valueOf();
    const timeout = setTimeout(() => {
      if (ackTimeoutTaskMap[resp.idServer]?.status !== 'pending') {
        return;
      }
      ackTimeoutTaskMap[resp.idServer].status = 'timeover';
      emit('sys:message:ack_timeout', {
        type: 'ACK_TIMEOUT',
        content: {
          yunXinMsgId: resp.idServer,
          message: 'timeout',
        },
      } as NimSystemACKTimeoutMessage);
      nimEventReport('nim:local:message:ack:timeout', {
        yunXinMsgId: resp.idServer,
      });

      // @qingsong.ding 10秒必超時
    }, 15 * 1000);
    ackTimeoutTaskMap[resp.idServer] = {
      timeout,
      status: 'pending',
      sentTimeMs: now,
    };
  }
  return resp;
};

export const sendSystem = (params: Omit<Nim.CustomSysMsgParams, 'done' | 'env'>) => {
  return promisifyNimMethod(__instance__, 'sendCustomSysMsg')({ ...params, env: NIM_ENV });
};

let lastDisconnectTimestamp = 0;

const sysMessageHandler = (p: any) => {
  if (p && p.content === 'pull_notice_msg') {
    emit('sys:message:pull_notice_msg', { type: 'PULL_NOTICE_MSG', content: null });

    return;
  }

  const content = nimMsgContentParser<NimSystemBasicMessage>(p);

  if (!content) {
    return;
  }
  if (p?.from === 'health') {
    emit('sys:message:health_ack', content as NimSystemHealthACKMessage);
    return;
  }
  const { type } = content;

  if (!type) {
    return;
  }

  if (type === 'ACK') {
    emit('sys:message:ack', content as NimSystemACKMessage);
    const {
      content: { yunXinMsgId },
    } = content as NimSystemACKMessage;
    if (ackTimeoutTaskMap[yunXinMsgId]) {
      clearTimeout(ackTimeoutTaskMap[yunXinMsgId].timeout);
      if (ackTimeoutTaskMap[yunXinMsgId].status === 'timeover') {
        const { sentTimeMs } = ackTimeoutTaskMap[yunXinMsgId];
        const delayMS = new Date().valueOf() - sentTimeMs;
        // 若本地超时后再收到ack上报到sentry
        nimEventReport('sys:ack:after:timeout', {
          delayMS,
          sentTimeMs,
          content,
        });
      }
      delete ackTimeoutTaskMap[yunXinMsgId];
    }
  } else if (type === 'ASSIGN_FAIL') {
    emit('sys:message:assign_fail', content as NimSytemAssignFailMessage);
  } else if (type === 'UNREAD_COUNT') {
    emit('sys:message:unread_count', content as NimSytemUnreadCountMessage);
  } else if (type === 'READ_EVENT') {
    emit('sys:message:read_event', content as NimSystemReadEventMessage);
  } else if (type === 'FOLLOWER_EVENT') {
    emit('sys:message:follower_event', content as NimSystemFollowerEventMessage);
  } else if (type === 'AI_REPLY') {
    emit('sys:message:ai_reply', content as NimSystemAIReplyMessage);
  } else if (type === 'OFFICIAL_READ_MARK') {
    emit('sys:message:official_read_mark', content as NimSystemOfficialReadMarkMessage);
  } else if (type === 'ASSISTANT_READ_MARK') {
    emit('sys:message:assistant_read_mark', content as NimSystemAssistantReadMarkMessage);
  } else if (type === 'REMIND_SCENE') {
    emit('sys:message:remind_scene', content as NimSystemRemindSceneMessage);
  } else if (type === 'REMIND_SOLVE') {
    emit('sys:message:remind_solve', content as NimSystemRemindSolveMessage);
  } else if (type === 'STORE_TRANSFER') {
    emit('sys:message:store_transfer', content as NimSystemStoreTransferMessage);
  } else if (type === 'PAY') {
    emit('sys:message:pay', content as NimSystemPayMessage);
  } else if (type === 'MESSAGE_EVENT_STREAM') {
    emit('sys:message:message_event_stream', content as NimSystemEventStreamMessage);
  } else if (type === 'REMOVE_TALK_CONTROL') {
    emit('sys:message:remove_talk_control', content as NimSystemRemoveTalkControlMessage);
  } else if (type === 'BATCH_TRANSFER') {
    emit('sys:message:batch_transfer', content as NimSystemBatchTransferMessage);
  } else if (type === 'SYNC_TIKTOK_TRADE_UID') {
    emit('sys:message:sync_tiktok_trade_uid', content as NimSystemSyncTiktokTradeUidMessage);
  } else if (type === 'SEND_MSG_REQ') {
    emit('sys:message:send_msg_req', content as NimSystemSendMsgReqMessage);
  }
};
const messageHandler = (p: Nim.OnMessageParam) => {
  const content: Conversation.Message = nimMsgContentParser<any>(p);
  if (content) {
    if (p.idServer) {
      const nimid = getNimCacheId(p.idServer);
      content.yunXinMsgId = p.idServer;
      content.msgId = content.msgId || nimid;
    }
    emit('message', content);
  }
};

const createOfflineNotify = (notify: (count: number) => void, delay: number = 1000) => {
  let count = 0;
  let timer: NodeJS.Timeout;

  const offlineNotify = (num: number) => {
    clearTimeout(timer);

    count += num;

    if (count === 0) {
      return;
    }

    timer = setTimeout(() => {
      notify(count);

      count = 0;
    }, delay);
  };

  return offlineNotify;
};

const offlineNotify = createOfflineNotify((count) => {
  notification.notify(
    {
      title: (i18n.t('nim.您有x条新消息') as unknown as (x: number) => string)(count),
      content: i18n.t('nim.请及时处理') || '',
      id: 'fakeMessageId',
      conversationId: 'fackConversationId',
    },
    {
      onClick: () => {
        focusChatttClient();
      },
    },
  );

  if (chatttSDK?.emitter?.emit) {
    chatttSDK?.emitter.emit('new_message', {});
  }
});

// 一个会话会产生一条离线消息，一条离线消息中会有该会话的多条新消息
const onofflinemsgsHandler = (p: Nim.OnOfflineMsgsParam) => {
  const notDisconnected = lastDisconnectTimestamp === 0;

  // eslint-disable-next-line no-console
  console.log('offlinemsg::', p);

  if (notDisconnected) {
    const msgLength = p.msgs.filter((msg) => msg.content).length;

    if (msgLength === 0) {
      return;
    }

    offlineNotify(msgLength);

    return;
  }

  const msgs: Conversation.Message[] = [];
  const msgIdToIdServer: Record<string, string> = {};
  p.msgs.forEach((msg: Nim.OnMessageParam) => {
    const content: Conversation.Message = nimMsgContentParser<any>(msg);
    if (content) {
      if (!content.msgFromType) {
        return;
      }
      msgs.push(content);
      if (msg.idServer) {
        msgIdToIdServer[content.msgId] = msg.idServer;
      }
    }
  });
  msgs.sort((m1, m2) => parseInt(m1.sendTime as string, 10) - parseInt(m2.sendTime as string, 10));
  (async () => {
    await wait(1800);
    for (let i = 0; i < msgs.length; i += 1) {
      const content = msgs[i];
      const idServer = msgIdToIdServer[content.msgId];
      if (idServer) {
        const nimid = getNimCacheId(idServer);
        content.yunXinMsgId = idServer;
        content.msgId = content.msgId || nimid;
      }
      emit('message', content);
      await wait(800);
    }
  })();
};
const onofflineCustomSysMsgHandler = (list: any[]) => {
  const notDisconnected = lastDisconnectTimestamp === 0;

  if (notDisconnected) {
    return;
  }

  list.forEach((x) => {
    sysMessageHandler(x);
  });
};

/**
 * 登录云信帐号.
 * NIM.getInstance接口为单例模式, 对于同一个帐号, 永远返回同一份实例, 即只有第一次调用会初始化一个实例,
 * 后续调用此接口会直接返回初始化过的实例, 同时也会调用接口更新 IM 配置更新传入的配置.
 * 每个帐号对应一个独立的`websocket`通道.
 * 后续调用此接口时, 如果连接已断开, 会自动建立连接, 当发生掉线时，SDK 会自动进行重连.
 * https://doc.yunxin.163.com/docs/TM5MzM5Njk/zE0NDY4Njc?platformId=60179
 */
function initInstance(pInst: Nim.GetInstanceParam, version: string = '8.6.2') {
  const pNim = pInst as Nim.NimGetInstanceParam;
  pNim.onconnect = (p: Nim.OnconnectParam) => {
    emit('connect', p);
    nimEventReport('connect', p, version);
  };
  pNim.ondisconnect = (p: Nim.OndisconnectParam) => {
    lastDisconnectTimestamp = Date.now();
    emit('disconnect', p);
    nimEventReport('disconnect', p, version);
  };
  pNim.onwillreconnect = (p: Nim.OnwillreconnectParam) => {
    emit('willReconnect', p);
    nimEventReport('willReconnect', p, version);
  };
  pNim.onerror = (p: Nim.ErrorParam) => {
    lastDisconnectTimestamp = Date.now();
    emit('error', p);
    nimEventReport('error', p, version);
  };
  pNim.onmsg = messageHandler;
  pNim.onofflinemsgs = onofflinemsgsHandler;

  pNim.oncustomsysmsg = sysMessageHandler;
  pNim.onofflinecustomsysmsgs = onofflineCustomSysMsgHandler;
  // onloginportschange

  const defaultLoader = () => import('assets/NIM_Web_NIM_v9.3.1');

  const NimWebLoader =
    {
      '8.6.2': () => import('assets/NIM_Web_NIM_v8.6.2'),
      '9.3.1': defaultLoader,
      /** 9.6.1不稳定，慎用 */
      '9.6.1': () => import('assets/NIM_Web_NIM_v9.6.1'),
    }[version] || defaultLoader;

  NimWebLoader()
    .then((res) => {
      const instObj = res.default.getInstance(pInst);
      __instance__ = instObj;
      // @ts-ignore
      window.__instance__ = __instance__;
      beginHearbeat(__instance__);
    })
    .catch((err) => {
      nimEventReport('loadSdkFailed', err?.toString(), version);
    });
}

interface LoginParams extends Nim.GetInstanceParam {
  version?: string;
}

const login = (arg: LoginParams) => {
  nimitt.emit(NIM_LOGIN_SIGNAL, arg);
};
nimitt.on(NIM_LOGIN_SIGNAL, (p) => {
  const { version, ...params } = p as LoginParams;

  initInstance(params, version);
});

const nimInst = {
  on,
  send,
  sendSystem,
  login,
  forceReconnect: () => {
    if (__instance__) {
      __instance__.disconnect();
      setTimeout(() => {
        __instance__?.connect?.();
        /**
         * 根据官方文档，disconnect、logout以后最好等待3秒以上再执行connect
         */
      }, 3000);
    }
  },
  logout: () => {
    if (__instance__) {
      __instance__.disconnect();
      __instance__.destroy();
    }
  },
};

function debugHandler(...args: any) {
  // eslint-disable-next-line no-console
  console.log('nim debug::::', args);
}
function addDebug() {
  nimitt.on('*', debugHandler);
}
function removeDebug() {
  nimitt.off('*', debugHandler);
}

if (process.env.NODE_ENV === 'development') {
  addDebug();
}

// @ts-ignore
Object.defineProperty(window, '__NIM_DEBUG__', {
  set(val) {
    if (val) {
      addDebug();
    } else {
      removeDebug();
    }
    return val;
  },
});
export default nimInst;
export type NimInst = typeof nimInst;
