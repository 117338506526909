import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export const orgConfigState = atom<General.OrgConfig>({
  key: 'orgConfigState',
  default: {
    remindEnable: false,
    kageEnable: false,
    refundEnable: false,
    reviewEnable: false,
    qaItemEnable: false,
    airwallexEnable: true,
    robotIndependentEnable: false,
    perfNavEnable: false,
  },
});

export const useOrgConfigStateValue = () => {
  return useRecoilValue(orgConfigState);
};

export const useSetOrgConfigStateValue = () => {
  return useSetRecoilState(orgConfigState);
};
