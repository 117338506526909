import { FormInstance } from 'antd';
import { noop } from 'lodash-es';
import { createContext, useMemo } from 'react';
import {
  GenerateProductDetailBody,
  GenerateProductTitleBody,
  Language,
  Platform,
} from 'services/api/chat.gpt';
import { PlatformItem } from './components/PlatformItem';
import { IDetailContent, useDetail } from './hooks/useDetail';
import useLanguages from './hooks/useLanguages';
import { ITitleContent, ITitlePageContent, useTitle } from './hooks/useTitle';

export interface ProductContentGeneratorContextProps {
  children: React.ReactNode;
}

export interface IContent {
  titleContent?: string;
  titleTranslateContent?: Record<Language, string>;
  detailContent?: string;
  detailTranslateContent?: Record<Language, string>;
  detailSummary?: string;
  detailSummaryTranslateContent?: Record<Language, string>;
}

export interface ProductContentGeneratorContextValue {
  languageOptions: Array<{ value: Language; label: string }>;
  platformOptions: Array<{
    value: Platform;
    render: JSX.Element;
  }>;

  // title service
  titleForm?: FormInstance<GenerateProductTitleBody | undefined>;
  titlePage: number;
  titlePageTotal: number;
  titleLoading: boolean;
  titleTranslateLoading: boolean;
  titleTranslateLang: Language;
  setTitleTranslateLang: React.Dispatch<React.SetStateAction<Language>>;
  setTitlePage: React.Dispatch<React.SetStateAction<number>>;
  setTitleLoading: React.Dispatch<React.SetStateAction<boolean>>;
  generateTitle?: () => Promise<void>;
  batchTranslateTitle?: (titleContents: ITitlePageContent, lang: Language) => Promise<void>;
  reGenerateTitle?: (titleContent: ITitleContent) => Promise<void>;
  resetTitlePageContent: () => void;
  titlePageContent: ITitlePageContent[];
  updateTitlePageContent: (index: [number, number], newContent: ITitleContent) => void;

  // detail service,
  detailForm?: FormInstance<GenerateProductDetailBody | undefined>;
  detailLoading: boolean;
  detailTranslateLoading: boolean;
  detailPage: number;
  detailPageTotal: number;
  detailPageContent: IDetailContent[];
  detailTranslateLang: Language;
  setDetailPage: React.Dispatch<React.SetStateAction<number>>;
  resetDetailPageContent: () => void;
  generateDetail?: () => Promise<void>;
  translateDetail?: (
    detailContent: IDetailContent,
    detailPage: number,
    lang: Language,
  ) => Promise<void>;
  setDetailTranslateLang: React.Dispatch<React.SetStateAction<Language>>;
  updateDetailPageContent: (index: number, newContent: IDetailContent) => void;
}

export const ProductContentGeneratorContextV2 = createContext<ProductContentGeneratorContextValue>({
  languageOptions: [],
  platformOptions: [],

  // title service
  titlePage: 0,
  titlePageTotal: 0,
  titleLoading: false,
  titleTranslateLoading: false,
  titleTranslateLang: 'ZH_CN',
  setTitleTranslateLang: noop,
  setTitlePage: noop,
  setTitleLoading: noop,
  resetTitlePageContent: noop,
  titlePageContent: [],
  updateTitlePageContent: noop,

  // detail service
  detailLoading: false,
  detailTranslateLoading: false,
  detailPage: 0,
  detailPageTotal: 0,
  detailPageContent: [],
  detailTranslateLang: 'ZH_CN',
  setDetailPage: noop,
  resetDetailPageContent: noop,
  setDetailTranslateLang: noop,
  updateDetailPageContent: noop,
});

// 支持的平台列表
const PLATFORMS: Platform[] = ['SHOPEE', 'LAZADA', 'AMAZON', 'TIKTOK', 'DEFAULT'];

export const ProductContentGeneratorProviderV2 = (props: ProductContentGeneratorContextProps) => {
  const { children } = props;
  const languageOptions = useLanguages();

  const titleService = useTitle();
  const detailService = useDetail();

  // 平台选项
  const platformOptions = useMemo(() => {
    return PLATFORMS.map((p) => ({
      value: p,
      render: <PlatformItem platform={p} />,
    }));
  }, []);

  const value = useMemo(
    () => ({
      ...titleService,
      ...detailService,
      languageOptions,
      platformOptions,
    }),
    [detailService, languageOptions, platformOptions, titleService],
  );

  return (
    <ProductContentGeneratorContextV2.Provider value={value}>
      {children}
    </ProductContentGeneratorContextV2.Provider>
  );
};
