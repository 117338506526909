import { Form, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { What } from 'views/Marketing/components/PolicyTable';
import { Info } from './MustIncludedKeysField';
import WordsInput from './WordsInput';

export interface MustExcludeKeysFieldProps {
  description?: string;
}

function MustExcludeKeysField(props: MustExcludeKeysFieldProps) {
  const { description } = props;

  const { t } = useTranslation('ViewProductContentGenerator');

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldsValue, setFieldsValue, validateFields }) => {
        const { mustExcludeKeys = [] } = getFieldsValue();

        return (
          <Form.Item
            name="mustExcludeKeys"
            label={
              <div>
                {t('禁用词')}
                {description && (
                  <Tooltip title={description}>
                    <What />
                  </Tooltip>
                )}
              </div>
            }
            rules={[
              {
                validator: async (_, value) => {
                  if (value && value.length > 200) {
                    return Promise.reject(new Error(t('最多可输入200个禁用词')));
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <>
              <WordsInput
                placeholder={t('请输入禁用词，按enter添加')}
                words={mustExcludeKeys}
                onWordsChange={(mustExcludeKeys) => {
                  setFieldsValue({ mustExcludeKeys });
                  validateFields(['mustExcludeKeys']);
                }}
              />
              <Info style={{ marginBottom: '-22px' }}>
                <div />
                <span
                  style={{
                    color:
                      mustExcludeKeys.length > 200 ? 'var(--ant-error-color)' : 'rgba(0,0,0,.45)',
                  }}
                >
                  {mustExcludeKeys.length}
                  /200
                </span>
              </Info>
            </>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}

export default MustExcludeKeysField;
