import { css } from '@emotion/css';
import { Select, SelectProps } from 'antd';
import { useMemo } from 'react';
import { phoneCodeRegionsMap } from 'utils/phoneHelpers';
import usePhoneCountry from '../usePhoneCountry';

const RegionSelect = css`
  /* position: relative;

  &::after {
    content: ' ';
    position: absolute;
    width: 1px;
    height: 24px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #d9d9d9;
  }

  .ant-select-arrow {
    right: 18px;
  } */
`;
export interface PhoneRegionSelectProps
  extends Omit<SelectProps, 'onChange' | 'options' | 'value'> {
  value?: General.PhoneRegionCodes;
  onChange?: (value: General.PhoneRegionCodes, region: General.PhoneRegions) => void;
}
const PhoneRegionSelect = (props: PhoneRegionSelectProps) => {
  const { value, onChange, ...selectProps } = props;
  const pcArr = usePhoneCountry();
  return (
    <Select<General.PhoneRegionCodes>
      className={RegionSelect}
      showSearch
      value={value}
      onChange={(value) => {
        onChange && onChange(value, phoneCodeRegionsMap[value]);
      }}
      dropdownMatchSelectWidth={false}
      dropdownStyle={{ width: '280px' }}
      optionLabelProp="code"
      optionFilterProp="label"
      options={useMemo(
        () =>
          pcArr.map((pc) => {
            return {
              label: `${pc.ma} ${pc.n}`,
              value: pc.ma,
              name: `${pc.ma}`,
              code: pc.ma,
            };
          }),
        [pcArr],
      )}
      {...selectProps}
    />
  );
};
export default PhoneRegionSelect;
