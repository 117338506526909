import { chatgptClient, ImResponse, resolve } from '.';

export type Language =
  | 'ZH_CN'
  | 'EN'
  | 'ID'
  | 'PH'
  | 'TH'
  | 'VN'
  | 'PT'
  | 'ES'
  | 'DE'
  | 'RU'
  | 'PL'
  | 'KR'
  | 'MY';

export type Platform = 'SHOPEE' | 'LAZADA' | 'AMAZON' | 'TIKTOK' | 'DEFAULT';
export interface GenerateProductBase {
  platform: Platform;
  // 语言
  language: Language;
  // 商品品类
  category: string;
  // 商品品牌
  brand: string;
  // 商品卖点
  sellingPoint: string;
  // 商品 listing
  listingContent: string;
  // 突出信息
  highlightInfo?: string;
  // 弱化信息
  weakenInfo?: string;
  // 热词
  mustIncludedKeys?: string[];
  // 禁用词
  mustExcludeKeys?: string[];
}

export interface GenerateProductTitleBody extends GenerateProductBase {
  // 生成条数
  generateResultNumber: number;
  titleRestriction: {
    length: number;
    // 是否限制长度
    limitLength?: boolean;
    // 是否不含特殊字符
    withoutSpecialCharactersAndLinks: boolean;
    // 是否不能大写
    notAllCapitalLetters: boolean;
    // 是否首字母大写
    capitalizedFirstLetter: boolean;
    // 是否不含标点
    withoutPunctuation: boolean;
  };
}

export interface GenerateProductTitleData {
  result: string;
  titles: string[];
  highlightKeys?: string[];
}

// 生成商品标题
export function generateProductTitle(body: GenerateProductTitleBody) {
  return resolve(chatgptClient.post<ImResponse<GenerateProductTitleData>>('/v1/item_title', body));
}

export interface GenerateProductDetailBody extends GenerateProductBase {
  listingRestriction: {
    // 字符限制
    length: number;
    // 是否不能使用特殊字符
    withoutSpecialCharactersAndLinks: boolean;
    // 是否不能使用表情
    withoutEmoticons: boolean;
  };
}

export interface GenerateProductDetailData {
  platform: Platform;
  result: string;
  summary: string;
  highlightKeys?: string[];
}

// 生成商品详情
export function generateProductDetail(body: GenerateProductDetailBody) {
  return resolve(chatgptClient.post<ImResponse<GenerateProductDetailData>>('/v1/listing', body));
}

export interface TranslateContentBody {
  language: Language;
  sourceContent: string;
}
export interface TranslateContentData {
  language: Language;
  sourceContent: string;
  result: string;
  summary: string;
}

// 翻译
export function translateContent(body: TranslateContentBody) {
  return resolve(chatgptClient.post<ImResponse<TranslateContentData>>('/v1/translate', body));
}

export interface TranslateTitleContentBody extends TranslateContentBody {
  itemTitleDto: GenerateProductTitleBody;
}
// 翻译标题
export function translateTitleContent(body: TranslateTitleContentBody) {
  return resolve(chatgptClient.post<ImResponse<TranslateContentData>>('/v1/translate_title', body));
}

export interface BatchTranslateTitleContentBody {
  translateTitleDtos: TranslateTitleContentBody[];
}

// 批量翻译标题
export function batchTranslateTitleContent(body: BatchTranslateTitleContentBody) {
  return resolve(
    chatgptClient.post<ImResponse<{ translationResponseVoList: TranslateContentData[] }>>(
      '/v1/batch_translate_title',
      body,
    ),
  );
}

export interface TranslateDetailContentBody extends TranslateContentBody {
  itemListingDto: GenerateProductDetailBody;
}

// 翻译详情
export function translateDetailContent(body: TranslateDetailContentBody) {
  return resolve(
    chatgptClient.post<ImResponse<TranslateContentData>>('/v1/translate_listing', body),
  );
}

export interface FreeLoginBody {
  deviceId: string;
  channel: string;
}

// Free login method
export function freeLogin(body: FreeLoginBody) {
  return resolve(chatgptClient.post<ImResponse<{ token: string }>>('/v1/login', body));
}

export interface HotkeyListBody {
  platformEnum: Exclude<Platform, 'DEFAULT'>;
  region: General.UpperCaseRegion;
  keyword: string;
  pageLanguage: General.SystemLanguage;
}

export interface Hotkey {
  keyword: string;
  translatedKeyword: string;
  targetLanguage: General.SystemLanguage;
  searchVolume: number;
}

export interface HotkeyListResponse {
  data: Hotkey[];
}

export function hotkeyList(body: HotkeyListBody) {
  return resolve(chatgptClient.post<ImResponse<HotkeyListResponse>>('/v1/hotkey', body));
}

export interface TranslateBody {
  language: Language;
  sourceContent: string;
}

export interface TranslateResponse {
  language: Language;
  sourceContent: string;
  result: string;
}

export function translate(body: TranslateBody) {
  return resolve(
    chatgptClient.post<ImResponse<TranslateResponse>>('/v1/gpt_translate?from=gpt', body),
  );
}
