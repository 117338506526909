import moment from 'moment';
import { useMemo } from 'react';
import { atom, selector, useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { org, updateOgInfo } from 'services/api/admin';
// import { errorToasterWithServerMsg } from 'utils/errorToaster';

export const orgState = atom<Oversea.Org | undefined>({
  key: 'orgState',
  default: selector({
    key: 'orgState/default',
    get: async () => {
      const info = await org().catch((e) => {
        // errorToasterWithServerMsg(e, '获取org信息失败，请稍后再试');
        return undefined;
      });
      return info;
    },
  }),
});

export const orgIdState = selector({
  key: 'orgInfoState/id',
  get: async ({ get }) => {
    const info = get(orgState);
    if (info?.id) {
      return parseInt(info?.id, 10);
    }
    return null;
  },
});

/**
 * 距离套餐结束的天数(如超过则为负数)
 */
const orgExpireInDaysState = selector({
  key: 'orgExpireInDaysState',
  get: ({ get }) => {
    const orgValue = get(orgState);
    if (!orgValue) {
      return undefined;
    }
    const { tryExpireTimestamp, activeExpireTimestamp, activeExpireDay } = orgValue;
    const expireTimestamp = Math.max(
      parseInt(tryExpireTimestamp, 10),
      parseInt(activeExpireTimestamp, 10),
    );
    const now = moment();
    const exp = moment(expireTimestamp);
    const diff = exp.diff(now, 'days');
    return Math.max(diff, activeExpireDay);
  },
});

const useOrg = () => useRecoilValue(orgState);

export const useOrgId = () => useRecoilValue(orgIdState);

/**
 * 距离套餐结束的天数(如超过则为负数)
 */
export const useOrgExpirInDays = () => useRecoilValue(orgExpireInDaysState);

export default useOrg;

export const useOrgInfoRrefesher = () =>
  useRecoilCallback(
    ({ set }) =>
      (..._params: any) => {
        org().then((org) => set(orgState, org));
      },
    [],
  );
export const useOrgNameUpdater = () => {
  const refresher = useOrgInfoRrefesher();
  return useRecoilCallback(
    () => async (name: string) => {
      await updateOgInfo({ name });
      refresher();
    },
    [refresher],
  );
};

export const useOrgValue = () => {
  // console.log('sb2 orgState', orgState, useRecoilValue(orgState));
  return useRecoilValue(orgState);
};

export const useSetOrgValue = () => {
  return useSetRecoilState(orgState);
};

export const isExpired = (orgValue?: Oversea.Org) => {
  if (!orgValue) {
    return false;
  }

  const { activeExpireDay } = orgValue;

  return activeExpireDay < 0;
};

export const useIsOrgOnlyTrial = () => {
  return isOnlyTrial(useOrg());
};

const isOnlyTrial = (org?: Oversea.Org) => {
  if (!org) {
    return undefined;
  }

  const { tryExpireTimestamp, activeExpireTimestamp, createTimestamp } = org;
  if (tryExpireTimestamp >= activeExpireTimestamp || createTimestamp === activeExpireTimestamp) {
    return true;
  }
  return false;
};

/**
 * 新用户定义：https://leyan.yuque.com/czpqnb/pd/lreu5k
 */
export const isNewOrg = (orgValue?: Oversea.Org) => {
  if (!orgValue) {
    return false;
  }

  const { tryExpireTimestamp, createTimestamp } = orgValue;

  const tryMoment = moment(parseInt(tryExpireTimestamp, 10));

  const createMoment = moment(parseInt(createTimestamp, 10));

  const nowMoment = moment();

  return tryMoment.isAfter(nowMoment) && createMoment.add(30, 'days').isAfter(nowMoment);
};

export const useIsNewOrg = () => {
  const orgValue = useRecoilValue(orgState);

  return useMemo(() => isNewOrg(orgValue), [orgValue]);
};
