/* eslint-disable import/prefer-default-export */
import { demoChannelHashes, leyanSellerChannelHash, mabangChannelHash } from '../const';
import { chatttSDKChannel } from '../../sdk';

// 需要隐藏订购相关能力的渠道包
// 销售专用渠道包、马帮渠道包、演示用渠道包
const HIDE_PAYMENT_CHANNEL_LIST = [...leyanSellerChannelHash, mabangChannelHash].concat(
  demoChannelHashes,
);

/**
 * 销售渠道包-无线上订购能力
 * PD: https://leyan.yuque.com/czpqnb/pd/igfu49
 *
 * 目前包括 马帮渠道包、演示用渠道包等
 */
export const hidePayment =
  chatttSDKChannel && HIDE_PAYMENT_CHANNEL_LIST.some((t) => t === chatttSDKChannel);
