export const starColors = {
  NO_STAR: '',
  YELLOW: '#F2A100',
  RED: '#F05B5B',
  GREEN: '#00BF69',
  BLUE: '#3370FF',
  PURPLE: '#705CF2',
};

export const hasStar = (color: string) =>
  ['YELLOW', 'RED', 'GREEN', 'BLUE', 'PURPLE'].includes(color);

/** 界面可过滤的星标 */
export const starUIFilterArr: Conversation.asteriskUIFilter[] = [
  'ALL',
  'YELLOW',
  'RED',
  'GREEN',
  'BLUE',
  'PURPLE',
];

/** 界面可操作的星标 */
export const starUIOps: Conversation.asteriskUIOps[] = [
  'NO_STAR',
  'YELLOW',
  'RED',
  'GREEN',
  'BLUE',
  'PURPLE',
];
