import useTrans from 'hooks/useTrans';
import { useMemo } from 'react';

export type PhoneCountry = {
  /** 国家缩写码 */
  k: General.PhoneRegions;
  /** 国家名 */
  n: string;
  /** 手机号前面的国家码,国内手机号无 */
  ma: General.PhoneRegionCodes;
};

export default function usePhoneCountry() {
  const pc = useTrans('phoneCountry');
  const pcArr: PhoneCountry[] = useMemo(
    () => [
      { k: 'cn', n: pc.cn, ma: '+86' },
      { k: 'tw', n: pc.tw, ma: '+886' },
      { k: 'hk', n: pc.hk, ma: '+852' },
      { k: 'mo', n: pc.mo, ma: '+853' },
      { k: 'my', n: pc.my, ma: '+60' },
      { k: 'id', n: pc.id, ma: '+62' },
      { k: 'th', n: pc.th, ma: '+66' },
      { k: 'ph', n: pc.ph, ma: '+63' },
      { k: 'sg', n: pc.sg, ma: '+65' },
      { k: 'vn', n: pc.vn, ma: '+84' },
      { k: 'br', n: pc.br, ma: '+55' },
      { k: 'mx', n: pc.mx, ma: '+52' },
      { k: 'cl', n: pc.cl, ma: '+56' },
      { k: 'ar', n: pc.ar, ma: '+54' },
      { k: 'co', n: pc.co, ma: '+57' },
      { k: 'fr', n: pc.fr, ma: '+33' },
      { k: 'es', n: pc.es, ma: '+34' },
      { k: 'pl', n: pc.pl, ma: '+48' },
    ],
    [pc],
  );
  return pcArr;
}
