import { lazy } from 'react';
import { RouteConfig } from 'types';

const TaskPage = lazy(() => import('./Task'));
const TaskDetailPage = lazy(() => import('./TaskDetailPage'));

// eslint-disable-next-line import/prefer-default-export
export const routes: RouteConfig[] = [
  {
    path: 'task',
    element: <TaskPage />,
  },
  {
    path: 'task-detail',
    element: <TaskDetailPage />,
  },
];
