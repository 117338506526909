/**
 * 提取独立模块
 * 避免单个文件过大 以及
 * 部分逻辑互相引用时导致循环依赖的问题
 */
const _chatttSDK = window?.__CHATTT_SDK__;
// eslint-disable-next-line import/no-mutable-exports
let chatttSDK = _chatttSDK;
// @ts-ignore
if (_chatttSDK?.db && _chatttSDK?.db?.promise !== true) {
  // 低版本sdk兼容性代码
  const promisify = <F extends (...args: any[]) => any>(
    fn: F,
  ): ((...args: Parameters<F>) => Promise<ReturnType<F>>) => {
    return (...args) =>
      new Promise((resolve) => {
        const rst = fn(...args);
        return resolve(rst);
      });
  };

  const promiseDb = {
    ..._chatttSDK?.db,
    init: promisify(_chatttSDK!.db.init),
    run: promisify(_chatttSDK!.db.run),
    get: promisify(_chatttSDK!.db.get),
    all: promisify(_chatttSDK!.db.all),
    exec: promisify(_chatttSDK!.db.exec),
    insert: promisify(_chatttSDK!.db.insert),
    close: promisify(_chatttSDK!.db.close),
    move: promisify(_chatttSDK!.db.move),
  } as unknown as Chattt.SDK['db'];
  // @ts-ignore
  chatttSDK = new Proxy(
    { ..._chatttSDK },
    {
      get: (target, selector) => {
        // @ts-ignore
        // }
        if (selector === 'db') {
          return promiseDb;
        }
        // @ts-ignore
        return target[selector];
      },
    },
  );
}

export default chatttSDK;

export const chatttSDKChannel = 'aigc_official';
