import styled from '@emotion/styled';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Language } from 'services/api/chat.gpt';
import { ITitlePageContent } from '../hooks/useTitle';

const HotWordContainer = styled.div`
  margin-bottom: 16px;
  padding: 12px 14px;
  background-color: #ffffff;
  border: 1px solid #f0f2f5;
  border-radius: 4px;

  & > span {
    margin-right: 8px;
  }
`;

export interface HotWordsDisplayProps {
  currentPageContent: ITitlePageContent | null;
  translateLang: Language;
}

export const HotWordsDisplay = (props: HotWordsDisplayProps) => {
  const { currentPageContent, translateLang } = props;
  const { t } = useTranslation('ViewProductContentGenerator');

  const { highlightKeys, list } = currentPageContent ?? {};

  const content = useMemo(() => {
    return (
      list?.reduce((result, item) => {
        return `${result} ${item.content} ${item.translateContent?.[translateLang] ?? ''}`;
      }, '') ?? ''
    );
  }, [list, translateLang]);

  const keys = useMemo(() => {
    return highlightKeys?.map((keyword) => {
      const regex = new RegExp(keyword, 'giu');
      const matches = content.toLowerCase().match(regex);
      return `${keyword}(${matches?.length ?? 0})`;
    });
  }, [content, highlightKeys]);

  return keys?.length ? (
    <HotWordContainer>
      <span>{t('hotWordsLabel')}</span>
      {keys.map((k) => (
        <Tag>{k}</Tag>
      ))}
    </HotWordContainer>
  ) : null;
};
