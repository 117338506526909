import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, message, Tooltip } from 'antd';
import { useTranslation, TFunction } from 'react-i18next';
import styled from '@emotion/styled';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import passwordValidator from 'utils/validator/password';
import { LeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import { resetPassword, ResetPasswordBody } from 'services/api/account';
import errorToaster from 'utils/errorToaster';
import { sendSMSCaptcha } from 'services/api/admin';
import { useSaveTempPhone, useSetSaveTempPhone } from 'hooks/business/saveTempPhone';
import AutoTrans from 'components/AutoTrans';
import useSMSCaptcha from 'components/SMSCaptchaButton/hooks/useSMSCaptcha';
import Shadowbox, { Title } from '../styled/Shadowbox';
import FlexContainer from '../components/StartupContainer';
import PhoneInput from '../components/PhoneInput';
import CaptchaInput from '../components/CaptchaInput';
import TopToolbar from '../styled/TopToolbar';

const { Item: FormItem, useForm } = Form;

const Content = styled.div`
  padding: 0 30px;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  border: none;
  margin-top: 8px;
`;
const BottomWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 13px;
  color: #84868c;

  .anticon {
    margin-left: 5px;
  }
`;
const PanelTitle = styled(Title)`
  margin-top: 8px;
`;

interface Props {}

const FindPasswordForm = (props: Props) => {
  const { countryCode: defaultCountryCode, mobile: defaultPhone } = useSaveTempPhone();
  const setSaveTempPhone = useSetSaveTempPhone();
  const { t } = useTranslation('ViewStartups');
  const [form] = useForm<ResetPasswordBody>();
  const [country, setCountry] = useState<General.PhoneRegions>('cn');
  const [countryCode, setCountryCode] = useState<General.PhoneRegionCodes>(
    defaultCountryCode || '+86',
  );
  const [isReady, setIsReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [captchaTimes, setCaptchaTimes] = useState(0);
  const nav = useNavigateWithQuery();

  const { cooldown, onSendSMS } = useSMSCaptcha({
    fetch: async () => {
      const phone = countryCode + form.getFieldValue('phone');
      let cooldown;
      try {
        await sendSMSCaptcha(phone, 'verify');
        setCaptchaTimes(captchaTimes + 1);
      } catch (e) {
        errorToaster(e, t('startups.form.item.captcha.send.error'));
        cooldown = 0;
      }
      return cooldown;
    },
  });

  const mobileValidate = (
    mobile: string,
    t: TFunction<'ViewStartups'>,
    country: string,
    countryCode: string,
  ) => {
    let error;
    if (!mobile || !isValidPhoneNumber(`${countryCode}${mobile}`, country as any)) {
      error = t?.('registry.form.item.account.validator.error');
    }
    return error;
  };

  const handleSubmit = useCallback(
    async (values) => {
      try {
        setLoading(true);
        const { phone, code, newPassword } = values as ResetPasswordBody;
        await resetPassword({ phone: `${countryCode}${phone}`, code, newPassword });
        setLoading(false);
        message.success(t('reset.message.success'));
        nav('/login');
      } catch (e) {
        setLoading(false);
        errorToaster(e, '验证码错误');
      }
    },
    [countryCode, nav, t],
  );

  useEffect(() => {
    setSaveTempPhone({
      countryCode,
      mobile: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexContainer>
      <Shadowbox>
        <TopToolbar>
          <Button
            type="link"
            icon={<LeftOutlined />}
            onClick={() => {
              nav('/login');
            }}
          >
            {t('reset.back.to.login')}
          </Button>
        </TopToolbar>
        <PanelTitle>{t('reset.title')}</PanelTitle>
        <Content>
          <Form
            form={form}
            layout="vertical"
            size="large"
            labelCol={{ span: 20 }}
            initialValues={{ phone: defaultPhone }}
            onValuesChange={(_, values) => {
              setIsReady(!!(values.phone && values.code && values.newPassword));
            }}
            onFinish={handleSubmit}
          >
            <FormItem
              label={t('registry.form.item.account.label')}
              name="phone"
              rules={[
                {
                  validator: (_, mobile) => {
                    const error = mobileValidate(mobile, t, country, countryCode);
                    return error ? Promise.reject(new Error(error)) : Promise.resolve();
                  },
                },
              ]}
            >
              <PhoneInput
                phoneCode={countryCode}
                onChange={(value, phoneCode, region) => {
                  setCountry(region);
                  setCountryCode(phoneCode);
                  form?.validateFields(['phone']);
                }}
              />
            </FormItem>
            <FormItem
              label={t('startups.form.item.captcha.label')}
              required
              dependencies={['phone']}
            >
              {({ getFieldValue }) => {
                const mobile = getFieldValue('phone');
                const disabled = !!mobileValidate(mobile, t, country, countryCode);
                return (
                  <FormItem
                    name="code"
                    rules={[{ required: true, message: t('required') }]}
                    noStyle
                  >
                    <CaptchaInput
                      disabled={disabled}
                      onSendSMS={onSendSMS}
                      cooldown={cooldown}
                      times={captchaTimes}
                    />
                  </FormItem>
                );
              }}
            </FormItem>
            <FormItem
              label={t('registry.form.item.find.password.label')}
              name="password"
              rules={[
                {
                  validator: (_, password) => {
                    const { valid, message } = passwordValidator(password);
                    if (valid) {
                      return Promise.resolve();
                    }
                    return Promise.reject(message);
                  },
                },
              ]}
              required
            >
              <Input.Password placeholder={t('reset.form.password.placeholder')} />
            </FormItem>
            <FormItem
              label={t('registry.form.item.confirm.password.label')}
              name="newPassword"
              rules={[
                {
                  validator: (_, password) => {
                    let { valid, message } = passwordValidator(password);
                    if (valid) {
                      const prevPwd = form.getFieldValue('password');
                      valid = prevPwd === password;
                      if (valid) {
                        return Promise.resolve();
                      }
                      message = t('reset.form.password.validate.diff');
                    }
                    return Promise.reject(message);
                  },
                },
              ]}
              required
            >
              <Input.Password placeholder={t('reset.password.confirm.placeholder')} />
            </FormItem>
          </Form>
          <SubmitButton
            type="primary"
            disabled={!isReady}
            size="large"
            loading={loading}
            onClick={() => form.submit()}
          >
            {t('reset.form.submit.btn')}
          </SubmitButton>
          <BottomWrap>
            <Tooltip title={t('reset.unbound.tips')}>
              <span>
                <AutoTrans ns="ViewStartups" i18nKey="reset.unbound.text" />
                <QuestionCircleOutlined />
              </span>
            </Tooltip>
          </BottomWrap>
        </Content>
      </Shadowbox>
    </FlexContainer>
  );
};

export default FindPasswordForm;
