import React, { useContext, useMemo } from 'react';
import { useLocation, createRoutesFromArray, matchRoutes } from 'react-router-dom';

import {
  RouterConfig,
  MatchedRoute,
  RouteConfig,
  PermissionedRoute,
  RouteAccessProps,
} from 'types';
import refine, { Refinable } from 'utils/refine';
import createNamedContext from 'utils/createNamedContext';
import { useAuthorization } from 'components/Authorization';
import { useRouteAccessProps } from 'hooks/business/useNewPermisson';

/**
 * 获取权限路由信息
 * @param initialState 初始状态
 * @param routes 路由配置项
 */
export function getPermissionedRoutes(accessProps: RouteAccessProps, routes: RouteConfig[]) {
  return routes.reduce<PermissionedRoute[]>((result, route) => {
    const { access, children } = route;

    const permission = access === undefined ? 'write' : access(accessProps);

    if (permission) {
      result.push({
        ...route,
        permission,
        children: children ? getPermissionedRoutes(accessProps, children) : undefined,
      });
    }

    return result;
  }, []);
}

/**
 * 未登录时使用pulicRoutes解析，绕开所有需要依赖登录态的判断
 * @param routes
 * @returns
 */
export function getPublicRoutes(routes: RouteConfig[]) {
  return routes.reduce<PermissionedRoute[]>((result, route) => {
    const { access, children } = route;
    /**
     * 因为未登录，route拥有自定义access的话，一律认为没有权限
     */
    const permission = access === undefined ? 'write' : false;

    if (permission) {
      result.push({
        ...route,
        permission,
        children: children ? getPublicRoutes(children) : undefined,
      });
    }

    return result;
  }, []);
}

/**
 * 获取匹配路由信息
 * @param routes 路由项配置
 * @param pathname 当前路径
 * @param basename 基础路径
 */
export function getMatchedRoutes(routes: PermissionedRoute[], pathname: string, basename: string) {
  const matchedRoutes: MatchedRoute[] = [];
  const routeMatches = matchRoutes(createRoutesFromArray(routes), pathname, basename);

  if (routeMatches) {
    let currentRoutes = routes;

    for (const match of routeMatches) {
      const route = currentRoutes.find((route) => {
        return match.route.path === route.path;
      });

      if (!route) {
        break;
      }

      matchedRoutes.push({
        ...route,
        pathname: match.pathname.indexOf('/') === 0 ? match.pathname : `/${match.pathname}`,
        params: match.params,
      });

      if (!route.children) {
        break;
      }

      currentRoutes = route.children;
    }
  }

  return matchedRoutes;
}

/**
 * 路由元信息 `context` 值
 */
export interface RouterMetaContextValue extends Required<Omit<RouterConfig, 'history'>> {
  /**
   * 当前路径
   */
  pathname: string;
  /**
   * 权限路由信息
   */
  permissionedRoutes: PermissionedRoute[];
  /**
   * 匹配路由信息
   */
  matchedRoutes: MatchedRoute[];
}

/**
 * 路由元信息 `context`
 */
export const RouterMetaContext = createNamedContext<RouterMetaContextValue>('RouterMetaContext', {
  pathname: '/',
  basename: '',
  routes: [],
  permissionedRoutes: [],
  matchedRoutes: [],
});

/**
 * 获取路由元信息
 */
export function useRouterMeta() {
  const meta = useContext(RouterMetaContext);

  return meta;
}

/**
 * 获取当前路径
 */
export function usePathname() {
  const { pathname } = useRouterMeta();

  return pathname;
}

/**
 * 获取基础路径
 */
export function useBasename() {
  const { basename } = useRouterMeta();

  return basename;
}

/**
 * 获取路由项配置
 */
export function useRoutes() {
  const { routes } = useRouterMeta();

  return routes;
}

/**
 * 获取权限路由信息
 */
export function usePermissionedRoutes() {
  const { permissionedRoutes } = useRouterMeta();

  return permissionedRoutes;
}

/**
 * 获取匹配路由信息
 */
export function useMatchedRoutes() {
  const { matchedRoutes } = useRouterMeta();

  return matchedRoutes;
}

/**
 * 获取当前匹配路由信息
 */
export function useCurrentMatchedRoute() {
  const { pathname } = useLocation();
  const matchedRoutes = useMatchedRoutes();

  return matchedRoutes.find((route) => {
    return route.pathname === pathname;
  });
}

/**
 * 获取当前路由是否frameless
 */
export function useCurrentRouteFrameless() {
  const route = useCurrentMatchedRoute();
  return Boolean(route?.frameless);
}

const { Provider: RouterMetaProvider } = RouterMetaContext;

/**
 * 路由元信息属性
 */
export interface RouterMetaProps extends Omit<RouterConfig, 'history'> {
  /**
   * 子节点或子节点渲染函数
   */
  children?: Refinable<(meta: RouterMetaContextValue) => React.ReactNode>;
}

/**
 * 路由元信息
 */
function AuthedRouterMeta(props: RouterMetaProps) {
  const { basename = '', routes, children } = props;

  const { pathname } = useLocation();

  const accessProps = useRouteAccessProps();

  const parentRouterMetaContextValue = useRouterMeta();
  const permissionedRoutes = useMemo(() => {
    return routes ? getPermissionedRoutes(accessProps, routes) : undefined;
  }, [accessProps, routes]);
  const routerMetaContextValue = useMemo(() => {
    if (routes && permissionedRoutes) {
      const matchedRoutes = getMatchedRoutes(permissionedRoutes, pathname, basename);
      return {
        basename,
        pathname,
        routes,
        permissionedRoutes,
        matchedRoutes,
      };
    }

    return {
      ...parentRouterMetaContextValue,
      basename,
      pathname,
    };
  }, [basename, parentRouterMetaContextValue, pathname, permissionedRoutes, routes]);
  return (
    <RouterMetaProvider value={routerMetaContextValue}>
      {refine(children, routerMetaContextValue)}
    </RouterMetaProvider>
  );
}

function PublicRouterMeta(props: RouterMetaProps) {
  const { basename = '', routes, children } = props;

  const { pathname } = useLocation();
  const parentRouterMetaContextValue = useRouterMeta();
  const permissionedRoutes = useMemo(() => {
    return routes ? getPublicRoutes(routes) : undefined;
  }, [routes]);
  const routerMetaContextValue = useMemo(() => {
    if (routes && permissionedRoutes) {
      const matchedRoutes = getMatchedRoutes(permissionedRoutes, pathname, basename);
      return {
        basename,
        pathname,
        routes,
        permissionedRoutes,
        matchedRoutes,
      };
    }

    return {
      ...parentRouterMetaContextValue,
      basename,
      pathname,
    };
  }, [basename, parentRouterMetaContextValue, pathname, permissionedRoutes, routes]);
  return (
    <RouterMetaProvider value={routerMetaContextValue}>
      {refine(children, routerMetaContextValue)}
    </RouterMetaProvider>
  );
}
const RouterMeta = (props: RouterMetaProps) => {
  const auth = useAuthorization();
  return useMemo(() => {
    if (!auth.authorized) {
      // 未登录时使用公开路由的meta信息
      return <PublicRouterMeta {...props} />;
    }
    return <AuthedRouterMeta {...props} />;
  }, [auth.authorized, props]);
};
export default RouterMeta;
