import { ImResponse, storeHomeV2Client } from '..';

export const permissionNameIdMap: Record<General.PermissionName, General.PermissionId> = {
  基础聊天权限: 1,
  管理员视角: 2,
  订单敏感信息: 3,
  编辑优惠券: 4,
  弃单处理页面: 5,
  操作弃单: 6,
  访问评价聚合页: 35,
  履约数据: 9,
  履约数据导出: 10,
  '绩效报告-查看个人绩效': 11,
  '绩效报告-查看部门绩效': 12,
  绩效报告导出: 13,
  '快捷回复-个人模版': 14,
  '快捷回复-团队模版': 15,
  超时自动回复: 16,
  重要提醒: 17,
  WA消息模版: 18,
  AI智能接待: 19,
  配置催单催付任务: 30,
  配置缺货提醒任务: 31,
  配置物流呵护任务: 32,
  配置弃单挽回任务: 33,
  配置评价管理任务: 34,
  帐号管理: 36,
  店铺管理: 37,
  买家管理: 38,
  进店欢迎语: 29,
};

export interface PermissionV2 {
  permissionId: number;
  permissionName: General.PermissionName;
  permissionGroupName: General.PermissionGroupName;
  storeScope: General.StoreScope;
  __LOCAL__: {
    disabled?: boolean;
    disabledScopes?: boolean;
  };
}

export const permissionsCannotChange: General.PermissionName[] = [
  '快捷回复-个人模版',
  '基础聊天权限',
];

export const scopesCannotChange: General.PermissionName[] = [
  '基础聊天权限',
  '快捷回复-个人模版',
  '店铺管理',
  '帐号管理',
  '履约数据导出',
  '绩效报告-查看个人绩效',
  '绩效报告-查看部门绩效',
  '绩效报告导出',
  '重要提醒',
  '买家管理',
];
/**
 * 某权限依赖的前置权限项
 */
export const permissionDependencies: Partial<General.PermissionDeps> = {
  操作弃单: ['弃单处理页面'],
  '绩效报告-查看部门绩效': ['绩效报告-查看个人绩效'],
};

/**
 * 查找依赖该权限作为前置条件的权限项
 */
export const permissionDependents = Object.keys(permissionDependencies).reduce(
  (res, permissionName) => {
    const deps = permissionDependencies[permissionName];
    deps?.forEach((dependentName) => {
      const record = res[dependentName];
      if (!record) {
        // @ts-expect-error General.PermissionDeps 依赖集需要过滤本身，使用了Exclude,但是Exclude把never引入了导致识别错误
        res[dependentName] = [permissionName];
      } else {
        // @ts-expect-error General.PermissionDeps 依赖集需要过滤本身，使用了Exclude,但是Exclude把never引入了导致识别错误
        record.push(permissionName);
      }
      return res;
    });
    return res;
  },
  {} as Partial<General.PermissionDeps>,
);

const orderPermissions = (permissions: PermissionV2[]) => {
  const welcomePermissionIndex = permissions.findIndex(
    ({ permissionName }) => permissionName === '进店欢迎语',
  );

  if (welcomePermissionIndex < 0) {
    return permissions;
  }

  const welcomePermission = permissions[welcomePermissionIndex];

  const removeWelcomePermissions = [
    ...permissions.slice(0, welcomePermissionIndex),
    ...permissions.slice(welcomePermissionIndex + 1),
  ];

  const autoReplyRermissionIndex = removeWelcomePermissions.findIndex(
    ({ permissionName }) => permissionName === '超时自动回复',
  );

  if (autoReplyRermissionIndex < 0) {
    return permissions;
  }

  return [
    ...removeWelcomePermissions.slice(0, autoReplyRermissionIndex + 1),
    welcomePermission,
    ...removeWelcomePermissions.slice(autoReplyRermissionIndex + 1),
  ];
};

const filterPermissions = (permissions: PermissionV2[]) => {
  return permissions.filter(
    ({ permissionName }) => permissionNameIdMap[permissionName] !== undefined,
  );
};

export const getNewPermissionsV2 = async () => {
  const resp = await storeHomeV2Client.get<ImResponse<{ permissions: PermissionV2[] }>>(
    '/admin/new_permissions',
  );

  const { permissions = [] } = resp.data.data;

  return (
    orderPermissions(filterPermissions(permissions)).map((p) => {
      return {
        ...p,
        __LOCAL__: {
          disabled: permissionsCannotChange.includes(p.permissionName),
          disabledScopes: scopesCannotChange.includes(p.permissionName),
        },
      };
    }) ?? []
  );
};
