import { AxiosRequestConfig } from 'axios';
import { isDemoChannel } from 'services/channels/demo';

// 演示状态下拦截相关的接口，并进行特殊处理

// eslint-disable-next-line import/prefer-default-export
export const demoRequestInterceptor = (config: AxiosRequestConfig) => {
  if (!isDemoChannel) {
    return config;
  }
  const { url: urlRaw = '' } = config;
  /**
   * 确保url以'/'开头
   */
  const url = urlRaw.trim().replace(/^\/*/, '/');
  switch (true) {
    /**
     * 拦截updateConversation接口以阻止小红点等数据被修改
     */
    case [
      '/oversea-conversation/v1/updateConversationV2',
      '/oversea-conversation/v1/updateConversation',
    ].some((t) => t === url || /^\/oversea-conversation\/v\d+\/updateConversationV\d+/.test(url)): {
      return {
        ...config,
        adapter(cfg: AxiosRequestConfig) {
          return Promise.resolve({
            data: {},
            status: 200,
            statusText: '',
            headers: config.headers,
            config,
          });
        },
      };
    }
    default: {
      return config;
    }
  }
};
