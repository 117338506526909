import { NavigateWithQuery } from 'hooks/useNavigateWithQuery';
import { lazy } from 'react';
import { RouterConfig } from 'types';
import { routes } from 'views/Main2/config';
import FindPasswordForm from 'views/Startups/FindPasswordForm';
import { routes as commentChildRoutes } from 'views/Comment/routes';
import { routes as comment2ChildRoutes } from 'views/CommentNew/routes';

/**
 * 开店流程相关
 */
const SimpleHeaderFrame = lazy(() => import('frameworks/SimpleHeaderFrame'));
const Registry = lazy(() => import('views/Startups'));

const Login = lazy(() => import('views/Startups/Login'));

/**
 * 商品详情生成
 */
const Main = lazy(() => import('views/Main2'));

const Comment = lazy(() => import('views/Comment'));

const Comment2 = lazy(() => import('views/CommentNew'));

/**
 * 路由配置
 */
const routerConfig: RouterConfig = {
  history: 'hash',
  routes: [
    {
      path: '/comment',
      redirect: true,
      element: <Comment />,
      helmet: {
        defaultTitle: '用户观点洞察',
        titleTemplate: '用户观点洞察',
      },
      children: commentChildRoutes,
    },
    {
      path: '/comment-new',
      redirect: true,
      element: <Comment2 />,
      helmet: {
        defaultTitle: '用户观点洞察',
        titleTemplate: '用户观点洞察',
      },
      children: comment2ChildRoutes,
    },
    {
      path: '/',
      redirect: true,
      element: <SimpleHeaderFrame headerType="default" />,
      helmet: {
        defaultTitle: '出海电商AI内容智造机 - 自动生成商品标题、商品详情内容',
        titleTemplate: '出海电商AI内容智造机 - 自动生成商品标题、商品详情内容',
      },
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'registry',
          element: <Registry />,
        },
        {
          path: 'findPassword',
          element: <FindPasswordForm />,
        },
      ],
    },
    {
      path: '/',
      redirect: true,
      element: <Main />,
      children: [
        ...routes,
        {
          path: '*',
          element: <NavigateWithQuery to={routes[0].path} replace />,
        },
      ],
    },
  ],
};

export default routerConfig;
