function copyText<T extends string | number>(
  text: T,
  container: HTMLElement = document.body,
): Promise<T> {
  return new Promise((resolve, reject) => {
    const input = document.createElement('textarea');

    input.style.position = 'fixed';
    input.style.top = '-10000px';
    input.style.left = '-10000px';

    input.setAttribute('readonly', 'readonly');

    container.appendChild(input);

    input.value = `${text}`;

    input.focus();

    input.select();

    const result = document.execCommand('copy');

    container.removeChild(input);

    return result ? resolve(text) : reject();
  });
}

export default copyText;
