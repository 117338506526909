import Icon from '@ant-design/icons';
import { css } from '@emotion/css/macro';
import { Select, SelectProps, Tag } from 'antd';
import { useSystemLanguageValue } from 'hooks/business/system/useSystem';
import { useMemo } from 'react';
import { Hotkey } from 'services/api/chat.gpt';
import { regionLanguageMap } from 'services/config/region';
import toLowerCase from 'utils/toLowerCase';
import { ReactComponent as FireSvg } from '../assets/fire.svg';

const selectClassName = css`
  .ant-select-selection-overflow {
    min-height: 51px;
    row-gap: 4px;
    .ant-select-selection-overflow-item {
      align-self: flex-start;
    }
  }
  .ant-select-selection-placeholder {
    top: 3px;
    transform: none;
    white-space: normal;
  }
  .ant-tag-hidden {
    display: inline-block;
  }
`;

export interface WordsInputProps extends SelectProps {
  words: string[];
  onWordsChange: (words: string[]) => void;
  regionHotkeysMap?: Partial<Record<General.UpperCaseRegion, Hotkey[]>>;
}

function WordsInput(props: WordsInputProps) {
  const { words, onWordsChange, regionHotkeysMap = {}, ...otherProps } = props;

  const systemLanguage = useSystemLanguageValue();

  const hotkeyMap = useMemo(() => {
    return Object.keys(regionHotkeysMap).reduce<
      Partial<Record<string, Hotkey & { region: General.UpperCaseRegion }>>
    >((res, region) => {
      const { [region]: hotkeys = [] } = regionHotkeysMap;

      return hotkeys.reduce((res, hotkey) => {
        res[hotkey.keyword] = { ...hotkey, region };

        return res;
      }, res);
    }, {});
  }, [regionHotkeysMap]);

  return (
    <Select
      className={selectClassName}
      open={false}
      mode="tags"
      value={words}
      onChange={onWordsChange}
      tagRender={(prop) => {
        const { value, ...otherProps } = prop;

        const { [value]: hotkey } = hotkeyMap;

        if (!hotkey) {
          return (
            <Tag color="blue" {...otherProps}>
              {value}
            </Tag>
          );
        }

        const { keyword, translatedKeyword, region, searchVolume } = hotkey;

        const targetLanguage = regionLanguageMap[toLowerCase(region)];

        return (
          <Tag color="green" {...otherProps}>
            {keyword}
            {systemLanguage !== targetLanguage && `(${translatedKeyword})`}
            <Icon component={FireSvg} />
            <span style={{ marginLeft: 0, color: '#F05B5B' }}>{searchVolume}</span>
          </Tag>
        );
      }}
      {...otherProps}
    />
  );
}

export default WordsInput;
