import React from 'react';
import {
  useTranslation,
  TransProps,
  Trans,
  TFuncKey,
  Namespace,
  DefaultNamespace,
} from 'react-i18next';

const AutoTrans = <
  K extends TFuncKey<N> extends infer A ? A : never,
  N extends Namespace = DefaultNamespace,
  E extends Element = HTMLDivElement,
>(
  props: TransProps<K, N, E>,
) => {
  // <Trans /> 组件似乎有个bug，直接使用<Trans /> 本身并不响应语言切换(例如中英转换后会继续使用旧值...)
  // 显式调用该hooks可以解决 - -||
  useTranslation();
  return <Trans<K, N, E> {...props} />;
};
export default React.memo(AutoTrans);
