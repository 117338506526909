import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const isChatttUpdateState = atom<{
  value: boolean;
  updateInfo?: {
    version: string;
    releaseDate: string;
    releaseNotes: string;
  };
}>({
  key: 'isChatttUpdateState',
  default: {
    value: false,
    updateInfo: undefined,
  },
});

export const useIsChatttUpdate = () => useRecoilValue(isChatttUpdateState);
export const useSetIsChatttUpdate = () => useSetRecoilState(isChatttUpdateState);

const isFettUpdateState = atom<{
  value: boolean;
  updateInfo?: {
    name: string;
    version: string;
  };
}>({
  key: 'isFettUpdateState',
  default: {
    value: false,
    updateInfo: undefined,
  },
});

export const useIsFettUpdate = () => useRecoilValue(isFettUpdateState);
export const useSetIsFettUpdate = () => useSetRecoilState(isFettUpdateState);
