import Icon, { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Select, Space } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from 'services/api/chat.gpt';
import { ReactComponent as SharpIcon } from '../assets/sharp.svg';
import { ReactComponent as TranslateIcon } from '../assets/translate.svg';
import { ProductContentGeneratorContextV2 } from '../ContextV2';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;
const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 0;
`;
const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

// GeneratorHeader interface
export interface GeneratorHeaderProps {
  title: string;
  page: number;
  pageTotal: number;
  lang: Language;
  translateLoading?: boolean;
  onChangeLang?: (lang: Language) => void;
  onTranslate?: () => void;
  onChangePage?: (page: number) => void;
}

export const GeneratorHeader = (props: GeneratorHeaderProps) => {
  const {
    title,
    lang,
    page,
    pageTotal,
    translateLoading = false,
    onChangeLang,
    onTranslate,
    onChangePage,
  } = props;
  const { languageOptions } = useContext(ProductContentGeneratorContextV2);
  const { t } = useTranslation('ViewProductContentGenerator');

  return (
    <Container>
      <FlexCenter>
        <SharpIcon />
        <Title>{title}</Title>
        <Space align="center">
          <Button
            type="text"
            size="small"
            disabled={page === 0}
            onClick={() => {
              onChangePage?.(page - 1);
            }}
          >
            <LeftOutlined />
          </Button>
          <span>
            {page + 1} / {pageTotal}
          </span>
          <Button
            type="text"
            size="small"
            disabled={page === pageTotal - 1}
            onClick={() => {
              onChangePage?.(page + 1);
            }}
          >
            <RightOutlined />
          </Button>
        </Space>
      </FlexCenter>
      <FlexCenter>
        <Select
          style={{ minWidth: 100 }}
          options={languageOptions}
          value={lang}
          onChange={onChangeLang}
        />
        <Button
          type="text"
          onClick={onTranslate}
          loading={translateLoading}
          icon={<Icon component={TranslateIcon} />}
        >
          {t('一键翻译')}
        </Button>
      </FlexCenter>
    </Container>
  );
};
