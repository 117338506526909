import Icon from '@ant-design/icons';
import { TFuncKey } from 'react-i18next';
import { RouteConfig } from 'types';
import { TitlePage } from 'views/ProductContentGenerator/TitlePage';
import { DetailPage } from 'views/ProductContentGenerator/DetailPage';
import Translate from 'views/Translate';
import { ReactComponent as TitleSvg } from './assets/title.svg';
import { ReactComponent as DetailSvg } from './assets/detail.svg';
import { ReactComponent as TranslateSvg } from './assets/translate.svg';

interface PRouteConfig extends RouteConfig {
  i18n: TFuncKey<'ViewProductContentGenerator'>;
}

export const isOnlyTranslate = (channel: string) => channel === 'aigc_dny123_translate';

export const isDny123 = (channel: string) => channel.startsWith('aigc_dny123');

export const routes = (() => {
  const titleRoute: PRouteConfig = {
    path: '/title',
    menu: {
      label: 'productTitle',
      icon: <Icon component={TitleSvg} />,
    },
    i18n: 'productTitle',
    element: <TitlePage />,
  };

  const detailRoute: PRouteConfig = {
    path: '/detail',
    menu: {
      label: 'productDetail',
      icon: <Icon component={DetailSvg} />,
    },
    i18n: 'productDetail',
    element: <DetailPage />,
  };

  const translateRoute: PRouteConfig = {
    path: '/translate',
    menu: {
      label: 'translate',
      icon: <Icon component={TranslateSvg} />,
    },
    i18n: 'translateBtn',
    element: <Translate />,
  };

  const { hash = '' } = window.location;

  const [, queryString = ''] = hash.split('?');

  const query = queryString.split('&').reduce<Record<string, string>>((res, cur) => {
    const [key, value] = cur.split('=');

    res[key] = value;

    return res;
  }, {});

  const { channel = '' } = query;

  if (isOnlyTranslate(channel)) {
    return [translateRoute];
  }

  if (isDny123(channel)) {
    return [titleRoute, detailRoute];
  }

  return [titleRoute, detailRoute, translateRoute];
})();
