/* eslint-disable no-console */
import { AuthorizationConfig } from 'types';
import { authorize } from 'services/api';
import cacheConfig from './authorizationCache';

const authorization: AuthorizationConfig<Oversea.Token.Payload, void> = {
  ...cacheConfig,
  onAuthorize(token, payload) {
    console.log(`"${payload.name}" authorize with token: ${token}`);

    authorize(token);
  },
  onUnauthorize(code, reason) {
    console.log(`unauthorize (${code}): "${reason}"`);
  },
  getInitialState: () => Promise.resolve(),
};

export default authorization;
