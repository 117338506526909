import { message } from 'antd';
import { useCallback } from 'react';
import { atom, selector, useRecoilCallback, useRecoilValue } from 'recoil';
import i18n from 'i18next';
import chatttSDK, { syncSysSettings } from 'services/chattt';
import { isUndefined } from 'lodash-es';

type MessageRemind = {
  soundRemind: boolean;
  popupRemind: boolean;
  flashRemind?: boolean;
  reviewRemind?: boolean;
  refundRemind?: boolean;
};

type CloseType = 'hidden' | 'close' | 'default';

export type SystemLanguage = General.SystemLanguage;

export interface SystemSetting {
  messageRemind: MessageRemind;
  language: SystemLanguage;
  closeType: CloseType;
  theme: 'Orange' | 'Blue';
}
const SYSTEM_SETTING_STORAGE_KEY = 'fett/systemSetting';

const effects = (setting: SystemSetting) => {
  localStorage.setItem(SYSTEM_SETTING_STORAGE_KEY, JSON.stringify(setting));

  i18n.changeLanguage(setting.language);

  syncSysSettings({
    language: setting.language,
    quitMode: setting.closeType,
  });
};

export const defaultRemindSetting: MessageRemind = {
  soundRemind: true,
  popupRemind: true,
  flashRemind: true,
  reviewRemind: true,
  refundRemind: true,
};

const getDefaultSystemSetting = (): SystemSetting => {
  const storage = localStorage.getItem(SYSTEM_SETTING_STORAGE_KEY);
  let language: General.Language = navigator.language === 'zh-CN' ? 'zh-CN' : 'en-US';
  let cached: SystemSetting | undefined;
  if (storage) {
    cached = JSON.parse(storage) as SystemSetting;
    language = cached.language ?? language;
  }
  i18n.changeLanguage(language);
  if (cached) {
    effects(cached);
    cached.theme = cached.theme ?? 'Blue';
    // 校验缓存中的主题是否合法
    if (cached.theme !== 'Orange' && cached.theme !== 'Blue') {
      if (process.env.NODE_ENV === 'development') {
        throw new Error('使用了未被支持的主题，请清空localstorage系统配置');
      }
      cached.theme = 'Blue';
    }
    return cached;
  }

  const setting: SystemSetting = {
    messageRemind: {
      ...defaultRemindSetting,
      flashRemind: chatttSDK?.app?.getFlashRemind?.() || true,
    },
    language,
    closeType: 'default',
    theme: 'Blue',
  };

  effects(setting);

  return setting;
};

const systemSettingState = atom({
  key: 'systemSettingState',
  default: getDefaultSystemSetting(),
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((state) => {
        effects(state);
      });
    },
  ],
});

export const useGetNotificationPermission = () => {
  return useRecoilCallback(
    ({ set }) =>
      () => {
        if (window.Notification.permission === 'default') {
          window.Notification.requestPermission().then((permission) => {
            set(systemSettingState, (setting) => {
              return {
                ...setting,
                messageRemind: {
                  ...setting.messageRemind,
                  popupRemind: permission === 'granted',
                },
              };
            });
          });
        }
      },
    [],
  );
};

const systemLanguageState = selector({
  key: 'systemLanguage',
  get: ({ get }) => get(systemSettingState).language,
});
function useSystem() {
  const systemSetting = useRecoilValue(systemSettingState);

  const setSystemSetting = useRecoilCallback(
    ({ set }) =>
      (state: SystemSetting) =>
        set(systemSettingState, state),
    [],
  );

  const handleMessageRemindSettingChange = useCallback(
    async (messageRemind: SystemSetting['messageRemind']) => {
      let result = Notification.permission;
      if (messageRemind.popupRemind && result !== 'granted') {
        result = await window.Notification.requestPermission();
        result === 'denied' && message.warn('您已禁用浏览器通知，请在设置中手动开启');
      }

      // Fixed: 首次渲染 undefined 值导致其他 switch 项无法更新
      if (!isUndefined(messageRemind.flashRemind)) {
        chatttSDK?.app?.setFlashRemind?.(messageRemind.flashRemind);
      }

      setSystemSetting({
        ...systemSetting,
        messageRemind: {
          ...messageRemind,
          popupRemind: messageRemind.popupRemind ? result === 'granted' : false,
        },
      });
    },
    [setSystemSetting, systemSetting],
  );
  const handleLanguageSettingChange = useCallback(
    (language: SystemLanguage) => {
      setSystemSetting({
        ...systemSetting,
        language,
      });
    },
    [setSystemSetting, systemSetting],
  );
  const handleCloseSettingChange = useCallback(
    (closeType: SystemSetting['closeType']) => {
      setSystemSetting({
        ...systemSetting,
        closeType,
      });
    },
    [setSystemSetting, systemSetting],
  );
  const handleThemeSettingChange = useCallback(
    (theme: SystemSetting['theme']) => {
      setSystemSetting({
        ...systemSetting,
        theme,
      });
    },
    [setSystemSetting, systemSetting],
  );

  return {
    systemSetting,
    handleMessageRemindSettingChange,
    handleLanguageSettingChange,
    handleCloseSettingChange,
    handleThemeSettingChange,
  };
}

export const useSystemLanguageValue = () => useRecoilValue(systemLanguageState);
export const useSystemSettingsValue = () => useRecoilValue(systemSettingState);

export default useSystem;
