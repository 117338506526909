import { useMemoizedFn } from 'ahooks3';
import { Form } from 'antd';
import { cloneDeep } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generateProductDetail,
  GenerateProductDetailBody,
  Language,
  Platform,
  translateDetailContent,
} from 'services/api/chat.gpt';
import useApiError from './useApiError';

const { useForm } = Form;

export interface IDetailContent {
  index: number;
  platform: Platform;
  detailContent?: string;
  detailTranslateContent?: Record<Language, string>;
  detailSummary?: string;
  detailSummaryTranslateContent?: Record<Language, string>;
}

export const formInitialValues: GenerateProductDetailBody = {
  platform: 'SHOPEE',
  language: 'EN',
  category: '',
  brand: '',
  sellingPoint: '',
  listingContent: '',
  highlightInfo: '',
  weakenInfo: '',
  listingRestriction: {
    length: 999,
    withoutSpecialCharactersAndLinks: true,
    withoutEmoticons: false,
  },
  mustIncludedKeys: [],
  mustExcludeKeys: [],
};

export const useDetail = () => {
  const [detailForm] = useForm<GenerateProductDetailBody>();
  const [detailLoading, setDetailLoading] = useState(false);
  const apiError = useApiError();
  const { t } = useTranslation('ViewProductContentGenerator');

  const [detailPage, setDetailPage] = useState(-1);
  const [detailPageContent, setDetailPageContent] = useState<IDetailContent[]>([]);

  const [detailTranslateLoading, setDetailTranslateLoading] = useState(false);
  const [detailTranslateLang, setDetailTranslateLang] = useState<Language>('ZH_CN');

  // 更新生成缓存状态
  const updateDetailPageContent = useMemoizedFn((index: number, newContent: IDetailContent) => {
    // 快速索引
    setDetailPageContent((prev) => {
      const clone = cloneDeep(prev);
      // 直接拷贝覆盖原有的值进行更新
      clone[index] = newContent;
      return [...clone];
    });
  });

  // 重置详情页缓存状态
  const resetDetailPageContent = useMemoizedFn(() => {
    setDetailPage(-1);
    setDetailPageContent([]);
    detailForm.setFieldsValue(formInitialValues);
  });

  // 生成详情
  const generateDetail = useMemoizedFn(async () => {
    try {
      const values = detailForm?.getFieldsValue();

      if (values) {
        setDetailLoading(true);
        const { platform, result, summary } = await generateProductDetail({
          ...values,
        });
        setDetailPageContent((prev) => {
          return [
            ...prev,
            {
              index: prev.length,
              platform,
              detailContent: result,
              detailSummary: summary,
            },
          ];
        });
        setDetailPage((prev) => prev + 1);
      }
    } catch (err) {
      apiError(err, t('timeoutTips'));
    } finally {
      setDetailLoading(false);
    }
  });

  // 翻译详情
  const translateDetail = useMemoizedFn(
    async (detailContent: IDetailContent, detailPage: number, lang: Language) => {
      try {
        const values = detailForm?.getFieldsValue();
        if (values) {
          setDetailTranslateLoading(true);
          const promises = [];

          // 翻译详情
          if (detailContent.detailContent) {
            promises.push(
              translateDetailContent({
                itemListingDto: values,
                language: lang,
                sourceContent: detailContent.detailContent,
              }),
            );
          } else {
            promises.push(Promise.resolve(undefined));
          }

          // 翻译描述
          if (detailContent.detailSummary) {
            promises.push(
              translateDetailContent({
                itemListingDto: values,
                language: lang,
                sourceContent: detailContent.detailSummary,
              }),
            );
          } else {
            promises.push(Promise.resolve(undefined));
          }

          const [detailResult, summaryResult] = await Promise.all(promises);
          // 更新缓存状态
          setDetailPageContent((prev) => {
            const clone = cloneDeep(prev);
            if (detailResult?.result) {
              const detailTranslateContent = prev[detailPage].detailTranslateContent ?? {};
              clone[detailPage].detailTranslateContent = {
                ...detailTranslateContent,
                [lang]: detailResult.result,
              } as Record<Language, string>;
            }
            if (summaryResult?.result) {
              const detailSummaryTranslateContent =
                prev[detailPage].detailSummaryTranslateContent ?? {};
              clone[detailPage].detailSummaryTranslateContent = {
                ...detailSummaryTranslateContent,
                [lang]: summaryResult.result,
              } as Record<Language, string>;
            }

            return [...clone];
          });
        }
      } catch (err) {
        apiError(err, t('timeoutTips'));
      } finally {
        setDetailTranslateLoading(false);
      }
    },
  );

  const detailPageTotal = useMemo(() => detailPageContent.length, [detailPageContent]);

  useEffect(() => {
    detailForm.setFieldsValue(formInitialValues);
  }, [detailForm]);

  return {
    detailForm,
    detailLoading,
    detailPage,
    detailPageContent,
    detailTranslateLang,
    detailTranslateLoading,
    detailPageTotal,
    generateDetail,
    setDetailPage,
    resetDetailPageContent,
    translateDetail,
    setDetailTranslateLang,
    updateDetailPageContent,
  };
};
