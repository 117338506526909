import styled from '@emotion/styled';
import { css } from '@emotion/css/macro';
import React, { HTMLAttributes } from 'react';
import BottomShadow from './BottomShadow';

const Box = styled.div`
  position: relative;
  width: 604px;
  margin: 0 auto;
  @media (max-width: 480px) {
    width: 100vw;
  }
`;

const Shadow = styled.div`
  position: relative;
  width: 480px;
  padding: 20px;
  margin: 0 auto;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 8px;
  z-index: 2;

  .ant-form-large .ant-form-item-label > label {
    height: 20px;
  }
  @media (max-width: 480px) {
    width: 100vw;
  }
`;

export const Title = styled.div`
  margin: 32px auto 26px auto;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 33px;
`;

export const style = {
  withUpdateTip: css`
    margin-top: 0 !important;
  `,
  updateTip: css`
    margin: 70px auto 16px auto;
    width: 720px;
  `,
};

interface Props extends HTMLAttributes<HTMLDivElement> {}

const Shadowbox = (props: Props) => {
  const { children } = props;

  return (
    <Box>
      <Shadow>{children}</Shadow>
      <BottomShadow />
    </Box>
  );
};

export default Shadowbox;
