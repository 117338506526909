import { css, Global } from '@emotion/react';
import { useMount } from 'ahooks3';
import { ConfigProvider } from 'antd';
import useSystem, { SystemSetting } from 'hooks/business/system/useSystem';
import React, { useContext, useMemo, useCallback } from 'react';
import { isMlj } from 'services/chattt';
import createNamedContext from 'utils/createNamedContext';

export type Themes = SystemSetting['theme'];
const defaultTheme: Themes = 'Orange';
const ThemesContext = createNamedContext<{ theme: Themes; onChange: (theme: Themes) => void }>(
  'fett-theme',
  { theme: defaultTheme, onChange: () => {} },
);

export const useThemeContext = () => useContext(ThemesContext);

const commonColors = {
  errorColor: '#FF433D',
  successColor: '#00AA5A',
  infoColor: '#5F8EFC',
};

const mljColors = {
  primaryColor: '#fa6400',
  ...commonColors,
};
export const themesColors = {
  Blue: {
    primaryColor: '#3366FF',
    ...commonColors,
  },
  Orange: {
    primaryColor: '#ee4e2d',
    ...commonColors,
  },
};
const themesVars: Record<Themes, any> = {
  Blue: css`
    --fett-colors-primary: ${themesColors.Blue.primaryColor}; // app 主色，跟antd组件保持一致
    --fett-colors-second: #fff7f7; // 套餐倒计时弹窗背景色
    --fett-colors-price: #f1694f; // 价格字体色
  `,
  Orange: css`
    --fett-colors-primary: ${themesColors.Orange.primaryColor}; // app 主色，跟antd组件保持一致
    --fett-colors-second: #fff7f7; // 套餐倒计时弹窗背景色
    --fett-colors-price: #f1694f; // 价格字体色
  `,
};
const commonVars = css`
  --fett-app-primary-color: var(--ant-primary-color);
  --fett-app-sider-nav-primary-bg: var(--fett-app-primary-color);
  --fett-free-trail-banner-primay-color: var(--fett-colors-primary);
  --fett-free-trail-banner-bg-color: var(--fett-colors-second);
`;
export const ThemesProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    systemSetting: { theme },
    handleThemeSettingChange,
  } = useSystem();
  const updateTheme = useCallback(
    (theme: Themes) => {
      let themeConfig = themesColors[theme];
      if (isMlj) {
        themeConfig = mljColors;
      }
      ConfigProvider.config({
        theme: themeConfig,
      });
      handleThemeSettingChange(theme);
    },
    [handleThemeSettingChange],
  );
  const CssVarRoot = useMemo(() => {
    const themeVars = themesVars[theme];
    return isMlj
      ? css`
          :root {
            ${themeVars}
            ${commonVars}
            --fett-app-primary-color: ${mljColors.primaryColor}; // 马六甲专用
          }
        `
      : css`
          :root {
            ${themeVars}
            ${commonVars}
            --fett-app-primary-color: var(--fett-colors-primary);
          }
        `;
  }, [theme]);
  useMount(() => {
    updateTheme(theme);
  });
  return (
    <ThemesContext.Provider value={{ theme, onChange: updateTheme }}>
      <>
        {useMemo(
          () => (
            <Global styles={CssVarRoot} />
          ),
          [CssVarRoot],
        )}
        {useMemo(() => children, [children])}
      </>
    </ThemesContext.Provider>
  );
};
export default ThemesProvider;
