import { QuestionCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled/macro';
import { useAuthorization } from 'components/Authorization';
import {
  MsgType,
  mustache2SlateValuePolicyMsgTypes,
  SlateValue2Mustache,
  SlateValue2MustacheV2,
} from 'components/TemplateEditor/mustacheUtils';
import { useSystemLanguageValue } from 'hooks/business/system/useSystem';
import { useIsMixingTokoModeValue } from 'hooks/business/toko/useTokoMode';
import { useCallback, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Descendant } from 'slate';
import { cancellingTradeScenarios } from '../config';

export interface DescendantExecuteContext {
  content: Descendant[];
  translatedContent: Descendant[];
}

export interface PolicyFormValues
  extends Omit<
    Marketing.Policy,
    | 'id'
    | 'taskId'
    | 'roundId'
    | 'priority'
    | 'bilingualExecuteContext'
    | 'bilingualExecuteContexts'
    | 'regionExecuteContexts'
    | 'executeItemContext'
    | 'cancelTradeConfig'
  > {
  id?: Marketing.Policy['id'];
  bilingualExecuteContext: DescendantExecuteContext;
  bilingualExecuteContexts: DescendantExecuteContext[];
  regionExecuteContexts?: {
    region: General.UpperCaseRegion;
    executeContext: DescendantExecuteContext;
  }[];
  regions?: General.UpperCaseRegion[];
  executeItemContext: {
    itemId: string;
    customizeContext?: Descendant[];
  }[];
  cancelTradeConfig?: Omit<Required<Marketing.Policy>['cancelTradeConfig'], 'contents'> & {
    contents: (Omit<
      Util.ElementType<Required<Marketing.Policy>['cancelTradeConfig']['contents']>,
      'executeContext'
    > & {
      executeContext: DescendantExecuteContext;
    })[];
  };
}

export type FV = PolicyFormValues;

export interface Validate {
  (errors: Partial<Record<keyof FV, any>>, values: FV, t: TFunction<'translation'>): void;
}

export const ColumnFormItem = styled.div`
  > div {
    display: flex;
    flex-direction: column !important;
    .${({ theme }) => theme.getAntdPrefixedClassName('form-item-label')} {
      text-align: left;
    }
  }
`;

export const Question = styled(QuestionCircleOutlined)`
  margin-left: 4px;
  cursor: help;
`;

export const InputWrapper = styled.div`
  width: 320px;
  > * {
    width: 100%;
  }
`;

export const useShippingStatusLabel = () => {
  const { t } = useTranslation();

  const systemLanguage = useSystemLanguageValue();

  const displayExtraText = systemLanguage === 'zh-CN';

  const statusLabelMap: Record<Marketing.ShippingStatus, string> = {
    TO_PROCESS: `${t('review.处理中')}${displayExtraText ? ' To Process' : ''}`,
    PROCESSED: `${t('review.已处理')}${displayExtraText ? ' Processed' : ''}`,
  };

  return statusLabelMap;
};

export const getSlateContent = (mustache: string): Descendant[] => {
  let slateValue: Descendant[] = mustache2SlateValuePolicyMsgTypes('');

  try {
    slateValue = mustache2SlateValuePolicyMsgTypes(mustache.replace(/^#mustache#/, ''));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }

  return slateValue;
};

export const getSlateExecuteContext = (
  executeContext: Marketing.ExecuteContext,
): Marketing.ExecuteContext<Descendant[]> => {
  const { content, translatedContent } = executeContext;
  return {
    content: getSlateContent(content),
    translatedContent: getSlateContent(translatedContent),
  };
};

export const getMustacheContent = (slateValue: Descendant[]): string => {
  return `#mustache#${SlateValue2Mustache(slateValue)}`;
};

export const getMustacheExecuteContext = (
  executeContext: Marketing.ExecuteContext<Descendant[]>,
): Marketing.ExecuteContext => {
  const { content, translatedContent } = executeContext;

  return {
    content: getMustacheContent(content),
    translatedContent: getMustacheContent(translatedContent),
  };
};

/** 策略 发送内容 多消息类型适配 */
export const getMustacheExecuteContextV2 = (
  executeContext: Marketing.ExecuteContext<Descendant[]>,
): Marketing.ExecuteContext => {
  const { content, translatedContent } = executeContext;
  return {
    content: `#mustache#${SlateValue2MustacheV2(content)}`,
    translatedContent: `#mustache#${SlateValue2MustacheV2(translatedContent)}`,
  };
};

export function getMsgTypes(
  platform: General.PlatformUpperCase,
  scenarioType: Marketing.AllScenarioType,
  isMixingTokoModeValue: boolean,
  orgId?: number,
): MsgType[] {
  if (scenarioType === 'POSITIVE_REVIEW_REPLY' || scenarioType === 'NEGATIVE_REVIEW_REPLY') {
    return ['text'];
  }

  if (platform === 'TOKOPEDIA') {
    if (!isMixingTokoModeValue || scenarioType === 'QUICK_PHRASE') {
      return ['text'];
    }

    return ['text', 'image'];
  }

  if (platform === 'ALIEXPRESS' || platform === 'TIKTOK') {
    if (scenarioType === 'QUICK_PHRASE') {
      return ['text'];
    }

    return ['text', 'image'];
  }

  if (scenarioType === 'POSITIVE_REVIEW_CARE') {
    return ['text', 'sticker', 'image', 'video', 'follow'];
  }

  if (scenarioType === 'OUT_STOCK_REMINDER') {
    return ['text', 'image', 'sticker'];
  }

  const isCancellingTrade = cancellingTradeScenarios.includes(
    scenarioType as Marketing.CancellingTradeScenarioType,
  );

  if (isCancellingTrade) {
    return ['text', 'sticker', 'image'];
  }

  if (
    scenarioType === 'CONFIRM_TRADE' &&
    (orgId === 8371 || orgId === 1002 || process.env.REACT_APP_ENV === 'feature') // 8371 唯一开通的客户 org，1002 测试 org。
  ) {
    return ['text', 'sticker', 'image', 'video', 'qrcode'];
  }

  return ['text', 'sticker', 'image', 'video'];
}

export function useGetMsgTypes() {
  const isMixingTokoValue = useIsMixingTokoModeValue();

  const { payload } = useAuthorization<Oversea.Token.Payload>();

  const { orgId } = payload || {};

  return useCallback(
    (platform: General.PlatformUpperCase, scenarioType: Marketing.AllScenarioType) => {
      return getMsgTypes(platform, scenarioType, isMixingTokoValue, orgId);
    },
    [isMixingTokoValue, orgId],
  );
}

export function useMsgTypes(
  platform: General.PlatformUpperCase,
  scenarioType: Marketing.AllScenarioType,
) {
  const getMsgTypes = useGetMsgTypes();

  return useMemo(() => getMsgTypes(platform, scenarioType), [platform, scenarioType, getMsgTypes]);
}
