import i18n from 'i18next';

const specialCharacters = [
  '`',
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '_',
  '+',
  '=',
  '-',
  '\\',
  '|',
  ']',
  '[',
  '{',
  '}',
  '"',
  "'",
  ';',
  ':',
  ',',
  '<',
  '.',
  '>',
  '/',
  '?',
];
/**
 *
 * @param password 密码
 * @param username 帐号名
 * @param mode 严格模式or简单模式，严格模式，检验全开，简单模式仅校验长度在8-20位内，当下默认简单模式
 * @doc https://leyan.yuque.com/czpqnb/pd/bb9cana2malhwe8o
 * @returns
 */

const passwordValidator = (
  password: string,
  username?: string,
  mode: 'simple' | 'strict' = 'simple',
): {
  valid: boolean;
  message: string;
} => {
  if (!password) {
    return {
      valid: false,
      message: i18n.t(
        mode === 'simple'
          ? 'registry.form.item.password.validator.error.length'
          : 'registry.form.item.password.validator.error.required',
        {
          ns: 'ViewStartups',
        },
      ),
    };
  }
  if (password.length < 8 || password.length > 20) {
    return {
      valid: false,
      message: i18n.t('registry.form.item.password.validator.error.length', { ns: 'ViewStartups' }),
    };
  }
  if (mode === 'simple') {
    return {
      valid: true,
      message: '',
    };
  }
  if (!/[0-9]/.test(password)) {
    return {
      valid: false,
      message: i18n.t('registry.form.item.password.validator.error.no.number', {
        ns: 'ViewStartups',
      }),
    };
  }
  if (!/[a-zA-z]/.test(password)) {
    return {
      valid: false,
      message: i18n.t('registry.form.item.password.validator.error.no.alphabet', {
        ns: 'ViewStartups',
      }),
    };
  }
  if (specialCharacters.every((x) => !password.includes(x))) {
    return {
      valid: false,
      message: i18n.t('registry.form.item.password.validator.error.no.special.characters', {
        ns: 'ViewStartups',
      }),
    };
  }
  if (username && password.includes(username)) {
    return {
      valid: false,
      message: i18n.t('registry.form.item.password.validator.error.include.username', {
        ns: 'ViewStartups',
      }),
    };
  }

  return {
    valid: true,
    message: '',
  };
};

export default passwordValidator;
