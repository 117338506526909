import Icon from '@ant-design/icons/lib/components/Icon';
import styled from '@emotion/styled/macro';
import { Button, Checkbox, Form, Input, message, Modal, ModalProps, Select, Skeleton } from 'antd';
import StatusButton from 'components/StatusButton';
import { useSystemLanguageValue } from 'hooks/business/system/useSystem';
import useCrud from 'hooks/useCrud';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hotkeyList, Hotkey, HotkeyListBody, Platform } from 'services/api/chat.gpt';
import { regionLanguageMap } from 'services/config/region';
import toLowerCase from 'utils/toLowerCase';
import Empty from 'views/Aide/components/Empty';
import emptyImage from '../assets/empty.png';
import { ReactComponent as FireSvg } from '../assets/fire.svg';
import useApiError from '../hooks/useApiError';
import { platformRegionMap } from './config';

const Container = styled.div`
  display: flex;
  margin: -24px;
  > * {
    flex: 1 0 0;
    padding: 16px 24px;
  }
  > section {
    border-left: solid 1px #f0f0f0;
    background: #fbfbfb;
  }
`;

const ResultWrapper = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      > * + * {
        margin-left: 8px;
      }
    }
  }
`;

const ResultTitle = styled.div`
  > div {
    font-weight: bold;
    > span {
      font-size: 12px;
      font-weight: normal;
      color: #84868c;
    }
  }
  > span {
    color: #84868c;
  }
`;

const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > * {
    margin-left: 8px;
  }
  > span {
    color: #84868c;
  }
`;

const Fire = styled.span`
  flex: 0 0 auto;
  font-size: 12px;
  color: #f05b5b;
`;

export interface HotkeyModalProps extends ModalProps {
  platform: Exclude<Platform, 'DEFAULT'>;
  onHotkeysSelect: (hotkeys: Hotkey[], region: General.UpperCaseRegion) => void;
}

function HotkeyModal(props: HotkeyModalProps) {
  const { platform, onCancel, onHotkeysSelect, ...otherProps } = props;

  const { t: pt } = useTranslation('ViewProductContentGenerator');

  const { t } = useTranslation();

  const regionLabel = t('region', { returnObjects: true });

  const systemLanguage = useSystemLanguageValue();

  const {
    loading,
    list = [],
    setList,
    fetchList,
  } = useCrud({
    readList: (props: HotkeyListBody) => hotkeyList(props).then(({ data }) => data),
  });

  const [emptyResult, setEmptyResult] = useState(false);

  const [keyword, setKeyword] = useState('');

  const regionList = platformRegionMap[platform];

  const [region, setRegion] = useState<General.UpperCaseRegion>(regionList[0]);

  useEffect(() => {
    if (!regionList.includes(region)) {
      setRegion(regionList[0]);
    }
  }, [regionList, region]);

  const [checkedList, setCheckedList] = useState<Hotkey[]>([]);

  const regionOptions = regionList.map((region) => {
    return {
      value: region,
      label: regionLabel[toLowerCase(region)],
    };
  });

  const [requestRegion, setRequestRegion] = useState(region);

  const targetLang = regionLanguageMap[toLowerCase(requestRegion)];

  const apiError = useApiError();

  const form = (
    <Form layout="vertical">
      <Form.Item label={pt('商品品类/核心搜索词')}>
        <Input.TextArea
          placeholder={pt('请输入商品品类或核心搜索词')}
          value={keyword}
          onChange={({ target: { value } }) => setKeyword(value)}
          autoSize={{
            minRows: 8,
            maxRows: 8,
          }}
        />
      </Form.Item>
      <Form.Item label={pt('选择站点')}>
        <Select
          placeholder={pt('请选择站点')}
          value={region}
          onChange={setRegion}
          options={regionOptions}
        />
      </Form.Item>
      <Form.Item>
        <StatusButton
          block
          type="primary"
          disabled={!keyword.trim()}
          onClick={() => {
            setList([]);

            setCheckedList([]);

            return fetchList({
              platformEnum: platform,
              region,
              keyword,
              pageLanguage: systemLanguage,
            })
              .then((res) => {
                if (res.length === 0) {
                  message.error(pt('未查询到符合条件的热词'));

                  setEmptyResult(true);
                } else {
                  setEmptyResult(false);
                }

                setRequestRegion(region);
              })
              .catch((err) => {
                apiError(err, pt('timeoutTips'));

                setEmptyResult(false);
              });
          }}
        >
          {pt('提交生成')}
        </StatusButton>
      </Form.Item>
    </Form>
  );

  const result = (() => {
    if (loading) {
      return (
        <ResultWrapper>
          <div>
            <Skeleton.Button active size="small" />
            <Skeleton.Button active size="small" />
          </div>
          <ul>
            {new Array(10).fill('').map(() => {
              return (
                <li>
                  <Skeleton.Avatar active size="small" shape="square" />
                  <Skeleton.Button active block size="small" />
                  <Skeleton.Button active size="small" />
                </li>
              );
            })}
          </ul>
        </ResultWrapper>
      );
    }

    if (list.length === 0) {
      if (emptyResult) {
        return (
          <Empty>
            <img src={emptyImage} alt="empty" />
            <p>{pt('未查询到符合条件的热词')}</p>
            <p>{pt('请修改后再次提交生成')}</p>
          </Empty>
        );
      }

      return (
        <Empty>
          <img src={emptyImage} alt="empty" />
          <p>{pt('请在左侧页面输入你的热词诉求')}</p>
          <p>{pt('AI会根据热度排名推荐热词')}</p>
        </Empty>
      );
    }

    return (
      <ResultWrapper>
        <ResultTitle>
          <div>
            {pt('热词')}
            <span>({pt('翻译')})</span>
          </div>
          <span>{pt('平台近30日热度')}</span>
        </ResultTitle>
        <ul>
          {list.map((hotkey) => {
            const { keyword, translatedKeyword, searchVolume } = hotkey;

            return (
              <li>
                <Checkbox
                  checked={checkedList.some((_) => _.keyword === keyword)}
                  onChange={({ target: { checked } }) => {
                    setCheckedList((list) => {
                      if (checked) {
                        return [...list, hotkey];
                      }

                      return list.filter((_) => _.keyword !== keyword);
                    });
                  }}
                >
                  {keyword}
                  {targetLang !== systemLanguage && (
                    <span style={{ color: '#84868C' }}>{`(${translatedKeyword})`}</span>
                  )}
                </Checkbox>
                <Fire>
                  <Icon component={FireSvg} />
                  {searchVolume}
                </Fire>
              </li>
            );
          })}
        </ul>
      </ResultWrapper>
    );
  })();

  const footer = (
    <Footer>
      {list.length > 0 && <span>{pt('已选x', { x: checkedList.length })}</span>}
      <Button onClick={onCancel}>{pt('取消')}</Button>
      <Button
        type="primary"
        disabled={checkedList.length === 0}
        onClick={(e) => {
          onHotkeysSelect(checkedList, requestRegion);

          if (onCancel) {
            onCancel(e);
          }
        }}
      >
        {pt('确定')}
      </Button>
    </Footer>
  );

  return (
    <Modal
      {...otherProps}
      title={pt('自动生成热词')}
      width={1000}
      onCancel={onCancel}
      footer={footer}
    >
      <Container>
        {form}
        <section>{result}</section>
      </Container>
    </Modal>
  );
}

export default HotkeyModal;
