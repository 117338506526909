import { lazy } from 'react';
import { RouteConfig } from 'types';

const ItemList = lazy(() => import('./ItemList'));
const KeywordList = lazy(() => import('./KeywordList'));

// eslint-disable-next-line import/prefer-default-export
export const routes: RouteConfig[] = [
  {
    path: 'itemList',
    element: <ItemList />,
  },
  {
    path: 'keywordList',
    element: <KeywordList />,
  },
  {
    path: 'convKeywordList',
    element: <KeywordList type="conversation" />,
  },
];
