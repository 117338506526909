import { InfoCircleFilled } from '@ant-design/icons';
import styled from '@emotion/styled/macro';
import { Button, Form, Tooltip } from 'antd';
import { uniq } from 'lodash-es';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Hotkey, Platform } from 'services/api/chat.gpt';
import { What } from 'views/Marketing/components/PolicyTable';
import HotkeyModal from './HotkeyModal';
import WordsInput from './WordsInput';

const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    > label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > button {
        height: 22px;
        padding: 0;
        font-size: 12px;
      }
    }
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  margin: 2px 0;
  font-size: 12px;
  > div {
    flex: 1 0 0;
    color: #f2a100;
    > span {
      margin-right: 4px;
    }
  }
  > span {
    margin-left: 8px;
  }
`;

const notSupportHotkeyPlatforms: Platform[] = ['AMAZON', 'DEFAULT'];

export interface MustIncludedKeysFieldProps {
  description?: string;
  hotkeyModalKey?: string | number;
}

function MustIncludedKeysField(props: MustIncludedKeysFieldProps) {
  const { description, hotkeyModalKey } = props;

  const { t } = useTranslation('ViewProductContentGenerator');

  const [visible, setVisible] = useState(false);

  const [regionHotkeysMap, setRegionHotkeysMap] = useState<
    Partial<Record<General.UpperCaseRegion, Hotkey[]>>
  >({});

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldsValue, setFieldsValue, validateFields }) => {
        const { platform, mustIncludedKeys = [] } = getFieldsValue();

        const modal = (
          <HotkeyModal
            key={hotkeyModalKey}
            platform={platform || 'SHOPEE'}
            visible={visible}
            onCancel={() => setVisible(false)}
            onHotkeysSelect={(hotkeys, region) => {
              setRegionHotkeysMap((map) => {
                const { [region]: preHotkeys = [] } = map;

                return {
                  ...map,
                  [region]: [...preHotkeys, ...hotkeys],
                };
              });

              setFieldsValue({
                mustIncludedKeys: uniq([...mustIncludedKeys, ...hotkeys.map((_) => _.keyword)]),
              });

              validateFields(['mustIncludedKeys']);
            }}
          />
        );

        return (
          <FormItem
            name="mustIncludedKeys"
            label={
              <>
                <div>
                  {t('热词')}
                  {description && (
                    <Tooltip title={description}>
                      <What />
                    </Tooltip>
                  )}
                </div>
                {!notSupportHotkeyPlatforms.includes(platform) && (
                  <>
                    <Button type="link" onClick={() => setVisible(true)}>
                      {t('无热词？试试自动生成热词')}
                    </Button>
                    {modal}
                  </>
                )}
              </>
            }
            rules={[
              {
                validator: async (_, value) => {
                  if (value && value.length > 10) {
                    return Promise.reject(new Error(t('最多可输入10个热词')));
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <>
              <div>
                <WordsInput
                  placeholder={t('请输入热词，按enter添加')}
                  words={mustIncludedKeys}
                  onWordsChange={(mustIncludedKeys) => {
                    setFieldsValue({ mustIncludedKeys });
                    validateFields(['mustIncludedKeys']);
                  }}
                  regionHotkeysMap={regionHotkeysMap}
                />
                <Info>
                  <div>
                    <InfoCircleFilled />
                    {t('热词语言与输出语言保持一致')}
                  </div>
                  <span
                    style={{
                      color:
                        mustIncludedKeys.length > 10 ? 'var(--ant-error-color)' : 'rgba(0,0,0,.45)',
                    }}
                  >
                    {mustIncludedKeys.length}
                    /10
                  </span>
                </Info>
              </div>
            </>
          </FormItem>
        );
      }}
    </Form.Item>
  );
}

export default MustIncludedKeysField;
