import React, { createContext, useContext, useRef, useMemo } from 'react';
import { createPortal } from 'react-dom';

export function createSlot() {
  interface SlotContextValue<T extends HTMLElement> {
    slotRef: React.RefObject<T>;
  }

  interface SlotProps<T extends HTMLElement>
    extends Omit<React.ProviderProps<SlotContextValue<T>>, 'value'> {}

  interface ProtalProps {
    children?: React.ReactNode;
  }

  const SlotContext = createContext<SlotContextValue<any> | null>(null);

  const { Provider } = SlotContext;

  function useSlotRef<T extends HTMLElement>() {
    const { slotRef } = useContext(SlotContext) as SlotContextValue<T>;

    return slotRef;
  }

  function Slot<T extends HTMLElement = HTMLElement>(props: SlotProps<T>) {
    const slotRef = useRef<T>();
    const value = useMemo(() => {
      return {
        slotRef,
      };
    }, []);

    return <Provider {...props} value={value} />;
  }

  function Portal(props: ProtalProps) {
    const { children } = props;

    const slotRef = useSlotRef();
    if (!slotRef.current) {
      console.log('SlotRef dom is not ready, skip.'); // eslint-disable-line
      return null;
    }
    return createPortal(children, slotRef.current as HTMLElement);
  }

  return {
    SlotContext,
    useSlotRef,
    Slot,
    Portal,
  };
}

const { SlotContext, useSlotRef, Slot, Portal } = createSlot();

export default Slot;
export { SlotContext, useSlotRef, Portal };
