import posthog from '@leyan/lytics';

let accid = '';
export function setAccid(nimAccid: string) {
  accid = nimAccid;
}

/** nim 事件上报 */
export function nimEventReport(eventName: string, custInfo?: any, sdkVersion?: string) {
  posthog.capture('$yxNimEvent', {
    accid,
    eventName,
    custInfo,
    sdkVersion,
  });
}
