import { Suspense, useEffect, lazy } from 'react';
import { RecoilRoot } from 'recoil';
import cnLocale from 'antd/es/locale/zh_CN';
import enLocale from 'antd/es/locale/en_US';
import idLocale from 'antd/es/locale/id_ID';
import thLocale from 'antd/es/locale/th_TH';
import esLocale from 'antd/es/locale/es_ES';
import viLocale from 'antd/es/locale/vi_VN';
import ptLocale from 'antd/es/locale/pt_BR';
import { ThemeProvider } from '@emotion/react/macro';
import { Result, Button, ConfigProvider, message, notification } from 'antd';

import routerConfig from 'services/config/router';
import ErrorBoundary from 'components/ErrorBoundary';
import AppLoading from 'components/AppLoading';
import Router from 'components/Router';
import RouterRender from 'components/RouterRender';
import Authorization, { onAuthorize, unauthorize } from 'components/Authorization';
import authorization from 'services/config/authorization';
import RouterMeta from 'components/RouterMeta';
import Slot from 'components/Slot';
import { useSystemLanguageValue } from 'hooks/business/system/useSystem';
import mjtTheme from 'themes/mlj';
import chatttSDK, { isMlj } from 'services/chattt';
import 'services/nim/nimHealth';
import { ThemesProvider as FettThemesProvider } from 'components/ThemesProvider';
import { useSpVerDeamon } from 'hooks/business/spVer/useIsSpVer';
import useAuthShops from 'hooks/business/authShops/useShopeeAuthShops';
import { css } from '@emotion/css';
import { useSetIsChatttUpdate, useSetIsFettUpdate } from 'hooks/useUpdater';
import { isDemoChannel, isDemoOrgs } from 'services/channels/demo';

const Notification = lazy(() => import('components/Notification'));

const {
  ConfigContext: { Consumer: ConfigConsumer },
} = ConfigProvider;

notification.config({
  maxCount: 3,
});

const languageLocaleMap = {
  'zh-CN': cnLocale,
  'en-US': enLocale,
  'zh-TW': cnLocale,
  id: idLocale,
  th: thLocale,
  vi: viLocale,
  pt: ptLocale,
  tl: enLocale,
  ms: enLocale,
  es: esLocale,
  pl: enLocale,
  fr: enLocale,
};

ConfigProvider.config({
  theme: isMlj ? mjtTheme : undefined,
});

// const isSP = /\#\/sp(\/|$)/.test(window.location.hash)
// console.log('isSP', isSP)
// if(isSP){
//   sploginS
// }

// 全局 message 配置
message.config({
  maxCount: 5,
});

const GlobalRest = css`
  /*  修正当前版本antd@4.18.3 Button loading 位置问题  */
  /* 官网4.19.* 已修正，项目依赖升级后可以去掉 */
  .ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 8px;
  }
`;

function Entry() {
  const language = useSystemLanguageValue();
  const locale = languageLocaleMap[language];
  const { stopMessageHandler, messageHandler } = useAuthShops();
  const setIsChatttUpdate = useSetIsChatttUpdate();
  const setIsFettUpdate = useSetIsFettUpdate();

  useEffect(() => {
    const handler = (evt: MessageEvent) => {
      const { event: eventName, payload } = evt.data;
      if (!stopMessageHandler) {
        messageHandler(evt);
      }

      if (eventName === 'app:updateDownloaded') {
        setIsChatttUpdate({
          value: true,
          updateInfo: {
            version: payload?.version,
            releaseDate: payload?.releaseDate,
            releaseNotes: payload?.releaseNotes,
          },
        });
      }
      if (eventName === 'fettApp:readyToUpdate') {
        setIsFettUpdate({
          value: true,
          updateInfo: {
            name: payload?.name,
            version: payload?.version,
          },
        });
      }
    };

    window.addEventListener('message', handler, false);
    return () => {
      window.removeEventListener('message', handler, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopMessageHandler]);

  return (
    <div className={GlobalRest}>
      <ConfigProvider locale={locale}>
        <ConfigConsumer>
          {({ getPrefixCls }) => {
            return (
              <ThemeProvider theme={{ getAntdPrefixedClassName: getPrefixCls }}>
                <FettThemesProvider>
                  <Slot>
                    <Router {...routerConfig}>
                      <Authorization
                        storage={authorization.storage}
                        cacheKey={authorization.cacheKey}
                        queryKey={authorization.queryKey}
                        onAuthorize={authorization.onAuthorize}
                        onUnauthorize={authorization.onUnauthorize}
                      >
                        <RouterMeta basename={routerConfig.basename} routes={routerConfig.routes}>
                          {({ basename, permissionedRoutes }) => {
                            // 2021-10-23: haofeng.zhao
                            // 临时为当天批次的用户特殊处理, 隐藏下载按钮,在网页端开放除im以外的所有功能.
                            // 后续看需求是否要独立一个部署环境.
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useSpVerDeamon();
                            return <RouterRender basename={basename} routes={permissionedRoutes} />;
                          }}
                        </RouterMeta>
                        <Notification />
                      </Authorization>
                    </Router>
                  </Slot>
                </FettThemesProvider>
              </ThemeProvider>
            );
          }}
        </ConfigConsumer>
      </ConfigProvider>
    </div>
  );
}

/**
 * 应用入口
 */
function App() {
  useEffect(() => {
    chatttSDK?.app?.loadNormal?.();
  }, []);

  return (
    <ErrorBoundary
      fallback={(error) => {
        return (
          <Result
            status="500"
            title="应用加载失败"
            subTitle="请稍后尝试手动重新加载"
            extra={
              <Button type="primary" onClick={() => window.location.reload()}>
                立即重新加载
              </Button>
            }
          />
        );
      }}
    >
      <Suspense fallback={<AppLoading />}>
        <RecoilRoot>
          <Entry />
        </RecoilRoot>
      </Suspense>
    </ErrorBoundary>
  );
}

onAuthorize<Oversea.Token.Payload>((_t, paylod) => {
  if (!isDemoChannel) {
    if (isDemoOrgs(paylod.orgId)) {
      message.error('演示帐号请使用专用包登录');
      unauthorize('@EVENT_TOKEN_FORBIDDEN', '演示帐号请使用专用包登录');
    }
  }
});
export default App;
