import { HTMLAttributes, memo, useCallback, useRef } from 'react';
import styled from '@emotion/styled/macro';
import { message } from 'antd';
import copyText from 'utils/copyText';
import copySvg from './assets/copy.svg';

const Wrapper = styled.span`
  cursor: pointer;
`;

export const Image = styled.img`
  width: 16px;
  height: 16px;
`;

const defaultChildren = <Image src={copySvg} alt="copy" />;

export interface CopyProps extends HTMLAttributes<HTMLSpanElement> {
  text: string | number;
  successMessage?: string;
  failedMessage?: string;
}

function Copy(props: CopyProps) {
  const {
    text,
    children = defaultChildren,
    successMessage,
    failedMessage,
    onClick,
    ...otherProps
  } = props;

  const containerRef = useRef(null);

  return (
    <Wrapper
      ref={containerRef}
      onClick={useCallback(
        (e) => {
          if (onClick) {
            onClick(e);
          }

          copyText(text, containerRef.current || undefined)
            .then(() => {
              successMessage && message.success(successMessage);
            })
            .catch(() => {
              failedMessage && message.error(failedMessage);
            });
        },
        [text, successMessage, failedMessage, onClick],
      )}
      {...otherProps}
    >
      {children}
    </Wrapper>
  );
}

export default memo(Copy);
