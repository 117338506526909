import authorizationCache from 'services/config/authorizationCache';
import { record } from './nimKick';

function logout(isKicked?: boolean) {
  const { storage = sessionStorage, cacheKey = `@${process.env.REACT_APP_NAME}-token` } =
    authorizationCache;

  storage.removeItem(cacheKey);

  const {
    location: { origin, search, pathname },
  } = window;

  let query = '';
  if (isKicked) {
    query = '?isKicked=true';
    record();
  }

  if (origin.startsWith('file:')) {
    window.location.replace(`${origin}${pathname}${query}`);
  } else {
    window.location.replace(`${origin}${search ? '/' : '/?chat=++'}#/login${query}`);
  }
}

export default logout;
