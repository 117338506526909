import { useCallback, useMemo } from 'react';
import { css, cx } from '@emotion/css';
import { Tag } from 'antd';
import { useFocused, useSelected } from 'slate-react';
import { useTranslation } from 'react-i18next';
import { ConceptElement } from '../slateTypes';

const styles = {
  container: css`
    display: inline-block;
    margin: 0 4px;
  `,
  tag: css`
    background-color: #f0f2f5;
    border: 0;
    color: #515359;
    margin: 0;
  `,
  selected: css`
    box-shadow: 0 0 0 3px #b4d5ff;
  `,
};

interface Props {
  element: any;
  attributes: any;
  children: any;
}

const TagConcept = (props: Props) => {
  const { element, attributes, children } = props;
  const { label } = element as ConceptElement;
  const focused = useFocused();
  const selected = useSelected();

  const { getConceptText } = useConceptText();

  const conceptText = getConceptText(label);

  return (
    <div {...attributes} contentEditable={false} className={styles.container}>
      <Tag color="#E6E4F2" className={cx(styles.tag, focused && selected && styles.selected)}>
        {conceptText}
      </Tag>
      {children}
    </div>
  );
};

function useConceptText() {
  const { t } = useTranslation();

  const conceptLabel = useMemo(() => {
    return {
      买家昵称: t('marketing.买家昵称'),
      买家地址: t('marketing.买家地址'),
      订单号: t('refundSetting.订单号'),
      缺货商品名称: t('aide.缺货商品名称'),
      推荐商品说明: t('aide.推荐商品说明'),
      店铺名称: t('marketing.店铺名称'),
    };
  }, [t]);

  const getConceptText = useCallback(
    (concept: string) => {
      return conceptLabel[concept as keyof typeof conceptLabel] || concept;
    },
    [conceptLabel],
  );

  return {
    conceptLabel,
    getConceptText,
  };
}

export default TagConcept;
