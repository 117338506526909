import { Card as CardOrg, Input, Space } from 'antd';
import styled from '@emotion/styled';

export const SectionTitle = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
  margin-bottom: 12px;

  &:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 80%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--ant-primary-color);
  }
`;

export const Footer = styled.div`
  width: 100%;
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 8px 0;
  box-shadow: 0px -2px 8px 0px rgba(167, 178, 204, 0.1),
    inset 0px 1px 0px 0px rgba(240, 242, 245, 1);
`;

export const Card = styled(CardOrg)`
  .ant-card-body {
    padding: 0;
  }

  &:not(:first-child) {
    margin-top: 24px;
  }
`;

export const CardBody = styled.div`
  padding: 24px;
`;

export const ActionGroup = styled(Space)`
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const LimitConditions = styled.div`
  padding: 8px 16px;
  background: #f7f9fa;
`;

export const mb4 = { marginBottom: 4 };

export const SellingPointTextArea = styled(Input.TextArea)`
  .ant-input {
    min-height: 84px;
  }
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;
