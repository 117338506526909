import styled from '@emotion/styled';
import { Button, Checkbox, Form, Input, message, Select, Space, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import AutoTrans from 'components/AutoTrans';
import useSystem from 'hooks/business/system/useSystem';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language, Platform } from 'services/api/chat.gpt';
import { useMemoizedFn } from 'ahooks3';
import CheckboxGroup from './components/CheckboxGroup';
import { GeneratorCard } from './components/GeneratorCard';
import GeneratorEmpty from './components/GeneratorEmpty';
import { GeneratorHeader } from './components/GeneratorHeader';
import MustExcludeKeysField from './components/MustExcludeKeysField';
import { SectionLayout } from './components/SectionLayout';
import {
  FlexCenter,
  LimitConditions,
  mb4,
  SectionTitle,
  SellingPointTextArea,
} from './components/styles';
import { ProductContentGeneratorContextV2 } from './ContextV2';
import useCopyText from './hooks/useCopyText';
import { FormItemInputNumber } from './components/FormItemInputNumber';
import { ClearButton } from './components/ClearButton';

const Bottom = styled.div`
  display: flex;
  justify-content: center;
`;
const TextCenter = styled.div`
  text-align: center;
`;

export interface DetailPageProps {}

const summaryPlatforms = ['LAZADA', 'AMAZON'];

export const DetailPage = () => {
  const {
    platformOptions,
    detailForm,
    detailLoading,
    detailPage,
    detailPageTotal,
    detailPageContent,
    detailTranslateLang,
    detailTranslateLoading,
    languageOptions,
    setDetailPage,
    generateDetail,
    resetDetailPageContent,
    translateDetail,
    setDetailTranslateLang,
    updateDetailPageContent,
  } = useContext(ProductContentGeneratorContextV2);
  const { t } = useTranslation('ViewProductContentGenerator');
  const { systemSetting } = useSystem();
  const [platform, setPlatform] = useState<Platform>('SHOPEE');
  const copyText = useCopyText();

  // 详情字符限制配置
  const detailCharactersLimitConfig = useMemo(() => {
    if (platform === 'TIKTOK') {
      return {
        min: 33,
        max: 999,
      };
    }
    return {
      min: 33,
      max: 2000,
    };
  }, [platform]);

  const againTitleRef = useRef<HTMLDivElement>(null);

  const optimization = useMemo(
    () =>
      detailPageTotal > 0 ? (
        <>
          <SectionTitle ref={againTitleRef} style={{ marginTop: 16 }}>
            {t('generateAgainLabel')}
          </SectionTitle>
          <Form.Item label={t('highlightInfo')} name="highlightInfo">
            <Input maxLength={100} placeholder={t('inputPlaceholder')} allowClear showCount />
          </Form.Item>
          <Form.Item label={t('weakInfo')} name="weakenInfo">
            <Input maxLength={100} placeholder={t('inputPlaceholder')} allowClear showCount />
          </Form.Item>
        </>
      ) : null,
    [t, detailPageTotal],
  );

  const handleReset = useMemoizedFn(() => {
    resetDetailPageContent();
    message.success(t('clearSuccessMsg'));
  });

  useEffect(() => {
    const { current: againTitle } = againTitleRef;

    if (detailPageTotal === 1 && againTitle) {
      againTitle.scrollIntoView({ behavior: 'smooth' });
    }
  }, [detailPageTotal]);

  const left = (
    <div>
      <Form.Item label={t('platform')} name="platform">
        <CheckboxGroup
          options={platformOptions}
          onChange={(value) => setPlatform(value as Platform)}
        />
      </Form.Item>
      <Form.Item label={t('productBrand')} name="brand">
        <Input maxLength={100} placeholder={t('inputPlaceholder')} allowClear showCount />
      </Form.Item>
      <Form.Item label={t('productType')} name="category">
        <Input maxLength={100} placeholder={t('inputPlaceholder')} allowClear showCount />
      </Form.Item>
      <Form.Item label={t('productPoint')} name="sellingPoint">
        <SellingPointTextArea
          maxLength={1000}
          placeholder={t('sellingPointPlaceholder')}
          allowClear
          showCount
        />
      </Form.Item>
      <Form.Item label={t('productListing')} name="listingContent">
        <TextArea maxLength={2000} placeholder={t('listingPlaceholder')} allowClear showCount />
      </Form.Item>
      <Form.Item label={t('inputLang')} name="language">
        <Select options={languageOptions} defaultValue="EN" />
      </Form.Item>
      <MustExcludeKeysField
        description={t('请在此输入禁止出现在商品详情中的词语，如：Free gifts，按enter键添加')}
      />
      <Form.Item label={t('limitCondition')}>
        <LimitConditions>
          <FlexCenter>
            <AutoTrans
              ns="ViewProductContentGenerator"
              i18nKey="limitCharacter"
              components={[
                <FormItemInputNumber
                  formName={['listingRestriction', 'length']}
                  formStyle={mb4}
                  defaultValue={999}
                  style={{
                    width: 70,
                    marginLeft: 4,
                    marginRight: 4,
                  }}
                  min={detailCharactersLimitConfig.min}
                  max={detailCharactersLimitConfig.max}
                />,
              ]}
            />
          </FlexCenter>
          <Form.Item
            style={mb4}
            name={['listingRestriction', 'withoutSpecialCharactersAndLinks']}
            valuePropName="checked"
          >
            <Checkbox>{t('productDetailOption3')}</Checkbox>
          </Form.Item>
          <Form.Item name={['listingRestriction', 'withoutEmoticons']} valuePropName="checked">
            <Checkbox>{t('productDetailOption4')}</Checkbox>
          </Form.Item>
        </LimitConditions>
        {optimization}
      </Form.Item>
      <Bottom>
        <ClearButton onClear={handleReset} />
      </Bottom>
    </div>
  );

  const footer = (
    <Space>
      <Button type="primary" loading={detailLoading} onClick={() => detailForm?.submit()}>
        {detailPageTotal > 0 ? t('generateAgain') : t('generateNow')}
      </Button>
    </Space>
  );

  const currentPageContent = useMemo(() => {
    return detailPage >= 0 ? detailPageContent[detailPage] : null;
  }, [detailPage, detailPageContent]);

  const summaryContent = useMemo(() => {
    const curPlatform = currentPageContent?.platform;
    const hasSummary =
      curPlatform && summaryPlatforms.includes(curPlatform) && !!currentPageContent?.detailSummary;

    if (hasSummary) {
      let title;

      if (curPlatform === 'LAZADA') {
        title = t('shortDescription');
      } else if (curPlatform === 'AMAZON') {
        title = (
          <>
            <TextCenter>{t('sellingPointDescription')}</TextCenter>
            {systemSetting.language !== 'en-US' && <TextCenter>(Bullet Points)</TextCenter>}
          </>
        );
      }

      const translateContent =
        currentPageContent?.detailSummaryTranslateContent?.[detailTranslateLang];

      return (
        <GeneratorCard
          key="productDetailSummary"
          title={title}
          content={currentPageContent?.detailSummary || ''}
          translateContent={translateContent}
          onCopyContent={() => copyText(currentPageContent?.detailSummary)}
          onCopyTranslateContent={() => copyText(translateContent)}
          onContentChange={(content) => {
            if (currentPageContent) {
              updateDetailPageContent(currentPageContent.index, {
                ...currentPageContent,
                detailSummary: content,
              });
            }
          }}
          onTranslateContentChange={(translateContent) => {
            if (currentPageContent) {
              const { index, detailSummaryTranslateContent } = currentPageContent;
              updateDetailPageContent(index, {
                ...currentPageContent,
                detailSummaryTranslateContent: {
                  ...detailSummaryTranslateContent,
                  [detailTranslateLang]: translateContent,
                } as Record<Language, string>,
              });
            }
          }}
        />
      );
    }

    return null;
  }, [
    copyText,
    currentPageContent,
    detailTranslateLang,
    systemSetting.language,
    t,
    updateDetailPageContent,
  ]);

  const right = useMemo(() => {
    if (detailLoading)
      return (
        <Spin tip={t('loadingTips')}>
          <div style={{ height: '300px' }} />
        </Spin>
      );

    // 无数据
    if (detailPageTotal <= 0) {
      return <GeneratorEmpty />;
    }

    const content = currentPageContent?.detailContent || '';
    const translateContent = currentPageContent?.detailTranslateContent?.[detailTranslateLang];
    const main = (
      <>
        <GeneratorCard
          key="productDetail"
          title={t('productDetail')}
          content={content}
          translateContent={translateContent}
          autoScrollToTranslate
          onCopyContent={() => copyText(content)}
          onCopyTranslateContent={() => copyText(translateContent)}
          onContentChange={(content) => {
            if (currentPageContent) {
              updateDetailPageContent(currentPageContent.index, {
                ...currentPageContent,
                detailContent: content,
              });
            }
          }}
          onTranslateContentChange={(translateContent) => {
            if (currentPageContent) {
              const { index, detailTranslateContent } = currentPageContent;
              updateDetailPageContent(index, {
                ...currentPageContent,
                detailTranslateContent: {
                  ...detailTranslateContent,
                  [detailTranslateLang]: translateContent,
                } as Record<Language, string>,
              });
            }
          }}
        />
        {summaryContent}
      </>
    );

    return (
      <>
        <GeneratorHeader
          title={t('detailPageTitle')}
          lang={detailTranslateLang}
          page={detailPage}
          pageTotal={detailPageTotal}
          translateLoading={detailTranslateLoading}
          onChangePage={setDetailPage}
          onChangeLang={setDetailTranslateLang}
          onTranslate={() => {
            if (currentPageContent) {
              // 翻译
              translateDetail?.(currentPageContent, detailPage, detailTranslateLang);
            }
          }}
        />
        <Spin tip={t('loadingTips')} spinning={detailTranslateLoading}>
          {main}
        </Spin>
      </>
    );
  }, [
    detailLoading,
    detailPageTotal,
    currentPageContent,
    detailTranslateLang,
    t,
    summaryContent,
    detailPage,
    detailTranslateLoading,
    setDetailPage,
    setDetailTranslateLang,
    copyText,
    updateDetailPageContent,
    translateDetail,
  ]);

  return (
    <Form
      layout="vertical"
      form={detailForm}
      onFinish={() => {
        generateDetail?.();
      }}
    >
      <SectionLayout left={left} footer={footer} right={right} />
    </Form>
  );
};
