const nimMsgContentParser = <R extends Object = Object>(p: Nim.OnMessageParam): R | null => {
  try {
    const content = JSON.parse(p.content);
    return content;
  } catch (e) {
    console.log(e); // eslint-disable-line
    return null;
  }
};

export default nimMsgContentParser;
