type Return<T> = T extends string ? Lowercase<T> : undefined;

function toLowerCase<T extends string | undefined>(str: T): Return<T> {
  if (str === undefined) {
    return str as undefined as Return<T>;
  }

  return str.toLowerCase() as Return<T>;
}

type ToUpperCaseReturn<T> = T extends string ? Uppercase<T> : undefined;

export function toUpperCase<T extends string | undefined>(str: T): ToUpperCaseReturn<T> {
  if (str === undefined) {
    return str as undefined as ToUpperCaseReturn<T>;
  }

  return str.toUpperCase() as ToUpperCaseReturn<T>;
}

export default toLowerCase;
