import { useTranslation } from 'react-i18next';

const ignoreDetailErrors: RPA.Shopee.DetailErrors[] = [
  'manualCancel',
  'abortFromImageCaptcha',
  'abortFromOTP',
];

const useTranslateErrorMsg = () => {
  const { t } = useTranslation('ViewShops');

  const translateErrMsg = (errMsg: string = '', error?: RPA.Shopee.DetailErrors) => {
    if (error && ignoreDetailErrors.includes(error)) {
      return '';
    }

    if (errMsg.includes('error_name_or_password_incorrect')) {
      return t('shoppe.code.5');
    }
    if (errMsg.includes('error_account_not_exist')) {
      return t('shoppe.code.2');
    }
    if (errMsg.includes('not allowed, use main account login')) {
      return t('shoppe.code.3');
    }
    if (errMsg.includes('request_overdue') || errMsg.includes('net::')) {
      return t('shoppe.code.4');
    }
    if (errMsg.includes('error_notfound')) {
      return t('shoppe.code.2');
    }
    if (errMsg.includes('error_perm')) {
      return t('shoppe.code.5');
    }
    if (errMsg.includes('error_api')) {
      return t('shoppe.code.6');
    }
    if (errMsg.includes('error_banned')) {
      return t('shoppe.code.7');
    }
    if (errMsg.startsWith('Request')) {
      return t('shoppe.code.4');
    }
    if (errMsg.includes('error_require_captcha')) {
      return t('shoppe.code.8');
    }
    if (errMsg.includes('error_send_vcode_fail')) {
      return t('shoppe.code.9');
    }
    if (errMsg.includes('error_need_phone_area_code')) {
      return t('shoppe.code.11');
    }
    if (errMsg.includes('ErrNoMerchantShop')) {
      return t('shoppe.code.12');
    }
    if (error === 'shopeeConfirmAuthFailed' || error === 'redirectShopeeAuthConfirmPageFailed') {
      return t('shoppe.code.4');
    }
    if (error === 'shopeeConfirmAuthWebviewClose') {
      return t('shoppe.code.10');
    }
    return t('shoppe.code.fallback');
  };

  return translateErrMsg;
};

export default useTranslateErrorMsg;
