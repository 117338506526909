import styled from '@emotion/styled';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import emptyImg from '../assets/empty.png';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Text = styled.p`
  margin-bottom: 0;
  color: #84868c;
`;

const GeneratorEmpty = () => {
  const { t } = useTranslation('ViewProductContentGenerator');

  return (
    <Container>
      <Empty
        image={emptyImg}
        imageStyle={{
          height: 190,
        }}
        description={
          <>
            <Text>{t('emptyText1')}</Text>
            <Text>{t('emptyText2')}</Text>
          </>
        }
      />
    </Container>
  );
};

export default GeneratorEmpty;
