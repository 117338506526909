import { merge } from 'lodash-es';
import { promiseWrap } from './promiseHelpers';

type PromiseOrNot<T = any> = T | Promise<T>;
export default async function mergeCompat<TObject = any, TSource = any>(
  obj: PromiseOrNot<TObject>,
  source: PromiseOrNot<TSource>,
): Promise<TSource & TObject> {
  return Promise.all([promiseWrap(obj), promiseWrap(source)]).then((all) => {
    return merge(...all);
  });
}
