/**
 * @description 提供一个定长数组
 * 1.支持队列操作：从头部删除元素，从尾部加入元素
 * 2.当队列满了以后push新元素自动从头部删除元素
 * 3.支持删除指定元素，但是需要自己提供元素查找方法。
 */
class FixedArray<T> {
  private len: number;

  private uniqItem: boolean;

  private list: T[] = [];

  constructor(length: number, uniqItem = false) {
    if (!length) {
      throw Error('must provide fixed array length');
    }
    this.len = length;
    this.uniqItem = uniqItem;
  }

  public get length() {
    return this.list.length;
  }

  /**
   *
   * @param item 需要添加到定长数组中的元素
   * @param autoRemove 当有元素被自动移除时触发
   */
  public push(item: T, onRemoveOldest?: (item: T) => void): T {
    if (this.uniqItem) {
      const index = this.list.findIndex((x) => x === item);
      if (index > -1) {
        this.list.splice(index, 1);
      }
    }

    this.list.push(item);

    if (this.list.length > this.len) {
      const oldest = this.list.shift();
      onRemoveOldest?.(oldest!);
    }

    return item;
  }

  public pop(): T | undefined {
    return this.list.pop();
  }

  public remove(item: T, compare?: (a: T, b: T) => boolean) {
    this.list = this.list.filter((x) => {
      if (typeof compare === 'function') {
        return !compare(x, item);
      }
      return item !== x;
    });
  }

  public get data(): T[] {
    return this.list;
  }
}

export default FixedArray;
