function getCookie() {
  const cookieList = document.cookie.split('; ');

  const cookieMap = cookieList.reduce<Record<string, string>>((res, cookie) => {
    const [key, value] = cookie.split('=');

    res[key] = value;

    return res;
  }, {});

  return cookieMap;
}

export default getCookie;
