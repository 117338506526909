import { getLeYanBotHost } from 'services/api';
import { isNimConnected } from 'services/nim/nimitt';

function createWorker(fnStr: string) {
  const blob = new Blob([`${fnStr}`], {
    type: 'text/javascript',
  });
  const url = URL.createObjectURL(blob);
  return new Worker(url);
}
export default function beginHearbeat(nimInstance: Nim.NimInstance | null) {
  const leyanbotHost = getLeYanBotHost();
  const url = `${leyanbotHost}/oversea-nim/heartbeat`;
  const token = sessionStorage.getItem('@fett-token') || localStorage.getItem('@fett-token');
  const heartbeatWorker = createWorker(`self.onmessage = () => {
    let lastTime = Date.now();
    let checkInterval = 1 * 1000;
    setInterval(() => {
      postMessage({type: 'worker-heartbeat'})
    }, 1000)
    setInterval(function () {
        const currentTime = Date.now();
        const duration = currentTime - lastTime
        if (duration > checkInterval * 2  ) {  // ignore small delays
            console.info('duration timeover <%s>ms', duration)
            postMessage({type: "wakeup", lastTime, currentTime});
        }
        lastTime = currentTime;
    }, checkInterval);
    setInterval(() => {
      fetch('${url}', {
        method: 'POST',
        mode: 'cors',
        headers: {
          authorization:
            'Bearer ${token}',
        },
      });
    }, 30 * 1000);
  }`);

  heartbeatWorker.postMessage('hearBeat!');

  heartbeatWorker.addEventListener('message', (e) => {
    if (e?.data?.type === 'wakeup') {
      const connected = isNimConnected();
      if (!connected && nimInstance) {
        nimInstance.connect();
      }
    } else if (e?.data?.type === 'worker-heartbeat') {
      // console.log('worker-heartbeatp', e)
    }
  });
  return heartbeatWorker;
}
