import { message } from 'antd';
import i18n from 'i18next';
import { isString } from 'lodash-es';
import serverMsg from 'utils/serverMsg';

/**
 * 按照后端定义的data.code 展示对应的错误信息，统一处理免去分散定义
 * @date 2021-09-28
 * @param {any} error: axios请求catch的原始error
 * @param {any} fallbackMessage:string 没有在errors.18n定义中找到对应错误代码时使用的错误信息
 * @returns {any}
 */
const errorToaster = (error: any, fallbackMessage?: string) => {
  if (isString(error?.message) && error.message.toLowerCase() === 'network error') {
    message.error(i18n.t('网络无法连接，请使用健康诊断检查下你的网络'));
  } else {
    const errResp = error.response;
    const code = errResp?.data?.code;
    const sysMessage = errResp?.data?.message;
    const key = `errors:${code}`;
    if (code && i18n.exists(key)) {
      message.error(i18n.t(key));
    } else {
      message.error(sysMessage || fallbackMessage || i18n.t('操作失败，请稍后再试'));
    }
  }
};
export default errorToaster;

/**
 * 按照后端定义的data.code 展示对应的错误信息，统一处理免去分散定义
 * @date 2021-09-28
 * @param {any} error: axios请求catch的原始error
 * @param {any} fallbackMessage:string 没有在errors.18n定义中找到对应错误代码时使用的错误信息
 * @param code — 希望提示服务端返回的 msg 时的 code，默认 400。
 * @returns {any}
 */
export const errorToasterWithServerMsg = async (
  error: any,
  fallbackMessage?: string,
  errorCode = 400,
) => {
  try {
    const parsed = await serverMsg(error, errorCode);
    message.error(parsed);
  } catch {
    errorToaster(error, fallbackMessage);
  }
};
