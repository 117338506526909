import { Platform } from 'services/api/chat.gpt';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as ShopeeIcon } from '../assets/shopee.svg';
import { ReactComponent as LazadaIcon } from '../assets/lazada.svg';
import { ReactComponent as AmazonIcon } from '../assets/amazon.svg';
import { ReactComponent as DefaultIcon } from '../assets/default.svg';
import { ReactComponent as TiktokIcon } from '../assets/tiktok.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    margin-left: 8px;
  }

  & > svg {
    width: 28px;
    height: 28px;
  }
`;

export interface PlatformItemProps {
  platform: Platform;
}

export const PlatformItem = (props: PlatformItemProps) => {
  const { platform } = props;
  const { t } = useTranslation('ViewProductContentGenerator');

  const mapping = useMemo(() => {
    return {
      SHOPEE: {
        label: 'Shopee',
        icon: <ShopeeIcon />,
      },
      LAZADA: {
        label: 'Lazada',
        icon: <LazadaIcon />,
      },
      AMAZON: {
        label: 'Amazon',
        icon: <AmazonIcon />,
      },
      TIKTOK: {
        label: 'Tiktok',
        icon: <TiktokIcon />,
      },
      DEFAULT: {
        label: t('defaultPlatform'),
        icon: <DefaultIcon />,
      },
    };
  }, [t]);

  return (
    <Container>
      {mapping[platform].icon}
      <span>{mapping[platform].label}</span>
    </Container>
  );
};
