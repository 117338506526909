import { CloseOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import {
  AutoComplete as AutoCompleteOrg,
  FormInstance,
  Button,
  Input as InputOrg,
  Row,
  Col,
  AutoCompleteProps,
} from 'antd';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { localPhoneParse, phoneCodeRegionsMap } from 'utils/phoneHelpers';
import PhoneRegionSelectOrg from './PhoneRegionSelect';

export interface PhoneInputProps {
  form?: FormInstance<any>;
  value?: string;
  phoneCode?: General.PhoneRegionCodes;
  autoComplete?: {
    items: string[];
    onRemoveItem?: (item: string, idx: number) => void;
  };
  onChange?: (
    value: string,
    regionCode: General.PhoneRegionCodes,
    region: General.PhoneRegions,
  ) => void;
}
const PhoneRegionSelect = styled(PhoneRegionSelectOrg)`
  max-width: 110px;
  /* ${({ theme }) => `${theme.getAntdPrefixedClassName('select-selector')}`} {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border: none!important;
  } */
`;
const InputGroup = styled(InputOrg.Group)`
  display: flex !important;
  input {
    height: 40px !important;
  }
`;
const Input = styled(InputOrg)`
  /* border-left: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  background: transparent; */
`;
const AutoComplete = styled(AutoCompleteOrg)`
  width: 100% !important;
`;
const Line = styled(Row)`
  padding: 4px 8px;
  border: 1px solid transparent;
  border-radius: 4px;
  &:hover {
    border-color: #91d5ff;
    background-color: #e6f7ff;
  }
`;
const DelBtn = styled(Button)`
  margin: 0 4px;
  background: none !important;
  padding: 0;
  color: #ccc;
`;
const PhoneInput = (props: PhoneInputProps) => {
  const {
    value: valueProp = '',
    autoComplete: { items: autoCompleteItems, onRemoveItem: onRemoveAutoCompleteItem } = {
      items: [],
    },
    onChange,
    phoneCode = phoneCodeRegionsMap.cn,
  } = props;
  const inputRef = useRef<InputOrg>(null);
  const valueRef = useRef({ value: valueProp, phoneCode });
  const { t } = useTranslation('ViewStartups');
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const handleChange = useCallback(() => {
    const {
      current: { value, phoneCode },
    } = valueRef;
    const region = phoneCodeRegionsMap[phoneCode];
    onChange?.(value, phoneCode, region);
  }, [onChange]);
  const inputComp = useMemo(() => {
    let inputComp = (
      <Input
        ref={inputRef}
        onFocus={() => {
          setAutoCompleteOpen(true);
        }}
        onBlur={() => {
          setAutoCompleteOpen(false);
        }}
        onClick={() => {
          if (!autoCompleteOpen) {
            setAutoCompleteOpen(true);
          }
        }}
        placeholder={t('registry.form.item.account.placeholder')}
        // bordered={false}
        value={valueProp}
        onChange={(e) => {
          valueRef.current.value = e.target.value;
          handleChange();
        }}
      />
    );
    if (autoCompleteItems.length >= 1) {
      const options: AutoCompleteProps['options'] = autoCompleteItems.map((phone, idx) => {
        return {
          options: [],
          value: phone,
          label: (
            <Row align="middle" justify="space-between">
              <Col span={22}>
                <Line
                  align="middle"
                  onClick={() => {
                    const target = localPhoneParse(phone);
                    if (!target) {
                      valueRef.current.value = phone;
                    } else {
                      valueRef.current.value = target.phone;
                      valueRef.current.phoneCode = target.code;
                    }
                    setAutoCompleteOpen(false);
                    handleChange();
                  }}
                  key={phone}
                  justify="space-between"
                >
                  {phone}
                </Line>
              </Col>
              <Col span={2}>
                {onRemoveAutoCompleteItem ? (
                  <DelBtn
                    type="text"
                    onClick={async () => {
                      onRemoveAutoCompleteItem(phone, idx);
                      setTimeout(() => {
                        inputRef.current?.focus();
                        // wait for re-render
                      }, 300);
                    }}
                  >
                    <CloseOutlined />
                  </DelBtn>
                ) : null}
              </Col>
            </Row>
          ),
        };
      });
      inputComp = (
        <AutoComplete value={valueProp} open={autoCompleteOpen} options={options}>
          {inputComp}
        </AutoComplete>
      );
    }
    return (
      <InputGroup compact>
        <PhoneRegionSelect
          value={phoneCode}
          onChange={(phoneCode) => {
            valueRef.current.phoneCode = phoneCode;
            handleChange();
          }}
        />
        {inputComp}
      </InputGroup>
    );
  }, [
    autoCompleteItems,
    autoCompleteOpen,
    handleChange,
    onRemoveAutoCompleteItem,
    phoneCode,
    t,
    valueProp,
  ]);
  return inputComp;
};

export default PhoneInput;
