const DEFAULT_FULL = ['catch', 'final'] as const;
const isPromise = (p: any, fullCheck = false) => {
  const basicCheck = typeof p === 'object' && typeof p.then === 'function';
  if (basicCheck && fullCheck) {
    const extraFailed = !DEFAULT_FULL.some((fc) => typeof p[fc] !== 'function');
    if (extraFailed) {
      return false;
    }
  }
  return basicCheck;
};

export const promiseWrap = <T = any>(target: T | Promise<T>): Promise<T> => {
  if (isPromise(target)) {
    return target as Promise<T>;
  }
  return Promise.resolve(target as T);
};

export default isPromise;
