import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'components/RouterQuery';
import { useEffect, useRef } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const LOCAL_STORAGE_KEY = '@fett/__VER';
const isSpVerState = atom({
  key: 'isSpVerState',
  default: localStorage.getItem(LOCAL_STORAGE_KEY) === 'SP',
});

export const useIsSpVerValue = () => useRecoilValue(isSpVerState);
export const useIsSpVerSetter = () => useSetRecoilState(isSpVerState);

export const useSpVerDeamon = () => {
  const query = useQuery();
  const isSpVer = useIsSpVerValue();
  const setIsSpVer = useIsSpVerSetter();
  const isSpVerRef = useRef(isSpVer);
  const nav = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (isSpVer) {
      return;
    }
    if (query?.ver === 'sp') {
      setIsSpVer(true);
      localStorage.setItem(LOCAL_STORAGE_KEY, 'SP');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isSpVerRef.current && !query?.ver) {
      const search = location.search || '?';
      const verStr = '&ver=sp';
      const next = { ...location, search: `${search}${verStr}` };
      // location.search = `${search}${search ? search: search}`
      nav(next, { replace: true, state: { ver: 'sp' } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query?.ver]);
  useEffect(() => {
    if (isSpVerRef.current && location.pathname === '/conversation') {
      nav('404', { replace: true, state: { ver: 'sp' } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
};

const useIsSpVer = useIsSpVerValue;
export default useIsSpVer;
