import { CustomSlateNodeType } from 'components/TemplateEditor/types';
import conceptSerializer from './conceptSerializer';
import dividingLineSerializer from './dividingLineSerializer';
import emoticonSerializer from './emoticonSerializer';
import imageSerializer from './imageSerializer';
import paramTextSerializer from './paramTextSerializer';
import paramHolderSerializer from './paramHolderSerializer';
import textSerializer from './textSerializer';

const serializerMap: Record<string, (node: any) => any> = {
  [CustomSlateNodeType.TEXT]: textSerializer,
  [CustomSlateNodeType.EMOTICON]: emoticonSerializer,
  [CustomSlateNodeType.CONCEPT]: conceptSerializer,
  [CustomSlateNodeType.DIVIDING_LINE]: dividingLineSerializer,
  [CustomSlateNodeType.IMAGE]: imageSerializer,
  [CustomSlateNodeType.PARAMTEXT]: paramTextSerializer,
  [CustomSlateNodeType.PARAMHOLDER]: paramHolderSerializer,
};

export default serializerMap;
