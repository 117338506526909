import { noop } from 'lodash-es';

export interface Message {
  title: string;
  content?: string;

  /**
   * @description 消息唯一标识，当消息被点击以后，通过回调通知调用方时会用到
   */
  id: string;

  /**
   * @description 消息属于哪个会话，当消息被点击以后，通过回调通知调用方时会用到
   */
  conversationId: string;
}

export interface NotifyOptions {
  /**
   * @description 是否声音提醒
   */
  sound: boolean;

  /**
   * @description 是否弹窗提醒
   */
  popup: boolean;

  /**
   * @description popup 停留时间,单位毫秒
   * @default 5000
   *
   */
  duration: number;

  /**
   *
   * @description 当弹窗消息被点击时触发
   */
  onClick: (id?: string, conversationId?: string) => void;
  /**
   *
   * @description 当弹窗消息被关闭时触发
   */
  onClose: (id?: string, conversationId?: string) => void;
}
const defaultOptions: NotifyOptions = {
  sound: false,
  popup: false,
  duration: 5000,
  onClick: noop,
  onClose: noop,
};
type Callback = (message: Message, options: NotifyOptions) => void;
class Notification {
  private _subscribeQueue: Callback[] = [];

  public subscribe(callback: Callback) {
    this._subscribeQueue.push(callback);
  }

  public unsubscribe(callback: Callback) {
    this._subscribeQueue = this._subscribeQueue.filter((item) => item !== callback);
  }

  public notify(message: Message, options: Partial<NotifyOptions> = {}) {
    const _options = {
      ...defaultOptions,
      ...options,
    };
    this._subscribeQueue.map((callback) => callback(message, _options));
  }
}

const notification = new Notification();

export default notification;
