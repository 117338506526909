import { Navigate, useNavigate } from 'react-router-dom';
import { useMemoizedFn } from 'ahooks3';
import { State, To } from 'history';
import { useQuery } from 'components/RouterQuery';
import { stringify, parse } from 'query-string';
import { merge } from 'lodash-es';
import { useEffect, useRef } from 'react';

/**
 * 允许返回的nav自动拼接当前路由中的query到to
 */
export default function useNavigateWithQuery() {
  const nav = useNavigate();
  const defaultQuery = useQuery();
  /**
   * 自动拼接当前路由中的query到to
   * @description 当传入的to带有?search时，会覆盖当前路由中的query, 优先级: to?a=1 > to.search: ?a=2 > defaultQuery
   * @example 例如1： 当前路由为#/abc/?token=123&a=1,  nav('/login?token=xxxx') => #/login/token=xxxx&a=1
   * @example 例如2： 当前路由为#/abc/?token=123&a=1,  nav({pathname: '/login?token=xxxx', search:?token=345}) => #/login/token=345&a=1
   * @param to hisotry To 可能为数值跳转，数值跳转时无法拼接query
   * @param opt
   */
  const navWithQuery = (
    to: number | To,
    opt?: {
      replace?: boolean;
      state?: State;
    },
  ) => {
    if (typeof to === 'number') {
      nav(to);
      return;
    }
    let url = typeof to === 'string' ? to : to.pathname;
    const [urlWithoutSearch, inputSearch] = (url as string)?.split?.('?') || [];
    let inputQuery: Record<string, any> | null = {};
    if (inputSearch) {
      url = urlWithoutSearch;
      inputQuery = parse(inputSearch) || {};
    }
    if (typeof to !== 'string' && to?.search) {
      inputQuery = merge(inputQuery, parse(to?.search));
    }
    const finalQuery = merge({}, defaultQuery, inputQuery);
    const finalTo = merge(
      { search: `?${stringify(finalQuery)}` },
      typeof to === 'string'
        ? {
            pathname: urlWithoutSearch,
          }
        : { ...to, pathname: urlWithoutSearch },
    );
    nav(finalTo);
  };
  return useMemoizedFn(navWithQuery);
}

export function NavigateWithQuery(props: Parameters<typeof Navigate>[0]) {
  const nav = useNavigateWithQuery();

  const refsCurrent = {
    props,
    nav,
  };
  const refs = useRef(refsCurrent);
  refs.current = refsCurrent;

  useEffect(() => {
    const {
      current: {
        props: { to, replace, state },
        nav,
      },
    } = refs;

    nav(to, {
      replace,
      state,
    });
  }, []);

  return null;
}
