const nimKickedCacheKey = '@fett/nimKicked';
const SAVE_VALUE = '1';

export const record = () => {
  sessionStorage.setItem(nimKickedCacheKey, SAVE_VALUE);
};

export const clear = () => {
  sessionStorage.removeItem(nimKickedCacheKey);
};

export const isKicked = () => {
  const value = sessionStorage.getItem(nimKickedCacheKey);
  return value === SAVE_VALUE;
};
