import { simpleStringify, useQuery } from 'components/RouterQuery';
import authorizationCache from 'services/config/authorizationCache';

const useLogout = () => {
  const query = useQuery();

  const logout = (path = '/login') => {
    const { storage = sessionStorage, cacheKey = `@${process.env.REACT_APP_NAME}-token` } =
      authorizationCache;

    storage.removeItem(cacheKey);

    const {
      location: { origin, search },
    } = window;

    let queryStr = '';
    if (query) {
      queryStr = `?${simpleStringify(query)}`;
    }

    window.location.replace(`${origin}${search ? '/' : '/?chat=++'}#${path}${queryStr}`);
  };

  return logout;
};

export default useLogout;
