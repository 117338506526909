import moment from 'moment';
import { useMemo } from 'react';
import chatttSDK, { demoChannelHashes } from 'services/chattt';

export const isDemoChannel = demoChannelHashes.some(
  (target) => (chatttSDK?.app?.channel as any) === target,
);

export const demoOrgs = [
  6532, // nta
  1262, // sth
];

export const isDemoOrgs = (orgId?: Oversea.Token.Payload['orgId']) =>
  orgId && demoOrgs.some((t) => t === orgId);

export const demoDataDateRange = [
  moment('2022-08-15 00:00:00', 'YYYY-MM-DD HH:mm:ss'),
  moment('2022-09-15 00:00:00', 'YYYY-MM-DD HH:mm:ss'),
];
export function buyerNickMask(buyerNick?: undefined): undefined;
export function buyerNickMask(buyerNick: string): string;
export function buyerNickMask(buyerNick?: string) {
  if (!isDemoChannel || !buyerNick) {
    return buyerNick;
  }
  return buyerNick.replace(/(^.{2}).{0,}$/, '$1****');
}
export function useMemoBuyerNickMask(buyerNick?: string | undefined) {
  return useMemo(() => buyerNickMask(buyerNick!), [buyerNick]);
}
