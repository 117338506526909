import { CopyOutlined, InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { useMemoizedFn, usePrevious } from 'ahooks3';
import { Button, Card, Divider, Input, Space, Tooltip } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';

const CCard = styled(Card)`
  .ant-card-head {
    padding: 0 12px;
  }
  .ant-card-head-title,
  .ant-card-extra {
    padding: 10px 0;
  }
  .ant-card-body {
    padding: 0;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
const TranslateBody = styled.div`
  background: #f7f9fa;
`;
const Body = styled.div`
  padding: 8px;
  white-space: pre-wrap;
`;
const TranslateToolbar = styled(Body)`
  display: flex;
  flex-direction: row-reverse;
  padding: 4px 14px;
`;
const InfoIcon = styled(InfoCircleOutlined)`
  color: #84868c;
`;
const ContentText = styled.div`
  padding: 5px 12px;
  cursor: pointer;

  &:hover {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
`;

export interface GeneratorCardProps {
  title: React.ReactNode;
  content: string;
  translateContent?: string;
  showReGenerate?: boolean;
  highlightKeys?: string[];
  autoScrollToTranslate?: boolean;
  onReGenerate?: () => Promise<any>;
  onCopyContent: () => void;
  onCopyTranslateContent: () => void;
  onContentChange: (content: string) => void;
  onTranslateContentChange: (translateContent: string) => void;
}

interface EditableContentPanelProps extends HTMLAttributes<HTMLDivElement> {
  text?: string;
  highlightKeys?: string[];
  onTextChange?: (value: string) => void;
}
const EditableContentPanel = ({
  text,
  highlightKeys = [],
  onTextChange,
  ...otherProps
}: EditableContentPanelProps) => {
  const { t } = useTranslation('ViewProductContentGenerator');
  const [isFocused, setIsFocused] = useState(false);

  const textarea = useRef<TextAreaRef>(null);

  const handleClick = useMemoizedFn(() => {
    setIsFocused(true);
  });

  useEffect(() => {
    if (isFocused) {
      textarea.current?.focus({
        cursor: 'end',
      });
    }
  }, [isFocused]);

  const content = useMemo(() => {
    if (isFocused) {
      return (
        <Input.TextArea
          ref={textarea}
          value={text}
          onChange={(e) => {
            onTextChange?.(e.target.value);
          }}
          autoSize
          autoFocus
          onBlur={() => setIsFocused(false)}
        />
      );
    }

    if (text) {
      return (
        <ContentText title={t('clickToModify')} onClick={handleClick}>
          <Highlighter
            searchWords={highlightKeys}
            textToHighlight={text}
            highlightClassName="content-highlight-words"
            autoEscape
          />
        </ContentText>
      );
    }
    return '';
  }, [handleClick, highlightKeys, isFocused, onTextChange, t, text]);

  return <Body {...otherProps}>{content}</Body>;
};

export const GeneratorCard = (props: GeneratorCardProps) => {
  const {
    title,
    content,
    translateContent,
    showReGenerate,
    highlightKeys = [],
    autoScrollToTranslate = false,
    onReGenerate,
    onCopyContent,
    onCopyTranslateContent,
    onContentChange,
    onTranslateContentChange,
  } = props;

  const [reGenerateLoading, setReGenerateLoading] = useState<boolean>(false);
  const { t } = useTranslation('ViewProductContentGenerator');

  const handleReGenerate = useMemoizedFn(async () => {
    try {
      setReGenerateLoading(true);
      await onReGenerate?.();
    } finally {
      setReGenerateLoading(false);
    }
  });

  const extra = (
    <Space split={<Divider type="vertical" />}>
      {showReGenerate && (
        <div>
          <Button
            type="text"
            size="small"
            icon={<ReloadOutlined />}
            loading={reGenerateLoading}
            onClick={handleReGenerate}
          >
            {t('reGenerateBtn')}
          </Button>
          <Tooltip title={t('reGenerateTips')}>
            <InfoIcon />
          </Tooltip>
        </div>
      )}
      <Button type="text" size="small" icon={<CopyOutlined />} onClick={onCopyContent}>
        {t('copyBtn')}
      </Button>
    </Space>
  );

  const translatedRef = useRef<HTMLDivElement>(null);

  const preTranslateContent = usePrevious(translateContent);

  useEffect(() => {
    const { current: translatedEle } = translatedRef;

    if (autoScrollToTranslate && translateContent && !preTranslateContent && translatedEle) {
      translatedEle.scrollIntoView({ behavior: 'smooth' });
    }
  }, [autoScrollToTranslate, translateContent, preTranslateContent]);

  return (
    <CCard title={title} extra={extra}>
      <EditableContentPanel
        text={content}
        highlightKeys={highlightKeys}
        onTextChange={onContentChange}
      />
      {translateContent ? (
        <TranslateBody ref={translatedRef}>
          <TranslateToolbar>
            <Button
              type="text"
              size="small"
              icon={<CopyOutlined />}
              onClick={onCopyTranslateContent}
            >
              {t('copyBtn')}
            </Button>
          </TranslateToolbar>
          <EditableContentPanel
            text={translateContent}
            highlightKeys={highlightKeys}
            onTextChange={onTranslateContentChange}
            style={{ paddingTop: 0 }}
          />
        </TranslateBody>
      ) : null}
    </CCard>
  );
};
