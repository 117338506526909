import isChannel from './isChannel';

/* eslint-disable import/prefer-default-export */
export const zhixiaRegLink = 'https://shopee.mobduos.com/#/login?type=1&urlCode=1647849044388';

export const zhixiaChannelHash = '82ae902caedcb44ee581cf367acf90a3';

export const yicangChannelHash = '9caea11390faee419c9a32ae3d5bf830';

export const mabangDemoChannelHash = '62bac3fd825ecb2659431008e93afa12';

export const mabangChannelHash = 'db6eb928a6751fcdd74d9ccd6c119788';

export const leyanSellerChannelHash = [
  'd9d57355663538fb1ffea46a9f898efd',
  '94886e5dc4a40c8ab7612b8f0c018122',
  'e7227d84b749dd66ad236b1fda030144',
  'a0526df56418730d0b8e6965d9170015',
  'bc44b77f9507bb7f40eb72abc367f100',
  'd50bfc694f953da0e0a62db3a67cfa4c',
  '91089f235074536ad350c7aba349f251',
  'ebbd0979dbbc04678df7697e485999f4',
];

export const upfosChannelHash = 'b98cb7f88ba8bafa4f492e6fe35f9141';

export const qianyiChannelHash = '018efbff9d9331443f54830359ae397b';

/**
 * 演示专用渠道包
 */
export const demoChannelHashes = [
  mabangDemoChannelHash,
  '5895326aaa097effd3fde96ae3c63cd5', // leyan 演示渠道
];

export const isZhixiaChannel = isChannel(zhixiaChannelHash);
export const isYicangChannel = isChannel(yicangChannelHash);
export const isMabangChannel = isChannel([mabangChannelHash, mabangDemoChannelHash]);
