import React from 'react';
import brazilSvg from './assets/region/brazil.svg';
import indonesiaSvg from './assets/region/indonesia.svg';
import malaysiaSvg from './assets/region/malaysia.svg';
import philippinesSvg from './assets/region/philippines.svg';
import singaporeSvg from './assets/region/singapore.svg';
import taiwanSvg from './assets/region/taiwan.svg';
import thailandSvg from './assets/region/thailand.svg';
import vietnamSvg from './assets/region/vietnam.svg';
import clSvg from './assets/region/cl.svg';
import coSvg from './assets/region/co.svg';
import esSvg from './assets/region/es.svg';
import mxSvg from './assets/region/mx.svg';
import plSvg from './assets/region/pl.svg';

import brazilMonetaryUnitSvg from './assets/region/monetaryUnit/brazil.svg';
import indonesiaMonetaryUnitSvg from './assets/region/monetaryUnit/indonesia.svg';
import malaysiaMonetaryUnitSvg from './assets/region/monetaryUnit/malaysia.svg';
import philippinesMonetaryUnitSvg from './assets/region/monetaryUnit/philippines.svg';
import singaporeMonetaryUnitSvg from './assets/region/monetaryUnit/singapore.svg';
import taiwanMonetaryUnitSvg from './assets/region/monetaryUnit/taiwan.svg';
import thailandMonetaryUnitSvg from './assets/region/monetaryUnit/thailand.svg';
import vietnamMonetaryUnitSvg from './assets/region/monetaryUnit/vietnam.svg';

import { ReactComponent as brFlag } from './assets/region/flag/br.svg';
import { ReactComponent as clFlag } from './assets/region/flag/cl.svg';
import { ReactComponent as coFlag } from './assets/region/flag/co.svg';
import { ReactComponent as esFlag } from './assets/region/flag/es.svg';
import { ReactComponent as frFlag } from './assets/region/flag/fr.svg';
import { ReactComponent as idFlag } from './assets/region/flag/id.svg';
import { ReactComponent as mxFlag } from './assets/region/flag/mx.svg';
import { ReactComponent as myFlag } from './assets/region/flag/my.svg';
import { ReactComponent as phFlag } from './assets/region/flag/ph.svg';
import { ReactComponent as plFlag } from './assets/region/flag/pl.svg';
import { ReactComponent as sgFlag } from './assets/region/flag/sg.svg';
import { ReactComponent as thFlag } from './assets/region/flag/th.svg';
import { ReactComponent as vnFlag } from './assets/region/flag/vn.svg';
import { ReactComponent as twFlag } from './assets/region/flag/tw.svg';

type Region = General.Region;
type UpperCaseRegion = General.UpperCaseRegion;
type Language = General.Language;

export const regionList: Region[] = [
  'id',
  'my',
  'th',
  'vn',
  'sg',
  'ph',
  'tw',
  'br',
  'cl',
  'co',
  'mx',
  'pl',
  'es',
  'fr',
  'gb',
];

export const isValidRegion = (
  region: string,
  list: General.Region[] = regionList,
): region is General.Region => list.includes(region as General.Region);

export const regionLabelMap: Record<Region, string> = {
  id: '印度尼西亚',
  my: '马来西亚',
  th: '泰国',
  vn: '越南',
  sg: '新加坡',
  ph: '菲律宾',
  tw: '台湾地区',
  br: '巴西',
  cl: '智利',
  co: '哥伦比亚',
  mx: '墨西哥',
  pl: '波兰',
  es: '西班牙',
  fr: '法国',
  gb: '全球',
};

export const regionShortLabelMap: Record<Region, string> = {
  id: '印尼',
  my: '马来',
  th: '泰国',
  vn: '越南',
  sg: '新加坡',
  ph: '菲律宾',
  tw: '台湾',
  br: '巴西',
  cl: '智利',
  co: '哥伦比亚',
  mx: '墨西哥',
  pl: '波兰',
  es: '西班牙',
  fr: '法国',
  gb: '全球',
};

export const regionEnLabelMap: Record<Region, string> = {
  id: 'Indonesia',
  my: 'Malaysia',
  th: 'Thailand',
  vn: 'Vietnam',
  sg: 'Singapore',
  ph: 'Philippines',
  tw: 'Taiwan China',
  br: 'Brazil',
  cl: 'Chile',
  co: 'Columbia',
  mx: 'Mexico',
  pl: 'Poland',
  es: 'Spain',
  fr: 'France',
  gb: '全球',
};

export const regionLanguageMap: Record<Region, Language> = {
  id: 'id',
  my: 'en-US',
  th: 'th',
  vn: 'vi',
  sg: 'en-US',
  ph: 'en-US',
  tw: 'zh-TW',
  br: 'pt',
  cl: 'es',
  co: 'es',
  mx: 'es',
  pl: 'pl',
  es: 'es',
  fr: 'fr',
  gb: 'en-US',
};

export const languageLabelMap: Record<Language, string> = {
  'zh-CN': '中文简体',
  'zh-TW': '中文繁体',
  id: '印尼语',
  'en-US': '英语',
  th: '泰语',
  vi: '越南语',
  pt: '葡萄牙语',
  tl: '菲律宾语',
  ms: '马来语',
  es: '西班牙语',
  pl: '波兰语',
  fr: '法语',
};

export const regionIconMap: Record<Region, string> = {
  br: brazilSvg,
  id: indonesiaSvg,
  my: malaysiaSvg,
  ph: philippinesSvg,
  sg: singaporeSvg,
  tw: taiwanSvg,
  th: thailandSvg,
  vn: vietnamSvg,
  cl: clSvg,
  co: coSvg,
  mx: mxSvg,
  pl: plSvg,
  es: esSvg,
  fr: '',
  gb: '',
};

export const regionMonetaryUnitIconMap: Record<Region, string> = {
  br: brazilMonetaryUnitSvg,
  id: indonesiaMonetaryUnitSvg,
  my: malaysiaMonetaryUnitSvg,
  ph: philippinesMonetaryUnitSvg,
  sg: singaporeMonetaryUnitSvg,
  tw: taiwanMonetaryUnitSvg,
  th: thailandMonetaryUnitSvg,
  vn: vietnamMonetaryUnitSvg,
  cl: '',
  co: '',
  mx: '',
  pl: '',
  es: '',
  fr: '',
  gb: '',
};

export const regionTimeZoneMap: Record<Region, string> = {
  id: 'Asia/Jakarta',
  my: 'Asia/Kuching',
  th: 'Asia/Bangkok',
  vn: 'Asia/Ho_Chi_Minh',
  sg: 'Asia/Singapore',
  ph: 'Asia/Manila',
  tw: 'Asia/Taipei',
  br: 'America/Sao_Paulo',
  cl: 'America/Santiago',
  co: 'America/Bogota',
  mx: 'America/Mexico_City',
  pl: 'Europe/Warsaw',
  es: 'Europe/Madrid',
  fr: 'Europe/Paris',
  gb: 'Asia/Shanghai',
};

export const regionMonetaryUnitMap: Record<Region, string> = {
  id: 'Rp',
  my: 'RM',
  th: '฿',
  vn: '₫',
  sg: 'S$',
  ph: '₱',
  tw: 'NT$',
  br: 'R$',
  cl: 'CLP',
  co: 'COP',
  mx: 'MX$',
  pl: 'zł',
  es: '€',
  fr: '€',
  gb: '$',
};

export const regionUpperCaseRegionMap: Record<Region, UpperCaseRegion> = {
  id: 'ID',
  my: 'MY',
  th: 'TH',
  vn: 'VN',
  sg: 'SG',
  ph: 'PH',
  tw: 'TW',
  br: 'BR',
  cl: 'CL',
  co: 'CO',
  mx: 'MX',
  pl: 'PL',
  es: 'ES',
  fr: 'FR',
  gb: 'GB',
};

export const upperCaseRegionRegionMap = regionList.reduce<Partial<Record<UpperCaseRegion, Region>>>(
  (res, region) => {
    const { [region]: upperCaseRegion } = regionUpperCaseRegionMap;

    res[upperCaseRegion] = region;

    return res;
  },
  {},
) as Record<UpperCaseRegion, Region>;

export const regionFlagComponentMap: Record<
  Region,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >
> = {
  id: idFlag,
  my: myFlag,
  th: thFlag,
  vn: vnFlag,
  sg: sgFlag,
  ph: phFlag,
  tw: twFlag,
  br: brFlag,
  cl: clFlag,
  co: coFlag,
  mx: mxFlag,
  pl: plFlag,
  es: esFlag,
  fr: frFlag,
  gb: () => null,
};

export const regionBuyerDetailHostMap: Record<General.Region, string> = {
  id: 'https://shopee.co.id',
  tw: 'https://shopee.tw',
  vn: 'https://shopee.vn',
  my: 'https://shopee.com.my',
  sg: 'https://shopee.sg',
  th: 'https://shopee.co.th',
  ph: 'https://shopee.ph',
  br: 'https://shopee.com.br',
  mx: 'https://shopee.com.mx',
  co: 'https://shopee.com.co',
  cl: 'https://shopee.cl',
  pl: 'https://shopee.pl',
  es: 'https://es.xiapibuy.com',
  fr: 'https://fr.xiapibuy.com',
  gb: '',
};

export const shopeeLocalSellerHostMap: Partial<Record<General.Region, string>> = {
  id: 'https://seller.shopee.co.id',
  vn: 'https://banhang.shopee.vn',
  my: 'https://seller.shopee.com.my',
  sg: 'https://seller.shopee.sg',
  th: 'https://seller.shopee.co.th',
  ph: 'https://seller.shopee.ph',
  br: 'https://seller.shopee.com.br',
  mx: 'https://seller.shopee.com.mx',
  co: 'https://seller.shopee.com.co',
  cl: 'https://seller.shopee.cl',
  pl: 'https://seller.shopee.pl',
};

export const lazadaSellerCenterHostMap: Partial<Record<General.UpperCaseRegion, string>> = {
  ID: 'https://sellercenter.lazada.co.id',
  MY: 'https://sellercenter.lazada.com.my',
  PH: 'https://sellercenter.lazada.com.ph',
  SG: 'https://sellercenter.lazada.sg',
  TH: 'https://sellercenter.lazada.co.th',
  VN: 'https://sellercenter.lazada.vn',
};

export const lazadaCrossSellerCenterHostMap: Partial<Record<General.UpperCaseRegion, string>> = {
  ID: 'https://sellercenter-id.lazada-seller.cn',
  MY: 'https://sellercenter-my.lazada-seller.cn',
  PH: 'https://sellercenter-ph.lazada-seller.cn',
  SG: 'https://sellercenter-sg.lazada-seller.cn',
  TH: 'https://sellercenter-th.lazada-seller.cn',
  VN: 'https://sellercenter-vn.lazada-seller.cn',
};

export type RateRegion = Exclude<General.UpperCaseRegion, 'TW'> | 'CN' | 'US';

export interface RegionFixedExchangeRateValue {
  shorthand: Exclude<Dashboard.CurrencyShorthand, 'RMB'>;
  rate: string;
  symbol: string;
  rateRule: string;
  region: RateRegion;
}
export const regionFixedExchangeRate: Partial<Record<RateRegion, RegionFixedExchangeRateValue>> = {
  CN: {
    symbol: '￥',
    shorthand: 'CNY',
    rateRule: '',
    rate: '1',
    region: 'CN',
  },
  ID: {
    symbol: 'Rp',
    shorthand: 'IDR',
    rateRule: 'CNY/IDR',
    rate: '2189.6',
    region: 'ID',
  },
  PH: {
    symbol: '₱',
    shorthand: 'PHP',
    rateRule: 'CNY/PHP',
    rate: '7.8700',
    region: 'PH',
  },
  TH: {
    symbol: '฿',
    shorthand: 'THB',
    rateRule: 'CNY/THB',
    rate: '5.1200',
    region: 'TH',
  },
  VN: {
    symbol: '₫',
    shorthand: 'VND',
    rateRule: 'CNY/VND',
    rate: '3480.1',
    region: 'VN',
  },
  SG: {
    symbol: 'S$',
    shorthand: 'SGD',
    rateRule: 'CNY/SGD',
    rate: '0.2091',
    region: 'SG',
  },
  MY: {
    symbol: 'RM',
    shorthand: 'MYR',
    rateRule: 'CNY/MYR',
    rate: '0.65546',
    region: 'MY',
  },
  MX: {
    symbol: 'Mex$',
    shorthand: 'MXN',
    rateRule: 'CNY/MXN',
    rate: '2.9351',
    region: 'MX',
  },
  CL: {
    symbol: 'CLP$',
    shorthand: 'CLP',
    rateRule: 'CNY/CLP',
    rate: '124.69',
    region: 'CL',
  },
  CO: {
    symbol: 'Col$',
    shorthand: 'COP',
    rateRule: 'CNY/COP',
    rate: '570.51',
    region: 'CO',
  },
  BR: {
    symbol: 'R$',
    shorthand: 'BRL',
    rateRule: 'CNY/BRL',
    rate: '0.71372',
    region: 'BR',
  },
  ES: {
    symbol: '€',
    shorthand: 'EUR',
    rateRule: 'CNY/EUR',
    rate: '0.1394',
    region: 'ES',
  },
  FR: {
    symbol: '€',
    shorthand: 'EUR',
    rateRule: 'CNY/EUR',
    rate: '0.1394',
    region: 'FR',
  },
  PL: {
    symbol: 'zł',
    shorthand: 'PLN',
    rateRule: 'CNY/PLN',
    rate: '0.63900',
    region: 'PL',
  },
  // TW: {
  //   symbol: 'NT$',
  //   shorthand: 'TWD',
  //   rateRule: 'CNY/TWD',
  //   rate: '4.3672',
  //   region: 'TW',
  // },
  US: {
    symbol: '$',
    shorthand: 'USD',
    rateRule: 'CNY/USD',
    rate: '0.1501',
    region: 'US',
  },
};

export const language2currencyMap: Record<
  General.SystemLanguage,
  RegionFixedExchangeRateValue | undefined
> = {
  'zh-CN': regionFixedExchangeRate.CN,
  'en-US': regionFixedExchangeRate.US,
  id: regionFixedExchangeRate.ID,
  th: regionFixedExchangeRate.TH,
  tl: regionFixedExchangeRate.PH,
  vi: regionFixedExchangeRate.VN,
  pt: regionFixedExchangeRate.BR,
  es: regionFixedExchangeRate.US,
};

export const translateLang2WhatsappLangMapping: Record<
  General.Language,
  WhatsappBusinessApi.Language
> = {
  'zh-CN': 'zh-CN',
  'en-US': 'en',
  id: 'id',
  pt: 'pt-BR',
  th: 'th',
  vi: 'vi',
  'zh-TW': 'zh-TW',
  tl: 'fil',
  es: 'es-ES',
  ms: 'ms',
  pl: 'pl',
  fr: 'fr',
};
