import Icon, { CloseOutlined, CopyOutlined, SwapRightOutlined } from '@ant-design/icons';
import styled from '@emotion/styled/macro';
import { Alert, Button, Select, Spin } from 'antd';
import { simpleStringify, useQuery } from 'components/RouterQuery';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Language, translate } from 'services/api/chat.gpt';
import Copy from 'views/Conversation/Sider/Copy';
import useApiError from 'views/ProductContentGenerator/hooks/useApiError';
import { useIsFreeLogin } from 'views/ProductContentGenerator/hooks/useFreeLogin';
import useLanguages from 'views/ProductContentGenerator/hooks/useLanguages';
import { useSystemLanguageValue } from 'hooks/business/system/useSystem';
import Example from './Example';
import { ReactComponent as StarMarkSvg } from './assets/starMark.svg';
import { ReactComponent as Tip1Svg } from './assets/tip1.svg';
import { ReactComponent as Tip2Svg } from './assets/tip2.svg';
import { ReactComponent as Tip3Svg } from './assets/tip3.svg';
import exampleCNImage from './assets/exampleCN.gif';
import exampleENImage from './assets/exampleEN.gif';
import backgroundImage from './assets/background.png';

const Container = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
  background: #fbfbfb;
`;

const StyledAlert = styled(Alert)`
  position: sticky;
  top: 0;
  z-index: 1;
  background: #faedd2;
  .anticon {
    font-size: 18px;
  }
`;

const Content = styled.div`
  padding: 24px;
  > * + * {
    margin-top: 22px;
  }
`;

const TranslationWrapper = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 320px;
  background: #fff;
  border: solid 1px #f0f2f5;
  border-radius: 2px;
  > header {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    height: 56px;
    padding: 0 16px;
    border-bottom: solid 1px #f0f2f5;
    > * + * {
      margin-left: 16px;
    }
    > .ant-select {
      width: 180px;
      .ant-select-selector {
        text-align: center;
        border-radius: 8px;
      }
    }
  }
  > div {
    flex: 1 0 0;
    display: flex;
    > * {
      flex: 1 0 0;
    }
  }
  > span {
    position: absolute;
    bottom: -22px;
    left: 0;
    color: var(--ant-error-color);
  }
`;

const Origin = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  > div {
    flex: 1 0 0;
    position: relative;
    > button {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 18px;
    }
    > textarea {
      width: 100%;
      height: 100%;
      padding-right: 24px;
      border: none;
      resize: none;
      outline: none;
    }
  }
  > span {
    flex: 0 0 auto;
    text-align: right;
    color: #b5b9bf;
  }
`;

const Translated = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  background: #f3f5f8;
  > p {
    flex: 1 0 0;
    margin-bottom: 0;
    overflow: auto;
    white-space: pre-wrap;
  }
  > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button:hover {
      color: var(--ant-primary-color);
    }
  }
`;

const Extra = styled.div`
  > header {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 26px;
    padding: 10px 12px;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    background: linear-gradient(131deg, #aca2ff 0%, #5482ff 100%);
  }
  > div {
    display: flex;
    align-items: stretch;
    > span {
      flex: 0 0 auto;
      width: 750px;
      padding: 16px;
      background: #edf2ff;
      > img {
        width: 100%;
        height: auto;
      }
    }
    > section {
      flex: 1 0 0;
      background: right bottom / 182px 213px no-repeat url(${backgroundImage}),
        linear-gradient(183deg, #f4f9ff 0%, #bfd0ff 100%);
      > ul {
        margin: 35px;
        padding: 0;
        list-style: none;
        > li {
          display: flex;
          align-items: center;
          margin-bottom: 22px;
          font-size: 26px;
          font-weight: bold;
          > .anticon {
            margin-right: 11px;
            font-size: 30px;
          }
        }
      }
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        > * + * {
          margin-left: 16px;
        }
      }
    }
  }
`;

const cacheKey = `${process.env.REACT_APP_NAME}/translateTraget`;

const useTarget = () => {
  const nav = useNavigate();

  const languageOptions = useLanguages();

  const query = useQuery<{ target: Language }>();

  const { target: queryTarget } = query;

  const target = useMemo(() => {
    const cacheTarget = localStorage.getItem(cacheKey);

    const defaultTarget = languageOptions.some((_) => _.value === cacheTarget)
      ? (cacheTarget as Language)
      : languageOptions[0].value;

    const target = languageOptions.some((_) => _.value === queryTarget)
      ? queryTarget
      : defaultTarget;

    return target;
  }, [queryTarget, languageOptions]);

  useEffect(() => {
    localStorage.setItem(cacheKey, target);
  }, [target]);

  const setTarget = (target: Language) => {
    nav(
      `?${simpleStringify({
        ...query,
        target,
      })}`,
    );
  };

  return {
    target,
    setTarget,
    languageOptions,
  };
};

function Translate() {
  const { t } = useTranslation('ViewProductContentGenerator');

  const systemLangauge = useSystemLanguageValue();

  const apiError = useApiError();

  const { channel } = useQuery();

  const isFreeLogin = useIsFreeLogin();

  const { target, setTarget, languageOptions } = useTarget();

  const targetSelect = <Select value={target} onChange={setTarget} options={languageOptions} />;

  const source = (
    <Select
      open={false}
      showArrow={false}
      value="auto"
      options={[
        {
          value: 'auto',
          label: t('自动检测'),
        },
      ]}
    />
  );

  const [originText, setOriginText] = useState('');

  const textLimit = 1500;

  const overLimit = originText.length > textLimit;

  const [translatedText, setTranslatedText] = useState('');

  const [translating, setTranslating] = useState(false);

  const translateButton = (
    <Button
      type="primary"
      loading={translating}
      disabled={!originText || originText.trim().length === 0 || overLimit}
      onClick={() => {
        setTranslating(true);

        return translate({
          language: target,
          sourceContent: originText,
        })
          .then(({ result }) => {
            setTranslatedText(result);
          })
          .catch((err) => {
            apiError(err, t('timeoutTips'));
          })
          .finally(() => {
            setTranslating(false);
          });
      }}
    >
      {t('translateBtn')}
    </Button>
  );

  const translateHeader = (
    <header>
      {source}
      <SwapRightOutlined />
      {targetSelect}
      {translateButton}
    </header>
  );

  const origin = (
    <Origin>
      <div>
        <textarea
          placeholder={t('inputPlaceholder')}
          value={originText}
          onChange={({ target: { value } }) => setOriginText(value)}
        />
        {originText.length > 0 && (
          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={() => {
              setOriginText('');
              setTranslatedText('');
            }}
          />
        )}
      </div>
      <span>
        <span style={{ color: overLimit ? 'var(--ant-error-color)' : '#B5B9BF' }}>
          {originText.length}
        </span>
        /{textLimit}
      </span>
    </Origin>
  );

  const translated = (
    <Translated>
      <p>{translatedText}</p>
      <div>
        {translatedText.length > 0 && (
          <Copy
            text={translatedText}
            successMessage={t('copySuccess')}
            failedMessage={t('copyFail')}
          >
            <Button type="text" icon={<CopyOutlined />}>
              {t('copyBtn')}
            </Button>
          </Copy>
        )}
      </div>
    </Translated>
  );

  const translation = (
    <Spin spinning={translating} delay={200} tip={t('翻译中，请稍后')}>
      <TranslationWrapper>
        {translateHeader}
        <div>
          {origin}
          {translated}
        </div>
        {overLimit && <span style={{ color: 'var(--ant-error-color)' }}>{t('字符超出限制')}</span>}
      </TranslationWrapper>
    </Spin>
  );

  const enStyle =
    systemLangauge === 'en-US'
      ? {
          fontSize: '16px',
        }
      : undefined;

  const extra = (
    <Extra>
      <header style={enStyle}>{t('translateTips')}</header>
      <div>
        <span>
          <img
            src={
              (
                {
                  'zh-CN': exampleCNImage,
                  'en-US': exampleENImage,
                } as any
              )[systemLangauge] || exampleCNImage
            }
            alt="example"
          />
        </span>
        <section>
          <ul>
            <li style={enStyle}>
              <Icon component={Tip1Svg} />
              {t('translateTips1')}
            </li>
            <li style={enStyle}>
              <Icon component={Tip2Svg} />
              {t('translateTips2')}
            </li>
            <li style={enStyle}>
              <Icon component={Tip3Svg} />
              {t('translateTips3')}
            </li>
          </ul>
          <div>
            {isFreeLogin && (
              <Button
                type="primary"
                target="__blank"
                href={`${process.env.REACT_APP_FETT_HOST}/#/registry?channel=${channel}`}
              >
                {t('免费注册')}
              </Button>
            )}
            <Button type="primary" target="__blank" href={`https://chatplus.ai?channel=${channel}`}>
              {t('下载客户端')}
            </Button>
          </div>
        </section>
      </div>
    </Extra>
  );

  return (
    <Container>
      <StyledAlert
        banner
        type="warning"
        icon={<Icon component={StarMarkSvg} />}
        message={t('translateAlert')}
      />
      <Content>
        <Example />
        {translation}
        {extra}
      </Content>
    </Container>
  );
}

export default Translate;
