import {
  GenerateProductDetailBody,
  GenerateProductTitleBody,
  Language,
  Platform,
} from 'services/api/chat.gpt';

export const officialChannel = 'aigc_official';

export const contactUsQrCode = `https://clients.zhisuitech.com/fett-gpt-public/contactUsQrCode.png?t=${Date.now()}`;
export const contactUsQrCodeDny123 = `https://clients.zhisuitech.com/fett-gpt-public/contactUsQrCode_dny123.png?t=${Date.now()}`;
export const contactUsQrCodeDny123Translate = `https://clients.zhisuitech.com/fett-gpt-public/aigc_dny123_translate_20230417.png?t=${Date.now()}`;
export const contactUsQrCodeKupo = `https://clients.zhisuitech.com/fett-gpt-public/contactUsQrCode_kupo.png?t=${Date.now()}`;

export const freeChannelsConfig = `https://clients.zhisuitech.com/fett-gpt-public/freeChannels.json?t=${Date.now()}`;
export const demoVideoURL =
  'https://clients.zhisuitech.com/fett-gpt-public/aigc_leyan_20230330.mp4';
export const demoVideoURLTranslate =
  'https://clients.zhisuitech.com/fett-gpt-public/aigc_dny123_translation_20230419.mp4';
export const demoVideoURLDny123 =
  'https://clients.zhisuitech.com/fett-gpt-public/aigc_leyan_20230324.mp4';
export const demoVideoThumbURL =
  'https://clients.zhisuitech.com/fett-gpt-public/aigc_leyan_thumb_20230324.jpg';

export const languageOptions: Array<{ value: Language; label: string }> = [
  {
    value: 'ZH_CN',
    label: '中文',
  },
  {
    value: 'EN',
    label: 'English',
  },
  {
    value: 'ID',
    label: 'Indonesian',
  },
  {
    value: 'PH',
    label: 'Filipino',
  },
  {
    value: 'TH',
    label: 'ภาษาไทย',
  },
  {
    value: 'VN',
    label: 'Tiếng Việt',
  },
  {
    value: 'PT',
    label: 'Português',
  },
  {
    value: 'ES',
    label: 'Español',
  },
  {
    value: 'DE',
    label: 'Deutsch',
  },
  {
    value: 'RU',
    label: 'Русский',
  },
  {
    value: 'PL',
    label: 'Polski',
  },
  {
    value: 'KR',
    label: '한국어',
  },
  {
    value: 'MY',
    label: 'Melayu',
  },
];

export type IForm = GenerateProductTitleBody & GenerateProductDetailBody;

export const formInitialValues: IForm = {
  platform: 'SHOPEE',
  language: 'EN',
  category: '',
  brand: '',
  sellingPoint: '',
  listingContent: '',
  highlightInfo: '',
  weakenInfo: '',
  generateResultNumber: 3,
  titleRestriction: {
    length: 100,
    limitLength: true,
    withoutSpecialCharactersAndLinks: true,
    notAllCapitalLetters: true,
    capitalizedFirstLetter: true,
    withoutPunctuation: true,
  },
  listingRestriction: {
    length: 999,
    withoutSpecialCharactersAndLinks: true,
    withoutEmoticons: false,
  },
};

export const platformRegionMap: Record<Exclude<Platform, 'DEFAULT'>, General.UpperCaseRegion[]> = {
  SHOPEE: ['SG', 'MY', 'TH', 'VN', 'PH', 'ID', 'BR'],
  LAZADA: ['SG', 'MY', 'TH', 'VN', 'PH', 'ID'],
  TIKTOK: ['SG', 'MY', 'TH', 'VN', 'PH', 'ID'],
  AMAZON: ['FR', 'ES', 'MX'],
};
