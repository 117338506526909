import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

// 临时存储登录手机号，用于找回密码进行回填
export const saveTempPhone = atom<{ countryCode: General.PhoneRegionCodes; mobile: string }>({
  key: 'saveTempPhone',
  default: {
    countryCode: '+86',
    mobile: '',
  },
});

export const useSaveTempPhone = () => {
  return useRecoilValue(saveTempPhone);
};

export const useSetSaveTempPhone = () => {
  return useSetRecoilState(saveTempPhone);
};
