import styled from '@emotion/styled';

const BottomShadow = styled.div`
  position: absolute;
  width: 480px;
  height: 8px;
  bottom: -14px;
  left: 50%;
  z-index: 1;
  background-color: var(--fett-app-primary-color);
  border-radius: 50%;
  transform: translateX(-50%);
  filter: blur(18px);
  @media (max-width: 480px) {
    width: 100vw;
    display: none;
  }
`;

export default BottomShadow;
