import posthog from '@leyan/lytics';
import React, { PureComponent, ErrorInfo } from 'react';

/**
 * 替换渲染函数
 */
export interface FallbackFunction {
  (error: Error, reset: () => void): React.ReactNode;
}

/**
 * 错误边界属性
 */
export interface ErrorBoundaryProps {
  /**
   * 抛错后替换渲染节点
   */
  fallback: FallbackFunction | React.ReactNode;
  componentName?: string;
}

/**
 * 错误边界正常状态
 */
export interface ErrorBoundaryNormalState {
  /**
   * 是否抛除错误
   */
  hasError: false;
  /**
   * 抛出错误信息
   */
  error: null;
}

/**
 * 错误边界错误状态
 */
export interface ErrorBoundaryErrorState {
  /**
   * 是否抛除错误
   */
  hasError: true;
  /**
   * 抛出错误信息
   */
  error: Error;
}

/**
 * 错误边界状态
 */
export type ErrorBoundaryState = ErrorBoundaryNormalState | ErrorBoundaryErrorState;

/**
 * 错误边界
 */
class ErrorBoundary extends PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    };
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const { componentName } = this.props;
    posthog.capture('$crash', {
      error: error.message,
      stack: info.componentStack,
      component: componentName,
    });
  }

  reset = () => {
    this.setState({
      hasError: false,
      error: null,
    });
  };

  render() {
    const {
      props: { fallback, children },
      state,
    } = this;

    if (state.hasError) {
      if (typeof fallback === 'function') {
        return fallback(state.error, this.reset) as React.ReactNode;
      }

      return fallback;
    }

    return children;
  }
}

export default ErrorBoundary;
