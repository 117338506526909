import styled from '@emotion/styled/macro';
import { Carousel } from 'antd';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  border: solid 1px #e2e4f9;
  .slick-dots {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    width: 90px;
    margin: 33px 5px 0;
    > li > button {
      width: 8px;
      height: 4px;
      border-radius: 3px;
      background: #e5e6ff;
    }
    > .slick-active > button {
      background: #9195ff !important;
    }
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  height: 72px;
  padding-right: 100px;
  border-radius: 2px;
  background: #fff;
  > span {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    margin-right: 16px;
    padding: 0 20px 0 15px;
    font-size: 16px;
    color: #5057ff;
    background: linear-gradient(128deg, #ebf6ff 0%, #e2e4f9 100%);
    &::before,
    &::after {
      display: block;
      content: '';
      position: absolute;
      right: 0;
      width: 10px;
      height: 50%;
      border-style: solid;
      border-width: 18px 5px 18px 5px;
      border-color: transparent;
      border-right-color: #fff;
    }
    &::before {
      top: 0;
      border-top-color: #fff;
    }
    &::after {
      bottom: 0;
      border-bottom-color: #fff;
    }
  }
  > div {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    max-width: 300px;
    height: 40px;
    margin-right: 12px;
    padding-right: 12px;
    border-right: solid 1px #f2f2f1;
    > span {
      flex: 0 0 auto;
      height: 18px;
      margin-right: 8px;
      padding: 0 4px;
      line-height: 16px;
      background: #d2dcff;
      border: solid 1px #5057ff;
      border-radius: 2px;
    }
    > p {
      margin: 0;
      white-space: pre-wrap;
    }
  }
  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      display: flex;
      align-items: center;
      line-height: 30px;
      > dt {
        font-weight: normal;
        color: #336fff;
      }
      > dd {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        font-weight: bold;
        > span {
          display: flex;
          align-items: center;
          height: 24px;
          margin: 0 4px;
          padding: 0 4px;
          border-radius: 2px;
          border: solid 1px #f93a4a;
          background: #ffdde0;
        }
      }
      &:not(:first-of-type) {
        > dd > span {
          border: solid 1px #00b578;
          background: #d3fff0;
        }
      }
    }
  }
`;

function Example() {
  const { t } = useTranslation('ViewProductContentGenerator');

  return (
    <Container>
      <Carousel autoplay autoplaySpeed={5000}>
        <div>
          <Content>
            <span>{t('翻译效果对比')}</span>
            <div>
              <span>英语</span>
              <p>WE SUPPORT COD</p>
            </div>
            <ul>
              <li>
                <dt>{t('G家翻译')}：</dt>
                <dd>
                  我们支持
                  <span>鳕鱼</span>
                </dd>
              </li>
              <li>
                <dt>{t('GPT翻译')}：</dt>
                <dd>
                  我们支持
                  <span>货到付款</span>
                </dd>
              </li>
            </ul>
          </Content>
        </div>
        <div>
          <Content>
            <span>{t('翻译效果对比')}</span>
            <div>
              <span>菲律宾语</span>
              <p>Hndi napo masarap ung juices nyo, parang matagal ng stock</p>
            </div>
            <ul>
              <li>
                <dt>{t('G家翻译')}：</dt>
                <dd>
                  你的果汁不好，好像
                  <span>好久没货</span>了
                </dd>
              </li>
              <li>
                <dt>{t('GPT翻译')}：</dt>
                <dd>
                  你们的果汁不好喝了，好像已经
                  <span>存货很久</span>了
                </dd>
              </li>
            </ul>
          </Content>
        </div>
        <div>
          <Content>
            <span>{t('翻译效果对比')}</span>
            <div>
              <span>印尼语</span>
              <p>bisa di gojek min?</p>
            </div>
            <ul>
              <li>
                <dt>{t('G家翻译')}：</dt>
                <dd>
                  你<span>能去jek min</span>
                  吗？
                </dd>
              </li>
              <li>
                <dt>{t('GPT翻译')}：</dt>
                <dd>
                  可以
                  <span>使用Gojek送货</span>
                  吗，先生？
                </dd>
              </li>
            </ul>
          </Content>
        </div>
        <div>
          <Content>
            <span>{t('翻译效果对比')}</span>
            <div>
              <span>泰语</span>
              <p>อกเท่าไหร่</p>
            </div>
            <ul>
              <li>
                <dt>{t('G家翻译')}：</dt>
                <dd>
                  <span>多少</span>
                </dd>
              </li>
              <li>
                <dt>{t('GPT翻译')}：</dt>
                <dd>
                  <span>胸围多少</span>
                </dd>
              </li>
            </ul>
          </Content>
        </div>
        <div>
          <Content>
            <span>{t('翻译效果对比')}</span>
            <div>
              <span>越南语</span>
              <p>Hướng dẫn trả hàng</p>
            </div>
            <ul>
              <li>
                <dt>{t('G家翻译')}：</dt>
                <dd>
                  <span>付款说明</span>
                </dd>
              </li>
              <li>
                <dt>{t('GPT翻译')}：</dt>
                <dd>
                  <span>退货指南</span>
                </dd>
              </li>
            </ul>
          </Content>
        </div>
        <div>
          <Content>
            <span>{t('翻译效果对比')}</span>
            <div>
              <span>越南语</span>
              <p>Ảnh này áo có 3 cúc</p>
            </div>
            <ul>
              <li>
                <dt>{t('G家翻译')}：</dt>
                <dd>
                  这张图片有3个
                  <span>按钮</span>
                </dd>
              </li>
              <li>
                <dt>{t('GPT翻译')}：</dt>
                <dd>
                  这张照片里的上衣有3个
                  <span>纽扣</span>
                </dd>
              </li>
            </ul>
          </Content>
        </div>
      </Carousel>
    </Container>
  );
}

export default Example;
