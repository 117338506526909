import { useCountDown } from 'ahooks';
import { Options, FormattedRes } from 'ahooks/lib/useCountDown';
import { useEffect, useState } from 'react';
import { atom, useRecoilState } from 'recoil';

export const countdownState = atom<FormattedRes | null>({
  key: 'countdownRes',
  default: null,
});

const useShopeeCountdown = (options: Options) => {
  const [, setTargetDate, formattedRes] = useCountDown(options);
  const [pending, setPending] = useState(false);
  const [countdown, setCountdown] = useRecoilState(countdownState);

  const startCountdown = (ms: number) => {
    if (pending) return;
    setPending(true);
    setTargetDate(Date.now() + ms);
  };

  const stopCountdown = () => {
    setPending(false);
    setTargetDate(undefined);
    setCountdown(null);
  };

  useEffect(() => {
    setCountdown(formattedRes);
  }, [formattedRes, setCountdown]);

  return {
    pending,
    startCountdown,
    stopCountdown,
    countdown,
  };
};

export default useShopeeCountdown;
