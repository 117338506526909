import FixedArray from './fixedArray';

let cache: FixedArray<string>;

const getCache = () => {
  if (cache) {
    return cache;
  }
  cache = new FixedArray<string>(20, true);
  return cache;
};

export default getCache();
