import styled from '@emotion/styled';
import { Input, Space, Spin } from 'antd';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  onSendSMS?: () => void;
  cooldown: number;
  times?: number;
  loading?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 2px;

  &:hover {
    border-color: var(--ant-primary-5);
  }

  &.captcha-input-focus {
    border-color: var(--ant-primary-color-hover);
    box-shadow: 0 0 0 2px var(--ant-primary-color-outline);
  }
`;

const ButtonText = styled.div`
  padding: 0 22px;
  position: relative;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  color: var(--ant-primary-color);
  cursor: pointer;

  &::before {
    content: ' ';
    position: absolute;
    width: 1px;
    height: 24px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #d9d9d9;
  }

  &.disabled {
    color: #d3d7e0;
  }
`;

const CaptchaInput = (props: Props) => {
  const {
    disabled = false,
    onChange,
    value,
    onSendSMS: onClick,
    cooldown,
    times = 0,
    loading,
  } = props;
  const { t } = useTranslation('ViewStartups');
  const [focus, setFocus] = useState(false);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value);
    },
    [onChange],
  );
  const calcDisabled = loading || disabled || cooldown > 0;
  return (
    <Wrapper className={focus ? 'captcha-input-focus' : ''}>
      <Input
        value={value}
        bordered={false}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onChange={handleChange}
      />
      <ButtonText
        className={calcDisabled ? 'disabled' : ''}
        onClick={calcDisabled ? undefined : onClick}
      >
        {loading ? (
          <Space>
            <Spin size="small" spinning={loading} />
            {t('发送中')}
          </Space>
        ) : (
          <>
            {cooldown > 0
              ? `${cooldown}${t('startups.form.item.captcha.send.cooldown')}`
              : t(
                  times
                    ? 'startups.form.item.captcha.send.again'
                    : 'startups.form.item.captcha.send',
                )}{' '}
          </>
        )}
      </ButtonText>
    </Wrapper>
  );
};

export default CaptchaInput;
