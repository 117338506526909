import styled from '@emotion/styled/macro';

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 350px;
  border-radius: 2px;
  > img {
    height: 160px;
    width: auto;
    margin-bottom: 24px;
  }
  > p {
    margin-bottom: 8px;
    text-align: center;
    color: var(--theme-text-2);
    div {
      color: var(--theme-text-1);
    }
  }
  > span {
    color: var(--theme-text-3);
  }
`;

export { default as comingImage } from '../assets/coming.png';

export default Empty;
