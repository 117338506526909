import styled from '@emotion/styled';
import React from 'react';

const Container = styled.section`
  display: flex;
  align-items: center;
  height: calc(100vh - 56px);
  background-color: #fbfbfb;
  overflow: hidden;

  .ant-btn-text {
    color: #84868c;

    &:hover,
    &:active {
      color: var(--ant-primary-color) !important;
      background-color: transparent;
    }
  }
`;
const Left = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 100%;
  overflow: hidden;
  background-color: #ffffff;
`;
const Right = styled.div`
  flex: 1;
  height: 100%;
  padding: 24px;
  overflow-y: auto;

  .content-highlight-words {
    position: relative;
    background-color: unset;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 5px;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #ffd47d;
      opacity: 0.5;
    }
  }
`;
const FormContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 16px 24px;
  overflow-y: auto;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  background: #ffffff;
  box-shadow: 0 -2px 8px 0 #a7b2cc1a, inset 0 1px 0 0 #f0f2f5;
  border-radius: 0 0 4px 4px;
`;

export interface SectionLayoutProps {
  left: React.ReactNode;
  right: React.ReactNode;
  footer?: React.ReactNode;
}

export const SectionLayout = (props: SectionLayoutProps) => {
  const { left, right, footer } = props;

  return (
    <Container>
      <Left>
        <FormContainer>{left}</FormContainer>
        {footer && <Footer>{footer}</Footer>}
      </Left>
      <Right>{right}</Right>
    </Container>
  );
};
