import { isString } from 'lodash-es';
import chatttSDK from 'services/chattt';

// eslint-disable-next-line import/prefer-default-export
export function isURL(target: any) {
  return isString(target) && /https?:\/\/.+/.test(target);
}

export const isInIframe = window.self !== window.top;

export const isIM1 = isInIframe;

export const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

export const isInElectron = !!chatttSDK;
