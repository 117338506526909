import { v4 as uuidV4 } from 'uuid';
import { throttle } from 'lodash-es';
import { sendSystem } from '.';
import nimitt, { getHealthStatus, setHealthStatus } from './nimitt';

const doNimHealthSend = (uuid: string = uuidV4()) =>
  sendSystem({
    scene: 'p2p',
    to: 'health',
    sendToOnlineUsersOnly: false,
    cc: true,
    content: JSON.stringify({ uuid }),
  });

// 暂时通过window挂载
// @ts-ignore
window.doNimHealthSend = doNimHealthSend;

let healthTimeout: ReturnType<typeof setInterval>;
const duration = 60 * 1000;
const sendHealthReq = throttle(() => {
  const uuid = uuidV4();
  const cb = (p: any) => {
    if (uuid === p?.uuid) {
      setHealthStatus('alive');
      off?.();
      off = null;
    }
  };
  nimitt.on('sys:message:health_ack', cb);
  let off: null | (() => void) = () => nimitt.off('sys:message:health_ack', cb);
  setTimeout(() => {
    if (off) {
      setHealthStatus('timeout');
      off();
      off = null;
    }
  }, duration);
  doNimHealthSend(uuid)
    .then(() => {
      const lastStatus = getHealthStatus();
      // 如果上次心跳没有异常，优先保留alive状态
      setHealthStatus(lastStatus === 'alive' ? 'alive' : 'pending');
    })
    .catch((e) => {
      setHealthStatus('error');
    });
}, duration);

// eslint-disable-next-line import/prefer-default-export

nimitt.on('connect', () => {
  clearInterval(healthTimeout);
  sendHealthReq();
  healthTimeout = setInterval(sendHealthReq, duration);
});

nimitt.on('error', () => {
  clearInterval(healthTimeout);
  setHealthStatus('ideal');
});
nimitt.on('disconnect', () => {
  clearInterval(healthTimeout);
  setHealthStatus('ideal');
});
nimitt.on('willReconnect', () => {
  clearInterval(healthTimeout);
  setHealthStatus('ideal');
});
