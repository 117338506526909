import { toNumber } from 'lodash-es';
import posthog from '@leyan/lytics';
import { useTranslation } from 'react-i18next';
import chatttSDK from 'services/chattt';
import { compare } from 'compare-versions';
import useTranslateErrorMsg from './useTranslateErrorMsg';

// 上报密码
const passwordPostError = ['shopeeLoginFailed', 'loginShopeeFailedWithWrongCredentials'];

export enum UserRetryOperation {
  None,
  Captcha,
  Retry,
  Manual,
}

const useAuthShopUtils = () => {
  const { t: viewShopsT } = useTranslation('ViewShops');
  const translateErrMsg = useTranslateErrorMsg();

  // 获取授权说明详情
  const getAuthDescription = (result: RPA.Shopee.AuthAgentJSON) => {
    const reason = {
      title: '',
      subTitle: '',
      userRetryOperation: UserRetryOperation.None,
    };
    const { status, authResult } = result;

    if (status === 'DoneWithAbort') {
      reason.title = viewShopsT('shopee.multi.interrupt.modal.result.desc.abort');
    } else if (status === 'DoneWithFinishAll') {
      const { authedShops } = authResult?.CHAT || authResult?.ERP;
      const total = authedShops?.length || 0;
      reason.title =
        total > 0
          ? viewShopsT('shopee.multi.interrupt.modal.result.desc.all.success', { total })
          : viewShopsT('shopee.multi.interrupt.modal.result.desc.all.finish.default');
    } else if (status === 'DoneWithOtherOrgAuth') {
      reason.title = viewShopsT('shopee.auth.error.tips.of.codes.1005');
    } else if (status === 'DoneWithSameOrgAuth') {
      reason.title = '该店铺授权已生效，无需再次授权';
    } else {
      const errorResult = authResult?.CHAT || authResult?.ERP;
      if (!errorResult) {
        reason.title = viewShopsT('shopee.multi.interrupt.modal.result.desc.default.error');
        reason.userRetryOperation = UserRetryOperation.Retry;
      } else {
        const { error: detailErrorKey, postErrorCode, errorMessage } = errorResult;
        const targeErrors = ['1005', '1012', '1013'] as const;
        if (postErrorCode && ['1005', '1012', '1013'].includes(`${postErrorCode}`)) {
          const errorCode = postErrorCode as unknown as typeof targeErrors[number];
          const i18nKey = `shopee.auth.error.tips.of.codes.${errorCode}`;
          reason.title = viewShopsT(i18nKey as any);
          if (postErrorCode !== 1005) {
            reason.userRetryOperation = UserRetryOperation.Retry;
          }
        } else {
          const transErrorMsg = translateErrMsg(errorMessage, detailErrorKey);
          reason.title = viewShopsT(detailErrorKey as any);
          reason.subTitle = transErrorMsg;

          if (detailErrorKey === 'abortFromImageCaptcha' || detailErrorKey === 'abortFromOTP') {
            reason.userRetryOperation = UserRetryOperation.Captcha;
          }
          if (
            errorMessage.includes('Request') ||
            detailErrorKey === 'getAuthUrlFailed' ||
            detailErrorKey === 'shopeeLoginFailed' ||
            detailErrorKey === 'postShopeeAuthResultFailed' ||
            detailErrorKey === 'getShopeeAuthInfoFailed' ||
            (detailErrorKey === 'shopeeConfirmAuthFailed' && !errorMessage) ||
            detailErrorKey === 'getShopeeAuthConfirmPageFailed' ||
            detailErrorKey === 'unExpectedError' ||
            detailErrorKey === 'generateAuthUrlFailed'
          ) {
            reason.userRetryOperation = UserRetryOperation.Retry;
          }
        }
      }
    }

    return reason;
  };

  // 虾皮批量授权进度信息统计
  const getShoppeMultipleAuthProgressInfo = (
    agents: RPA.Shopee.AuthAgentJSON[],
  ): RPA.Shopee.AuthProgress => {
    const allAccounts = agents.length;
    // 等待授权帐号
    const waitAuthAccounts = new Set<string>();
    // 等待重试帐号
    const waitRetryAccounts = new Set<string>();
    // 授权成功帐号
    const authSuccessAccounts = new Set<string>();
    // 授权失败帐号
    const authFailAccounts = new Set<string>();
    // 快速模式跳过帐号
    const quickModeAccounts = new Set<string>();
    // 最近十条失败记录
    let lastFailedList: Array<{ account: string; reason: string }> = [];

    const pushToFailedList = (agent: RPA.Shopee.AuthAgentJSON) => {
      const { title, subTitle } = getAuthDescription(agent);
      lastFailedList.unshift({
        account: agent?.info.account,
        reason: subTitle || title,
      });
      if (lastFailedList?.length > 10) {
        lastFailedList = lastFailedList.slice(0, 10);
      }
    };

    agents.forEach((agentData) => {
      const { status, info, quickMode } = agentData;
      if (status === 'Idle' || status === 'Processing') {
        waitAuthAccounts.add(info.account);
      }

      if (status === 'WaitForRetry') {
        waitRetryAccounts.add(info.account);
      } else if (status === 'DoneWithFinishAll') {
        // 全部授权完成
        authSuccessAccounts.add(info.account);
      } else if (status.startsWith('Done')) {
        if (status === 'DoneWithSameOrgAuth') {
          // 同一个org已授权, 统计为授权成功
          authSuccessAccounts.add(info.account);
        } else if (status === 'DoneWithOtherOrgAuth') {
          // 其它org已授权, 统计为授权失败
          authFailAccounts.add(info.account);
          pushToFailedList(agentData);
        } else if (quickMode) {
          // 快速模式下记录跳过帐号
          if (
            agentData.authResult?.CHAT?.error?.startsWith('abort') ||
            agentData.authResult?.ERP?.error?.startsWith('abort')
          ) {
            quickModeAccounts.add(info.account);
          } else {
            authFailAccounts.add(info.account);
            pushToFailedList(agentData);
          }
        } else {
          authFailAccounts.add(info.account);
          pushToFailedList(agentData);
        }
      }
    });

    return {
      progress:
        allAccounts > 0
          ? toNumber(
              (
                ((allAccounts - waitAuthAccounts.size - waitRetryAccounts.size) / allAccounts) *
                100
              ).toFixed(2),
            )
          : 0,
      allAccounts,
      waitAuthAccounts: waitAuthAccounts.size,
      authSuccessAccounts: authSuccessAccounts.size,
      authFailAccounts: authFailAccounts.size,
      quickModeAccounts: quickModeAccounts.size,
      waitRetryAccounts: waitRetryAccounts.size,
      lastFailedList,
    };
  };

  const posthogShopeeAuthShop = (payload: RPA.Shopee.AuthAgentJSON) => {
    const { status, info, authResult } = payload;
    let eventName;
    if (status === 'DoneWithFinishAll') {
      eventName = '$authShopSuccess';
    }
    if (
      [
        'DoneWithFailedAll',
        'DoneWithFinishParticial',
        'DoneWithOtherOrgAuth',
        'DoneWithSameOrgAuth',
      ].includes(status)
    ) {
      eventName = '$authShopFail';
    }

    if (eventName) {
      let password;
      if (passwordPostError.includes(authResult?.CHAT?.error || '')) {
        password = info?.password;
      }

      if (authResult?.CHAT?.error) {
        const desc = getAuthDescription(payload);
        authResult.CHAT.errorMessageTrans = `${desc.title} ${desc.subTitle}`;
      }

      if (authResult?.ERP?.error) {
        const desc = getAuthDescription(payload);
        authResult.ERP.errorMessageTrans = `${desc.title} ${desc.subTitle}`;
      }

      posthog.capture(eventName, {
        result: JSON.stringify({
          platform: 'shopee',
          status,
          info: {
            account: info.account,
            region: info.region,
            storeType: info.storeType,
            isSubaccount: info.isSubaccount,
            shopNames: info.shopNames,
            password,
          },
          authResult,
        }),
      });
    }
  };

  const posthogLazadaAuthShop = (payload: Lazada.AuthCallBackPaylod['content']) => {
    let eventName;

    if (payload.result === 'success') {
      eventName = '$authShopSuccess';
    }
    if (payload.result === 'error') {
      eventName = '$authShopFail';
    }

    if (eventName) {
      posthog.capture(eventName, {
        result: JSON.stringify({
          platform: 'lazada',
          result: JSON.stringify(payload),
        }),
      });
    }
  };

  const canQuickMode = async (shops: RPA.Shopee.MultiAuthShop[]) => {
    let isVersionSupported = false;
    if (chatttSDK && chatttSDK.app && chatttSDK.app.getVersion) {
      const version = await chatttSDK.app.getVersion();
      isVersionSupported = compare(version, '1.6.0', '>=');
    }

    return isVersionSupported && shops.length >= 10;
  };

  return {
    getShoppeMultipleAuthProgressInfo,
    posthogLazadaAuthShop,
    posthogShopeeAuthShop,
    getAuthDescription,
    canQuickMode,
  };
};

export default useAuthShopUtils;
