import 'antd/dist/antd.variable.min.css';
import 'react-contexify/dist/ReactContexify.css';
import 'react-app-polyfill/stable';
import 'moment/locale/zh-cn';
import moment from 'moment';
import numeral from 'numeral';
import { injectGlobal } from '@emotion/css';
import '@emotion/styled';
import ysbth from 'assets/fonts/ysbth.ttf';
import 'services/i18n';

injectGlobal`
  @font-face {
    font-family: you she biao ti hei;
    src: url(${ysbth});
  }
  * {
    overscroll-behavior: auto;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: hsla(0, 0%, 70.6%, 0.5);
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
  .react-contexify {
    z-index: 99999;
  }
  .ant-select-arrow {
    color: rgba(0, 0, 0, .85);
  }
`;

numeral.defaultFormat('0,0');
numeral.nullFormat('-');

moment.locale('zh-cn');
