import { DeleteOutlined } from '@ant-design/icons';
import { useMemoizedFn } from 'ahooks3';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

export interface ClearButtonProps {
  onClear: () => void;
}

export const ClearButton = (props: ClearButtonProps) => {
  const { onClear } = props;
  const { t } = useTranslation('ViewProductContentGenerator');

  const handleClick = useMemoizedFn(() => {
    Modal.confirm({
      title: t('clearConfirmTitle'),
      content: t('clearConfirmContent'),
      onOk: onClear,
    });
  });

  return (
    <>
      <Button type="text" size="small" icon={<DeleteOutlined />} onClick={handleClick}>
        {t('clearData')}
      </Button>
    </>
  );
};
