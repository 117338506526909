import { ImageElement } from '../../slateTypes';

function imageSerializer(imageElement: ImageElement) {
  const { type, images } = imageElement;
  if (images && images.length) {
    return images
      .map(({ imageId }) => `{{#${type}}}${encodeURIComponent(imageId || '')}{{/${type}}}`)
      .join('');
  }
  return '';
}

export default imageSerializer;
