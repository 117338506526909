import chatttSDK from 'services/chattt';

/** 数据表定义版本标记 */
const dbVersionCfg = '20220905-1738';

let userId: number;
/** 当前登录帐号id */
export function setUserId(uid: number) {
  userId = uid;
}

let orgId: number;
/** 当前登录帐号所在的orgId */
export function setOrgId(id: number) {
  orgId = id;
}

/** 数据表定义 */
async function dbSchema(sql: string) {
  const db = await getDb();
  try {
    await db.exec(sql);
    return true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
  return false;
}

/** 更新本地数据表定义标记 */
async function setDbVersion(version: string) {
  const db = await getDb();
  return db.run('update cfg set value=? where key=?', [version, `dbVersion_${orgId}_${userId}`]);
}

async function getCfgVersion() {
  const db = await getDb();
  try {
    const r = await db.get('select value from cfg where key = ?', [`cfg_ver`]);
    if (!r) {
      await initKey(`cfg_ver`, '');
      return '';
    }
    return r.value as string;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
  return '';
}
async function setCfgVersion(version: string) {
  const db = await getDb();
  return db.run('update cfg set value=? where key=?', [version, `cfg_ver`]);
}

/** 获取本地的数据表定义版本 */
async function getDbVersion() {
  const db = await getDb();
  try {
    const r = await db.get('select value from cfg where key = ?', [`dbVersion_${orgId}_${userId}`]);
    if (!r) {
      await initKey(`dbVersion_${orgId}_${userId}`, '');
      return '';
    }
    return r.value as string;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
  return '';
}

export const syncOverMark = '00_over';
/** 是否完成`消息或会话`同步 */
export function isLastSyncOver(v: string) {
  return v === syncOverMark;
}

/** 设置`会话表`最近一次同步的标记 */
export async function setLastConvUpdate(offsetValue: string) {
  const db = await getDb();
  return db.run('update cfg set value=? where key=?', [
    offsetValue,
    `lastConvUpdate_${orgId}_${userId}`,
  ]);
}

/** 获取`会话表`最近一次同步的标记 */
export async function getLastConvUpdate() {
  const db = await getDb();
  const r = await db.get('select value from cfg where key=?', [
    `lastConvUpdate_${orgId}_${userId}`,
  ]);
  if (!r) {
    await initKey(`lastConvUpdate_${orgId}_${userId}`, '');
    return '';
  }
  return r.value as string;
}

/** 设置`会话表`同步过程中的同步的标记
 * offsetValue: updateTime:offset
 */
export async function setLastConvUpdateProcess(offsetValue: string) {
  const db = await getDb();
  return db.run('update cfg set value=? where key=?', [
    offsetValue,
    `lastConvUpdate_${orgId}_${userId}_Process`,
  ]);
}

/** 获取`会话表`同步过程中的同步的标记
 * [updateTime, offset]
 */
export async function getLastConvUpdateProcess() {
  const db = await getDb();
  const r = await db.get('select value from cfg where key=?', [
    `lastConvUpdate_${orgId}_${userId}_Process`,
  ]);
  if (!r) {
    await initKey(`lastConvUpdate_${orgId}_${userId}_Process`, '');
    return ['', ''];
  }
  return (r.value as string).split(':');
}

/** offsetValue: updateTime:offset */
export async function setLastConvUpdateEnd(offsetValue: string) {
  const db = await getDb();
  return db.run('update cfg set value=? where key=?', [
    offsetValue,
    `lastConvUpdate_${orgId}_${userId}_End`,
  ]);
}
export async function hasEverConvUpdateEnd() {
  const key = `lastConvUpdate_${orgId}_${userId}_End`;
  const db = await getDb();
  const r = await db.get('select value from cfg where key=?', [key]);
  if (!r) {
    await initKey(key, '');
    return false;
  }
  if (r.value) {
    return true;
  }
  return false;
}

/** 设置`消息表`最近一次同步的最大`updateTime`标记 */
export async function setLastMsgUpdate(updateTime: string, oId: string) {
  const db = await getDb();
  return db.run('update cfg set value=? where key=?', [updateTime, `lastMsgUpdate_${oId}`]);
}
/** 获取`消息表`最近一次同步的最大`updateTime`标记 */
export async function getLastMsgUpdate(oId: string) {
  const db = await getDb();
  const r = await db.get('select value from cfg where key=?', [`lastMsgUpdate_${oId}`]);
  if (!r) {
    await initKey(`lastMsgUpdate_${oId}`, '');
    return '';
  }
  return r.value as string;
}

/** 设置`消息表`最近一次同步过程中已同步的`updateTime`标记, 值为`over`时表示同步成功, 否则为已同步到的`updateTime`记录, 该记录之前没同步的还需要继续同步
 * offsetValue: updateTime:offset
 */
export async function setLastMsgUpdateProcess(updateTime: string, oId: string) {
  const db = await getDb();
  return db.run('update cfg set value=? where key=?', [updateTime, `lastMsgUpdate_${oId}_Process`]);
}
/** 获取`消息表`最近一次同步过程中已同步的`updateTime`标记
 * [updateTime, offset]
 */
export async function getLastMsgUpdateProcess(oId: string) {
  const key = `lastMsgUpdate_${oId}_Process`;
  const db = await getDb();
  const r = await db.get('select value from cfg where key=?', [key]);
  if (!r) {
    await initKey(key, '');
    return ['', ''];
  }
  return (r.value as string).split(':');
}
/** offsetValue: updateTime:offset */
export async function setLastMsgUpdateEnd(offsetValue: string, oId: string) {
  const db = await getDb();
  return db.run('update cfg set value=? where key=?', [offsetValue, `lastMsgUpdate_${oId}_End`]);
}
export async function hasEverMsgUpdateEnd(oId: string) {
  const key = `lastMsgUpdate_${oId}_End`;
  const db = await getDb();
  const r = await db.get('select value from cfg where key=?', [key]);
  if (!r) {
    await initKey(key, '');
    return false;
  }
  if (r.value) {
    return true;
  }
  return false;
}

/** 对特定配置数据初始化 */
async function initKey(key: string, value: string) {
  const db = await getDb();
  const r = await db.get('select value from cfg where key=?', [key]);
  if (r === undefined) {
    await db.run('insert into cfg(key,value) values(?,?)', [key, value]);
  }
  return true;
}

/** 重置消息更新记录,重新拉取全量消息 */
async function resetMsgUpdateMark() {
  const db = await getDb();
  await db.run(`delete from cfg where key like 'lastMsgUpdate%'`);
  return true;
}

/**
 * 配置表
 *
 * key:
 *    dbVersion   数据定义版本
 *    lastConvUpdate  `会话表`最近一次同步的标记
 */
async function initCfg() {
  await dbSchema(`CREATE TABLE IF NOT EXISTS "cfg" (
    "key" TEXT NOT NULL DEFAULT '',
    "value" TEXT NOT NULL DEFAULT ''
  );`);
  await initKey(`dbVersion_${orgId}_${userId}`, '');
  await initKey(`lastConvUpdate_${orgId}_${userId}`, '');

  // 当数据表字段更新, 需要重新同步数据时, 把特定数据版本和已记录的版本做比较
  if ((await getDbVersion()) < '20211125-2051') {
    await setLastConvUpdate('');
    await resetMsgUpdateMark();
  }
}

/**
 * 会话表, 客服纬度
 */
async function initConversation() {
  const convTable = `conversation_${userId}`;
  await dbSchema(`CREATE TABLE IF NOT EXISTS "${convTable}" (
      "id" INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
      "convId" TEXT NOT NULL DEFAULT '',
      "msgTime" TEXT NOT NULL DEFAULT '',
      "cnt" TEXT NOT NULL DEFAULT '', -- 用户名下面的提示内容
      "buyerName" TEXT NOT NULL DEFAULT '',
      "shopId" TEXT NOT NULL DEFAULT '',
      "json" TEXT NOT NULL DEFAULT ''
    );`);
  // dbVersion 20211025-1641: 新增 buyerId
  await dbSchema(`ALTER TABLE "${convTable}" ADD COLUMN 'buyerId' TEXT DEFAULT '';`);

  await dbSchema(
    `CREATE INDEX IF NOT EXISTS '${convTable}.msgTime' ON '${convTable}' ( msgTime DESC );`,
  );
  await dbSchema(
    `CREATE INDEX IF NOT EXISTS '${convTable}.convId' ON '${convTable}' ( convId DESC );`,
  );
  await dbSchema(
    `CREATE INDEX IF NOT EXISTS '${convTable}.buyerId' ON '${convTable}' ( buyerId DESC );`,
  );
  await dbSchema(
    `CREATE INDEX IF NOT EXISTS '${convTable}.buyerName' ON '${convTable}' ( buyerName );`,
  );
}

/**
 * 本地消息表, org 纬度, 所有登录帐号共享消息表
 */
async function initMsg() {
  const tb = `convMsg_${orgId}`;
  await dbSchema(`CREATE TABLE IF NOT EXISTS "${tb}" (
    "id" INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
    "convId" TEXT NOT NULL DEFAULT '',
    "msgId" TEXT NOT NULL DEFAULT '',
    "msgTime" TEXT NOT NULL DEFAULT '',
    "msgFromType" TEXT NOT NULL DEFAULT '',
    "msgType" TEXT NOT NULL DEFAULT '',
    "cnt" TEXT NOT NULL DEFAULT '', -- 消息搜索匹配字段: 消息文本 或 订单号 或 商品ID
    "json" TEXT NOT NULL DEFAULT ''
  );`);
  await dbSchema(`CREATE INDEX IF NOT EXISTS '${tb}.convId' ON '${tb}' ( convId DESC );`);
  await dbSchema(`CREATE INDEX IF NOT EXISTS '${tb}.msgId' ON '${tb}' ( msgId DESC );`);
  await dbSchema(`CREATE INDEX IF NOT EXISTS '${tb}.msgTime' ON '${tb}' ( msgTime DESC );`);
  // await dbSchema(`CREATE INDEX IF NOT EXISTS '${tb}.msgType' ON '${tb}' ( msgType DESC );`);
  await dbSchema(`CREATE INDEX IF NOT EXISTS '${tb}.cnt' ON '${tb}' ( cnt DESC );`);
  if ((await getDbVersion()) < '20211214-1106') {
    await dbSchema(`Drop table convMsg;`);
    const cfgVer = getCfgVersion();
    if (!cfgVer) {
      await dbSchema(`Delete from cfg;`);
      await getCfgVersion();
      await setCfgVersion(dbVersionCfg);
    }
  }
}

/**
 * 本地店铺表, org 纬度
 */
async function initStores() {
  const table = `stores_${orgId}`;
  await dbSchema(`CREATE TABLE IF NOT EXISTS "${table}" (
    "id" INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
    "storeId" TEXT NOT NULL DEFAULT '',
    "storeName" TEXT NOT NULL DEFAULT '',
    "alias" TEXT NOT NULL DEFAULT '',
    "externalType" TEXT NOT NULL DEFAULT '',
    "region" TEXT NOT NULL DEFAULT ''
  );`);
  // dbVersion 20220628-1717: 新增 tunnelAccountId
  await dbSchema(`ALTER TABLE "${table}" ADD COLUMN 'tunnelAccountId' TEXT DEFAULT '';`);
  await dbSchema(`CREATE INDEX IF NOT EXISTS '${table}.storeId' ON '${table}' ( storeId DESC );`);
  if ((await getDbVersion()) < '20211214-1045') {
    await dbSchema(`Delete from "${table}";`);
  }
}

let chatppDBInited = false;
export default async function getDb() {
  if (!chatttSDK?.db) {
    throw new Error('nodbAbility');
  }

  const db = chatttSDK?.db;
  if (chatppDBInited) {
    return db;
  }

  chatppDBInited = true;
  await db.init('chatpp.db');

  const localDbVer = await getDbVersion();
  if (!localDbVer || localDbVer < dbVersionCfg) {
    await initCfg();
    await initConversation();
    await initMsg();
    await initStores();

    await setDbVersion(dbVersionCfg);
  }
  return db;
}

/** sqlite in 语句, 需要`'`分割 */
export function sqlPartIn(arr: string[]) {
  return `'${arr.join("','")}'`;
}

/** 获取当前登录用户的 conversation 表名 */
export function getConversationTable() {
  return `conversation_${userId}`;
}

/** 获取当前登录用户的 店铺 表名 */
export function getStoreTable() {
  return `stores_${orgId}`;
}

/** 获取当前登录用户的 msg 表名 */
export function getMsgTable() {
  return `convMsg_${orgId}`;
}

export async function deleteConversationsByStoreId(storeId: string) {
  try {
    const db = await getDb();

    const conversationTableName = getConversationTable();

    await db.run(`DELETE FROM ${conversationTableName} WHERE shopId=${storeId}`);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export async function deleteMessagesByStoreId(storeId: string) {
  try {
    const db = await getDb();

    const messageTableName = getMsgTable();

    await db.run(`DELETE FROM ${messageTableName} WHERE json LIKE '%"storeId":"${storeId}"%'`);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export async function deleteStoreById(storeId: string) {
  try {
    const db = await getDb();

    const storeTableName = await getStoreTable();

    await db.run(`DELETE FROM ${storeTableName} WHERE storeId=${storeId}`);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function deleteStoresData(storeIds: string[]) {
  storeIds.forEach(async (storeId) => {
    await deleteConversationsByStoreId(storeId);

    await deleteMessagesByStoreId(storeId);

    await deleteStoreById(storeId);
  });
}
