import 'setupApp';

import ReactDOM from 'react-dom';
import App from 'App';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import chatttSDK from 'services/chattt';
import { init } from 'utils/tracker';
import 'utils/fps';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    // 偶然会遇到sentry post 400,如有需要可以关闭该项
    // sendClientReports: false,
  });
  if (chatttSDK?.app?.channel) {
    Sentry.setTag('channel', chatttSDK?.app.channel);
  }
  if (chatttSDK?.app?.getVersion) {
    chatttSDK.app
      .getVersion()
      .then((chatttVersion) => {
        if (chatttVersion) {
          Sentry.setTag('chatpp', chatttVersion.replace(/^v{0,}/, 'v'));
          localStorage.setItem('chatppVer', chatttVersion);
        }
      })
      .catch((e) => {
        // do nothing
      });
  }
}
init({});

ReactDOM.render(<App />, document.getElementById('root'));
