import Icon from '@ant-design/icons';
import { ReactNode } from 'react';
import { TFuncKey } from 'react-i18next';
import { RouteAccessProps, RoutePermission } from 'types';
import { getPermission } from 'hooks/business/useNewPermisson';

import { ReactComponent as CancelTradeSvg } from './assets/cancelTrade.svg';
import { ReactComponent as OnlyRefundSvg } from './assets/onlyRefund.svg';
import { ReactComponent as ReturnRefundSvg } from './assets/returnRefund.svg';
import { ReactComponent as RefusableSvg } from './assets/refusable.svg';
import { ReactComponent as ReviewSvg } from '../assets/review.svg';

export interface ScenarioRouteAccess {
  (
    props: RouteAccessProps & {
      platform?: General.PlatformUpperCaseTunnel;
      region?: General.UpperCaseRegion;
    },
  ): RoutePermission | false;
}

export const getRouteAccess =
  (permissionName: General.PermissionName): ScenarioRouteAccess =>
  ({ newPermissionEnable, newPermissionMap, platform, region }) =>
    getPermission({
      newPermissionEnable,
      newPermissionMap,
      permissionName,
      platform,
      region,
    })
      ? 'write'
      : false;

export type AideType = 'saveOrder' | 'review' | 'logistics';

export type Gather = 'cancelTrade' | 'onlyRefund' | 'returnRefund' | 'review' | 'logistics';

export const onlyRefundScenarios: Marketing.OnlyRefundScenarioType[] = [
  'ONLY_REFUND_NOT_RECEIPT',
  'ONLY_REFUND_ITEM_MISSING',
  'ONLY_REFUND_WRONG_ITEM',
  'ONLY_REFUND_PHYSICAL_DMG',
  'ONLY_REFUND_FUNCTIONAL_DMG',
];

export const returnRefundScenarios: Marketing.ReturnRefundScenarioType[] = [
  'RETURN_REFUND_ITEM_MISSING',
  'RETURN_REFUND_WRONG_ITEM',
  'RETURN_REFUND_PHYSICAL_DMG',
  'RETURN_REFUND_FUNCTIONAL_DMG',
];

export const cancelledTradeScenarios: Marketing.CancelledTradeScenarioType[] = [
  'CANCELLED_TRADE_CHANGE_VOUCHER',
  'CANCELLED_TRADE_MODIFY_ORDER',
  'CANCELLED_TRADE_CHANGE_MIND',
  'CANCELLED_TRADE_FOUND_CHEAPER',
  'CANCELLED_TRADE_TOO_TROUBLESOME',
  'CANCELLED_TRADE_OTHERS',
];

export const cancellingTradeScenarios: Marketing.CancellingTradeScenarioType[] = [
  'CANCELLING_TRADE_CHANGE_ADDRESS',
  'CANCELLING_TRADE_MODIFY_ORDER',
  'CANCELLING_TRADE_OTHERS',
  'CANCELLING_TRADE_IRRESPONDING',
];

export const cancelTradeScenarios: Marketing.CancelTradeScenarioType[] = [
  ...cancellingTradeScenarios,
  ...cancelledTradeScenarios,
];

export const positiveReviewScenarios: Marketing.PositiveReviewScenarioType[] = [
  'POSITIVE_REVIEW_CARE',
  'POSITIVE_REVIEW_REPLY',
];

export const negativeReviewScenarios: Marketing.NegativeReviewScenarioType[] = [
  'NEGATIVE_REVIEW_SAVE',
  'NEGATIVE_REVIEW_REPLY',
];

export const reviewManagementScenarios: Marketing.ReviewManagementScenarioType[] = [
  ...positiveReviewScenarios,
  ...negativeReviewScenarios,
];

export const reviewScenarios: Marketing.ReviewScenarioType[] = [
  'PRAISE_REMINDER',
  ...reviewManagementScenarios,
];

export const logisticsScenarios: Marketing.LogisticsScenarioType[] = [
  'CONFIRM_TRADE',
  'SHIPPED_REMINDER',
  'DELIVERY_REMINDER',
  'SIGN_REMINDER',
];

export const isLogisticsScenarioType = (
  scenarioType: any,
): scenarioType is Marketing.LogisticsScenarioType => logisticsScenarios.includes(scenarioType);

export const noRegionScenarios: Marketing.NoRegionScenario[] = ['TIMEOUT_REPLY', 'WELCOME_REPLY'];

export const isNoRegionScenario = (scenarioType: any): scenarioType is Marketing.NoRegionScenario =>
  noRegionScenarios.includes(scenarioType);

export const aideGathersMap: Record<AideType, Gather[]> = {
  saveOrder: ['returnRefund', 'onlyRefund', 'cancelTrade'],
  review: ['review'],
  logistics: ['logistics'],
};

export const gatherScenariosMap: Record<Gather, Marketing.AllScenarioType[]> = {
  onlyRefund: onlyRefundScenarios,
  returnRefund: returnRefundScenarios,
  cancelTrade: cancelTradeScenarios,
  review: reviewScenarios,
  logistics: logisticsScenarios,
};

export const aideTypeI18nLabelMap: Record<AideType, TFuncKey> = {
  saveOrder: 'aide.弃单挽回',
  review: 'aide.评价管理',
  logistics: 'aide.物流呵护',
};

export const gatherI18nLabelMap: Record<Gather, TFuncKey> = {
  cancelTrade: 'aide.取消订单',
  onlyRefund: 'aide.仅退款',
  returnRefund: 'aide.退货/退款',
  review: 'aide.评价管理',
  logistics: 'aide.物流呵护',
};

export const gatherIconMap: Record<Gather, ReactNode> = {
  cancelTrade: <Icon component={CancelTradeSvg} />,
  onlyRefund: <Icon component={OnlyRefundSvg} />,
  returnRefund: <Icon component={ReturnRefundSvg} />,
  review: <Icon component={ReviewSvg} />,
  logistics: <Icon component={ReviewSvg} />,
};

export const gatherAideTypeMap: Record<Gather, AideType> = {
  cancelTrade: 'saveOrder',
  onlyRefund: 'saveOrder',
  returnRefund: 'saveOrder',
  review: 'review',
  logistics: 'logistics',
};

export type SingleScenario =
  | 'ORDER_REMINDER'
  | 'PAYMENT_REMINDER'
  | 'OUT_STOCK_REMINDER'
  | 'TIMEOUT_REPLY'
  | 'WELCOME_REPLY'
  | 'QUICK_PHRASE';

const singleScenarios: SingleScenario[] = [
  'ORDER_REMINDER',
  'PAYMENT_REMINDER',
  'OUT_STOCK_REMINDER',
  'TIMEOUT_REPLY',
  'WELCOME_REPLY',
  'QUICK_PHRASE',
];

export const isSingleScenarioType = (scenarioType: any): scenarioType is SingleScenario =>
  singleScenarios.includes(scenarioType);

export const scenarioTypeGatherMap: Record<
  Exclude<Marketing.AllScenarioType, SingleScenario>,
  Gather
> = {
  ONLY_REFUND_NOT_RECEIPT: 'onlyRefund',
  ONLY_REFUND_ITEM_MISSING: 'onlyRefund',
  ONLY_REFUND_WRONG_ITEM: 'onlyRefund',
  ONLY_REFUND_PHYSICAL_DMG: 'onlyRefund',
  ONLY_REFUND_FUNCTIONAL_DMG: 'onlyRefund',
  RETURN_REFUND_ITEM_MISSING: 'returnRefund',
  RETURN_REFUND_WRONG_ITEM: 'returnRefund',
  RETURN_REFUND_PHYSICAL_DMG: 'returnRefund',
  RETURN_REFUND_FUNCTIONAL_DMG: 'returnRefund',
  CANCELLED_TRADE_CHANGE_VOUCHER: 'cancelTrade',
  CANCELLED_TRADE_MODIFY_ORDER: 'cancelTrade',
  CANCELLED_TRADE_CHANGE_MIND: 'cancelTrade',
  CANCELLED_TRADE_FOUND_CHEAPER: 'cancelTrade',
  CANCELLED_TRADE_TOO_TROUBLESOME: 'cancelTrade',
  CANCELLED_TRADE_OTHERS: 'cancelTrade',
  CANCELLING_TRADE_CHANGE_ADDRESS: 'cancelTrade',
  CANCELLING_TRADE_MODIFY_ORDER: 'cancelTrade',
  CANCELLING_TRADE_IRRESPONDING: 'cancelTrade',
  CANCELLING_TRADE_OTHERS: 'cancelTrade',
  PRAISE_REMINDER: 'review',
  NEGATIVE_REVIEW_SAVE: 'review',
  NEGATIVE_REVIEW_REPLY: 'review',
  POSITIVE_REVIEW_CARE: 'review',
  POSITIVE_REVIEW_REPLY: 'review',
  CONFIRM_TRADE: 'logistics',
  SHIPPED_REMINDER: 'logistics',
  DELIVERY_REMINDER: 'logistics',
  SIGN_REMINDER: 'logistics',
};

export const scenarioTypeI18nLabelMap: Record<Marketing.AllScenarioType, TFuncKey> = {
  ONLY_REFUND_NOT_RECEIPT: 'aide.未收到货物',
  ONLY_REFUND_ITEM_MISSING: 'aide.收到货物少件',
  ONLY_REFUND_WRONG_ITEM: 'aide.收到货物错误',
  ONLY_REFUND_PHYSICAL_DMG: 'aide.收到货物破损',
  ONLY_REFUND_FUNCTIONAL_DMG: 'aide.收到货物无法使用',
  RETURN_REFUND_ITEM_MISSING: 'aide.收到货物少件',
  RETURN_REFUND_WRONG_ITEM: 'aide.收到货物错误',
  RETURN_REFUND_PHYSICAL_DMG: 'aide.收到货物破损',
  RETURN_REFUND_FUNCTIONAL_DMG: 'aide.收到货物无法使用',
  CANCELLED_TRADE_CHANGE_VOUCHER: 'refundSetting.修改优惠券',
  CANCELLED_TRADE_MODIFY_ORDER: 'aide.修改订单',
  CANCELLED_TRADE_CHANGE_MIND: 'aide.不想买了',
  CANCELLED_TRADE_FOUND_CHEAPER: 'aide.在别处找到更便宜的',
  CANCELLED_TRADE_TOO_TROUBLESOME: 'aide.付款程序太麻烦',
  CANCELLED_TRADE_OTHERS: 'aide.其他',
  CANCELLING_TRADE_CHANGE_ADDRESS: 'refundSetting.修改收货地址',
  CANCELLING_TRADE_MODIFY_ORDER: 'aide.修改已有订单',
  CANCELLING_TRADE_OTHERS: 'aide.其他/改变主意',
  CANCELLING_TRADE_IRRESPONDING: 'aide.卖家不响应咨询',
  ORDER_REMINDER: 'marketing.催下单',
  PAYMENT_REMINDER: 'marketing.催付款',
  PRAISE_REMINDER: 'marketing.催好评',
  NEGATIVE_REVIEW_SAVE: 'aide.中差评挽回',
  NEGATIVE_REVIEW_REPLY: 'aide.中差评自动回评',
  POSITIVE_REVIEW_CARE: 'aide.好评关怀',
  POSITIVE_REVIEW_REPLY: 'aide.好评自动回评',
  CONFIRM_TRADE: 'aide.订单确认',
  SHIPPED_REMINDER: 'aide.已发货',
  DELIVERY_REMINDER: 'aide.尾程派送',
  SIGN_REMINDER: 'aide.已签收',
  OUT_STOCK_REMINDER: 'aide.缺货提醒',
  TIMEOUT_REPLY: 'setting.nav.autoReply',
  WELCOME_REPLY: 'aide.进店欢迎语',
  QUICK_PHRASE: 'phrase.快捷回复',
};

export const scenarioPermissionMap: Record<Marketing.AllScenarioType, General.PermissionName> = {
  ONLY_REFUND_NOT_RECEIPT: '配置弃单挽回任务',
  ONLY_REFUND_ITEM_MISSING: '配置弃单挽回任务',
  ONLY_REFUND_WRONG_ITEM: '配置弃单挽回任务',
  ONLY_REFUND_PHYSICAL_DMG: '配置弃单挽回任务',
  ONLY_REFUND_FUNCTIONAL_DMG: '配置弃单挽回任务',
  RETURN_REFUND_ITEM_MISSING: '配置弃单挽回任务',
  RETURN_REFUND_WRONG_ITEM: '配置弃单挽回任务',
  RETURN_REFUND_PHYSICAL_DMG: '配置弃单挽回任务',
  RETURN_REFUND_FUNCTIONAL_DMG: '配置弃单挽回任务',
  CANCELLED_TRADE_CHANGE_VOUCHER: '配置弃单挽回任务',
  CANCELLED_TRADE_MODIFY_ORDER: '配置弃单挽回任务',
  CANCELLED_TRADE_CHANGE_MIND: '配置弃单挽回任务',
  CANCELLED_TRADE_FOUND_CHEAPER: '配置弃单挽回任务',
  CANCELLED_TRADE_TOO_TROUBLESOME: '配置弃单挽回任务',
  CANCELLED_TRADE_OTHERS: '配置弃单挽回任务',
  CANCELLING_TRADE_CHANGE_ADDRESS: '配置弃单挽回任务',
  CANCELLING_TRADE_MODIFY_ORDER: '配置弃单挽回任务',
  CANCELLING_TRADE_OTHERS: '配置弃单挽回任务',
  CANCELLING_TRADE_IRRESPONDING: '配置弃单挽回任务',
  ORDER_REMINDER: '配置催单催付任务',
  PAYMENT_REMINDER: '配置催单催付任务',
  PRAISE_REMINDER: '配置评价管理任务',
  NEGATIVE_REVIEW_SAVE: '配置评价管理任务',
  NEGATIVE_REVIEW_REPLY: '配置评价管理任务',
  POSITIVE_REVIEW_CARE: '配置评价管理任务',
  POSITIVE_REVIEW_REPLY: '配置评价管理任务',
  CONFIRM_TRADE: '配置物流呵护任务',
  SHIPPED_REMINDER: '配置物流呵护任务',
  DELIVERY_REMINDER: '配置物流呵护任务',
  SIGN_REMINDER: '配置物流呵护任务',
  OUT_STOCK_REMINDER: '配置缺货提醒任务',
  TIMEOUT_REPLY: '超时自动回复',
  WELCOME_REPLY: '进店欢迎语',
  QUICK_PHRASE: '快捷回复-个人模版',
};

export const cancellingIcon = <Icon component={RefusableSvg} />;

export const scenarioIconMap: Partial<Record<Marketing.AllScenarioType, ReactNode>> = {
  CANCELLING_TRADE_CHANGE_ADDRESS: cancellingIcon,
  CANCELLING_TRADE_MODIFY_ORDER: cancellingIcon,
  CANCELLING_TRADE_IRRESPONDING: cancellingIcon,
  CANCELLING_TRADE_OTHERS: cancellingIcon,
};

export const scenarioTypeTipsMap: Partial<Record<Marketing.AllScenarioType, TFuncKey>> = {
  NEGATIVE_REVIEW_SAVE: 'aide.买家符合设置的评价星级条件时自动发送安抚话术，提升中差评挽回率',
};

export const singleScenarioTypePathMap: Record<SingleScenario, string> = {
  ORDER_REMINDER: 'order',
  PAYMENT_REMINDER: 'pay',
  OUT_STOCK_REMINDER: 'outstock',
  TIMEOUT_REPLY: 'basis/autoReply',
  WELCOME_REPLY: 'basis/welcome',
  QUICK_PHRASE: 'basis/phrase',
};

export const marketingScenarios: Marketing.ScenarioType[] = [
  'ORDER_REMINDER',
  'PAYMENT_REMINDER',
  'PRAISE_REMINDER',
];

export const isMarketingScenarioType = (
  scenarioType: any,
): scenarioType is Marketing.ScenarioType => marketingScenarios.includes(scenarioType);

export const scenarioTriggerActionI18nMap = {
  ORDER_REMINDER: 'marketing.会话结束',
  PAYMENT_REMINDER: 'marketing.买家下单',
  PRAISE_REMINDER: {
    SIGNED: 'marketing.买家签收',
    CONFIRMED: 'marketing.买家确认收货',
  },
} as const;

export const scenarioFirstRoundMinTimeMap: Record<Marketing.ScenarioType, number> = {
  ORDER_REMINDER: 1,
  PAYMENT_REMINDER: 1,
  PRAISE_REMINDER: 0,
};

export const supportLazadaScenarios: Marketing.AllScenarioType[] = [
  'QUICK_PHRASE',
  'ORDER_REMINDER',
  'PAYMENT_REMINDER',
  'NEGATIVE_REVIEW_REPLY',
  'POSITIVE_REVIEW_REPLY',
  'CONFIRM_TRADE',
  'SHIPPED_REMINDER',
  'TIMEOUT_REPLY',
  'WELCOME_REPLY',
];

export const supportTiktokScenarios: Marketing.AllScenarioType[] = [
  // 'ORDER_REMINDER', // 催下单
  // 'PAYMENT_REMINDER',
  // 'NEGATIVE_REVIEW_REPLY',
  // 'POSITIVE_REVIEW_REPLY',
  // 'CONFIRM_TRADE',
  // 'SHIPPED_REMINDER',
  // 'TIMEOUT_REPLY',
  // 'WELCOME_REPLY',
];

export const supportMixingTokopediaScenarios: Marketing.AllScenarioType[] = [
  'ORDER_REMINDER',
  'CONFIRM_TRADE',
  'SHIPPED_REMINDER',
  'TIMEOUT_REPLY',
];

export const supportTokopediaScenarios: Marketing.AllScenarioType[] = [
  'QUICK_PHRASE',
  'TIMEOUT_REPLY',
  'WELCOME_REPLY',
];

export const supportAliexpressScenarios: Marketing.AllScenarioType[] = [
  'WELCOME_REPLY',
  'TIMEOUT_REPLY',
  'ORDER_REMINDER',
  'PAYMENT_REMINDER',
  'DELIVERY_REMINDER',
  'SIGN_REMINDER',
];

export const isReviewManagementScenarioType = (
  scenarioType: any,
): scenarioType is Marketing.ReviewManagementScenarioType =>
  reviewManagementScenarios.includes(scenarioType);

export const isPositiveReviewScenarioType = (
  scenarioType: any,
): scenarioType is Marketing.PositiveReviewScenarioType =>
  positiveReviewScenarios.includes(scenarioType);
export const isNegativeReviewScenarioType = (
  scenarioType: any,
): scenarioType is Marketing.NegativeReviewScenarioType =>
  negativeReviewScenarios.includes(scenarioType);

export const positiveReviewStars = [4, 5];
export const negativeReviewStars = [1, 2, 3, 4];

type ConflictScenarioType = 'PRAISE_REMINDER' | 'SIGN_REMINDER';

export const conflictScenarios: ConflictScenarioType[] = ['PRAISE_REMINDER', 'SIGN_REMINDER'];

export const isConfictScenarioType = (scenarioType: any): scenarioType is ConflictScenarioType =>
  conflictScenarios.includes(scenarioType);
