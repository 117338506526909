import { useEffect, useState } from 'react';
import { languagesFlag } from 'services/api/flagr';
import { languageOptions } from '../components/config';

// 获取 flagr 语言选择项
const useLanguages = () => {
  const [options, setOptions] = useState(languageOptions);

  useEffect(() => {
    languagesFlag()
      .then((res) => {
        if (res && res.options) {
          setOptions(res.options);
        }
      })
      .catch(() => {});
  }, []);

  return options;
};

export default useLanguages;
