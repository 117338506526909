import {
  CopyOutlined,
  EllipsisOutlined,
  FormOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/css/macro';
import {
  Table,
  TableColumnsType,
  TableProps,
  Tooltip,
  Badge,
  Button,
  Dropdown,
  Menu,
  message,
  Switch,
} from 'antd';
import EditorPreviewer from 'components/EditorPreviewer';
import { mustache2SlateValuePolicyMsgTypes } from 'components/TemplateEditor/mustacheUtils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { isValidContent, updateMarketingPolicy, UpdatePriorityType } from 'services/api/oms';
import { regionTimeZoneMap } from 'services/config/region';
import { Descendant } from 'slate';
import toLowerCase from 'utils/toLowerCase';
import StatusSwitch from 'components/StatusSwitch';
import { noop } from 'lodash-es';
import {
  cancellingTradeScenarios,
  isMarketingScenarioType,
  isNoRegionScenario,
  isReviewManagementScenarioType,
} from 'views/Aide/Scenario/config';
import { useShippingStatusLabel } from 'views/Aide/Scenario/PolicyForm/share';
import { CustomSlateNodeType } from 'components/TemplateEditor/types';

export const What = styled(QuestionCircleOutlined)`
  margin-left: 8px;
  font-weight: 400;
  color: var(--theme-text-3);
  cursor: help;
`;

const ButtonWrapper = styled.div`
  > button {
    padding-left: 0;
    padding-right: 24px;
  }
`;

export const Template = styled.div`
  cursor: pointer;
  > div {
    width: 100%;
    height: 18px;
    > * {
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    * {
      display: inline-block;
      height: 18px;
      max-width: 100%;
      margin-top: 0;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const styles = {
  tooltip: css`
    max-width: 800px;
  `,
  preLine: css`
    white-space: pre-line;
  `,
  table: (activePolicyId?: number) => css`
    tbody {
      tr[data-row-key='${activePolicyId}'] {
        background: #fff6e5;
        > td {
          background: #fff6e5;
        }
      }
    }
  `,
};

type TR = Marketing.Policy;

export const getChunks = (context: { content: string; translatedContent: string }) => {
  const { content, translatedContent } = context;

  let originSegments: Descendant[] = [];
  let translatedSegments: Descendant[] = [];

  try {
    originSegments = mustache2SlateValuePolicyMsgTypes(content.replace(/^#mustache#/, ''));
    translatedSegments = mustache2SlateValuePolicyMsgTypes(
      translatedContent.replace(/^#mustache#/, ''),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }

  type Chunk = {
    type:
      | 'text'
      | CustomSlateNodeType.IMAGE
      | CustomSlateNodeType.VIDEO
      | CustomSlateNodeType.STICKER
      | CustomSlateNodeType.FOLLOW
      | CustomSlateNodeType.QRCODE;
    content: Descendant[];
    translatedContent?: Descendant[];
  };

  const chunks = originSegments.reduce<Chunk[]>((res, segment, index) => {
    const lastChunk = res[res.length - 1];

    const translatedSegment = translatedSegments[index];

    if (segment.type !== CustomSlateNodeType.IMAGE) {
      if (lastChunk && lastChunk.type === 'text') {
        lastChunk.content.push(segment);

        if (translatedSegment && translatedSegment.type === segment.type) {
          if (lastChunk.translatedContent) {
            lastChunk.translatedContent.push(translatedSegment);
          } else {
            lastChunk.translatedContent = [translatedSegment];
          }
        }
      } else {
        res.push({
          type: 'text',
          content: [segment],
          translatedContent:
            translatedSegment && translatedSegment.type === segment.type
              ? [translatedSegment]
              : undefined,
        });
      }

      return res;
    }

    const { children: [{ text = 'image' } = {}] = [] } = segment;

    const textTypeMap = {
      image: CustomSlateNodeType.IMAGE,
      video: CustomSlateNodeType.VIDEO,
      sticker: CustomSlateNodeType.STICKER,
      follow: CustomSlateNodeType.FOLLOW,
      qrcode: CustomSlateNodeType.QRCODE,
    } as const;

    const type = textTypeMap[text as keyof typeof textTypeMap] || CustomSlateNodeType.IMAGE;

    if (lastChunk && lastChunk.type === type) {
      lastChunk.content.push(segment);
    } else {
      res.push({
        type,
        content: [segment],
      });
    }

    return res;
  }, []);

  return chunks;
};

export const useGetPolicyName = () => {
  const { t } = useTranslation();

  const policyName = useMemo(() => t('aide.policyName', { returnObjects: true }), [t]);

  const getPolicyName = useCallback(
    (name: string) => {
      const result = name.match(/^策略(\d+)$/);

      if (result) {
        return t('aide.policyName.策略x', { x: result[1] });
      }

      return policyName[name as keyof typeof policyName] || name;
    },
    [t, policyName],
  );

  return {
    t,
    policyName,
    getPolicyName,
  };
};

export interface PolicyTableProps extends TableProps<TR> {
  isPolicy?: boolean;
  editable?: boolean;
  activePolicyId?: number;
  showActivePolicyTip?: boolean;
  scenario: Marketing.Scenario;
  region: General.UpperCaseRegion;
  platform: General.PlatformUpperCase;
  orderBy: Marketing.PolicyOrderBy;
  onPolicyEdit: (record: TR) => void;
  onPolicyCopy: (record: TR, roundId: string) => void;
  onPolicyDelete: (id: number) => void;
  onPriorityUpdate: (id: number, type: UpdatePriorityType) => void;
  onUpdated?: () => void;
}

function PolicyTable(props: PolicyTableProps) {
  const {
    isPolicy = false,
    editable = true,
    activePolicyId,
    showActivePolicyTip,
    scenario,
    region: upperCaseRegion,
    platform,
    orderBy,
    pagination,
    dataSource,
    onPolicyEdit,
    onPolicyCopy,
    onPolicyDelete,
    onPriorityUpdate,
    onUpdated = noop,
    ...otherProps
  } = props;

  const { t, getPolicyName } = useGetPolicyName();

  const marketingLabel = t('marketing', { returnObjects: true });

  const region = toLowerCase(upperCaseRegion);

  const timeZone = platform === 'ALIEXPRESS' ? regionTimeZoneMap.gb : regionTimeZoneMap[region];

  const shippingStatusLabelMap = useShippingStatusLabel();

  const isMarketing = isMarketingScenarioType(scenario.scenario);

  const isCancellingScenario = cancellingTradeScenarios.includes(
    scenario.scenario as Marketing.CancellingTradeScenarioType,
  );

  const isReviewManagement = isReviewManagementScenarioType(scenario.scenario);

  const isNoRegion = isNoRegionScenario(scenario.scenario);

  const columns: TableColumnsType<TR> = [
    {
      key: 'priority',
      dataIndex: 'priority',
      width: 130,
      title: (
        <div>
          {t('aide.执行顺序')}
          <Tooltip
            overlayClassName={styles.tooltip}
            placement="topLeft"
            arrowPointAtCenter
            title={
              isPolicy
                ? t('aide.单次任务中，同一订单满足店铺下多个策略时，仅执行优先级最靠前的策略')
                : marketingLabel[
                    '单次营销任务中，同一订单满足店铺下多个营销任务时，仅执行优先级最靠前的任务'
                  ]
            }
          >
            <What />
          </Tooltip>
        </div>
      ),
      ...(editable
        ? {
            sorter: true,
            sortOrder: orderBy === 'priorityAsc' ? 'ascend' : 'descend',
          }
        : {}),
      showSorterTooltip: false,
    },
    {
      key: 'name',
      dataIndex: 'name',
      width: 200,
      title: marketingLabel.策略名称,
      render: getPolicyName,
    },
    {
      key: 'enable',
      dataIndex: 'enable',
      width: 120,
      title: t('aide.策略开关'),
      render: (enable: TR['enable'], record) => {
        return (
          <StatusSwitch
            disabled={!editable}
            checked={enable}
            onChange={(enable) => {
              return updateMarketingPolicy({
                ...record,
                enable,
              })
                .then(() => {
                  message.success(marketingLabel.保存成功);

                  onUpdated();
                })
                .catch(() => {
                  message.error(marketingLabel['保存失败，请稍后再试']);
                });
            }}
            checkedChildren={t('aide.开')}
            unCheckedChildren={t('aide.关')}
          />
        );
      },
    },
    ...(!isReviewManagement
      ? [
          {
            key: 'status',
            width: 120,
            title: (
              <div data-guide="marketing-guide-step-4">
                {t('aide.策略状态')}
                <Tooltip
                  overlayClassName={styles.tooltip}
                  title={
                    <div>
                      {`${marketingLabel.生效中}: ${
                        isPolicy
                          ? t('aide.策略开关为开，且当前时间在策略有效期内')
                          : marketingLabel['启用状态为开，且当前时间在任务有效期内']
                      }`}
                      <br />
                      {`${marketingLabel.未开始}: ${
                        isPolicy
                          ? t('aide.策略开关为开，且当前时间在策略生效开始时间之前')
                          : marketingLabel['启用状态为开，且当前时间在任务生效开始时间之前']
                      }`}
                      <br />
                      {`${marketingLabel.已过期}: ${
                        isPolicy
                          ? t('aide.策略开关为开，且当前时间在策略生效结束时间之后')
                          : marketingLabel['启用状态为开，且当前时间在任务生效结束时间之后']
                      }`}
                      <br />
                      {`${marketingLabel.未启用}: ${
                        isPolicy ? t('aide.策略开关为关') : marketingLabel['启用状态为关']
                      }`}
                    </div>
                  }
                >
                  <What />
                </Tooltip>
              </div>
            ),
            render: (_: any, record: TR) => {
              const {
                enable,
                termOfValidity: { type, dateTimeQuantum, timeQuantumGroup },
              } = record;

              if (!enable) {
                return <Badge status="warning" text={marketingLabel.未启用} />;
              }

              if (type === 'LONG_TERM') {
                return <Badge status="success" text={marketingLabel.生效中} />;
              }

              if (type === 'DATE_TIME_QUANTUM' && dateTimeQuantum) {
                const { startDateTime, endDateTime } = dateTimeQuantum;

                if (startDateTime && endDateTime) {
                  const now = moment();

                  if (now.isBefore(moment.tz(startDateTime, timeZone))) {
                    return <Badge color="geekblue" text={marketingLabel.未开始} />;
                  }

                  if (now.isAfter(moment.tz(endDateTime, timeZone))) {
                    return <Badge status="default" text={marketingLabel.已过期} />;
                  }
                }
              }

              if (type === 'TIME_QUANTUM_GROUP' && timeQuantumGroup) {
                const { startDate, endDate, startTime, endTime } = timeQuantumGroup;

                const startDateTime = `${startDate} ${startTime}`;
                const endDateTime = `${endDate} ${endTime}`;

                if (startDateTime && endDateTime) {
                  const now = moment();

                  if (now.isBefore(moment.tz(startDateTime, timeZone))) {
                    return <Badge color="geekblue" text={marketingLabel.未开始} />;
                  }
                  if (now.isAfter(moment.tz(endDateTime, timeZone))) {
                    return <Badge status="default" text={marketingLabel.已过期} />;
                  }
                }
              }

              return <Badge status="success" text={marketingLabel.生效中} />;
            },
          },
          {
            key: 'time',
            title: marketingLabel.有效期,
            width: 250,
            className: styles.preLine,
            render: (_: any, record: TR) => {
              const {
                termOfValidity: { type, dateTimeQuantum, timeQuantumGroup },
              } = record;

              if (type === 'LONG_TERM') {
                return marketingLabel.长期;
              }

              if (type === 'DATE_TIME_QUANTUM' && dateTimeQuantum) {
                const { startDateTime = '', endDateTime = '' } = dateTimeQuantum;

                return `${startDateTime} ~ ${endDateTime}`;
              }

              if (type === 'TIME_QUANTUM_GROUP' && timeQuantumGroup) {
                const {
                  startDate = '',
                  endDate = '',
                  startTime = '',
                  endTime = '',
                } = timeQuantumGroup;

                return `${startDate} ~ ${endDate}\n${marketingLabel.每天} ${startTime}-${endTime}`;
              }

              return '-';
            },
          },
        ]
      : [
          {
            key: 'stars',
            dataIndex: 'autoReviewConfig',
            width: 200,
            title: t('reviewSetting.适用评分'),
            render: (config: TR['autoReviewConfig']) => {
              const { stars = [] } = config || {};

              return stars.length ? stars.map((star) => t('review.x星')(star)).join(' ') : '-';
            },
          },
        ]),
    ...(scenario.scenario === 'NEGATIVE_REVIEW_REPLY' && platform !== 'LAZADA'
      ? [
          {
            key: 'delayDay',
            dataIndex: 'autoReviewConfig',
            width: 150,
            title: (
              <div>
                {t('aide.延时回评')}
                <Tooltip
                  title={t(
                    'aide.同一个店铺下，同时配置了同一星级的中差评自动回评与中差评挽回，支持延时触发中差评自动回评。',
                  )}
                >
                  <What />
                </Tooltip>
              </div>
            ),
            render: (config: TR['autoReviewConfig']) => {
              const { delayDay } = config || {};

              return delayDay === 0 ? '-' : t('aide.x天', { x: delayDay });
            },
          },
        ]
      : []),
    ...(isCancellingScenario
      ? [
          {
            key: 'shippingStatus',
            dataIndex: 'cancelTradeConfig',
            width: 150,
            title: t('aide.出货进度'),
            render: (cancelTradeConfig: TR['cancelTradeConfig']) => {
              const { shippingStatus = [] } = cancelTradeConfig || {};

              return shippingStatus.map((status) => {
                return <div>{shippingStatusLabelMap[status]}</div>;
              });
            },
          },
          {
            key: 'autoCancel',
            dataIndex: 'cancelTradeConfig',
            width: 150,
            title: t('aide.自动同意买家申请'),
            render: (cancelTradeConfig: TR['cancelTradeConfig']) => {
              const { autoCancel = false } = cancelTradeConfig || {};

              return (
                <Switch
                  disabled
                  checked={autoCancel}
                  checkedChildren={t('aide.开')}
                  unCheckedChildren={t('aide.关')}
                />
              );
            },
          },
        ]
      : []),
    {
      key: 'template',
      title: t('reviewSetting.发送内容'),
      width: 400,
      render: (_, record: TR) => {
        const {
          bilingualExecuteContexts = [],
          cancelTradeConfig: { autoCancel = false, contents = [] } = {},
          regionExecuteContexts = [],
        } = record;

        const { content = '', translatedContent = '' } =
          (() => {
            if (isNoRegion) {
              return regionExecuteContexts[0]?.executeContext;
            }

            if (isCancellingScenario) {
              return (
                !autoCancel
                  ? contents[0]
                  : contents.find(({ sendPoint }) => sendPoint === 'CANCELLED')
              )?.executeContext;
            }

            return bilingualExecuteContexts[0];
          })() || {};

        const chunks = getChunks({ content, translatedContent });

        if (chunks.length === 0) {
          return '-';
        }

        const isTextOnly = chunks.length === 1 && chunks[0].type === 'text';

        if (!isTextOnly) {
          const chunkTypeLabelMap = {
            text: t('phrase.文本'),
            image: t('phrase.图片'),
            video: t('phrase.视频'),
            sticker: t('phrase.表情'),
            follow: t('phrase.邀请关注'),
            qrcode: '自动发货二维码',
          };

          return chunks.map(({ type }) => `[${chunkTypeLabelMap[type]}]`).join('');
        }

        const [{ content: value, translatedContent: translatedValue = [] }] = chunks;

        return (
          <Template>
            <EditorPreviewer
              value={isValidContent(translatedValue) ? translatedValue : value}
              hoverPop
            />
          </Template>
        );
      },
    },
    {
      key: 'operation',
      title: t('remind.操作'),
      width: 300,
      fixed: 'right',
      render: (_, record: TR, index) => {
        const { id: policyId } = record;

        const { current, pageSize, total } = pagination || {};

        const isFirst = index === 0 && current === 1;
        const isLast =
          current && pageSize && total ? index + (current - 1) * pageSize + 1 === total : false;
        const isPriorityFirst = orderBy === 'priorityAsc' ? isFirst : isLast;
        const isPriorityLast = orderBy === 'priorityAsc' ? isLast : isFirst;

        return (
          <ButtonWrapper>
            <Button
              key="edit"
              disabled={!editable}
              type="link"
              icon={<FormOutlined />}
              onClick={() => onPolicyEdit(record)}
            >
              {marketingLabel.编辑}
            </Button>
            <Dropdown
              key="copy"
              disabled={!editable}
              overlay={
                <Menu onClick={(e) => onPolicyCopy(record, e.key)}>
                  {scenario.tasks.map((task) => {
                    const { taskId, taskName, rounds } = task;

                    if (rounds.length > 0 && (!isMarketing || platform === 'ALIEXPRESS')) {
                      return <Menu.Item key={rounds[0].roundId}>{taskName}</Menu.Item>;
                    }

                    return (
                      <Menu.SubMenu key={taskId} title={taskName}>
                        {rounds.map((round) => {
                          const { round: roundCount, roundId } = round;

                          return (
                            <Menu.Item key={roundId}>
                              {marketingLabel.复制到第x次(roundCount)}
                            </Menu.Item>
                          );
                        })}
                      </Menu.SubMenu>
                    );
                  })}
                </Menu>
              }
            >
              <Button disabled={!editable} type="link" icon={<CopyOutlined />}>
                {marketingLabel.复制}
              </Button>
            </Dropdown>
            <Dropdown
              key="more"
              disabled={!editable}
              overlay={
                <Menu
                  onClick={({ key }) => {
                    if (key === 'delete') {
                      onPolicyDelete(policyId);
                      return;
                    }

                    onPriorityUpdate(policyId, key as UpdatePriorityType);
                  }}
                >
                  {total !== undefined && total > 1 && (
                    <>
                      {!isPriorityFirst && (
                        <>
                          <Menu.Item key="top">{marketingLabel.置顶}</Menu.Item>
                          <Menu.Item key="up">{marketingLabel.上移}</Menu.Item>
                        </>
                      )}
                      {!isPriorityLast && (
                        <>
                          <Menu.Item key="down">{marketingLabel.下移}</Menu.Item>
                          <Menu.Item key="bottom">{marketingLabel.置底}</Menu.Item>
                        </>
                      )}
                    </>
                  )}
                  <Menu.Item key="delete">{marketingLabel.删除}</Menu.Item>
                </Menu>
              }
            >
              <Button disabled={!editable} type="link" icon={<EllipsisOutlined />}>
                {marketingLabel.更多}
              </Button>
            </Dropdown>
          </ButtonWrapper>
        );
      },
    },
  ];

  const tableRef = useRef<HTMLDivElement>(null);

  const findTargetRef = useRef(false);

  const refsCurrent = {
    activePolicyId,
    t,
  };
  const refs = useRef(refsCurrent);
  refs.current = refsCurrent;

  useEffect(() => {
    if (!showActivePolicyTip) {
      return;
    }

    const { current: table } = tableRef;

    const {
      current: { activePolicyId, t },
    } = refs;

    if (findTargetRef.current || !activePolicyId || !table || !dataSource) {
      return;
    }

    findTargetRef.current = true;

    const targetTr = table.querySelector(`tr[data-row-key='${activePolicyId}']`);

    if (!targetTr) {
      message.warn(t('aide.未找到，策略可能已被删除'));

      return;
    }

    // modal 打开后，会滚动一次，所以需要延迟滚动。
    setTimeout(() => {
      targetTr.scrollIntoView({ behavior: 'smooth' });
    }, 300);
  }, [dataSource, showActivePolicyTip]);

  return (
    <Table
      className={styles.table(activePolicyId)}
      ref={tableRef}
      rowKey="id"
      scroll={{ x: '100%' }}
      pagination={{
        ...pagination,
        showSizeChanger: activePolicyId === undefined,
      }}
      columns={columns}
      dataSource={dataSource}
      {...otherProps}
    />
  );
}

export default PolicyTable;
