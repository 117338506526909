import { useCallback, useEffect, useState } from 'react';

const DEFAULT_COOL_DOWN = 60;

interface Props {
  disabled?: boolean;
  /**
   * @returns
   * 返回false代表发送失败
   */
  fetch: () => Promise<number | undefined | never | false>;
}

const useSMSCaptcha = (props: Props) => {
  const { disabled: disabledProp, fetch } = props;
  const [loading, setLoading] = useState(false);
  const [cooldown, setCoolDown] = useState(0);
  const [times, setTimes] = useState(0);
  const [error, setError] = useState<'overflow'>(); // overflow 超限
  const disabled = disabledProp || cooldown > 0;

  const onSendSMS = useCallback(async () => {
    if (disabled) {
      return false;
    }
    let cooldown: number | undefined;
    let isSuccessed = false;
    try {
      setError(undefined);
      setLoading(true);
      const rst = await fetch();
      isSuccessed = rst !== false;
      cooldown = rst === false ? 0 : rst ?? DEFAULT_COOL_DOWN;
      setTimes((t) => t + 1);
    } catch (e) {
      cooldown = 0;
      isSuccessed = false;
    }
    setLoading(false);
    if (cooldown && cooldown > 0) {
      setCoolDown(cooldown);
    }
    return isSuccessed;
  }, [disabled, fetch]);

  useEffect(() => {
    let timeout = -1;
    if (cooldown > 0) {
      timeout = setTimeout(() => {
        setCoolDown((c) => c - 1);
      }, 1000) as unknown as number;
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [cooldown]);
  return {
    coolingdown: cooldown > 0,
    times,
    loading,
    cooldown,
    onSendSMS,
    error,
  };
};

export default useSMSCaptcha;
