import { useMemoizedFn } from 'ahooks3';
import { Form } from 'antd';
import { cloneDeep, uniq } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GenerateProductTitleBody,
  generateProductTitle,
  Language,
  BatchTranslateTitleContentBody,
  batchTranslateTitleContent,
} from 'services/api/chat.gpt';

import useApiError from './useApiError';

const { useForm } = Form;

export interface ITitleContent {
  index: [number, number];
  content: string;
  translateContent?: Record<Language, string>;
}

export interface ITitlePageContent {
  list: ITitleContent[];
  highlightKeys: string[];
}

const formInitialValues: GenerateProductTitleBody = {
  platform: 'SHOPEE',
  language: 'EN',
  category: '',
  brand: '',
  sellingPoint: '',
  listingContent: '',
  highlightInfo: '',
  weakenInfo: '',
  generateResultNumber: 3,
  titleRestriction: {
    length: 100,
    withoutSpecialCharactersAndLinks: true,
    notAllCapitalLetters: true,
    capitalizedFirstLetter: true,
    withoutPunctuation: true,
  },
  mustIncludedKeys: [],
  mustExcludeKeys: [],
};

export const useTitle = () => {
  const [titleForm] = useForm<GenerateProductTitleBody>();
  const [titleLoading, setTitleLoading] = useState(false);
  const apiError = useApiError();
  const { t } = useTranslation('ViewProductContentGenerator');

  const [titlePage, setTitlePage] = useState(-1);
  const [titlePageContent, setTitlePageContent] = useState<ITitlePageContent[]>([]);

  const [titleTranslateLoading, setTitleTranslateLoading] = useState(false);
  const [titleTranslateLang, setTitleTranslateLang] = useState<Language>('ZH_CN');

  // 更新生成缓存状态
  const updateTitlePageContent = useMemoizedFn(
    (index: [number, number], newContent: ITitleContent) => {
      // 快速索引
      const [one, two] = index;
      setTitlePageContent((prev) => {
        const clone = cloneDeep(prev);
        // 直接拷贝覆盖原有的值进行更新
        clone[one].list[two] = newContent;
        return [...clone];
      });
    },
  );

  // 更新热词列表
  const updateHighlightKeys = useMemoizedFn((index: [number, number], hotWords: string[]) => {
    const [page] = index;
    setTitlePageContent((prev) => {
      const clone = cloneDeep(prev);
      const old = clone[page].highlightKeys;
      clone[page].highlightKeys = uniq([...old, ...hotWords]);
      return [...clone];
    });
  });

  // 生成标题
  const generateTitle = useMemoizedFn(async () => {
    try {
      setTitleLoading(true);

      const values = titleForm.getFieldsValue();
      if (values) {
        const { titles, highlightKeys = [] } = await generateProductTitle({
          ...values,
        });

        setTitlePageContent((prev) => {
          return [
            ...prev,
            {
              list: titles.map((item, index) => {
                return {
                  index: [prev.length, index],
                  content: item,
                };
              }),
              highlightKeys,
            },
          ];
        });
        setTitlePage((prev) => prev + 1);
      }
    } catch (err) {
      apiError(err, t('timeoutTips'));
    } finally {
      setTitleLoading(false);
    }
  });

  // 一键翻译
  const batchTranslateTitle = useMemoizedFn(
    async (titlePageContent: ITitlePageContent, lang: Language) => {
      try {
        setTitleTranslateLoading(true);
        const values = titleForm.getFieldsValue();
        if (values && titlePageContent.list.length > 0) {
          // 构造批量翻译 title 请求对象
          const body: BatchTranslateTitleContentBody = {
            translateTitleDtos: titlePageContent.list.map((titleContent) => {
              return {
                itemTitleDto: {
                  ...values,
                  // 此时调用接口需要将表单的生成条数重置为 1
                  generateResultNumber: 1,
                },
                language: lang,
                sourceContent: titleContent.content,
              };
            }),
          };
          const { translationResponseVoList } = await batchTranslateTitleContent(body);
          titlePageContent.list.forEach((titleContent, index) => {
            updateTitlePageContent(titleContent.index, {
              ...titleContent,
              translateContent: {
                ...(titleContent.translateContent ?? {}),
                [lang]: translationResponseVoList[index].result,
              } as Record<Language, string>,
            });
          });
        }
      } catch (err) {
        apiError(err, t('timeoutTips'));
      } finally {
        setTitleTranslateLoading(false);
      }
    },
  );

  // 重新生成标题
  const reGenerateTitle = useMemoizedFn(async (titleContent: ITitleContent) => {
    try {
      const values = titleForm.getFieldsValue();
      if (values) {
        const { result, highlightKeys = [] } = await generateProductTitle({
          ...values,
          // 单条重新生成标题
          generateResultNumber: 1,
        });
        updateTitlePageContent(titleContent.index, {
          ...titleContent,
          content: result,
          // 重置翻译内容
          translateContent: undefined,
        });
        updateHighlightKeys(titleContent.index, highlightKeys);
      }
    } catch (err) {
      apiError(err, t('timeoutTips'));
    }
  });

  const resetTitlePageContent = useMemoizedFn(() => {
    setTitlePage(-1);
    setTitlePageContent([]);
    titleForm.setFieldsValue(formInitialValues);
  });

  const titlePageTotal = useMemo(() => titlePageContent.length, [titlePageContent.length]);

  useEffect(() => {
    titleForm.setFieldsValue(formInitialValues);
  }, [titleForm]);

  return {
    titleForm,
    titleLoading,
    titleTranslateLoading,
    titlePage,
    titlePageTotal,
    titlePageContent,
    titleTranslateLang,
    setTitleTranslateLang,
    setTitlePage,
    setTitleLoading,
    generateTitle,
    batchTranslateTitle,
    reGenerateTitle,
    resetTitlePageContent,
    updateTitlePageContent,
  };
};
