import mitt from 'mitt';

const nimitt = mitt();

// 独立模块以解决某些时候存在的循环依赖
export default nimitt;

let nimConnected = false;

export const isNimConnected = () => nimConnected;

type HealthStatus = 'ideal' | 'pending' | 'alive' | 'timeout' | 'error';
let healthStatus: HealthStatus = 'ideal';

export const getHealthStatus = () => healthStatus;
export const setHealthStatus = (status: HealthStatus) => {
  healthStatus = status;
};

nimitt.on('connect', () => {
  nimConnected = true;
});
nimitt.on('error', () => {
  nimConnected = false;
});
nimitt.on('disconnect', () => {
  nimConnected = false;
});
nimitt.on('willReconnect', () => {
  nimConnected = false;
});
