import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export const isSbsMode = atom<boolean>({
  key: 'isSbsMode',
  default: false,
});

export const useIsSbsMode = () => {
  return useRecoilValue(isSbsMode);
};

export const useSetIsSbsMode = () => {
  return useSetRecoilState(isSbsMode);
};
