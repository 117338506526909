import { RequestConfig } from 'utils/AxiosClient';
import chatttSDK from 'services/chattt';
import { SystemLanguage } from 'hooks/business/system/useSystem';
import productLine from 'productLine';
import { officialChannel } from 'views/ProductContentGenerator/components/config';
import { flagrClient } from '.';
import { Language } from './chat.gpt';

export enum FlagKey {
  Config = 'kyyp85q9wsgegzjbc',
  KageEnable = 'kn61ubyeuopzwdfm4',
  RefundEnable = 'kmnuap5k4161ztpnb',
  ReviewEnable = 'kihrqunhk925tbuwe',
  QaItemEnable = 'kf5jq31zax3y3557p',
  ChannelModules = 'kw4t3eed7ec4n18eu',
  WelfareCenter = 'kpr8ovmkhk76eycsg',
  Anniversary = 'k8c9i3i5jpwkyi6ng',
  DashboardPerfEnable = 'kv5r7s16zezjisup8',
  FeedbackCaseCategoryList = 'kf3irnxcrihi6d1fp',
  DashboardRankEnable = 'k3eybxn2az5t5xvyt',
  NimVersion = 'k7opgt9titjsq6rjj',
  TokoMode = 'kqhiyqxotdb9m5fyt',
  AuthShop = 'k9c8xjbvccrp3yqr3',
  Languages = 'kucu4ivtf6jnbpt7j',
}

export interface FlagrResponse<T extends Object> {
  variantAttachment: T;
}

export interface ConfigResponse {
  // 为了避免轮训的时候setState 引起重渲染，在这里增加个version，只有当轮训查到的版本和当前版本不一致时再setState
  version: string;
  contactQrcode: string;
  payQrcode: string;
  billQrcode: string;
  qaQrcode: string;
  qaRegionList: General.Region[];
  // 禁用授权代理org
  authProxyDisabledList: number[];
  latestVersion: string;
  newOrgSyncMessageIntervalSeconds: number;
  androidQrcode: string;
  iosQrcode: string;
  // 超时自动回复延迟时间最小值
  autoReplyDelayMinLimit: number;
  // 超时自动回复延迟时间最大值
  autoReplyDelayMaxLimit: number;
  // 超时自动回复冷却时间最小值
  autoReplyFrequencyMinLimit: number;
  // 超时自动回复冷却时间最大值
  autoReplyFrequencyMaxLimit: number;
  // 批量转接会话上限
  batchTransferMaxLimit: number;
  // 后端服务搜索
  onlineSearch: boolean;
  // 耗时超过一定毫秒数的请求才上报
  requestReportMinDuration: number;
  // 云信 db 开关
  nimDb: boolean;
  // 云信 dbLog 开关
  nimDbLog: boolean;
}

export interface ConfigParams {
  orgId: number;
}

export interface DashboardPerfParams {
  orgId: number;
}

export interface EnableResponse {
  enable: true;
}

export interface ChannelModulesResponse {
  /**
   * 知虾相关的渠道组件的渲染开关
   */
  zhixia: {
    /**
     * 应用中心：知虾卡片
     */
    appCenterCard: boolean;
    /**
     * 左边导航栏 知虾按钮
     */
    nav: boolean;
    /**
     * 不设置默认为true，为false时不允许访问 '/zhixia' 路由(注意nav跟应用卡片对该路由有依赖)
     */
    route: boolean;
    /**
     * 顶栏的 知虾动态icon(zhixialogo.gif)
     */
    topbarGif: boolean;
    /**
     * 订购页面 知虾的优惠推广信息(zhixiaGift 组件)
     */
    zhixiaGift: boolean;
  };
}

export interface WelfareCenterConfig {
  iconUrl: string;
  text: Record<SystemLanguage, string>;
  text2: Record<SystemLanguage, string>;
  redirectPath: string;
}

export interface WelfareCenterResponse {
  welfareCenterConfig: WelfareCenterConfig[];
}

export interface AnniversaryResponse {
  skipModal?: boolean;
}
export interface DashboardPerfResponse {
  allowed?: boolean;
  perfNav?: boolean;
}

export interface FeedbackCaseCategoryListResponse {
  list: Feedback.CaseCategory[];
}

export interface NimVersionResponse {
  version: string;
}

export interface TokoModeResponse {
  /**
   * @implements https://leyan.yuque.com/czpqnb/pd/qtw42lq49cqxnlli#EZgcs
   * default: 默认
   * official: 纯白口
   * mixing: 白口+灰口
   */
  mode: 'default' | 'mixing' | 'official';
  userAgent?: string;
}

export type AuthShopOpenMode = 'internal' | 'external';

export interface AuthShopResponse {
  // 授权打开浏览器方式
  openMode: AuthShopOpenMode;
}

export interface LanguagesResponse {
  options: Array<{ value: Language; label: string }>;
}

export interface LoginFreeResponse {
  channels: string[];
}

export interface Response {
  [FlagKey.Config]: ConfigResponse;
  [FlagKey.KageEnable]: EnableResponse;
  [FlagKey.RefundEnable]: EnableResponse;
  [FlagKey.ReviewEnable]: EnableResponse;
  [FlagKey.QaItemEnable]: EnableResponse;
  [FlagKey.ChannelModules]: ChannelModulesResponse;
  [FlagKey.WelfareCenter]: WelfareCenterResponse;
  [FlagKey.Anniversary]: AnniversaryResponse;
  [FlagKey.DashboardPerfEnable]: DashboardPerfResponse;
  [FlagKey.FeedbackCaseCategoryList]: FeedbackCaseCategoryListResponse;
  [FlagKey.DashboardRankEnable]: {
    allowed?: boolean;
  };
  [FlagKey.NimVersion]: NimVersionResponse;
  [FlagKey.TokoMode]: TokoModeResponse;
  [FlagKey.AuthShop]: AuthShopResponse;
  [FlagKey.Languages]: LanguagesResponse;
}

export interface EnableParams {
  orgId: number;
}

export interface NimVersionParams {
  orgId: number;
  userId: number;
  countryCode?: string;
}

export interface Params {
  [FlagKey.Config]: ConfigParams;
  [FlagKey.KageEnable]: EnableParams;
  [FlagKey.RefundEnable]: EnableParams;
  [FlagKey.ReviewEnable]: EnableParams;
  [FlagKey.QaItemEnable]: EnableParams;
  [FlagKey.ChannelModules]: ConfigParams;
  [FlagKey.WelfareCenter]: ConfigParams;
  [FlagKey.Anniversary]: ConfigParams;
  [FlagKey.DashboardPerfEnable]: DashboardPerfParams;
  [FlagKey.FeedbackCaseCategoryList]: any;
  [FlagKey.DashboardRankEnable]: ConfigParams;
  [FlagKey.NimVersion]: NimVersionParams;
  [FlagKey.TokoMode]: ConfigParams;
  [FlagKey.AuthShop]: ConfigParams;
  [FlagKey.Languages]: {};
}
/**
 *
 * @param key
 * @param params 请求时默认自动携带当前渠道信息
 * @param config
 * @returns
 */
function fetchFlag<T extends FlagKey>(key: T, params: Params[T], config: RequestConfig = {}) {
  return flagrClient
    .post<FlagrResponse<Response[T]>>(
      '/evaluation',
      {
        entityContext: {
          ...params,
          // 默认携带 channel
          channel: officialChannel,
          productLine,
        },
        flagKey: key,
      },
      config,
    )
    .then((res) => {
      const {
        data: { variantAttachment },
      } = res;
      return variantAttachment;
    })
    .catch(() => {});
}

export function configFlag(orgId: number) {
  return fetchFlag(FlagKey.Config, { orgId });
}

export function kageEnableFlag(orgId: number) {
  return fetchFlag(FlagKey.KageEnable, { orgId });
}

export function refundEnableFlag(orgId: number) {
  return fetchFlag(FlagKey.RefundEnable, { orgId });
}

export function reviewEnableFlag(orgId: number) {
  return fetchFlag(FlagKey.ReviewEnable, { orgId });
}

export function qaItemEnableFlag(orgId: number) {
  return fetchFlag(FlagKey.QaItemEnable, { orgId });
}

export function navFlag(orgId: number) {
  return fetchFlag(FlagKey.ChannelModules, { orgId });
}
export function anniversaryFlag(orgId: number) {
  return fetchFlag(FlagKey.Anniversary, { orgId });
}
export function dashboardPrefFlag(orgId: number) {
  return fetchFlag(FlagKey.DashboardPerfEnable, { orgId });
}

export function dashboardRankFlag(orgId: number) {
  return fetchFlag(FlagKey.DashboardRankEnable, { orgId });
}

export function feedbackCaseCategoryListFlag() {
  return fetchFlag(FlagKey.FeedbackCaseCategoryList, undefined);
}

export function nimVersionFlag(params: NimVersionParams) {
  return fetchFlag(FlagKey.NimVersion, {
    ...params,
    countryCode: chatttSDK?.app?.getLocaleCountryCode(),
  });
}

export function tokoModeFlag(params: NimVersionParams) {
  return fetchFlag(FlagKey.NimVersion, {
    ...params,
  });
}

export function authShopFlag(orgId: number) {
  return fetchFlag(FlagKey.AuthShop, { orgId });
}

export function languagesFlag() {
  return fetchFlag(FlagKey.Languages, {});
}

export default fetchFlag;
