import posthog from '@leyan/lytics';

const DYNAMIC_SAMPLE_RATES = [2, 5, 15, 20, 40, 60];

let sampleRateIdx = 0;

let sampleRate = DYNAMIC_SAMPLE_RATES[sampleRateIdx]; //  x分钟
const MAX_AVERAGE_LENGTH = 10;

let maxFPS = 0;
let minFPS = 9999;
const fpsArr: Array<number> = [];

let frameCount = 0;
let start = 0;
const resetAVG = () => {
  maxFPS = 0;
  minFPS = 9999;
  fpsArr.length = 0;
};
const avgFPS = () =>
  Math.ceil(
    fpsArr.reduce<number>((agr, nxt) => {
      return agr + nxt;
    }, 0) / fpsArr.length,
  );

const calcFPS = () => {
  const now = performance.now();
  frameCount += 1;
  if (start === 0) {
    start = now;
  } else if (now - start >= 1000) {
    fpsArr.push(frameCount);
    maxFPS = Math.max(maxFPS, frameCount);
    minFPS = Math.min(minFPS, frameCount);
    const data = {
      maxFPS,
      minFPS,
      avgFPS: avgFPS(),
    };
    posthog.capture('$web_fps', data);
    sampleRateIdx += 1;
    sampleRate = DYNAMIC_SAMPLE_RATES[sampleRateIdx];
    if (!sampleRate) {
      sampleRateIdx = 0;
      sampleRate = DYNAMIC_SAMPLE_RATES[sampleRateIdx];
    }
    if (fpsArr.length >= MAX_AVERAGE_LENGTH) {
      resetAVG();
    }
    frameCount = 0;
    start = 0;
    setTimeout(calcFPS, sampleRate * 60 * 1000);
    return;
  }
  window.requestAnimationFrame(calcFPS);
};

// calc immediately in first load
calcFPS();

export default calcFPS;
