import { Editor, Descendant } from 'slate';
import { ReactEditor, RenderElementProps } from 'slate-react';

export type SlatePlugin = (editor: Editor) => Editor;
export type RenderElement = (props: RenderElementProps) => JSX.Element;

export interface LySlatePlugin {
  elementType: string;
  slatePlugin: SlatePlugin;
  processComponent: React.ComponentType<RenderElementProps>;
}

export interface PluginParseUtils {
  editor: Editor;
  renderElement: (props: RenderElementProps) => JSX.Element;
}

export enum PackageType {
  // 淘系-千牛
  QN = 'qn',
  // 京东
  JD = 'classic',
}
export interface LabelInfo {
  label: string;
}
export interface EmoticonInfo {
  packageName: PackageType;
  code: number;
  label: string;
  shortcut: string;
}

export interface ConceptInfo extends LabelInfo {}

export interface ImageInfo {
  imageId: string | undefined;
  [key: string]: any;
}

export interface ParamTextInfo extends LabelInfo {
  canEditor: boolean;
}

export interface ParamHolderInfo extends LabelInfo {}

export enum CustomSlateNodeType {
  PARAGRAPH = 'paragraph',
  TEXT = 'text',
  DIVIDING_LINE = 'eos',
  EMOTICON = 'emoticon',
  CONCEPT = 'concept',
  IMAGE = 'image',
  VIDEO = 'video',
  STICKER = 'sticker',
  FOLLOW = 'follow',
  QRCODE = 'qrcode',
  PARAMTEXT = 'paramText',
  PARAMHOLDER = 'paramHolder',
}

export interface LyEditor extends ReactEditor {
  insertDividingLine: (node: Descendant) => void;
  insertConcept: (node: Descendant) => void;
  insertEmoticon: (node: Descendant) => void;
}
