import styled from '@emotion/styled';
import React from 'react';
import Icon from '@ant-design/icons';
import { Col, Row } from 'antd';
import { ReactComponent as CheckedSvgIcon } from '../assets/checked.svg';

const OptionItem = styled.div`
  position: relative;
  height: 100%;
  padding: 6px 12px;
  border: 1px solid #d3d7e0;
  border-radius: 4px;
  cursor: pointer;

  &.checked {
    color: var(--ant-primary-color);
    border-color: var(--ant-primary-color);
  }

  & > .anticon {
    position: absolute;
    right: -1px;
    bottom: -1px;
    font-size: 16px;
  }
`;

const Container = styled(Row)`
  & .ant-col {
    margin-bottom: 8px;
  }
`;

export interface CheckboxGroupOption {
  value: string | number;
  render?: React.ReactNode;
}

export interface CheckboxGroupProps {
  className?: string;
  value?: string | number;
  gutter?: number;
  options?: CheckboxGroupOption[];
  onChange?: (value: string | number) => void;
  layout?: 'horizontal' | 'vertical';
}
const StyledIcon = styled(Icon)`
  color: var(--fett-app-primary-color);
`;
const CheckboxGroup = (props: CheckboxGroupProps) => {
  const { className, value, options, gutter = 16, layout = 'horizontal', onChange } = props;

  return (
    <Container gutter={gutter} className={`${className || ''} ${layout}`}>
      {options?.map((option) => {
        const checked = option.value === value;

        return (
          <Col lg={layout === 'horizontal' ? 24 / options.length : 24} key={option.value}>
            <OptionItem
              className={checked ? 'checked' : ''}
              onClick={() => onChange && onChange(option.value)}
            >
              {option.render}
              {checked ? <StyledIcon component={CheckedSvgIcon} /> : null}
            </OptionItem>
          </Col>
        );
      })}
    </Container>
  );

  // return (
  //   <Space>
  //     {options?.map((option) => {
  //       const checked = option.value === value;

  //       return (
  //           <OptionItem
  //             className={checked ? 'checked' : ''}
  //             onClick={() => onChange && onChange(option.value)}
  //           >
  //             {option.render}
  //             {checked ? <StyledIcon component={CheckedSvgIcon} /> : null}
  //           </OptionItem>
  //       );
  //     })}
  //   </Space>
  // );
};

export default CheckboxGroup;
