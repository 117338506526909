import isMock from 'utils/mock';
import { doorClient, resolve, ImResponse } from '.';

export const extractFailCauseFromLastMsgContent = (talk: Conversation.Conversation) => {
  // 后端给了个坑底的数据格式，把错误原因放在了 lastMsgContent中，而lastMsgContent是个string，要先转成json才能拿到
  // 在请求的时候做个转换，方便前端使用。

  try {
    const lastMsgContent = JSON.parse(talk.lastMsgContent);
    return {
      ...talk,
      failCause: lastMsgContent.failCause,
    };
  } catch (error) {
    return {
      ...talk,
      failCause: undefined,
    };
  }
};

export enum ConversationListReplied {
  // 未处理
  Untreated = '0',
  // 已处理
  Processed = '1',
}

/** 查询会话列表 */
export function queryConversationList(params: ApiConversation.ConversationListParams) {
  if (process.env.NODE_ENV !== 'production' && isMock()) {
    return import('__data__/mock.conversation/queryConversationList').then((r) => {
      return r.default(params);
    });
  }
  return resolve(
    doorClient.get<ImResponse<ApiConversation.ConversationListResponse>>(
      '/oversea-conversation/v1/queryConversationList',
      {
        params,
        transformResponse(data) {
          try {
            const json = JSON.parse(data);
            return {
              ...json,
              data: {
                ...json.data,
                talkInfo: json.data.talkInfo.map(extractFailCauseFromLastMsgContent),
              },
            };
          } catch (error) {
            return data;
          }
        },
      },
    ),
  );
}

export function queryRemindConversationList(
  params: Omit<ApiConversation.ConversationListParams, 'offset' | 'pageSize'>,
) {
  return resolve(
    doorClient.get<ImResponse<ApiConversation.ConversationListResponse>>(
      '/oversea-conversation/v1/queryRemindConversationList',
      {
        params,
        transformResponse(data) {
          try {
            const json = JSON.parse(data);
            return {
              ...json,
              data: {
                ...json.data,
                talkInfo: json.data.talkInfo.map(extractFailCauseFromLastMsgContent),
              },
            };
          } catch (error) {
            return data;
          }
        },
      },
    ),
  );
}

/** 查询单个会话, 推来消息,本地无会话时调用 */
export function getTalkInfo(params: ApiConversation.GetTalkInfoParam) {
  if (process.env.NODE_ENV !== 'production' && isMock()) {
    return import('__data__/mock.conversation/getTalkInfo').then((r) => {
      return r.default(params);
    });
  }
  return resolve(
    doorClient.get<ImResponse<Conversation.Conversation>>('/aggregation/v1/getTalkInfo', {
      params,
      transformResponse(data) {
        try {
          const json = JSON.parse(data);
          return {
            ...json,
            data: extractFailCauseFromLastMsgContent(json.data),
          };
        } catch (error) {
          return data;
        }
      },
    }),
  );
}

export function getTalkInfoWithPhone(
  params: ApiConversation.GetTalkInfoParam & { needPhone?: boolean },
) {
  return resolve(
    doorClient.get<ImResponse<Conversation.Conversation>>('/aggregation/v1/getAggTalk', {
      params,
      transformResponse(data) {
        try {
          const json = JSON.parse(data);
          return {
            ...json,
            data: {
              ...extractFailCauseFromLastMsgContent(json.data.talk),
              phone: json.data.phone,
            },
          };
        } catch (error) {
          return data;
        }
      },
    }),
  );
}

/** 会话转发 */
export function transferConversation(params: ApiConversation.TransferConversationParam) {
  if (process.env.NODE_ENV !== 'production' && isMock()) {
    return import('__data__/mock.conversation/transferConversation').then((r) => {
      return r.default(params);
    });
  }
  return resolve(
    doorClient.post<ImResponse<undefined>>('/oversea-conversation/v1/transferConversation', params),
  );
}

/** 批量转发会话 */
export function batchTransferConversation(
  payload: ApiConversation.BatchTransferConversationPayload,
) {
  return resolve(
    doorClient.post<ImResponse<undefined>>('/oversea-conversation/v1/batchTransfer', payload),
  );
}

/** 更新会话 */
export function updateConversation(params: ApiConversation.UpdateConversationParam) {
  if (process.env.NODE_ENV !== 'production' && isMock()) {
    return import('__data__/mock.conversation/updateConversation').then((r) => {
      return r.default(params);
    });
  }
  return resolve(
    doorClient.post<ImResponse<undefined>>('/oversea-conversation/v1/updateConversation', params),
  );
}
export function updateConversationV2(params: ApiConversation.UpdateConversationV2Param) {
  return resolve(
    doorClient.post<ImResponse<ApiConversation.UpdateConversationResData>>(
      '/oversea-conversation/v1/updateConversationV2',
      params,
    ),
  );
}

/** 不在ChatPlusAI回复的消息同步,每隔2分钟调用 */
export function syncMessage(params: ApiConversation.SyncMessageParam) {
  if (process.env.NODE_ENV !== 'production' && isMock()) {
    return import('__data__/mock.conversation/syncMessage').then((r) => {
      return r.default(params);
    });
  }
  return resolve(
    doorClient.post<ImResponse<undefined>>('/oversea-conversation/v1/syncMessage', params),
  );
}

/** 获取店铺列表信息 */
export function getStoreList() {
  return resolve(
    doorClient.get<ImResponse<ApiConversation.StoreListResponse>>(
      '/oversea-conversation/v1/getStoreList',
      {},
    ),
  );
}

/** 查询接待统计信息 */
export function queryReceptionBuyerStat(params: ApiConversation.ReceptionBuyerStatParam) {
  if (process.env.NODE_ENV !== 'production' && isMock()) {
    return import('__data__/mock.conversation/queryReceptionBuyerStat').then((r) => {
      return r.default(params);
    });
  }
  return resolve(
    doorClient.get<ImResponse<Conversation.ReceptionBuyerStat>>(
      '/oversea-conversation/v1/queryReceptionBuyerStat',
      { params },
    ),
  );
}

/** 本地搜索: message同步 https://leyan.yuque.com/czpqnb/yzt2rg/awac1v */
export function pullMessage(params: ApiConversation.PullMessageParam) {
  if (process.env.NODE_ENV !== 'production' && isMock()) {
    return import('__data__/mock.conversation/pullMessage').then((r) => {
      return r.default(params);
    });
  }
  return resolve(
    doorClient.post<ImResponse<ApiConversation.PullMessageResponse>>(
      '/oversea-conversation/v1/pullMessage',
      params,
    ),
  );
}

export interface ISearchKeyword {
  type: 'all' | 'buyer' | 'order' | 'item' | 'msg';
  keyword: string;
  offset?: number | string;
  pageSize: number;
}
export interface ISearchResult {
  buyerId: string;
  buyerNick: string;
  conversationId: string;
  externalBuyerId: string;
  externalStoreId: string;
  keyword: string;
  lastMsgId: string;
  lastMsgContent: string;
  lastMsgTime: string;
  lastMsgType: string;
  platform: General.PlatformUpperCase;
  region: General.UpperCaseRegion;
  storeAlias: string;
  storeId: string;
  storeName: string;
  hasTalk: boolean;
  yunXinTid: string;
  tunnelAccountId: string;
  targetMsgContent: string;
  targetMsgId: string;
  targetMsgTime: string;
  targetMsgType: string;
}
export interface ISearchKeywordRst {
  buyerSearchResult: {
    hasNext: boolean;
    searchRow: ISearchResult[];
  };
  orderSearchResult: {
    hasNext: boolean;
    searchRow: ISearchResult[];
  };
}
export function searchKeyword(params: ISearchKeyword) {
  return resolve(
    doorClient.get<ImResponse<ISearchKeywordRst>>('/oversea-conversation/v1/search/searchKeyword', {
      params,
    }),
  );
}

export interface ISearchKeywordResult {
  hasNext: boolean;
  searchRow: ISearchResult[];
  nextOffset: string;
}

export interface ISearchAllResult {
  buyerSearchResult: ISearchKeywordResult;
  orderSearchResult: ISearchKeywordResult;
  itemSearchResult: ISearchKeywordResult;
  msgSearchResult: ISearchKeywordResult;
}

export function searchAll(params: Omit<ISearchKeyword, 'type'>) {
  return resolve(
    doorClient.get<ImResponse<ISearchAllResult>>('/oversea-conversation/v1/search/searchAll', {
      params,
    }),
  );
}

export function searchType(params: ISearchKeyword) {
  return resolve(
    doorClient.get<ImResponse<ISearchKeywordResult>>('/oversea-conversation/v1/search/searchType', {
      params,
    }),
  );
}
