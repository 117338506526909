const PRODUCT_LINES: Array<Oversea.ProductLine> = ['chatpp', 'aeplugin'];

/**
 * 只用于非生产环境，进行快速切换
 */
const PRODUCT_CHACHE_KEY = '@fett/productLine';
/**
 * 只用于非生产环境，进行快速切换
 */
const getCacheProdutLine = () => {
  const cachedLine = localStorage.getItem(PRODUCT_CHACHE_KEY);
  return (cachedLine && PRODUCT_LINES.find((t) => t === cachedLine)) || 'chatpp';
};

const isProductionApp = process.env.REACT_APP_ENV === 'production';
/**
 * @description orgId : productionLin(1:n)
 * @description 对org添加产品线属性以细化不同平台、产品、用户之间的关系及定价体系的划分
 */
const getProductLine = (): Oversea.ProductLine => {
  if (isProductionApp) {
    // 速卖通：aeplugin.zhisuitech.com
    // 处理新逻辑时需要考虑离线包的host: file://盘符:/xxx
    return window.location.host !== 'aeplugin.zhisuitech.com' ? 'chatpp' : 'aeplugin';
  }
  return getCacheProdutLine();
};

const productLine = getProductLine();
export default productLine;

if (!isProductionApp) {
  /**
   * 只用于非生产环境，进行快速切换
   */
  const getNextProductLine = () => {
    const c = getCacheProdutLine();
    const idx = PRODUCT_LINES.findIndex((t) => t === c);
    return PRODUCT_LINES[idx + 1] || PRODUCT_LINES[0];
  };

  // @ts-expect-error
  window.switchProductLine = (pl?: Oversea.ProductLine) => {
    let target: Oversea.ProductLine = pl ?? 'chatpp';
    if (pl) {
      if (PRODUCT_LINES.every((t) => t !== pl)) {
        // eslint-disable-next-line no-console
        console.warn('输入了无效的ProductLine，请输入以下的任意一个值', PRODUCT_LINES.join(','));
        // eslint-disable-next-line no-console
        console.warn('或不输入任何参数可直接toggle到一下一个ProductLine');
        // eslint-disable-next-line no-console
        console.warn(
          '若使用toggle，当前ProductLine 为<%s>，下一个为<%s>',
          getCacheProdutLine(),
          getNextProductLine(),
        );
        return;
      }
    } else {
      target = getNextProductLine();
    }
    localStorage.setItem('@fett/productLine', target);
    window.location.reload();
  };
  // eslint-disable-next-line no-console
  console.info('current product line:', productLine);
}
