import { nimEventReport } from 'components/Nim/nimReport';

type NimInstance = Nim.NimInstance;
type ParamWithNimDone = { done: (err: any, data: any) => any; [x: string]: any };
type MethodsWithDone = (arg: ParamWithNimDone, ...args: any[]) => any;
type PromisifableMethods = Util.PickByTypeLike<NimInstance, MethodsWithDone>;
export type PromisifableKeys = keyof PromisifableMethods;

const promisify = (__instance__: Nim.NimInstance | null, key: PromisifableKeys) => {
  if (!__instance__) {
    throw new Error('should not call a Nim instance method before initail');
  }
  const cb = __instance__[key];
  type CB = typeof cb;
  type Args = Parameters<CB>;
  type Done = Args[0]['done'];
  type Return = Parameters<Done>[1];
  type FirstArg = Omit<Args[0], 'done'>;
  type RestArgs = Util.OmitFirst<Args>;
  return (first: FirstArg, ...rest: RestArgs): Promise<Return> => {
    return new Promise((resolve, reject) => {
      const done: Done = (err: any, data: Return) => {
        if (err) {
          nimEventReport('nim_promisify_catch', {
            error: err,
            event: key,
            args: [first].concat(rest),
          });
          reject(new Error(err));
        } else {
          resolve(data);
        }
      };
      cb.call(__instance__, { ...first, done }, ...rest);
    });
  };
};
export default promisify;
