import { message } from 'antd';
import { useAuthorization } from 'components/Authorization';
import { useMemoizedFn } from 'ahooks3';
import { noop } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { atom, selector, useRecoilValue, useSetRecoilState } from 'recoil';
import { getUserRoles, Role } from 'services/api/department/roleWithDepartment';
import useIsSpVer from './spVer/useIsSpVer';
import { useOrgConfigStateValue } from './useOrgConfig';
import usePermission from './usePermission';

export { permissionNameIdMap } from 'services/api/department/permissions';

export const newPermissionState = atom<{
  newPermissionEnable?: boolean;
  roles: Role[];
  loaded: boolean;
}>({
  key: 'newPermissionState',
  default: {
    newPermissionEnable: true,
    roles: [],
    loaded: false,
  },
});

export const useUpdateUserRoles = () => {
  const setNewPermissionState = useSetRecoilState(newPermissionState);

  return useMemoizedFn(() => {
    return getUserRoles()
      .then(({ roles }) => {
        setNewPermissionState({
          newPermissionEnable: true,
          roles,
          loaded: true,
        });
      })
      .catch(() => {
        setNewPermissionState((pre) => {
          if (pre.loaded) {
            return pre;
          }

          return {
            ...pre,
            loaded: true,
          };
        });

        message.error('获取用户权限失败');
      });
  });
};

export const useSetupNewPermission = () => {
  const { payload } = useAuthorization<Oversea.Token.Payload>();

  const { orgId } = payload || {};

  const updateUserRoles = useUpdateUserRoles();

  useEffect(() => {
    if (!orgId) {
      return noop;
    }

    const timer = setInterval(() => {
      updateUserRoles();
    }, 1000 * 60 * 60 * 2);

    updateUserRoles();

    return () => {
      clearInterval(timer);
    };
  }, [orgId, updateUserRoles]);
};

const mergeRegionScopes = (
  current: General.StoreScope['regionStoreScopes'],
  next: General.StoreScope['regionStoreScopes'],
) => {
  return [...current, ...next].reduce<General.StoreScope['regionStoreScopes']>((res, cur) => {
    const { region, storeCount, storeIds } = cur;

    const regionTarget = res.find((_) => _.region === region);

    if (!regionTarget) {
      res.push({ ...cur });

      return res;
    }

    regionTarget.storeCount += storeCount;
    regionTarget.storeIds = [...(regionTarget.storeIds || []), ...(storeIds || [])];

    return res;
  }, []);
};

const mergePlatformScopes = (current: General.StoreScope[], next: General.StoreScope[]) => {
  return [...current, ...next].reduce<General.StoreScope[]>((res, cur) => {
    const { platform, regionStoreScopes, storeCount } = cur;

    const platformTarget = res.find((_) => _.platform === platform);

    if (!platformTarget) {
      res.push({ ...cur });

      return res;
    }

    platformTarget.regionStoreScopes = mergeRegionScopes(
      platformTarget.regionStoreScopes,
      regionStoreScopes,
    );
    platformTarget.storeCount += storeCount;

    return res;
  }, []);
};

export type NewPermissionMap = Partial<Record<General.PermissionName, General.StoreScope[]>>;

export const newPermissionMapState = selector({
  key: 'newPermissionMapState',
  get: ({ get }) => {
    const { roles } = get(newPermissionState);

    return roles.reduce<NewPermissionMap>((res, role) => {
      return role.permissionWithScopes.reduce((res, { permissionName, platformStoreScopes }) => {
        res[permissionName] = mergePlatformScopes(res[permissionName] || [], platformStoreScopes);

        return res;
      }, res);
    }, {});
  },
});

export interface GetPermissionProps {
  newPermissionEnable?: boolean;
  newPermissionMap: NewPermissionMap;
  permissionName: General.PermissionName;
  platform?: General.PlatformUpperCaseTunnel;
  region?: General.UpperCaseRegion;
  storeId?: number;
  fallbackPermission?: boolean;
}

export const getPermission = (props: GetPermissionProps) => {
  const {
    newPermissionEnable,
    newPermissionMap,
    permissionName,
    platform,
    region,
    storeId,
    fallbackPermission = true,
  } = props;

  if (newPermissionEnable === undefined) {
    return false;
  }

  if (newPermissionEnable === false) {
    return fallbackPermission;
  }

  const { [permissionName]: scopes } = newPermissionMap;

  if (scopes === undefined) {
    return false;
  }

  if (platform === undefined) {
    return true;
  }

  const platformTarget = scopes.find((scope) => scope.platform === platform);

  if (platformTarget === undefined || !platformTarget.storeCount) {
    return false;
  }

  if (region === undefined) {
    return true;
  }

  const regionTarget = platformTarget.regionStoreScopes.find(
    (scope) => scope.region === 'all' || scope.region === region,
  );

  if (regionTarget === undefined || !regionTarget.storeCount) {
    return false;
  }

  if (storeId === undefined) {
    return true;
  }

  const { storeIds = [] } = regionTarget;

  return storeIds.length === 0 || storeIds.includes(storeId);
};

export const useGetPermission = () => {
  const { newPermissionEnable } = useRecoilValue(newPermissionState);

  const newPermissionMap = useRecoilValue(newPermissionMapState);

  return useCallback(
    (
      permissionName: General.PermissionName,
      props?: Pick<GetPermissionProps, 'platform' | 'region' | 'storeId' | 'fallbackPermission'>,
    ) => {
      return getPermission({
        newPermissionEnable,
        newPermissionMap,
        permissionName,
        ...props,
      });
    },
    [newPermissionEnable, newPermissionMap],
  );
};

export const useRouteAccessProps = () => {
  const isSpver = useIsSpVer();

  const permissionMap = usePermission();

  const orgConfig = useOrgConfigStateValue();

  // const orgValue = useOrgValue();

  const { newPermissionEnable } = useRecoilValue(newPermissionState);

  const newPermissionMap = useRecoilValue(newPermissionMapState);

  return useMemo(() => {
    return {
      isSpver,
      permissionMap,
      orgConfig,
      orgValue: undefined,
      newPermissionEnable,
      newPermissionMap,
    };
  }, [isSpver, permissionMap, orgConfig, newPermissionEnable, newPermissionMap]);
};
